import React from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortalWrapper from './PortalWrapper';
import { getFieldConfigByResourceName } from '../utils/utils';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FieldConfig } from 'c1g-ui-library';

interface StatusDropdownButtonProps {
    resourceName?: string;
    status: number;
    onStatusChange: (status: number) => void;
    fieldConfigs?: FieldConfig[];
    statusColorMap: Record<number, string>;
    statusIconMap: Record<number, IconDefinition>;
    filterEnum: Record<number, string>; // New prop for manual text mapping
    disabled?: boolean
}

/**
 * StatusDropdownButton Component
 *
 * A flexible dropdown button that allows the user to update the status of a resource.
 * If fieldConfigs are provided, it will derive options dynamically; otherwise, it falls
 * back to manually provided statusTextMap.
 *
 * Props:
 * - resourceName (string): Name of the resource.
 * - status (number): Current status code.
 * - onStatusChange (function): Callback when a new status is selected.
 * - fieldConfigs (FieldConfig[], optional): Array of field configurations (if applicable).
 * - statusColorMap (Record<number, string>): Maps status codes to color classes.
 * - statusIconMap (Record<number, IconDefinition>): Maps status codes to icons.
 * - filterEnum (Record<number, string>): Maps status codes to text labels (fallback).
 */
const StatusDropdownButton: React.FC<StatusDropdownButtonProps> = ({
    resourceName,
    status,
    onStatusChange,
    fieldConfigs,
    statusColorMap,
    statusIconMap,
    filterEnum,
    disabled
}) => {
    // Try to get field configuration, otherwise fallback to manually passed text map
    const fieldConfig: FieldConfig | {} = getFieldConfigByResourceName(fieldConfigs, resourceName ?? '') ?? {};
    const options = (fieldConfig as FieldConfig).options ?? filterEnum;

    return (
        <Dropdown>
            <Dropdown.Toggle
                disabled={disabled}
                as={Button}
                variant="link"
                id="dropdown-basic"
                className={`bg-grey w-100 ${statusColorMap[status]} text-wrap`}
            >
                <span className="d-flex align-items-center">
                    {<FontAwesomeIcon icon={statusIconMap[status]} className="me-2" />}
                    {options[status] || 'Status'}
                </span>
            </Dropdown.Toggle>
            <PortalWrapper>
                <Dropdown.Menu>
                    {Object.entries(options)
                        .filter(([statusKey]) => Number(statusKey) !== status)
                        .map(([statusKey, statusValue]) => (
                            <Dropdown.Item
                                key={statusKey}
                                onClick={() => onStatusChange(Number(statusKey))}
                                className="d-flex align-items-center"
                            >
                                <FontAwesomeIcon
                                    width={15}
                                    icon={statusIconMap[Number(statusKey)]}
                                    className={`me-2 ${statusColorMap[Number(statusKey)]}`}
                                />
                                {statusValue as string}
                            </Dropdown.Item>
                        ))}
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default StatusDropdownButton;
