import { AxiosError } from 'axios';
import { ApiClient, FieldConfig, NotificationToast, useDocumentTitle, usePermissions, useToast } from 'c1g-ui-library';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import EducationDetails from '../../components/educations/detail/EducationDetails';
import { Education, Permissions } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';
import { fetchAndCombineFieldConfigs } from '../../utils/utils';

function EducationDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'ClientOffice', moduleTranslations: moduleTranslations });;
  const { educationId } = useParams();
  const [education, setEducation] = useState<Education>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();
  const { show, message, error, showToast, hideToast } = useToast();
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewEducations, 'read');

      if (hasPermission) {
        educationId && fetchEducation(educationId);
        if (!fieldConfigs || fieldConfigs.length === 0) {
          fetchAndCombineFieldConfigs(['educations'], setFieldConfigs);
        }
      } else {
        navigate('/errors/error404');
      }
    }
  }, [educationId, permissionsLoaded]);

  const fetchEducation = async (educationId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/educations/${educationId}`);
      const educationResponse = response.data;
      setEducation(educationResponse);
      setDynamicTitle(educationResponse.title)
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setEducation(undefined);
    educationId && fetchEducation(educationId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <EducationDetails education={education} isLoading={isLoading} onSubmitSuccess={handleModalSubmit} fieldConfigs={fieldConfigs}></EducationDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default EducationDetail;
