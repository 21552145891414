import React, { ChangeEvent, FormEvent, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Spinner,
} from 'react-bootstrap';

import { ApiClient } from 'c1g-ui-library';
import { Deal } from '../../../interfaces';
import FormGroup from '../../form/FormGroup';
import SelectGroup from '../../form/SelectGroup';
import { getFieldOptions } from '../../../utils/utils';
import { FieldConfig } from 'c1g-ui-library';
import { AxiosError } from 'axios';

type DealAbortModalProps = {
    modalTitle: string;
    deal?: Deal;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    onModalClose: () => void;
    fieldConfigs: FieldConfig[];
};

interface FormValues {
    abortDate: string | null
    abortReason: string | null
    abortNotice: string
}

/**
 * DealAbortModal Component
 * 
 * This component renders a modal to handle the process of aborting a deal.
 * It includes a form with fields for specifying the abort date, reason, and an optional comment.
 * Upon submission, the data is sent to the API to update the deal status to "aborted."
 *
 * Props:
 * - modalTitle: Title of the modal
 * - deal: The deal object being edited, containing information like abortDate and abortReason
 * - onSubmitSuccess: Callback function executed after a successful or failed submission
 * - onModalClose: Function to close the modal
 * - fieldConfigs: Array of field configurations used to populate select options and other fields
 */
const DealAbortModal: React.FC<
    DealAbortModalProps
> = ({ modalTitle, deal, onSubmitSuccess, onModalClose, fieldConfigs }) => {
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        abortDate: deal?.abortDate ?? null,
        abortReason: deal?.abortReason ?? null,
        abort: true,
        abortNotice: '',
    });

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                await ApiClient.put(`/deals/${deal?.id}`, formValues);
                onSubmitSuccess('Erfolgreich gespeichert', false);
            } catch (error: any) {
                console.error(error.message as AxiosError);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { id, value } = e.target;
        setFormValues((prev) => ({ ...prev, [id]: value }));
    };

    return (
        <>
            <Modal
                centered
                size="lg"
                show={true}
                onHide={onModalClose}
                backdrop="static"
                keyboard={false}
                fullscreen='lg-down'
            >
                <Modal.Header>
                    <div className='text-black'>
                        <Modal.Title>
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        <p>Der Deal kann nachträglich wieder fortgesetzt werden.</p>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>

                        <Row>
                            <Col>
                                <FormGroup<FormValues>
                                    id="abortDate"
                                    label={'Datum des Abbruchs'}
                                    type="date"
                                    value={formValues.abortDate}
                                    onChange={(e) => setFormValues({ ...formValues, abortDate: e.target.value })}
                                    required
                                />
                                <SelectGroup<FormValues>
                                    id="abortReason"
                                    label="Grund des Abbruchs"
                                    options={[{ value: '', label: 'Bitte auswählen', }, ...getFieldOptions(fieldConfigs, 'lastDeal.abortReason')]}
                                    value={formValues.abortReason}
                                    onChange={handleInputChange}
                                    required
                                />
                                <FormGroup<FormValues>
                                    id="abortNotice"
                                    label='Kommentar'
                                    type="textarea"
                                    value={formValues.abortNotice}
                                    onChange={(e) => setFormValues({ ...formValues, abortNotice: e.target.value })}
                                    noResize
                                    required
                                />
                            </Col>
                        </Row>

                        <div className='d-flex justify-content-end'>
                            <Button className='btn-soft-primary me-3' onClick={onModalClose}>
                                Nicht jetzt
                            </Button>

                            <Button
                                disabled={isLoading}
                                variant="danger"
                                type="submit"
                            >
                                Deal abbrechen
                                {isLoading && (
                                    <Spinner
                                        className="ms-2"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></Spinner>
                                )}
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DealAbortModal;
