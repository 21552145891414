import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';

type InfoValue = any

interface Info {
  label: string;
  value: InfoValue;
  isMultiline?: boolean;
  isImage?: boolean;
  firstColumnSize?: number
  link?: string
}

interface GenericInfosCardProps {
  imageSrc?: string;
  title?: string;
  infos: Info[];
  showHorizontalLine?: boolean;
  cardHeight?: boolean;
  children?: ReactNode;
  firstColumnSize?: number
}

const GenericInfosCard: React.FC<GenericInfosCardProps> = ({
  imageSrc,
  title,
  infos,
  showHorizontalLine = false,
  cardHeight = true,
  children,
  firstColumnSize = 4,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false)
  }, [imageSrc]);

  const imageSkeleton = (
    <div className="skeleton-loader skeleton-image mb-3" style={{ height: '200px' }}></div>
  );

  return (
    <Card className={`card-block card-stretch ${cardHeight && 'card-height'}`}>
      {children}
      <Card.Body>

        {imageSrc && <>
          {!imageLoaded && imageSkeleton}
          <img
            className="img-fluid w-100 mb-3 rounded generic-info-card-image"
            src={imageSrc}
            alt={title || ''}
            style={{ display: imageLoaded ? 'block' : 'none' }}
            onLoad={() => setImageLoaded(true)}
          />
        </>
        }
        {title && <h5 className="mb-3">{title}</h5>}
        {showHorizontalLine && <div className="horizontal-line my-3"></div>}
        {infos.map((info, index) => (
          <DetailRow
            key={index}
            label={info.label}
            value={info.value}
            isMultiline={info.isMultiline}
            isImage={info.isImage}
            firstColumnSize={firstColumnSize}
            link={info.link}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

interface DetailRowProps extends Info { }

const DetailRow: React.FC<DetailRowProps> = ({
  label,
  value,
  isMultiline = false,
  isImage = false,
  firstColumnSize,
  link
}) => {
  const [showFullText, setShowFullText] = useState<boolean>(false);
  const maxLength = 100;

  interface Section {
    heading: string;
    items: string[];
  }

  const parseContent = (content: string): Section[] => {
    const sections = content.split('##').filter(Boolean);
    return sections.map((section) => {
      const parts = section.split('#').filter(Boolean);
      const heading = parts.shift()!;
      const items = parts;
      return { heading, items };
    });
  };

  const renderValue = (value: InfoValue) => {
    if (typeof value === 'string' && value.includes('##')) {
      const content = parseContent(value);
      const firstTwoItems = content.slice(0, 2);

      if (value.length > maxLength && !showFullText) {
        return (
          <div className="text-black">
            {firstTwoItems.map((section, index) => (
              <div key={index}>
                {section.items.length === 0 ? (
                  <strong style={{ textDecoration: 'underline' }}>
                    {section.heading}
                  </strong>
                ) : (
                  <p className="p-0 mb-1">{section.heading}</p>
                )}
                <ul className="pb-0 mb-0">
                  {section.items.map((item, itemIndex) => (
                    <li key={itemIndex}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
            <div className="text-end">
              <Button
                size="sm"
                variant="link p-0 m-0"
                onClick={() => setShowFullText(true)}
              >
                Mehr Anzeigen
              </Button>
            </div>
          </div>
        );
      } else {
        return (
          <div className="text-black">
            {content.map((section, index) => (
              <div key={index}>
                {section.items.length === 0 ? (
                  <strong style={{ textDecoration: 'underline' }}>
                    {section.heading}
                  </strong>
                ) : (
                  <p className="p-0 mb-1">{section.heading}</p>
                )}
                <ul>
                  {section.items.map((item, itemIndex) => (
                    <li key={itemIndex}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        );
      }
    } else if (typeof value === 'string' && isMultiline) {
      return (
        <p
          className="text-black text-wrap text-break"
          dangerouslySetInnerHTML={{ __html: value }}
        ></p>
      );
    }
    else if (typeof value === 'string' && link) {
      return (
        <a href={link.toString()} target="_blank" rel="noreferrer" className='text-black text-decoration-none' style={{ color: 'inherit' }}>
          {value} {value !== '-' && <FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
        </a>
      );
    } else {
      if (
        typeof value === 'string' &&
        value.length > maxLength &&
        !showFullText
      ) {
        return (
          <>
            <p className="text-black text-wrap text-break">
              {value.substring(0, maxLength)}{' '}
              <Button
                size="sm"
                variant="link p-0 m-0 align-baseline"
                onClick={() => setShowFullText(true)}
              >
                Mehr Anzeigen
              </Button>
            </p>
          </>
        );
      } else {
        return <p className="text-black text-wrap text-break">{value}</p>;
      }
    }
  };

  return (
    <Row className="mb-2">
      <Col lg={firstColumnSize}>
        <p className="font-weight-bold">{label}</p>
      </Col>

      {isImage ? (
        <Col>
          <div className='border rounded p-2'>

            <img width='auto' height={70} src={value}></img>
          </div>

        </Col>
      ) : (
        <Col>{renderValue(value)}</Col>
      )}
    </Row>
  );
};

export default GenericInfosCard;
