import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, ColumnMapping, FieldConfig, NotificationToast, SortCaret, usePermissions, useResetUrlParams, useSelection, useSortableData, useTableHeight, useToast } from 'c1g-ui-library';
import {
  memo,
  useEffect,
  useState,
} from 'react';
import { Button, Col, Dropdown, Form, Row, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ColumnSelection from '../../components/ColumnSelection';
import {
  ComboButtonId,
} from '../../components/ComboButtonGroup';
import ParticipantInitialsComponent from '../../components/ParticipantInitialsComponent';
import PortalWrapper from '../../components/PortalWrapper';
import ResetFiltersButton from '../../components/ResetFilterButton';
import SearchInput from '../../components/SearchInput';
import ViewSelector from '../../components/ViewSelector';
import Card from '../../components/bootstrap/card';
import DateRangeDropdownFilter from '../../components/filter/DateRangeDropdownFilter';
import GenericDropdownFilter from '../../components/filter/GenericDropdownFilter';
import { abortReasonColorMap, abortReasonIconMap, bgsStatusColorMap, bgsStatusIconMap, dealStatusColorMap, dealStatusIconMap, timeModelColorMap, timeModelIconMap, wvStatusColorMap, wvStatusIconMap } from '../../components/filter/iconAndColorMappings';
import SelectionActions from '../../components/participants/SelectionActions';
import { DefaultColumnRender } from '../../components/table/DefaultColumnRender';
import DynamicPagination from '../../components/table/DynamicPagination';
import PaginationInfo from '../../components/table/PaginationInfo';
import SaveCancelPopup from '../../components/table/SaveCancelPopup';
import TableNoDataMessage from '../../components/table/TableNoDataMessage';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import { defaultRenderHeader } from '../../components/table/utils';
import { Participant, Permissions } from '../../interfaces';
import {
  getEnumValue,
  PermissionsEnum,
} from '../../utils/enum';
import { addPrefixToFilters, fetchAndCombineFieldConfigs, getFieldConfigByResourceName, getInitials } from '../../utils/utils';

export interface ParticipantFilters {
  dealStatus: string | number | null
  wvStatus: string | number | null
  bgsStatus: string | number | null
  timeModel: string | number | null
  abortReason: string | number | null
  startDate: string | null
  endDate: string | null
}

interface ParticipantsResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  searchFilters: string[];
  list: Participant[];
}

const Participants = memo(() => {
  useTableHeight();
  const navigate = useNavigate();
  const resetUrlParams = useResetUrlParams();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedSearchColumn, setSelectedSearchColumn] = useState<ComboButtonId | ''>('all');
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [lastQueryParams, setLastQueryParams] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const [showPopup, setShowPopup] = useState(false);
  const { requestSort, sortConfig, setSortConfig, getSortDirection } = useSortableData(participants, showPopup);
  const [pendingChanges, setPendingChanges] = useState<{ [key: string]: any }>({});
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const [editableCell, setEditableCell] = useState<{ rowId: number | null; columnKey: string | null }>({
    rowId: null,
    columnKey: null,
  });
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<ParticipantFilters>({
    dealStatus: null,
    wvStatus: null,
    bgsStatus: null,
    timeModel: null,
    abortReason: null,
    startDate: null,
    endDate: null
  });

  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const {
    selectedItems: selectedParticipants,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow
  } = useSelection(participants);

  // Fetch the list of participants based on the current filters, search, sort and pagination
  const fetchParticipants = async () => {
    setParticipants([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedFilters.dealStatus?.toString()) {
      queryParams += `&lastDeal.status=${selectedFilters.dealStatus?.toString()}`;
    }

    if (selectedFilters.wvStatus?.toString()) {
      queryParams += `&lastDeal.wvStatus=${selectedFilters.wvStatus?.toString()}`;
    }

    if (selectedFilters.bgsStatus?.toString()) {
      queryParams += `&lastDeal.bgsStatus=${selectedFilters.bgsStatus?.toString()}`;
    }

    if (selectedFilters.timeModel?.toString()) {
      if (selectedFilters.timeModel === 'besidesTheJob') {
        queryParams += `&lastDeal.besidesTheJob=true`;
      } else {
        queryParams += `&lastDeal.timeModel=${selectedFilters.timeModel?.toString()}`;
      }
    }

    if (selectedFilters.abortReason?.toString()) {
      queryParams += `&lastDeal.abortReason=${selectedFilters.abortReason?.toString()}`;
    }

    if (selectedFilters.startDate?.toString()) {
      queryParams += `&filter_period[start]=${selectedFilters.startDate?.toString()}`;
    }

    if (selectedFilters.endDate?.toString()) {
      queryParams += `&filter_period[end]=${selectedFilters.endDate?.toString()}`;
    }

    if (limit.toString()) {
      queryParams += `&limit=${limit}`;
    }
    if (searchQuery) {
      queryParams += `&search=${encodeURIComponent(searchQuery)}`;
      if (selectedSearchColumn !== 'all') {
        queryParams += `&column=${encodeURIComponent(selectedSearchColumn)}`;
      }
    }

    if (sortConfig?.field) {
      queryParams += `&sort[field]=${encodeURIComponent(sortConfig.field)}`;
      queryParams += `&sort[type]=${sortConfig.type}`;
    }

    try {
      const response = await ApiClient.get(`/participants${queryParams}`);
      setLastQueryParams(queryParams)
      const participantResponse = response.data as ParticipantsResponse;
      setTotalPages(participantResponse.amountPages);
      setParticipants(participantResponse.list ?? []);
      setCurrentPage(participantResponse.page);
      setLimit(participantResponse.itemsPerPage);
      setTotalEntries(participantResponse.amountAllItems);
      setAvailableFilter(addPrefixToFilters(participantResponse.searchFilters, 'participants'));
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to trigger fetching of participants when filters, search, paginationm or other dependenies change
  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'read');

      if (hasPermission) {
        fetchParticipants();

        // Fetch only once
        if (!fieldConfigs || Object.keys(fieldConfigs).length === 0) {
          fetchAndCombineFieldConfigs(['participants'], setFieldConfigs);
        }

      } else {
        navigate('/errors/error404');
      }
    }
  }, [selectedFilters, currentPage, searchQuery, selectedSearchColumn, selectedColumns, limit, sortConfig]);

  // Effect to reset search input
  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);

  // Routes to detail view
  const handleRouteToDetailView = (participantsId: number) => {
    navigate(`/${companyId}/participants/${participantsId}`);
  };

  // Fetch participants again after an update, optionally showing a message
  const handleParticipantsUpdateSubmit = (message?: string, isError?: boolean) => {
    fetchParticipants();
    if (message) {
      showToast(message, isError);
    }
  };

  // Resets all filters and search fields
  const resetSearch = () => {
    resetUrlParams();
    setSearchQuery('');
    setSelectedSearchColumn('all');
    setCurrentPage(1);
    setResetSearchInput(true);
  };

  // Handles view selection changes from the view selection component.
  const handleSelectionChange = (
    selectedColumns: string[],
    selectedFilters: any,
    selectedSortConfig: any,
    selectedLimit: number,
    selectedSearchTerm: string,
    selectedSearchColumn: string
  ) => {
    resetSearch()
    setSelectedColumns(selectedColumns);
    setSelectedFilters(selectedFilters);
    setSortConfig(selectedSortConfig);
    setLimit(selectedLimit);

    if (selectedSearchTerm || selectedSearchColumn) {
      setSearchQuery(selectedSearchTerm);
      setSelectedSearchColumn(selectedSearchColumn);
    }
  };

  // Handle clicks on table cells for editing
  const handleCellClick = (rowId: number | null, columnKey: string | null) => {
    setEditableCell({ rowId, columnKey });
  };

  // Handle changes to table cells for editing
  const handleFieldChange = (rowId: number, columnKey: string, value: any) => {
    setPendingChanges(prevChanges => ({
      ...prevChanges,
      [`${rowId}-${columnKey}`]: { rowId, columnKey, value },
    }));
    setShowPopup(true);
  };

  // Save changes made in editable cells
  const handleSaveChange = async () => {
    const changesToSubmit = Object.values(pendingChanges);

    try {
      for (const change of changesToSubmit) {
        if (typeof change.value === 'object' && change.value !== null) {
          const relation = change.columnKey.split('.')[0];
          await ApiClient.put(`/products/${change.rowId}`, { [`${relation}_id`]: change.value.value });
        } else {
          await ApiClient.put(`/participants/${change.rowId}`, {
            [getFieldConfigByResourceName(fieldConfigs, change.columnKey)?.fieldName as string]: change.value
          });
        }
      }

      setPendingChanges({});
      setEditableCell({ rowId: null, columnKey: null });

      await fetchParticipants();
      showToast('Erfolgreich gespeichert', false);
      setShowPopup(false);
    } catch (error: any) {
      console.error(error.message as AxiosError);
    }
  };


  // Revert a single change in editable cell
  const handleRevertChange = () => {
    const { rowId, columnKey } = editableCell;

    if (rowId === null || columnKey === null) return;

    const newPendingChanges = { ...pendingChanges };
    delete newPendingChanges[`${rowId}-${columnKey}`];

    setPendingChanges(newPendingChanges);
    setEditableCell({ rowId: null, columnKey: null });
    setShowPopup(Object.keys(newPendingChanges).length > 0);
  };

  // Revert all changes made in the editable cells
  const handleRevertChanges = () => {
    setPendingChanges({});
    setEditableCell({ rowId: null, columnKey: null });
    setShowPopup(false);
  };

  // Handle search input change
  const handleSearch = (data: { query: string; filter?: string }) => {
    const { query, filter } = data;

    if (query) {
      setSearchQuery(query);
      setSelectedSearchColumn(filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  // Define how special columns should be rendered
  const columnMapping: { [key: string]: ColumnMapping<Participant> } = {
    'participants.title': {
      renderHeader: (key: any) => (
        <th
          key={key}
          className="sticky-col cursor-pointer"
          scope="col"
          title="Titel"
          onClick={() => requestSort('participants.title')}
        >
          <div className="d-flex align-items-center position-relative table-cell-wrap max-w-100">
            <Form.Check
              disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'delete')}
              className="me-3"
              type="checkbox"
              checked={isAllSelected}
              onChange={handleSelectAll}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            Titel <div className="position-absolute" style={{ right: 0 }}><SortCaret direction={getSortDirection('participants.title')} /></div>
          </div>
        </th>
      ),

      render: (participant: Participant) => (
        <td key={participant.id} className="sticky-col py-3">
          <div className="d-flex align-items-center justify-content-start">
            <Form.Check
              disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'delete')}
              className="me-3"
              type="checkbox"
              checked={selectedParticipants[participant.id] ?? false}
              onChange={() => { }}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectRow(participant.id, e);
              }}
            />
            <div className='me-2'>
              <ParticipantInitialsComponent size={20} initials={getInitials(participant?.title ?? '')}></ParticipantInitialsComponent>
            </div>

            <Link
              to={`/${companyId}/participants/${participant.id}`}
              className="btn-link ps-0 text-start table-cell-wrap max-w-table-title"
              title={participant.title ?? ''}
            >
              {participant.title}
            </Link>
          </div>
        </td>
      ),
    },
    'lastDeal.status': {
      render: (participant: Participant) => (
        <td key={`${participant.id}-dealstatus`}>
          <FontAwesomeIcon
            icon={dealStatusIconMap[participant.lastDeal?.status]}
            className={`me-2 ${dealStatusColorMap[participant.lastDeal?.status]}`}
          />
          {getEnumValue(
            getFieldConfigByResourceName(fieldConfigs, 'lastDeal.status')?.options ?? {},
            participant.lastDeal?.status.toString()
          )}
        </td>
      ),
    },
    'lastDeal.wvStatus': {
      render: (participant: Participant) => (
        <td key={`${participant.id}-wvstatus`}>
          <FontAwesomeIcon
            icon={wvStatusIconMap[participant.lastDeal?.wvStatus]}
            className={`me-2 ${wvStatusColorMap[participant.lastDeal?.wvStatus]}`}
          />
          {getEnumValue(
            getFieldConfigByResourceName(fieldConfigs, 'lastDeal.wvStatus')?.options ?? {},
            participant.lastDeal?.wvStatus.toString()
          )}
        </td>
      ),
    },
    'lastDeal.bgsStatus': {
      render: (participant: Participant) => (
        <td key={`${participant.id}-bgsstatus`}>
          <FontAwesomeIcon
            icon={bgsStatusIconMap[participant.lastDeal?.bgsStatus]}
            className={`me-2 ${bgsStatusColorMap[participant.lastDeal?.bgsStatus]}`}
          />
          {getEnumValue(
            getFieldConfigByResourceName(fieldConfigs, 'lastDeal.bgsStatus')?.options ?? {},
            participant.lastDeal?.bgsStatus.toString()
          )}
        </td>
      ),
    },
  };

  return (
    <div className='container-fluid p-40'>
      <div className='table-controls-wrapper'>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <h3>Teilnehmer</h3>
        </div>
        <Card className="card-block card-stretch card-height">
          <Card.Body>
            <Row className="d-flex justify-content-between mb-4">
              <Col md={9}>
                {searchQuery &&
                  <div className="d-flex align-items-baseline mb-3">
                    <h4 className="m-0">Suchergebnisse</h4>
                    <span className="ms-3 d-flex align-items-baseline">
                      <Button
                        className="btn-ghost m-0 p-0 fs-6"
                        variant="link"
                        onClick={resetSearch}
                      >
                        Suche beenden
                      </Button>
                    </span>
                  </div>
                }
                <ViewSelector
                  selectedSortConfig={sortConfig}
                  selectedFilters={selectedFilters}
                  selectedColumns={selectedColumns}
                  selectedLimit={limit}
                  selectedSearchColumn={selectedSearchColumn}
                  selectedSearchTerm={searchQuery}
                  entityType='participants'
                  onSelectionChange={handleSelectionChange}
                />
              </Col>
              <Col md={3}>
                <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} initialSearchTerm={searchQuery} initialSearchColumn={selectedSearchColumn} fieldConfigs={fieldConfigs} />
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex justify-content-start align-items-center">
                  <div className="d-flex custom-scrollbar-x horizontal-scroll">
                    {/* StatusStudy Filter */}
                    <GenericDropdownFilter
                      selectedFilter={selectedFilters.dealStatus ?? null}
                      handleFilterChange={(status) => {
                        setSelectedFilters(filters => ({ ...filters, dealStatus: status }));
                        setCurrentPage(1);
                      }}
                      filterEnum={getFieldConfigByResourceName(fieldConfigs, 'lastDeal.status')?.options ?? {}}
                      iconMap={dealStatusIconMap}
                      colorMap={dealStatusColorMap}
                      isDisabled={false}
                      titlePlaceholder="Studienstatus">
                    </GenericDropdownFilter>

                    {/* TimeModel Filter */}
                    <GenericDropdownFilter
                      selectedFilter={selectedFilters.timeModel ?? null}
                      handleFilterChange={(status) => {
                        setSelectedFilters(filters => ({ ...filters, timeModel: status }));
                        setCurrentPage(1);
                      }}
                      filterEnum={{
                        ...getFieldConfigByResourceName(fieldConfigs, 'lastDeal.timeModel')?.options,
                        besidesTheJob: 'Berufsbegleitend'
                      }}
                      iconMap={timeModelIconMap}
                      colorMap={timeModelColorMap}
                      isDisabled={false}
                      titlePlaceholder="Zeitmodell"
                    />

                    {/* AbortReason Filter */}
                    <GenericDropdownFilter
                      selectedFilter={selectedFilters.abortReason ?? null}
                      handleFilterChange={(status) => {
                        setSelectedFilters(filters => ({ ...filters, abortReason: status }));
                        setCurrentPage(1);
                      }}
                      filterEnum={getFieldConfigByResourceName(fieldConfigs, 'lastDeal.abortReason')?.options ?? {}}
                      iconMap={abortReasonIconMap}
                      colorMap={abortReasonColorMap}
                      isDisabled={false}
                      titlePlaceholder="Abbruchgrund"
                    />

                    {/* DateRange Filter */}
                    <DateRangeDropdownFilter
                      onDateRangeChange={(startDate: string | null, endDate: string | null) => {
                        setSelectedFilters(filters => ({
                          ...filters,
                          startDate: startDate ? startDate : null,
                          endDate: endDate ? endDate : null
                        }));
                        setCurrentPage(1);
                      }}
                      initialStartDate={selectedFilters.startDate ? new Date(selectedFilters.startDate) : null}
                      initialEndDate={selectedFilters.endDate ? new Date(selectedFilters.endDate) : null}
                      isDisabled={false}
                      titlePlaceholder="Zeitraum"
                    />

                    {/* BgsStatus Filter */}
                    <GenericDropdownFilter
                      selectedFilter={selectedFilters.bgsStatus ?? null}
                      handleFilterChange={(status) => {
                        setSelectedFilters(filters => ({ ...filters, bgsStatus: status }));
                        setCurrentPage(1);
                      }}
                      filterEnum={getFieldConfigByResourceName(fieldConfigs, 'lastDeal.bgsStatus')?.options ?? {}}
                      iconMap={bgsStatusIconMap}
                      colorMap={bgsStatusColorMap}
                      titlePlaceholder="BGS - Status"
                    />

                    {/* WvStatus Filter */}
                    <GenericDropdownFilter
                      selectedFilter={selectedFilters.wvStatus ?? null}
                      handleFilterChange={(status) => {
                        setSelectedFilters(filters => ({ ...filters, wvStatus: status }));
                        setCurrentPage(1);
                      }}
                      filterEnum={getFieldConfigByResourceName(fieldConfigs, 'lastDeal.wvStatus')?.options ?? {}}
                      iconMap={wvStatusIconMap}
                      colorMap={wvStatusColorMap}
                      titlePlaceholder="WV - Status"
                    />

                    <div className="sticky-right-reset-filter">
                      <ResetFiltersButton filters={selectedFilters} setFilters={(newFilters: ParticipantFilters) => {
                        setSelectedFilters(newFilters);
                        setCurrentPage(1);
                      }} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {selectedCount > 0 && (
          <SelectionActions
            selectedItems={selectedParticipants}
            selectedCount={selectedCount}
            handleDeSelectAll={handleDeSelectAll}
            onSubmitSuccess={handleParticipantsUpdateSubmit}
            amountAllItems={totalEntries}
            entityType='participants'
            fieldConfigs={fieldConfigs}
            queryParams={lastQueryParams}
          ></SelectionActions>
        )}
      </div>

      <div style={{ overflowX: 'auto' }}>
        <Table className="sticky-table" responsive="md" size="sm" style={{ overflow: 'auto' }}>
          <thead>
            <tr>
              {selectedColumns.map((columnKey) =>
                columnMapping[columnKey]?.renderHeader
                  ? columnMapping[columnKey].renderHeader!(columnKey)
                  : defaultRenderHeader(columnKey, fieldConfigs, requestSort, getSortDirection, 'participants')
              )}

              <th className="cursor-pointer text-end align-top sticky-right bg-grey w-40-px" scope="col">
                <ColumnSelection
                  selectedColumns={selectedColumns}
                  onSelectionChange={columns => setSelectedColumns(columns)}
                  fieldConfigs={fieldConfigs}
                  entityType='participants'
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={`skeleton-row-${index}`} columnCount={selectedColumns.length + 1} />
              ))
              : participants.map((participant) => (
                <tr key={participant.id} className="bg-white">
                  {selectedColumns.map((columnKey) =>
                    columnMapping[columnKey]
                      ? columnMapping[columnKey].render(participant)
                      : <DefaultColumnRender
                        key={`${participant.id}-${columnKey}`}
                        item={participant}
                        columnKey={columnKey}
                        editableCell={editableCell}
                        handleCellClick={handleCellClick}
                        handleFieldChange={handleFieldChange}
                        handleRevertChange={handleRevertChange}
                        fieldConfigs={fieldConfigs}
                        pendingChanges={pendingChanges}
                        activeTooltip={activeTooltip}
                        setActiveTooltip={setActiveTooltip}
                        module={'participants'}
                      />
                  )}
                  <td className='sticky-right bg-white' key={participant.id}>
                    <Dropdown>
                      <Dropdown.Toggle as="div" className="no-caret cursor-pointer d-inline-block">
                        <div className="px-2">
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </div>
                      </Dropdown.Toggle>
                      <PortalWrapper>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleRouteToDetailView(participant.id)}><FontAwesomeIcon className='me-1' width={15} icon={faCircleInfo} /> Mehr Details</Dropdown.Item>
                        </Dropdown.Menu>
                      </PortalWrapper>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            {!isLoading && participants.length === 0 && (
              <TableNoDataMessage
                message="Keine Teilnehmer"
              />
            )}
          </tbody>
        </Table>
      </div>

      {totalEntries > 0 && (
        <div className='pagination-wrapper pt-2'>
          <Row >
            <Col>
              <PaginationInfo
                currentPage={currentPage}
                limit={limit}
                totalEntries={totalEntries}
                onLimitChange={(size) => {
                  setLimit(size);
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <DynamicPagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
          </Row>
        </div>
      )}

      {showPopup && (
        <SaveCancelPopup
          onSave={handleSaveChange}
          onAbort={handleRevertChanges}
          pendingChangesCount={Object.keys(pendingChanges).length}
        />
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
});

export default Participants;
