import {
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import {
    PermissionsEnum,
} from '../../utils/enum';

import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, Role, usePermissions, useSelection, useSortableData, useTableHeight, useToast } from 'c1g-ui-library';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/bootstrap/card';
import ComboButtonGroup, {
    ComboButtonId,
} from '../../components/ComboButtonGroup';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';
import AddEditRoleModal from '../../components/roles/modal/AddEditRoleModal';
import SearchInput from '../../components/SearchInput';
import DynamicPagination from '../../components/table/DynamicPagination';
import PaginationInfo from '../../components/table/PaginationInfo';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import TableNoDataMessage from '../../components/table/TableNoDataMessage';
import { Permissions } from '../../interfaces';
import { getRoleStatusIcon } from '../../utils/roles/utils';
import { getModuleList } from '../../utils/utils';

interface RolesResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Role[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const Roles = memo(() => {
  useTableHeight();
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const { items: sortedRoles, requestSort } = useSortableData(roles);
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const getId = useCallback((role: Role) => role.id, []);

  const {
    selectedItems: selectedRoles,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(roles, getId);

  const handleSearch = (data: { query: string; filter?: string }) => {
    const { query, filter } = data;

    if (query) {
      setSearchQuery(query);
      setSelectedSearchFilter(filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  const fetchRoles = useCallback(async () => {
    setRoles([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&status=${selectedCombo}`;

    } else {
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
        }
      }
    }

    try {
      const response = await ApiClient.get(`/roles${queryParams}`);
      const certificatesResponse = response.data as RolesResponse;
      setTotalPages(certificatesResponse.amountPages);
      setRoles(certificatesResponse.list);
      setCurrentPage(certificatesResponse.page);
      setLimit(certificatesResponse.itemsPerPage);
      setTotalEntries(certificatesResponse.amountAllItems);
      setAvailableFilter(certificatesResponse.searchFilters)
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, selectedCombo, searchQuery]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewRoles, 'read');

      if (hasPermission) {
        fetchRoles();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    searchQuery,
    selectedCombo,
    fetchRoles,
    permissionsLoaded,
    location,
  ]);


  const handleRoleUpdateSubmit = () => {
    fetchRoles();
  };

  const handleComboSelect = (newCombo: ComboButtonId) => {
    setSelectedCombo(newCombo);
    setCurrentPage(1);
  };

  const resetSearch = () => {
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);


  useEffect(() => {
    resetSearch();
  }, [location]);


  return (
    <div className='container-fluid p-40'>
      <div className='table-controls-wrapper'>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <h3>Rollenverwaltung</h3>
          <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewRoles, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
            Rolle hinzufügen
          </Button>
        </div>
        <Card className="card-block card-stretch card-height">
          <Card.Body>
            <Row className="d-flex justify-content-between mb-4">
              <Col md={6}>
                {searchQuery ?
                  <div className="d-flex align-items-baseline">
                    <h4 className="m-0">Suchergebnisse</h4>
                    <span className="ms-3 d-flex align-items-baseline">
                      <Button
                        className="btn-ghost m-0 p-0 fs-6"
                        variant="link"
                        onClick={resetSearch}
                      >
                        Suche beenden
                      </Button>
                    </span>
                  </div>

                  : <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedCombo}
                    setSelectedCombo={handleComboSelect}
                    borderRadius="normal"
                  ></ComboButtonGroup>}
              </Col>
              <Col md={3}>
                <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
              </Col>
            </Row>
            <Row>
              <Col>

              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table className="sticky-table" responsive="md" size="sm" style={{ overflow: 'auto' }}>
          <thead>
            <tr>
              <th
                className="d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.ViewRoles, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewRoles, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Titel
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('modules')}
              >
                Module
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('status')}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <GeneralSelectionActions
                selectedItems={selectedRoles}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                onSubmit={handleRoleUpdateSubmit}
                amountAllItems={totalEntries}
                entityType='roles'
              ></GeneralSelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedRoles.map((role) => (
                <tr key={role.id} className="bg-white">
                  <td className="d-flex align-items-center py-3">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.ViewRoles, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewRoles, 'delete')}
                      className="me-3"
                      type="checkbox"
                      checked={
                        selectedRoles[role.id] ?? false
                      }
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(role.id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/roles/${role.id}`}
                      className="btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {role.title}
                    </Link>
                  </td>
                  <td>{getModuleList(role.modules)}</td>
                  <td className='text-center'>
                    {getRoleStatusIcon(role.status)}
                  </td>
                </tr>
              ))}
            {!isLoading && sortedRoles.length === 0 && (
              <TableNoDataMessage
                message="Keine Rollen"
              />
            )}
          </tbody>
        </Table>
      </div>

      {totalEntries > 0 && (
        <div className='pagination-wrapper pt-2'>
          <Row>
            <Col>
              <PaginationInfo
                currentPage={currentPage}
                limit={limit}
                totalEntries={totalEntries}
                onLimitChange={(size) => {
                  setLimit(size);
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <DynamicPagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
          </Row>
        </div>
      )}

      {showAddEditModal && <AddEditRoleModal
        modalTitle={`Rolle hinzufügen`}
        onSubmitSuccess={handleRoleUpdateSubmit}
        onModalClose={() => setShowAddEditModal(false)}
      >
      </AddEditRoleModal>}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
});

export default Roles;
