import DefaultLayout from '../layouts/default-layout';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../views/dashboard/dashboard';
import Participants from '../views/participants/participants';
import ParticipantDetail from '../views/participants/participants-detail';
import Educations from '../views/educations/educations';
import EducationDetail from '../views/educations/education-detail';
import Deals from '../views/deals/deals';
import DealDetail from '../views/deals/deal-detail';
import Settings from '../views/settings/settings';
import SettingDetail from '../views/settings/setting-detail';
import Roles from '../views/roles/roles';
import RoleDetail from '../views/roles/roles-detail';
import Users from '../views/users/users';
import Webhooks from '../views/webhooks/webhooks';
import WebhookDetail from '../views/webhooks/webhook-detail';
import ApiKeys from '../views/apikeys/apikeys';
import ApiKeyDetail from '../views/apikeys/apikey-detail';
import Media from '../views/media/media';
import Satisfactions from '../views/satisfactions/satisfactions';
import SatisfactionDetail from '../views/satisfactions/satisfaction-detail';
import Activities from '../views/activities/acitivities';
import ActivityDetail from '../views/activities/activity-detail';
import Presences from '../views/presences/presences'
import PresenceDetail from '../views/presences/presence-detail';
import Absences from '../views/absences/absences'
import AbsenceDetail from '../views/absences/absence-detail';
import Companions from '../views/companions/companions';
import CompanionDetail from '../views/companions/companion-detail';
import { Navigate } from 'react-router-dom';
import AbsenceReports from '../views/deals/absence-reports/absence-reports';
import AbsenceReportDetail from '../views/deals/absence-reports/absence-reports-detail';
import Test from '../views/Test/Test';

export const DefaultRouter = [
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        path: '/',
        element: <Navigate to="/oc" />,
      },
      {
        path: '/:companyId',
        element: <DefaultLayout app_name='ClientOffice' />,
        children: [
          {
            path: '/:companyId/test',
            element: <Test/>
          },
          {
            path: '/:companyId/',
            element: <Dashboard />,
          },
          {
            path: '/:companyId/participants',
            element: <Participants />,
          },
          {
            path: '/:companyId/participants/:participantId',
            element: <ParticipantDetail />,
          },
          {
            path: '/:companyId/roles',
            element: <Roles />,
          },
          {
            path: '/:companyId/roles/:roleId',
            element: <RoleDetail />,
          },
          {
            path: '/:companyId/settings',
            element: <Settings />,
          },
          {
            path: '/:companyId/settings/:settingId',
            element: <SettingDetail />,
          },
          {
            path: '/:companyId/users',
            element: <Users />,
          },
          {
            path: '/:companyId/deals',
            element: <Deals />,
          },
          {
            path: '/:companyId/deals/:dealId',
            element: <DealDetail />,
          },
          {
            path: '/:companyId/deals/absence-reports',
            element: <AbsenceReports />,
          },
          {
            path: '/:companyId/deals/absence-reports/:absenceReportId',
            element: <AbsenceReportDetail />,
          },
          {
            path: '/:companyId/educations',
            element: <Educations />,
          },
          {
            path: '/:companyId/educations/:educationId',
            element: <EducationDetail />,
          },
          {
            path: '/:companyId/webhooks',
            element: <Webhooks />,
          },
          {
            path: '/:companyId/webhooks/:webhookId',
            element: <WebhookDetail />,
          },
          {
            path: '/:companyId/apikeys',
            element: <ApiKeys />,
          },
          {
            path: '/:companyId/apikeys/:apikeyId',
            element: <ApiKeyDetail />,
          },
          {
            path: '/:companyId/media/:folderId?/:mediumId?',
            element: <Media />,
          },
          {
            path: '/:companyId/satisfactions/:satisfactionId',
            element: <SatisfactionDetail />,
          },
          {
            path: '/:companyId/satisfactions',
            element: <Satisfactions />,
          },
          {
            path: '/:companyId/activities/:activityId',
            element: <ActivityDetail />,
          },
          {
            path: '/:companyId/activities',
            element: <Activities />,
          },
          {
            path: '/:companyId/presences/:presenceId',
            element: <PresenceDetail />,
          },
          {
            path: '/:companyId/presences',
            element: <Presences />,
          },
          {
            path: '/:companyId/absences/:absenceId',
            element: <AbsenceDetail />,
          },
          {
            path: '/:companyId/absences',
            element: <Absences />,
          },
          {
            path: '/:companyId/companions',
            element: <Companions />,
          },
          {
            path: '/:companyId/companions/:companionId',
            element: <CompanionDetail />,
          },
        ],
      },
    ],
  },
];
