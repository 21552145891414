import {
    faCheckCircle,
    faChevronDown,
    faChevronUp,
    faCircle,
    faEllipsisH,
    faHourglass,
    faInfoCircle,
    faLock,
    faLockOpen,
    faSpinner,
    faUserUnlock
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient } from 'c1g-ui-library';
import React, { useEffect, useState } from 'react';
import { Dropdown, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import Nestable, { RenderItem } from 'react-nestable';
import { DealModuleData, } from '../../../interfaces';
import { formatDate } from '../../../utils/utils';
import PortalWrapper from '../../PortalWrapper';
import ModuleActionConfirmationModal from '../modal/ModuleActionConfirmationModal';
interface DealModulesProps {
    dealId: number;
}

/**
 * DealModules Component
 * 
 * This component fetches and displays the list of modules for a specific deal.
 * It uses a nested list structure (via `react-nestable`) to render modules and their associated units.
 * Each module displays its title, status, date range, progress, and an action dropdown.
 * The user can open a modal to perform an action on a module manually.
 * 
 * Props:
 * - `dealId` (number): The ID of the deal for which modules should be fetched.
 */

const DealModules: React.FC<DealModulesProps> = ({ dealId }) => {
    const [modules, setModules] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedAction, setSelectedAction] = useState<string | null>(null);
    const [selectedModule, setSelectedModule] = useState<any | null>(null);

    const fetchModules = async () => {
        try {
            setIsLoading(true);
            const response = await ApiClient.get(`/deals/${dealId}/modules`);
            const transformedModules = response.data.map((item: DealModuleData) => ({
                id: item.module.id,
                title: item.module.title,
                start: item.start,
                end: item.end,
                durationUes: item.module.durationUes,
                state: item.state,
                lastManualDate: item.lastManualDate,
                lwId: item.lwId,
                progress: item.progress,
                children: item.units?.map((unit) => ({
                    id: unit.id,
                    title: unit.title,
                    progress: unit.progress,
                    durationUes: unit.durationUes,
                    lwId: unit.lwId
                })),
            }));

            setModules(transformedModules);
        } catch (error: any) {
            console.error('Error fetching modules:', error.message as AxiosError);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchModules();
    }, [dealId]);

    const getProgressIcon = (progress: number) => {
        if (progress === 100) {
            return <FontAwesomeIcon className="text-success" icon={faCheckCircle} />;
        }
        if (progress === 0) {
            return <FontAwesomeIcon className="text-danger" icon={faCircle} />;
        }
        return <FontAwesomeIcon className="text-warning" icon={faSpinner} />;
    };

    const getTooltip = (part1?: string, part2?: string) => {
        const tooltipContent = (
            <>
                {part1 && <div>{part1}</div>}
                {part2 && <div>{part2}</div>}
            </>
        );

        return <Tooltip id="tooltip">{tooltipContent}</Tooltip>;
    };

    const getTooltipContent = (state: string) => {
        switch (state) {
            case 'lock':
                return `Manuell gesperrt`;
            case 'sended':
                return 'Automatisch freigeschaltet';
            case 'open':
                return 'Warten auf automatische Freischaltung';
            case 'unlock':
                return `Manuell freigeschaltet`;
            default:
                return '';
        }
    };

    const openActionModal = (action: string, module: any) => {
        setSelectedAction(action);
        setSelectedModule(module);
    };

    const handleModalClose = () => {
        setSelectedAction(null);
        setSelectedModule(null);
        fetchModules();
    };

    const renderItem = ({
        item,
        collapseIcon,
        depth,
    }: {
        item: any;
        index: number;
        depth: number;
        isDraggable: boolean;
        collapseIcon: React.ReactNode;
        handler: React.ReactNode;
    }) => {
        const isModule = depth === 0;
        let stateIcon;
        let stateIconClass;

        switch (item.state) {
            case 'lock':
                stateIcon = faLock;
                stateIconClass = 'text-danger';
                break;
            case 'sended':
                stateIcon = faLockOpen;
                stateIconClass = 'text-success';
                break;
            case 'open':
                stateIcon = faHourglass;
                stateIconClass = 'text-warning';
                break;
            case 'unlock':
                stateIcon = faUserUnlock;
                stateIconClass = 'text-success';
                break;
            default:
                stateIcon = faCircle;
                stateIconClass = 'text-muted';
        }

        return (
            <div className="d-flex  align-items-center">
                <div 
                    className={`${depth === 0 ? 'module-item cursor-pointer' : 'module-subitem'} d-flex align-items-center gap-3 p-2 rounded-3 w-100`}
                    onClick={e => { e.stopPropagation(); React.isValidElement(collapseIcon) && collapseIcon.props.onClick(e); }}
                >
                    <div
                        className="d-flex align-items-center word-break"
                    >
                        {collapseIcon}
                        <span className="ms-2 text-light-black">{item.title}</span>
                    </div>

                    <div className="horizontal-line flex-grow-1"></div>

                    <div className="d-flex align-items-center justify-content-end gap-2">
                        {isModule && (
                            <>
                                {/* Status Icon */}
                                <div className="d-flex justify-content-center" style={{ width: '20px' }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={getTooltip(
                                            getTooltipContent(item.state),
                                            item.lastManualDate ? `am ${formatDate(item.lastManualDate, 'd.m.yy')}` : ''
                                        )}
                                    >
                                        <FontAwesomeIcon
                                            className={`${stateIconClass} cursor-pointer`}
                                            icon={stateIcon}
                                        />
                                    </OverlayTrigger>
                                </div>

                                {/* Date Range */}
                                <div className="text-start" style={{ width: '130px' }}>
                                    <span className="text-nowrap text-light-black">
                                        {formatDate(item.start, 'd.m.yy')} - {formatDate(item.end, 'd.m.yy')}
                                    </span>
                                </div>
                            </>
                        )}

                        {item.progress !== null && item.progress >= 0 && <>
                            {isModule && <div className="text-start" >
                                <span>·</span>
                            </div>}


                            <div className="text-start" style={{ width: '50px' }}>
                                <span className="text-nowrap text-light-black">
                                    {getProgressIcon(item.progress)} {item.progress}%
                                </span>
                            </div>

                            <div className="text-start">
                                <span>·</span>
                            </div>
                        </>}

                        {/* Info Icon */}
                        <div className="text-start" style={{ width: '20px' }}>
                            <OverlayTrigger
                                placement="top"
                                overlay={getTooltip(item?.lwId, `${item?.durationUes ?? ''} UE`)}
                            >
                                <FontAwesomeIcon icon={faInfoCircle} className='cursor-pointer' />
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
                {/* Actions Dropdown */}
                <div className="text-start px-2">
                <Dropdown>
                    <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic"
                        className="btn-light-ghost no-caret cursor-pointer py-0 px-1 align-items-center justify-content-center"
                        style={{ width: '24px', height: '24px' }}
                    >
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </Dropdown.Toggle>
                    <PortalWrapper>
                        <Dropdown.Menu>
                            {isModule && <>
                                {(item.state !== 'sended' && item.state !== 'unlock') && <Dropdown.Item as="div" onClick={() => openActionModal('moduleUnlock', item)}>
                                    <FontAwesomeIcon className="text-primary" width={30} icon={faUserUnlock} />
                                    Sofort freischalten
                                </Dropdown.Item>}

                                {item.state !== 'lock' && <Dropdown.Item as="div" onClick={() => openActionModal('moduleLock', item)}>
                                    <FontAwesomeIcon className="text-primary" width={30} icon={faLock} />
                                    Sperren
                                </Dropdown.Item>}
                            </>}

                            {!isModule && <>
                                <Dropdown.Item as="div" onClick={() => openActionModal('setEmptyProgress', item)}>
                                    <FontAwesomeIcon className="text-primary" width={30} icon={faCircle} />
                                    Fortschritt 0%
                                </Dropdown.Item>

                                <Dropdown.Item as="div" onClick={() => openActionModal('setFullProgress', item)}>
                                    <FontAwesomeIcon className="text-primary" width={30} icon={faCheckCircle} />
                                    Fortschritt 100%
                                </Dropdown.Item>
                            </>
                            }
                        </Dropdown.Menu>
                    </PortalWrapper>
                </Dropdown>
            </div>
        </div>
        );
    };

    return (
        <div className="deal-modules">
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center 50-vw" style={{ height: '500px' }}>
                    <Spinner animation="border" role="status"></Spinner>
                </div>
            ) : (
                <Nestable
                    disableDrag
                    items={modules}
                    renderItem={renderItem as RenderItem}
                    collapsed
                    renderCollapseIcon={({ isCollapsed }) =>
                        isCollapsed ? (
                            <FontAwesomeIcon icon={faChevronDown} />
                        ) : (
                            <FontAwesomeIcon icon={faChevronUp} />
                        )
                    }
                />
            )}

            {selectedModule && selectedAction && (
                <ModuleActionConfirmationModal
                    dealId={dealId}
                    lwId={selectedModule.lwId}
                    moduleTitle={selectedModule.title}
                    action={selectedAction}
                    onClose={handleModalClose}
                />
            )}
        </div>
    );
};

export default DealModules;
