import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { formatDateWithoutTime, getFieldConfigByResourceName } from '../../utils/utils';
import DealAbortModal from './../deals/modal/DealAbortModal';
import { Deal } from '../../interfaces';
import { getEnumValue, PermissionsEnum } from '../../utils/enum';
import { FieldConfig, usePermissions } from 'c1g-ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import DealCancellationLogs from './../participants/detail/DealCancellationLogs';
import { ApiClient } from 'c1g-ui-library';
import { Permissions } from '../../interfaces';
import { AxiosError } from 'axios';

interface DealManagementProps {
    deal: Deal
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    fieldConfigs: FieldConfig[];
}

/**
 * DealManagement Component
 * 
 * This component is responsible for managing a single deal's lifecycle, allowing users to either:
 * - Abort an active deal.
 * - Resume a previously aborted deal.
 * 
 * Props:
 * - deal: The current deal object, including properties such as status, abortDate, abortReason, and abortNotice.
 * - onSubmitSuccess: Callback function triggered after a successful or failed API request. It accepts a message string and an optional error flag.
 * - fieldConfigs: Array of field configurations, used to render and map deal-related fields dynamically.
 */

const DealManagement: React.FC<DealManagementProps> = ({
    deal,
    onSubmitSuccess,
    fieldConfigs
}) => {
    const [showDealAbortModal, setShowDealAbortModal] = useState(false);
    const { userHasPermissionByRight } = usePermissions<Permissions>();

    const handleResume = async () => {
        try {
            const data = {
                abortDate: null,
                abortReason: null,
                abort: false,
                abortNotice: '',
            }

            await ApiClient.put(`/deals/${deal?.id}`, data);
            onSubmitSuccess('Erfolgreich gespeichert', false);
        } catch (error: any) {
            console.error(error.message as AxiosError);
        }
    };

    return (
        <Col md={12}>
            <Card className='border rounded shadow-none card-block card-stretch m-0'>
                <Card.Body>
                    <h5 className="card-title">Deal verwalten</h5>
                    {(Number(deal.status) === 0 || Number(deal.status) === 1) && (
                        <div className="border rounded border-danger p-3 d-flex justify-content-between align-items-center">
                            <div>
                                <h5>Deal abbrechen</h5>
                                <div>Der Deal kann nachträglich wieder fortgesetzt werden.</div>
                            </div>
                            <div>
                                <Button className='d-flex align-items-center gap-2' disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write')} variant="danger" onClick={() => setShowDealAbortModal(true)}>
                                    <FontAwesomeIcon icon={faBan}></FontAwesomeIcon> <span>Deal abbrechen</span>
                                </Button>
                            </div>
                        </div>
                    )}

                    {Number(deal.status) === 3 && (
                        <div className="border rounded border-danger p-3">
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <h5>Deal abgebrochen</h5>
                                    <p>
                                        Der Deal kann wieder fortgesetzt werden
                                    </p>
                                </div>

                                <Button variant="secondary" className='btn-soft-primary' onClick={handleResume}>
                                    Deal fortsetzen
                                </Button>
                            </div>

                            <div className="horizontal-line my-3"></div>

                            <Row className='py-2'>
                                <Col lg={2}>Abbruchdatum</Col>
                                <Col lg={10} className='text-black'>{formatDateWithoutTime(deal.abortDate ?? '')}</Col>
                            </Row>
                            <Row className='py-2'>
                                <Col lg={2}>Grund</Col>
                                <Col lg={10} className='text-black'>{getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'lastDeal.abortReason')?.options ?? {}, deal?.abortReason)}</Col>
                            </Row>
                            <Row className='py-2'>
                                <Col lg={2}>Kommentar</Col>
                                <Col lg={10} className='text-black'>{deal.abortNotice || '-'}</Col>
                            </Row>

                            <DealCancellationLogs cancellationLogs={deal.cancellationLogs} fieldConfigs={fieldConfigs} />
                        </div>
                    )}
                </Card.Body>
            </Card>
            {showDealAbortModal &&
                <DealAbortModal fieldConfigs={fieldConfigs} modalTitle='Deal Abbrechen' deal={deal} onSubmitSuccess={onSubmitSuccess} onModalClose={() => setShowDealAbortModal(false)}>
                </DealAbortModal>}
        </Col>
    );
};

export default DealManagement;
