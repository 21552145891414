import React, { useState } from 'react';
import { Row, Col, Button, Table } from 'react-bootstrap';
import { CompanyMeasure } from '../../interfaces';
import Card from '../bootstrap/card';
import { usePermissions, Setting } from 'c1g-ui-library';
import { PermissionsEnum } from '../../utils/enum';
import SkeletonCard from '../skeleton/SkeletonCard';
import GenericInfosCard from '../GenericInfosCard';
import CompanyMeasuresModal from './modal/CompanyMeasuresModal';
import { formatDateWithoutTime } from '../../utils/utils';
import { Permissions } from '../../interfaces';

interface CompanyMeasuresDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null;
    onSubmitSuccess: (settingsId: number) => void;
}

/**
 * CompanyMeasuresDetails Component
 *
 * This component displays details of Unternehmensmaßnahmen (Company Measures), including 
 * general information (title, ident, and description) and a list of associated measures. 
 * Users with the appropriate permissions can edit these measures through a modal.
 *
 * Props:
 * @param {boolean} [isLoading] - Optional flag indicating whether data is loading.
 * @param {Setting | null} [setting] - Optional `Setting` object containing Unternehmensmaßnahmen data.
 * @param {(settingsId: number) => void} onSubmitSuccess - Callback function triggered on successful update.
 * ```
 */
const CompanyMeasuresDetails: React.FC<CompanyMeasuresDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions<Permissions>();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const measures = setting?.details as Record<string, CompanyMeasure> ?? {}

    function getInfo1() {
        return [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewSettings, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Allgemein"
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={12}>
                    <Card className="card-block card-stretch card-height">
                        <Card.Body>
                            <h5>Unternehmensmaßnahmen</h5>
                            {Object.keys(measures).length > 0 ? (
                                <Table size="sm">
                                    <thead>
                                        <tr>
                                            <th>Nummer</th>
                                            <th>Bezeichnung</th>
                                            <th>Startdatum</th>
                                            <th>Enddatum</th>
                                            <th>PSM-Frei</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(measures).map(([number, details]) => (
                                            <tr key={number}>
                                                <td>{number}</td>
                                                <td>{details.designation || '-'}</td>
                                                <td>{details.startDate ? formatDateWithoutTime(details.startDate) : '-'}</td>
                                                <td>{details.endDate ? formatDateWithoutTime(details.endDate) : '-'}</td>
                                                <td>{details.psmFree ? 'Ja' : 'Nein'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>Keine Unternehmensmaßnahmen vorhanden</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {setting && showAddEditModal && <CompanyMeasuresModal
                modalTitle={`${setting.title} bearbeiten`}
                setting={setting}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            />}
        </>
    );
};

export default CompanyMeasuresDetails;