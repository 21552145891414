import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { MediaItem } from 'c1g-ui-library';
import { formatSizeUnits, getImageUrl, getMediaIcon, getMediaStatusIcons, getMediaType } from '../../utils/media/utils';
import GenericInfosCard from '../GenericInfosCard';

interface MediaDetailsProps {
    media: MediaItem;
    onClose: () => void;
    firstColumnSize?: number
}

/**
 * MediaDetails Component
 *
 * This component displays detailed information about a media item, 
 * including its type, MIME type, size, dimensions, and description. 
 * It utilizes the `GenericInfosCard` component to render the information in a structured format.
 *
 * Props:
 * - `media`: The media item object of type `MediaItem`, containing metadata and details about the media.
 * - `onClose`: A callback function to handle closing the media details view.
 * - `firstColumnSize` (optional): Number defining the size of the first column in the details layout. Default is 5.
 */

const MediaDetails: React.FC<MediaDetailsProps> = ({
    media,
    onClose,
    firstColumnSize = 5
}) => {
    function getMediaInfo() {
        const generalInfo = [
            { label: 'Typ:', value: getMediaType(media) },
            { label: 'Mime-Typ:', value: media?.mimeType || '-' },
            { label: 'Größe:', value: media.size ? formatSizeUnits(media.size) : '-' },
            {
                label: 'Abmessungen:',
                value: media.width && media.height ? `${media.width} x ${media.height}` : '-'
            },
            {
                label: 'Beschreibung:',
                value: media?.description || '-'
            }
        ];
        return generalInfo;
    }

    return (
        <>
            <GenericInfosCard
                imageSrc={getImageUrl(media)}
                title={'Eigenschaften'}
                infos={getMediaInfo()}
                showHorizontalLine
                cardHeight={false}
                firstColumnSize={firstColumnSize}
            >

                <div className='d-flex align-items-center justify-content-between p-3'>
                    <div className='d-flex align-items-center'>
                        {getMediaIcon(media)} {getMediaStatusIcons(media)} <h5 className='mb-0'>{media.title}</h5>
                    </div>

                    <div>
                        <FontAwesomeIcon
                            icon={faClose}
                            onClick={onClose}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </div>

            </GenericInfosCard>
        </>
    );
};

export default MediaDetails;
