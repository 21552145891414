import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { Collapse, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

// Typdefinition für Untermenüelement
export interface SubmenuItem {
  title: string;
  pathname: string;
  icon?: ReactNode;
}

interface SidebarMenuProps {
  pathname: string;
  title: string;
  minititle?: string;
  target?: string;
  isTag: 'true' | 'false';
  staticIcon?: 'true' | 'false';
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  hasSubmenu?: boolean;
  submenuItems?: SubmenuItem[];
  autoCloseSubmenu?: boolean;
}

/**
 * SidebarMenu component
 *
 * Renders an individual menu item within the sidebar.
 * It supports both `<li>` elements and `Nav.Item` depending on the `isTag` prop.
 *
 * Features:
 * - Displays a link with an optional icon (via children).
 * - Adds a tooltip with the full title when collapsed.
 * - Highlights the active menu item based on current route.
 * - Supports optional click handler for custom logic (e.g., logout).
 * - Handles self-click (reloads the page if already on the same route).
 * - Supports submenu items that can be expanded/collapsed via an arrow icon.
 * - Auto-opens submenu when a submenu item is active
 * - Auto-closes submenu when navigating away from submenu items (if autoCloseSubmenu is true)
 *
 * Props:
 * - pathname: string → target path for the menu item.
 * - title: string → visible label and tooltip for the item.
 * - minititle: string (optional) → shown in collapsed mode via tooltip.
 * - target: string (optional) → supports `_blank` or other target values.
 * - isTag: 'true' | 'false' → determines whether to render a `<li>` or `Nav.Item`.
 * - staticIcon: 'true' | 'false' (optional) → whether to display a static SVG icon.
 * - children: ReactNode → used to render icon components or any custom content.
 * - onClick: function (optional) → custom handler executed on click.
 * - hasSubmenu: boolean (optional) → indicates whether this menu has a submenu.
 * - submenuItems: SubmenuItem[] (optional) → array of submenu items to render.
 * - autoCloseSubmenu: boolean (optional) → auto-close the submenu when navigating away
 *
 * Used inside VerticalNav to build up the sidebar navigation.
 */
const SidebarMenu: React.FC<SidebarMenuProps> = ({
  onClick,
  pathname,
  title,
  minititle,
  target,
  isTag,
  staticIcon,
  children,
  hasSubmenu = false,
  submenuItems = [],
  autoCloseSubmenu = true,
}) => {
  const location = useLocation();
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
    if (location.pathname === pathname) {
      e.preventDefault();
      window.location.href = pathname;
    }
  };

  const handleToggleSubmenu = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setSubmenuOpen(!submenuOpen);
  };

  // Separate checks for the main element and subelements
  const isMainActive = 
    location.pathname === pathname ||
    location.pathname.startsWith(`${pathname}/`);

  const isSubmenuItemActive = hasSubmenu && submenuItems.some(item => 
    location.pathname === item.pathname || 
    location.pathname.startsWith(`${item.pathname}/`)
  );

  // Open the submenu automatically, if a submenu item is active
  // Close the submenu, if no submenu item is active and autoCloseSubmenu is enabled
  useEffect(() => {
    if (isSubmenuItemActive) {
      setSubmenuOpen(true);
    } else if (autoCloseSubmenu && !isMainActive) {
      // If we navigate to another page (and no submenu item is active),
      // and autoCloseSubmenu is enabled, then we close the submenu
      setSubmenuOpen(false);
    }
  }, [isSubmenuItemActive, isMainActive, autoCloseSubmenu]);

  // Css classes based on the active status
  let navItemClass = 'nav-item';
  if (isMainActive) {
    navItemClass += ' active w-100';
  } else if (isSubmenuItemActive) {
    navItemClass += ' sub-active';
  }

  return (
    <>
      {isTag === 'true' && (
        <li className={navItemClass}>
          <div className="link-container d-flex align-items-center justify-content-between">
            <Link
              to={pathname}
              className={`${isMainActive ? 'active' : ''} nav-link w-100`}
              aria-current="page"
              onClick={handleOnClick}
            >
              <OverlayTrigger placement="right" overlay={<Tooltip>{title}</Tooltip>}>
                <div>{children}</div>
              </OverlayTrigger>
              <span className="item-name">{title}</span>
              
            </Link>
            {hasSubmenu && (
              <div 
                className="submenu-toggle w-100 d-flex align-items-center justify-content-end px-3" 
                onClick={handleToggleSubmenu}
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={submenuOpen ? faChevronUp : faChevronDown} size="xs" />
              </div>
            )}
          </div>

          
          {hasSubmenu && (
            <Collapse in={submenuOpen} dimension="height">
              <div className="submenu-container">
                <ul className="submenu list-unstyled">
                  {submenuItems.map((item, index) => (
                  <li key={index} className={`${location.pathname === item.pathname || location.pathname.startsWith(`${item.pathname}/`) ? 'active' : ''} nav-sub-item`}>
                    <Link 
                      to={item.pathname} 
                      className={`${location.pathname === item.pathname || location.pathname.startsWith(`${item.pathname}/`) ? 'active' : ''} nav-link py-2`}
                    >
                      {item.icon && <span className="me-2">{item.icon}</span>}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                ))}
                </ul>
              </div>
            </Collapse>
          )}
        </li>
      )}

      {isTag === 'false' && (
        <Nav.Item as="li" className={isMainActive ? 'active' : isSubmenuItemActive ? 'sub-active' : ''}>
          <Link
            to={pathname}
            className={`${isMainActive ? 'active' : ''} nav-link`}
            onClick={handleOnClick}
            target={target}
          >
            {staticIcon === 'true' && (
              <i className="icon">
                {/* SVG or static icon can go here */}
              </i>
            )}
            {children}
            <OverlayTrigger placement="right" overlay={<Tooltip>{title}</Tooltip>}>
              <i className="sidenav-mini-icon">{minititle}</i>
            </OverlayTrigger>
            <span className="item-name">{title}</span>
            {hasSubmenu && (
              <div 
                className="ms-auto" 
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSubmenuOpen(!submenuOpen);
                }}
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={submenuOpen ? faChevronUp : faChevronDown} size="xs" />
              </div>
            )}
          </Link>
          
          {hasSubmenu && (
            <Collapse in={submenuOpen}>
              <div className="submenu-container">
                <ul className="submenu list-unstyled ps-4">
                {submenuItems.map((item, index) => (
                  <li key={index} className={`${location.pathname === item.pathname || location.pathname.startsWith(`${item.pathname}/`) ? 'active' : ''} nav-item`}>
                    <Link 
                      to={item.pathname} 
                      className={`${location.pathname === item.pathname || location.pathname.startsWith(`${item.pathname}/`) ? 'active' : ''} nav-link py-2`}
                    >
                      {item.icon && <span className="me-2">{item.icon}</span>}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                  ))}
                </ul>
              </div>
            </Collapse>
          )}
        </Nav.Item>
      )}
    </>
  );
};

SidebarMenu.displayName = 'SidebarMenu';
export default SidebarMenu;
