import { faEllipsis, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, Setting, useToast } from 'c1g-ui-library';
import React, { FormEvent, useRef, useState } from 'react';
import {
    Button,
    Col,
    Dropdown,
    Form,
    Modal,
    Row,
    Spinner,
} from 'react-bootstrap';
import { AircallNumber } from '../../../interfaces';
import { handleInputChange } from '../../../utils/form/utils';
import Card from '../../bootstrap/card';
import FormGroup from '../../form/FormGroup';

type AircallNumbersModalProps = {
    modalTitle: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    details: AircallNumber[];
}

/**
 * AircallNumbersModal Component
 *
 * This component provides a modal interface for managing settings related to Aircall numbers.
 * It supports editing general settings like title and description, and adding or removing Aircall numbers.
 *
 * Props:
 * - `modalTitle`: A string for the modal's title.
 * - `setting` (optional): The initial setting object, including title, description, and details (Aircall numbers).
 * - `onSubmitSuccess`: A callback function invoked after successful form submission, receiving the settings ID.
 * - `onModalClose`: A callback function invoked when the modal is closed.
 */

const AircallNumbersModal: React.FC<AircallNumbersModalProps> = ({
    modalTitle,
    setting,
    onSubmitSuccess,
    onModalClose,
}) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newEntry, setNewEntry] = useState<{ name: string; number: string }>({
        name: '',
        number: '',
    });

    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [formValues, setFormValues] = useState<FormValues>({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        details: (setting?.details as AircallNumber[]) ?? [],
    });

    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = await ApiClient.put(
                    `/settings/${setting?.id}`,
                    prepareFormDataForSubmit(formValues)
                );
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error: any) {
                console.error(error.message as AxiosError);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const prepareFormDataForSubmit = (formValues: FormValues) => {
        return {
            title: formValues.title,
            ident: formValues.ident,
            description: formValues.description,
            details: formValues.details,
        };
    };

    const handleAddEntry = () => {
        if (newEntry.name.trim() && newEntry.number.trim()) {
            if (
                formValues.details.some(
                    (detail) => detail.name === newEntry.name
                )
            ) {
                showToast('Name muss eindeutig sein', true);
            } else {
                setFormValues((prev) => ({
                    ...prev,
                    details: [
                        ...prev.details,
                        {
                            name: newEntry.name,
                            number: parseInt(newEntry.number, 10),
                        },
                    ],
                }));
                setNewEntry({ name: '', number: '' });
            }
        } else {
            showToast('Name und Nummer müssen ausgefüllt sein', true);
        }
    };

    const handleRemoveEntry = (name: string) => {
        setFormValues((prev) => ({
            ...prev,
            details: prev.details.filter((detail) => detail.name !== name),
        }));
    };

    return (
        <>
            <Modal
                centered
                show
                onHide={onModalClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button p-0"
                            >
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}>
                                    <FontAwesomeIcon width={30} icon={faXmark} />
                                    Schließen
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <Row>
                            <Col lg={6}>
                                <Card className="card-block card-stretch card-height">
                                    <Card.Body>
                                        <h5>Allgemein</h5>
                                        <FormGroup<FormValues>
                                            id="title"
                                            label="Titel"
                                            value={formValues.title}
                                            onChange={(e) => handleInputChange(e, setFormValues)}
                                        />
                                        <FormGroup<FormValues>
                                            id="description"
                                            label="Beschreibung"
                                            value={formValues.description}
                                            onChange={(e) => handleInputChange(e, setFormValues)}
                                            type='textarea'
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className="card-block card-stretch card-height">
                                    <Card.Body>
                                        <h5>Aircall Nummern</h5>
                                        {formValues.details.map((detail) => (
                                            <Row key={detail.name} className="mb-2 d-flex align-items-center">
                                                <Col>
                                                    {detail.name}: <span className='text-black'>{detail.number}</span>
                                                </Col>
                                                <Col xs="auto">
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => handleRemoveEntry(detail.name)}
                                                    >
                                                        <FontAwesomeIcon icon={faXmark} />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Row className="mb-3">
                                            <Col>
                                                <FormGroup
                                                    id="newEntryName"
                                                    label="Name"
                                                    value={newEntry.name}
                                                    onChange={(e) =>
                                                        setNewEntry((prev) => ({
                                                            ...prev,
                                                            name: e.target.value,
                                                        }))
                                                    }
                                                    marginBottom='0'
                                                />
                                            </Col>
                                            <Col>
                                                <FormGroup
                                                    id="newEntryNumber"
                                                    label="Nummer"
                                                    value={newEntry.number}
                                                    onChange={(e) =>
                                                        setNewEntry((prev) => ({
                                                            ...prev,
                                                            number: e.target.value,
                                                        }))
                                                    }
                                                    marginBottom='0'
                                                />
                                            </Col>
                                            <Col xs="auto" className="d-flex align-items-end">
                                                <Button variant="secondary" onClick={handleAddEntry}>
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>

            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default AircallNumbersModal;
