import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, ColumnMapping, FieldConfig, NotificationToast, SortCaret, usePermissions, useResetUrlParams, useSelection, useSortableData, useTableHeight, useToast } from 'c1g-ui-library';
import {
    memo,
    useEffect,
    useState,
} from 'react';
import { Button, Col, Dropdown, Form, Row, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ColumnSelection from '../../components/ColumnSelection';
import {
    ComboButtonId,
} from '../../components/ComboButtonGroup';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';
import PortalWrapper from '../../components/PortalWrapper';
import SearchInput from '../../components/SearchInput';
import ViewSelector from '../../components/ViewSelector';
import Card from '../../components/bootstrap/card';
import { DefaultColumnRender } from '../../components/table/DefaultColumnRender';
import DynamicPagination from '../../components/table/DynamicPagination';
import PaginationInfo from '../../components/table/PaginationInfo';
import SaveCancelPopup from '../../components/table/SaveCancelPopup';
import TableNoDataMessage from '../../components/table/TableNoDataMessage';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import { defaultRenderHeader } from '../../components/table/utils';
import { Education, Permissions } from '../../interfaces';
import {
    PermissionsEnum,
} from '../../utils/enum';
import { addPrefixToFilters, fetchAndCombineFieldConfigs, getFieldConfigByResourceName } from '../../utils/utils';

export interface EducationFilters {
  // no filters for now
}

interface EducationsResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  searchFilters: string[];
  list: Education[];
}

const Educations = memo(() => {
  useTableHeight();
  const navigate = useNavigate();
  const resetUrlParams = useResetUrlParams();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedSearchColumn, setSelectedSearchColumn] = useState<ComboButtonId | ''>('all');
  const [educations, setEducations] = useState<Education[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [lastQueryParams, setLastQueryParams] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const [showPopup, setShowPopup] = useState(false);
  const { requestSort, sortConfig, setSortConfig, getSortDirection } = useSortableData(educations, showPopup);
  const [pendingChanges, setPendingChanges] = useState<{ [key: string]: any }>({});
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const [editableCell, setEditableCell] = useState<{ rowId: number | null; columnKey: string | null }>({
    rowId: null,
    columnKey: null,
  });
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<EducationFilters>({
  });

  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const {
    selectedItems: selectedEducations,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow
  } = useSelection(educations);

  // Fetch the list of educations based on the current filters, search, sort and pagination
  const fetchEducations = async () => {
    setEducations([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (limit.toString()) {
      queryParams += `&limit=${limit}`;
    }
    if (searchQuery) {
      queryParams += `&search=${encodeURIComponent(searchQuery)}`;
      if (selectedSearchColumn !== 'all') {
        queryParams += `&column=${encodeURIComponent(selectedSearchColumn)}`;
      }
    }

    if (sortConfig?.field) {
      queryParams += `&sort[field]=${encodeURIComponent(sortConfig.field)}`;
      queryParams += `&sort[type]=${sortConfig.type}`;
    }

    try {
      const response = await ApiClient.get(`/educations${queryParams}`);
      setLastQueryParams(queryParams)
      const educationResponse = response.data as EducationsResponse;
      setTotalPages(educationResponse.amountPages);
      setEducations(educationResponse.list ?? []);
      setCurrentPage(educationResponse.page);
      setLimit(educationResponse.itemsPerPage);
      setTotalEntries(educationResponse.amountAllItems);
      setAvailableFilter(addPrefixToFilters(educationResponse.searchFilters, 'educations'));
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to trigger fetching of educations when filters, search, paginationm or other dependenies change
  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewEducations, 'read');

      if (hasPermission) {
        fetchEducations();

        // Fetch only once
        if (!fieldConfigs || Object.keys(fieldConfigs).length === 0) {
          fetchAndCombineFieldConfigs(['educations'], setFieldConfigs);
        }

      } else {
        navigate('/errors/error404');
      }
    }
  }, [selectedFilters, currentPage, searchQuery, selectedSearchColumn, selectedColumns, limit, sortConfig]);

  // Effect to reset search input
  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);

  // Routes to detail view
  const handleRouteToDetailView = (educationsId: number) => {
    navigate(`/${companyId}/educations/${educationsId}`);
  };

  // Fetch educations again after an update, optionally showing a message
  const handleEducationsUpdateSubmit = (message?: string, isError?: boolean) => {
    fetchEducations();
    if (message) {
      showToast(message, isError);
    }
  };

  // Resets all filters and search fields
  const resetSearch = () => {
    resetUrlParams();
    setSearchQuery('');
    setSelectedSearchColumn('all');
    setCurrentPage(1);
    setResetSearchInput(true);
  };

  // Handles view selection changes from the view selection component.
  const handleSelectionChange = (
    selectedColumns: string[],
    selectedFilters: any,
    selectedSortConfig: any,
    selectedLimit: number,
    selectedSearchTerm: string,
    selectedSearchColumn: string
  ) => {
    resetSearch()
    setSelectedColumns(selectedColumns);
    setSelectedFilters(selectedFilters);
    setSortConfig(selectedSortConfig);
    setLimit(selectedLimit);

    if (selectedSearchTerm || selectedSearchColumn) {
      setSearchQuery(selectedSearchTerm);
      setSelectedSearchColumn(selectedSearchColumn);
    }
  };

  // Handle clicks on table cells for editing
  const handleCellClick = (rowId: number | null, columnKey: string | null) => {
    setEditableCell({ rowId, columnKey });
  };

  // Handle changes to table cells for editing
  const handleFieldChange = (rowId: number, columnKey: string, value: any) => {
    setPendingChanges(prevChanges => ({
      ...prevChanges,
      [`${rowId}-${columnKey}`]: { rowId, columnKey, value },
    }));
    setShowPopup(true);
  };

  // Save changes made in editable cells
  const handleSaveChange = async () => {
    const changesToSubmit = Object.values(pendingChanges);

    try {
      for (const change of changesToSubmit) {
        if (typeof change.value === 'object' && change.value !== null) {
          const relation = change.columnKey.split('.')[0];
          await ApiClient.put(`/educations/${change.rowId}`, { [`${relation}_id`]: change.value.value });
        } else {
          await ApiClient.put(`/educations/${change.rowId}`, {
            [getFieldConfigByResourceName(fieldConfigs, change.columnKey)?.fieldName as string]: change.value
          });
        }
      }

      setPendingChanges({});
      setEditableCell({ rowId: null, columnKey: null });

      await fetchEducations();
      showToast('Erfolgreich gespeichert', false);
      setShowPopup(false);
    } catch (error: any) {
      console.error(error.message as AxiosError);
    }
  };


  // Revert a single change in editable cell
  const handleRevertChange = () => {
    const { rowId, columnKey } = editableCell;

    if (rowId === null || columnKey === null) return;

    const newPendingChanges = { ...pendingChanges };
    delete newPendingChanges[`${rowId}-${columnKey}`];

    setPendingChanges(newPendingChanges);
    setEditableCell({ rowId: null, columnKey: null });
    setShowPopup(Object.keys(newPendingChanges).length > 0);
  };

  // Revert all changes made in the editable cells
  const handleRevertChanges = () => {
    setPendingChanges({});
    setEditableCell({ rowId: null, columnKey: null });
    setShowPopup(false);
  };

  // Handle search input change
  const handleSearch = (data: { query: string; filter?: string }) => {
    const { query, filter } = data;

    if (query) {
      setSearchQuery(query);
      setSelectedSearchColumn(filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  // Define how special columns should be rendered
  const columnMapping: { [key: string]: ColumnMapping<Education> } = {
    'educations.title': {
      renderHeader: (key: any) => (
        <th
          key={key}
          className="sticky-col cursor-pointer"
          scope="col"
          title="Titel"
          onClick={() => requestSort('educations.title')}
        >
          <div className="d-flex align-items-center position-relative table-cell-wrap max-w-100">
            <Form.Check
              disabled={!userHasPermissionByRight(PermissionsEnum.ViewEducations, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewEducations, 'delete')}
              className="me-3"
              type="checkbox"
              checked={isAllSelected}
              onChange={handleSelectAll}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            Titel <div className="position-absolute" style={{ right: 0 }}><SortCaret direction={getSortDirection('educations.title')} /></div>
          </div>
        </th>
      ),

      render: (education: Education) => (
        <td key={education.id} className="sticky-col py-3">
          <div className="d-flex align-items-center justify-content-start">
            <Form.Check
              disabled={!userHasPermissionByRight(PermissionsEnum.ViewEducations, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewEducations, 'delete')}
              className="me-3"
              type="checkbox"
              checked={selectedEducations[education.id] ?? false}
              onChange={() => { }}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectRow(education.id, e);
              }}
            />
            <Link
              to={`/${companyId}/educations/${education.id}`}
              className="btn-link ps-0 text-start table-cell-wrap max-w-table-title"
              title={education.title ?? ''}
            >
              {education.title}
            </Link>
          </div>
        </td>
      ),
    },
  };

  return (
    <div className='container-fluid p-40'>
      <div className='table-controls-wrapper'>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <h3>Kursverwaltung</h3>
        </div>
        <Card className="card-block card-stretch card-height">
          <Card.Body>
            <Row className="d-flex justify-content-between mb-4">
              <Col md={9}>
                {searchQuery &&
                  <div className="d-flex align-items-baseline mb-3">
                    <h4 className="m-0">Suchergebnisse</h4>
                    <span className="ms-3 d-flex align-items-baseline">
                      <Button
                        className="btn-ghost m-0 p-0 fs-6"
                        variant="link"
                        onClick={resetSearch}
                      >
                        Suche beenden
                      </Button>
                    </span>
                  </div>
                }
                <ViewSelector
                  selectedSortConfig={sortConfig}
                  selectedFilters={selectedFilters}
                  selectedColumns={selectedColumns}
                  selectedLimit={limit}
                  selectedSearchColumn={selectedSearchColumn}
                  selectedSearchTerm={searchQuery}
                  entityType='educations'
                  onSelectionChange={handleSelectionChange}
                />
              </Col>
              <Col md={3}>
                <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} initialSearchTerm={searchQuery} initialSearchColumn={selectedSearchColumn} fieldConfigs={fieldConfigs} />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {selectedCount > 0 && (
          <GeneralSelectionActions
            selectedItems={selectedEducations}
            selectedCount={selectedCount}
            handleDeSelectAll={handleDeSelectAll}
            onSubmit={handleEducationsUpdateSubmit}
            amountAllItems={totalEntries}
            queryParams={lastQueryParams}
            entityType='educations'
          ></GeneralSelectionActions>
        )}
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table className="sticky-table" responsive="md" size="sm" style={{ overflow: 'auto' }}>
          <thead>
            <tr>
              {selectedColumns.map((columnKey) =>
                columnMapping[columnKey]?.renderHeader
                  ? columnMapping[columnKey].renderHeader!(columnKey)
                  : defaultRenderHeader(columnKey, fieldConfigs, requestSort, getSortDirection, 'educations')
              )}

              <th className="cursor-pointer text-end align-top sticky-right bg-grey w-40-px" scope="col">
                <ColumnSelection
                  selectedColumns={selectedColumns}
                  onSelectionChange={columns => setSelectedColumns(columns)}
                  fieldConfigs={fieldConfigs}
                  entityType='educations'
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={`skeleton-row-${index}`} columnCount={selectedColumns.length + 1} />
              ))
              : educations.map((education) => (
                <tr key={education.id} className="bg-white">
                  {selectedColumns.map((columnKey) =>
                    columnMapping[columnKey]
                      ? columnMapping[columnKey].render(education)
                      : <DefaultColumnRender
                        key={`${education.id}-${columnKey}`}
                        item={education}
                        columnKey={columnKey}
                        editableCell={editableCell}
                        handleCellClick={handleCellClick}
                        handleFieldChange={handleFieldChange}
                        handleRevertChange={handleRevertChange}
                        fieldConfigs={fieldConfigs}
                        pendingChanges={pendingChanges}
                        activeTooltip={activeTooltip}
                        setActiveTooltip={setActiveTooltip}
                        module={'educations'}
                      />
                  )}
                  <td className='sticky-right bg-white' key={education.id}>
                    <Dropdown>
                      <Dropdown.Toggle as="div" className="no-caret cursor-pointer d-inline-block">
                        <div className="px-2">
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </div>
                      </Dropdown.Toggle>
                      <PortalWrapper>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleRouteToDetailView(education.id)}><FontAwesomeIcon className='me-1' width={15} icon={faCircleInfo} /> Mehr Details</Dropdown.Item>
                        </Dropdown.Menu>
                      </PortalWrapper>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            {!isLoading && educations.length === 0 && (
              <TableNoDataMessage
                message="Keine Kurse"
              />
            )}
          </tbody>
        </Table>
      </div>

      {totalEntries > 0 && (
        <div className='pagination-wrapper pt-2'>
          <Row>
            <Col>
              <PaginationInfo
                currentPage={currentPage}
                limit={limit}
                totalEntries={totalEntries}
                onLimitChange={(size) => {
                  setLimit(size);
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <DynamicPagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
          </Row>
        </div>
      )}

      {showPopup && (
        <SaveCancelPopup
          onSave={handleSaveChange}
          onAbort={handleRevertChanges}
          pendingChangesCount={Object.keys(pendingChanges).length}
        />
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
});

export default Educations;
