import React, { useState } from 'react';
import { Participant } from '../../../interfaces';
import { Button } from 'react-bootstrap';
import ParticipantInitialsComponent from '../../ParticipantInitialsComponent';
import StatusDisplay from '../StatusDisplay';
import { getInitials } from '../../../utils/utils';
import Skeleton from 'react-loading-skeleton';
import ParticipantHeaderDropdown from '../../ParticipantHeaderDropdown';
import { FieldConfig, usePermissions } from 'c1g-ui-library';
import { PermissionsEnum } from '../../../utils/enum';
import AddEditParticipantModal from '../modal/AddEditParticipantModal';
import InfoDialog from '../../InfoDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import ParticipantAccessLernweltModal from '../modal/ParticipantAccessLernweltModal';
import { Permissions } from '../../../interfaces';

interface ParticipantDetailsHeaderProps {
    isLoading?: boolean;
    participant?: Participant;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    fieldConfigs: FieldConfig[];
}

/**
 * ParticipantDetailsHeader Component
 * 
 * This component displays the header section for a participant's details. It includes the participant's
 * initials, title, status, and provides actions like editing or archiving the participant.
 * 
 * Props:
 * - `isLoading` (boolean): Indicates whether the component is in a loading state, displaying skeleton placeholders.
 * - `participant` (Participant): The participant data object containing details like title, ID, and status.
 * - `onSubmitSuccess` (function): Callback function triggered after a successful archive or edit action.
 * - `fieldConfigs` (FieldConfig[]): Array of field configurations for rendering dynamic options.
 * 
 * Example Usage:
 * 
 * <ParticipantDetailsHeader
 *   isLoading={false}
 *   participant={participantData}
 *   onSubmitSuccess={handleSuccess}
 *   fieldConfigs={fieldConfigs}
 * />
 */
const ParticipantDetailsHeader: React.FC<ParticipantDetailsHeaderProps> = ({
    isLoading,
    participant,
    onSubmitSuccess,
    fieldConfigs
}) => {
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showAccessLernweltModal, setShowAccessLernweltModal] = useState(false);
    const [dialogContent, setDialogContent] = useState<{ headline: string; message: string } | null>(null);

    const { userHasPermissionByRight } = usePermissions<Permissions>();

    const handleDropdownChange = (action: string) => {
        if (action === 'archive') {
            setShowArchiveModal(true);
        }

        if (action === 'viewAsParticipant') {
            setShowAccessLernweltModal(true);
        }

        if (action === 'openHubSpotTN') {
            const url = `https://app-eu1.hubspot.com/contacts/26690801/record/0-1/${participant?.hubspotId}`;
            window.open(url, '_blank');
        }
        if (action === 'openHubSpotLastDeal') {
            const url = `https://app-eu1.hubspot.com/contacts/26690801/record/0-3/${participant?.lastDeal.hubspotDealId}`;
            window.open(url, '_blank');
        }
    };

    return (
        <>
            <div className="d-flex gap-4 justify-content-start align-items-start align-items-lg-center flex-nowrap">
                <div>
                    <ParticipantInitialsComponent inHeader={true} size={64} initials={getInitials(participant?.title ?? '')}></ParticipantInitialsComponent>
                </div>
                <div className="d-flex flex-column gap-2 flex-shrink-1">
                    <h4 className="mb-0">{isLoading ? <Skeleton width={150} /> : participant?.title || '-'}</h4>
                    {participant && <StatusDisplay participant={participant} fieldConfigs={fieldConfigs} />}
                </div>

                <div className="d-flex mt-2 mt-lg-0 flex-shrink-0 ms-auto">
                    <ParticipantHeaderDropdown
                        disabled={!!dialogContent}
                        participant={participant}
                        onActionClick={handleDropdownChange}
                    ></ParticipantHeaderDropdown>
                    <Button
                        disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write') || !participant || !fieldConfigs}
                        variant="primary"
                        onClick={() => setShowAddEditModal(true)}
                    >
                        Bearbeiten
                    </Button>
                </div>
                
            </div>
            


            {showAddEditModal && (
                <AddEditParticipantModal
                    participant={participant}
                    modalTitle="Teilnehmer bearbeiten"
                    onSubmitSuccess={onSubmitSuccess}
                    onModalClose={() => setShowAddEditModal(false)}
                    fieldConfigs={fieldConfigs}
                />
            )}

            {dialogContent && (
                <InfoDialog onClose={() => setDialogContent(null)} title={dialogContent.headline}>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <span className="text-black text-break">{dialogContent.message}</span>
                        <FontAwesomeIcon icon={faCircleCheck} className="text-success ms-2" size="lg" />
                    </div>
                </InfoDialog>
            )}

            {showAccessLernweltModal && participant?.id && (
                <ParticipantAccessLernweltModal
                    participantId={participant.id}
                    onClose={() => setShowAccessLernweltModal(false)}
                />
            )}
        </>
    );
};

export default ParticipantDetailsHeader;
