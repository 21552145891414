import { Card, CardBody, CardHeader, CardTitle, Col, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarChart } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useRef, useState } from "react";
import { ApiClient } from "c1g-ui-library";
import { AxiosError } from "axios";

const AuditKPIs = (): JSX.Element => {
    const initialized = useRef(false);
    const locale = document.documentElement.lang || navigator.language || 'en-EN';
    const intl = new Intl.NumberFormat(locale, {minimumFractionDigits: 2, maximumFractionDigits: 2})
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState({
        satisfaction: 0,
        integrations: 0,
        dropouts: 0,
        compliants: 0,
        presences: 0,
        absences: 0
    });
    
    useEffect(() => {
        if (initialized.current) return;
        initialized.current = true;

        (async () => {
            try {
                const { data } = await ApiClient.get(`/dashboard/auditKpis`);

                stats.satisfaction = data.satisfaction.percentage;
                stats.integrations = data.deals.integration_percentage
                stats.dropouts = data.deals.dropout_percentage
                stats.compliants = data.complaints.percentage
                stats.presences = data.presences.percentage
                stats.absences = data.absences.percentage

                setStats(stats);
            } catch (error: any) {
                console.error(error.message as AxiosError);
            } finally {
                setTimeout(setIsLoading.bind(this, false), 200);
            }
        })()
    }, [])

    return <Card>        
        <CardHeader>
            <CardTitle as="h4">
                <FontAwesomeIcon icon={faBarChart} /> Audit-KPIs
            </CardTitle>
        </CardHeader>
        <CardBody className="position-relative">
            { isLoading &&
                <div className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center">
                    <Spinner animation="border" role="status" className="position-absolute">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
            <div className={`${isLoading && "invisible"}`}>
                <Row>
                    <Col xs="6" sm="4" md="3" lg="5" className="text-truncate">Kundenzufriedenheit</Col>
                    <Col xs="6" sm="3" className="text-end">{intl.format(stats.satisfaction)}%</Col>
                </Row>
                <Row>
                    <Col xs="6" sm="4" md="3" lg="5" className="text-truncate">Integrationsquote</Col>
                    <Col xs="6" sm="3" className="text-end">{intl.format(stats.integrations)}%</Col>
                </Row>
                <Row>
                    <Col xs="6" sm="4" md="3" lg="5" className="text-truncate">Abbruchquote</Col>
                    <Col xs="6" sm="3" className="text-end">{intl.format(stats.dropouts)}%</Col>
                </Row>
                {/*
                <Row>
                    <Col xs="6" sm="4" md="3" lg="5" className="text-truncate">Beschwerdequote</Col>
                    <Col xs="6" sm="3" className="text-end">{intl.format(stats.compliants)}%</Col>
                </Row>
                <Row>
                    <Col xs="6" sm="4" md="3" lg="5" className="text-truncate">Anwesenheitsquote</Col>
                    <Col xs="6" sm="3" className="text-end">{intl.format(stats.presences)}%</Col>
                </Row>
                <Row>
                    <Col xs="6" sm="4" md="3" lg="5" className="text-truncate">Fehlzeitenquote</Col>
                    <Col xs="6" sm="3" className="text-end">{intl.format(stats.absences)}%</Col>
                </Row>
                */}
            </div>
        </CardBody>
    </Card>
}

export default AuditKPIs;