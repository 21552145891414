import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";
import { ApiClient, usePermissions, useSortableData, useToast } from "c1g-ui-library";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AbsenceReport, Permissions } from "../../../interfaces";
import { PermissionsEnum } from "../../../utils/enum";
import { formatDate } from "../../../utils/utils";
import SkeletonRow from "../../table/skeletonRow/SkeletonRow";
import SendMailModal from "../modal/SendMailModal";

interface AbsenceReportListProps {
  dealId: number;
}

/**
 * EngagementsList Component
 *
 * This component displays a sortable table of engagements associated with a specific deal.
 * It fetches data from an API, applies user permissions, and dynamically renders the table rows.
 * Each row includes engagement details such as the calendar week, time period, absence status,
 * overall status, and downloadable forms.
 *
 * Props:
 * - `dealId` (number): The ID of the deal for which engagements are to be displayed.
 */
const AbsenceReportList: React.FC<AbsenceReportListProps> = ({ dealId }) => {
  const navigate = useNavigate();;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const [absenceReport, setAbsenceReport] = useState<AbsenceReport[]>([]);
  const { items: sortedAbsenceReport, requestSort } = useSortableData(absenceReport);

  const [sendMailConfig, setSendMailConfig] = useState<{
    title: string;
    email: string;
    dealId: number;
    type?: number;
    id?: number;
  } | null>(null);

  const { showToast } = useToast();

  const fetchAbsenceReport = async (dealId: number) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/absencesreports?deal_id=${dealId}`);
      const absenceResponse = response.data;
      const absenceReportArray = Object.values(absenceResponse.list) as AbsenceReport[]
      setAbsenceReport(absenceReportArray);
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccess = (message?: string) => {
    if (message) {
      showToast(message);
    }
    fetchAbsenceReport(dealId);
    setSendMailConfig(null);
  };

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(
        PermissionsEnum.ViewParticipants,
        "read"
      );

      if (hasPermission) {
        fetchAbsenceReport(dealId);
      } else {
        navigate("/errors/error404");
      }
    }
  }, [permissionsLoaded]);

  return (
    <>
      <div className="absence-report-list" style={{ overflowX: "auto" }}>
        <Table responsive="md" size="sm">
          <thead>

            <tr>
              {/* Title */}
              <th
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
                scope="col"
                onClick={() => requestSort("title")}
              >
                Titel
              </th>

              {/* Year */}
              <th scope="col" onClick={() => requestSort("year")}>
                Jahr
              </th>

              {/* Month */}
              <th scope="col" onClick={() => requestSort("month")}>
                Monat
              </th>

              {/* Gesendet am */}
              <th scope="col" onClick={() => requestSort("sentAt")}>
                Gesendet am
              </th>

              {/* Formular Modal */}
              <th scope="col">
                <button className="border-0 bg-transparent" disabled>
                  <FontAwesomeIcon icon={faEnvelope} />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : absenceReport.map((report: any) => (
                <tr key={report.id}>
                  <td>{report.title ? report.title : "-"}</td>
                  <td>{report.year ? report.year : "-"}</td>
                  <td>{report.month ? report.month : "-"}</td>
                  <td>{report.sentAt ? formatDate(report.sentAt, `d.m.yy`) : "-"}</td>
                  <td>
                    <button className="btn-light-ghost rounded" onClick={() => setSendMailConfig({
                      title: "",
                      email: "",
                      dealId: dealId,
                      type: 1,
                      id: report.id
                    })}>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && absenceReport.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: "50px" }}
          >
            <p className="p-0 m-0">Keine Fehlzeitenberichte gefunden</p>
          </div>
        )}
        {sendMailConfig && (
          <SendMailModal
            dealId={dealId}
            email=""
            title="Fehlzeitenbericht senden"
            type={3}
            onModalClose={() => setSendMailConfig(null)}
            onSubmitSuccess={handleSuccess}
            id={sendMailConfig.id}

          />
        )}
      </div>
    </>
  );
};

export default AbsenceReportList;
