import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { ApiClient } from 'c1g-ui-library';
import SelectGroup from '../../form/SelectGroup';
import FormGroup from '../../form/FormGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { useToast } from 'c1g-ui-library';
import { AxiosError } from 'axios';

interface ModuleActionConfirmationModalProps {
    dealId: number;
    lwId: string;
    moduleTitle: string;
    action: string;
    onClose: () => void;
}

interface FormValues {
    reasonKey: string;
    reason: string;
}

interface ReasonOption {
    value: string;
    label: string;
    text: string;
}

/**
 * ModuleActionConfirmationModal Component
 * 
 * This modal is used to confirm and execute various actions on a module.
 * Actions include:
 * - Unlocking (`moduleUnlock`) → Freischalten
 * - Locking (`moduleLock`) → Sperren
 * - Setting progress to 0% (`setEmptyProgress`) → Fortschritt zurücksetzen
 * - Setting progress to 100% (`setFullProgress`) → Fortschritt speichern
 * 
 * Props:
 * - `dealId` (number) → The deal ID the module belongs to.
 * - `lwId` (string) → The module's unique learning ID.
 * - `moduleTitle` (string) → The title of the module being modified.
 * - `action` (string) → The action type (`moduleUnlock`, `moduleLock`, `setEmptyProgress`, `setFullProgress`).
 * - `onClose` (function) → Callback function to close the modal.
 */
const ModuleActionConfirmationModal: React.FC<ModuleActionConfirmationModalProps> = ({
    dealId,
    lwId,
    moduleTitle,
    action,
    onClose
}) => {
    const [formValues, setFormValues] = useState<FormValues>({ reasonKey: '', reason: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [reasons, setReasons] = useState<ReasonOption[]>([]);
    const { showToast } = useToast();

    useEffect(() => {
        const fetchReasons = async () => {
            try {
                const response = await ApiClient.get(`/settings?ident=reasonsModuleAccess`);
                const reasonsData = response.data.list[0].details;
                const actionReasons = reasonsData[action] || {};

                const options: ReasonOption[] = [
                    { value: '', label: 'Bitte auswählen', text: '' },
                    ...Object.entries(actionReasons).map(([key, value]) => {
                        const reason = value as { label: string; text: string };
                        return {
                            value: key,
                            label: reason.label,
                            text: reason.text
                        };
                    })
                ];

                setReasons(options);
            } catch (error: any) {
                console.error('Error fetching reasons:', error.message as AxiosError);
            }
        };

        fetchReasons();
    }, []);

    const isReasonValid = formValues.reason.length >= 100;
    const isFormValid = formValues.reasonKey.length > 0 && (formValues.reasonKey !== 'other' || isReasonValid);

    const handleConfirm = async () => {
        setIsLoading(true);
        try {
            const selectedReason = reasons.find(r => r.value === formValues.reasonKey);
            const textToSend = formValues.reasonKey === 'other' ? formValues.reason : selectedReason?.text || '';

            let payload: any = {
                lwId,
                reason: textToSend
            };

            let endpoint = `/deals/${dealId}`;
            let successMessage = `"${moduleTitle}" wurde erfolgreich`;
            switch (action) {
                case 'moduleUnlock':
                    endpoint = `${endpoint}/moduleAccess`;
                    payload.locked = false;
                    successMessage = `${successMessage} freigeschaltet.`;
                    break;
                case 'moduleLock':
                    endpoint = `${endpoint}/moduleAccess`;
                    payload.locked = true;
                    successMessage = `${successMessage} gesperrt.`;
                    break;
                case 'setEmptyProgress':
                    endpoint = `${endpoint}/learningProgress`;
                    payload.complete = false;
                    successMessage = `${successMessage} auf 0% zurückgesetzt.`;
                    break;
                case 'setFullProgress':
                    endpoint = `${endpoint}/learningProgress`;
                    payload.complete = true;
                    successMessage = `${successMessage} auf 100% gesetzt.`;
                    break;
                default:
                    throw new Error('Unbekannte Aktion');
            }

            await ApiClient.post(endpoint, payload);
            showToast(successMessage);
            onClose();
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsLoading(false);
        }
    };

    const actionLabels: Record<string, { title: string; reasonLabel: string; buttonText: string, buttonVariant: string }> = {
        moduleUnlock: {
            title: `"${moduleTitle}" freischalten`,
            reasonLabel: 'Grund der Freischaltung',
            buttonText: 'Freischalten',
            buttonVariant: 'success'
        },
        moduleLock: {
            title: `"${moduleTitle}" sperren`,
            reasonLabel: 'Grund der Sperrung',
            buttonText: 'Sperren',
            buttonVariant: 'danger'
        },
        setEmptyProgress: {
            title: `"${moduleTitle}" auf 0% setzen`,
            reasonLabel: 'Grund für Zurücksetzen des Fortschritts',
            buttonText: 'Fortschritt zurücksetzen',
            buttonVariant: 'warning'
        },
        setFullProgress: {
            title: `"${moduleTitle}" auf 100% setzen`,
            reasonLabel: 'Grund für das Setzen des Fortschritts auf 100%',
            buttonText: 'Fortschritt speichern',
            buttonVariant: 'primary'
        }
    };

    const { title, reasonLabel, buttonText, buttonVariant } = actionLabels[action] || {
        title: 'Aktion bestätigen',
        reasonLabel: 'Grund auswählen',
        buttonText: 'Bestätigen',
        buttonVariant: 'primary'
    };

    return (
        <Modal show onHide={onClose} centered>
            <Modal.Body>
                <h4 className="mb-3">{title}</h4>

                <Form>
                    <SelectGroup
                        id="reason"
                        label={reasonLabel}
                        options={reasons}
                        value={formValues.reasonKey}
                        onChange={(e) => setFormValues({ ...formValues, reasonKey: e.target.value })}
                    />

                    {formValues.reasonKey === 'other' && (
                        <>
                            <FormGroup
                                id="reason"
                                label="Begründung"
                                type="textarea"
                                value={formValues.reason}
                                onChange={(e) => setFormValues({ ...formValues, reason: e.target.value })}
                                noResize
                            />
                            <div className={`text-end mt-1 ${isReasonValid ? 'text-success' : 'text-danger'}`}>
                                {isReasonValid ? (
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} className="text-success me-1" />
                                        Zeichen erreicht
                                    </>
                                ) : (
                                    `${formValues.reason.length}/100 Zeichen benötigt`
                                )}
                            </div>
                        </>
                    )}

                    <div className="d-flex justify-content-end mt-4">
                        <Button onClick={onClose} className="btn btn-soft-primary me-2" disabled={isLoading}>
                            Abbrechen
                        </Button>
                        <Button
                            variant={buttonVariant}
                            onClick={handleConfirm}
                            disabled={!isFormValid || isLoading}
                        >
                            {buttonText}
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModuleActionConfirmationModal;
