import { faEllipsis, faGraduationCap, faDownload, faArchive } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PortalWrapper from './PortalWrapper';
import { faHubspot, faIntercom } from '@fortawesome/free-brands-svg-icons';
import { usePermissions } from 'c1g-ui-library';
import { Participant, Permissions } from '../interfaces';

interface ParticipantHeaderDropdownProps {
    disabled?: boolean;
    onActionClick: (action: string) => void;
    participant?: Participant
}

/**
 * ParticipantHeaderDropdown Component
 * 
 * This component renders a dropdown menu with various actions that can be triggered for a participant.
 * It uses FontAwesome icons to visually represent each action in the dropdown.
 * 
 * Props:
 * - `onActionClick`: A callback function that is triggered when an action is selected. 
 *   It receives a string parameter representing the selected action.
 * - `participant`: The participant object.
 * - `disabled`: Should the button be disabled.
 */
const ParticipantHeaderDropdown: React.FC<ParticipantHeaderDropdownProps> = ({ onActionClick, participant, disabled }) => {
    const { userHasPermissionByRight } = usePermissions<Permissions>();

    const handleActionClick = (action: string) => () => {
        onActionClick(action);
    };

    return (
        <Dropdown className="me-2 rounded">
            <Dropdown.Toggle
                disabled={disabled}
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow h-100 py-2 px-3"
            >
                <FontAwesomeIcon icon={faEllipsis} />
            </Dropdown.Toggle>

            <PortalWrapper>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={handleActionClick('viewAsParticipant')}>
                        <FontAwesomeIcon className="text-primary" width={20} icon={faGraduationCap} /> Lernwelt als Teilnehmer sehen
                    </Dropdown.Item>
                    {/* <Dropdown.Item disabled onClick={handleActionClick('openIntercom')}>
                        <FontAwesomeIcon className="text-primary" width={20} icon={faIntercom} /> Intercom aufrufen
                    </Dropdown.Item> */}
                    <Dropdown.Item onClick={handleActionClick('openHubSpotTN')}>
                        <FontAwesomeIcon className="text-primary" width={20} icon={faHubspot} /> HubSpot (TN) aufrufen
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleActionClick('openHubSpotLastDeal')}>
                        <FontAwesomeIcon className="text-primary" width={20} icon={faHubspot} /> HubSpot (Aktueller Deal) aufrufen
                    </Dropdown.Item>
                    {/* <div className="horizontal-line mt-2 mb-1"></div>
                    <Dropdown.Item disabled onClick={handleActionClick('downloadAudit')}>
                        <FontAwesomeIcon className="text-primary" width={20} icon={faDownload} /> Audit download
                    </Dropdown.Item> */}
                    {/* <div className="horizontal-line mt-1 mb-2"></div>

                    {
                        participant?.status !== 3 ?
                            <Dropdown.Item disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write')} onClick={handleActionClick('archive')}>
                                <FontAwesomeIcon className="text-danger" width={20} icon={faArchive} /> Archivieren

                            </Dropdown.Item> :

                            <Dropdown.Item disabled onClick={handleActionClick('delete')}>
                                <FontAwesomeIcon className="text-danger" width={20} icon={faArchive} /> Löschen
                            </Dropdown.Item>
                    } */}

                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default ParticipantHeaderDropdown;
