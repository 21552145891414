import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Stage, Container, Sprite, Text } from '@pixi/react';
import * as PIXI from 'pixi.js';
import { TextStyle } from 'pixi.js';
import stempelLogoOc from '../../assets/images/stempel_logo_oc.png';
import stempelLogoCn from '../../assets/images/stempel_logo_cn.png';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairsSimple, faMagnifyingGlassMinus, faMagnifyingGlassPlus } from '@fortawesome/pro-regular-svg-icons';
import FormGroup from './../form/FormGroup';
import { ApiClient } from 'c1g-ui-library';
import { Deal } from '../../interfaces';
import SelectGroup from './../form/SelectGroup';
import { AxiosError } from 'axios';
import { useToast } from 'c1g-ui-library';

const labels = {
  actionNrLeft: "Maßnahmennummer, links",
  actionNrMiddle: "Maßnahmennummer, mitte",
  actionNrRight: "Maßnahmennummer, rechts",
  designation: "Maßnahmenbezeichnung",
  start: "Maßnahmendauer, Beginn",
  end: "Maßnahmendauer, Ende",
  participant: "Maßnahmendauer, Teilnehmer (Name)",
  participationStart: "Teilnahme, Beginn",
  participationEnd: "Teilnahme, Ende",
  durationWeeks: "Dauer in Wochen",
  modules: "Maßnahmenbausteine",
  locationDate: "Ort und Datum",
  imageScale: "Logo + Signatur",
};

const fontSizeOptions = [
  { value: '12', label: '12' },
  { value: '16', label: '16' },
  { value: '20', label: '20' },
  { value: '24', label: '24' },
  { value: '28', label: '28' },
  { value: '32', label: '32' },
  { value: '36', label: '36' },
  { value: '40', label: '40' },
  { value: '44', label: '44' },
  { value: '48', label: '48' },
  { value: '52', label: '52' },
];

const imageScaleOptions = [
  { value: '0.25', label: 'Bildgröße: 25%' },
  { value: '0.50', label: 'Bildgröße: 50%' },
  { value: '0.75', label: 'Bildgröße: 75%' },
  { value: '1.00', label: 'Originalgröße (100%)' },
  { value: '1.25', label: 'Bildgröße: 125%' },
  { value: '1.50', label: 'Bildgröße: 150%' },
];

interface BGSImageEditorProps {
  imageUrl: string;
  initialFormValues?: any;
  inEditMode: boolean;
  onFormChange?: (hasChanged: boolean) => void;
  deal?: Deal
}

interface TextData {
  content?: string;
  x: number | null
  y: number | null
  fontSize?: number;
  scale?: number;
}

interface FormValues {
  actionNrLeft: TextData;
  actionNrMiddle: TextData;
  actionNrRight: TextData;
  designation: TextData;
  start: TextData;
  end: TextData;
  participant: TextData;
  participationStart: TextData;
  participationEnd: TextData;
  durationWeeks: TextData;
  modules: TextData;
  locationDate: TextData;
  imageScale: TextData;
}

const defaultFormValues: FormValues = {
  actionNrLeft: { content: '', x: null, y: null, fontSize: 44 },
  actionNrMiddle: { content: '', x: null, y: null, fontSize: 44 },
  actionNrRight: { content: '', x: null, y: null, fontSize: 44 },
  designation: { content: '', x: null, y: null, fontSize: 44 },
  start: { content: '', x: null, y: null, fontSize: 44 },
  end: { content: '', x: null, y: null, fontSize: 44 },
  participant: { content: '', x: null, y: null, fontSize: 44 },
  participationStart: { content: '', x: null, y: null, fontSize: 44 },
  participationEnd: { content: '', x: null, y: null, fontSize: 44 },
  durationWeeks: { content: '', x: null, y: null, fontSize: 44 },
  modules: { content: '', x: null, y: null, fontSize: 44 },
  locationDate: { content: '', x: null, y: null, fontSize: 44 },
  imageScale: { x: null, y: null, scale: 0.5 },
};

export interface ImageEditorRef {
  saveData: () => Promise<void>;
  resetFormValues: () => void;
  loadInitialData: () => void
}

/**
 * BGSImageEditor Component
 *
 * This component provides an interactive interface for editing and customizing images, 
 * specifically designed for managing text annotations and scaling elements for a "Bildungsgutschein" (BGS) document.
 * It leverages PIXI.js for rendering and React for handling state and user interactions.
 *
 * Props:
 * - imageUrl (string): URL of the image to be displayed and edited.
 * - initialFormValues (object, optional): Initial values for the form fields to prepopulate data.
 * - inEditMode (boolean): Toggles between viewing and editing modes.
 * - onFormChange (function, optional): Callback to notify if the form values have changed.
 * - deal (Deal, optional): Deal object containing data related to the document.
 */

const BGSImageEditor: React.ForwardRefRenderFunction<ImageEditorRef, BGSImageEditorProps> = ({ imageUrl, initialFormValues, inEditMode, onFormChange, deal }, ref) => {
  const companyId = sessionStorage.getItem('companyId') || 'oc';
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues || defaultFormValues);
  const [initialValues, setInitialValues] = useState<FormValues>({
    ...formValues,
  });

  const { showToast } = useToast();
  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialValues);
  };

  const [selectedTextId, setSelectedTextId] = useState<keyof FormValues | 'imageScale' | null>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [globalFontSize, setGlobalFontSize] = useState('');

  const stageRef = useRef<PIXI.Application | null>(null);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setSelectedTextId(null);
      }
    };
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    const container = document.querySelector('.image-container');
    if (container) {
      const containerWidth = container.clientWidth;
      const initialZoom = containerWidth / imageDimensions.width;
      setZoom(Math.min(initialZoom, 1));
    }
  }, [imageDimensions]);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
      calculateOptimalFontSize(img.width, img.height)
    };
  }, [imageUrl]);

  useEffect(() => {
    if (onFormChange) {
      onFormChange(checkIfDataChanged());
    }
  }, [formValues]);


  useEffect(() => {
    if (!inEditMode) {
      setSelectedTextId(null)
    }
  }, [inEditMode]);

  useImperativeHandle(ref, () => ({
    saveData,
    resetFormValues,
    loadInitialData
  }));

  const calculateOptimalFontSize = (imageWidth: number, imageHeight: number) => {
    const smallerDimension = Math.min(imageWidth, imageHeight);
    const optimalFontSize = Math.max(12, Math.floor(smallerDimension / 50));
    const closestFontSize = getClosestFontSize(optimalFontSize, fontSizeOptions)
    handleGlobalFontSizeChange(closestFontSize)
  };

  const getClosestFontSize = (optimalFontSize: number, fontSizeOptions: { value: string; label: string }[]): number => {
    return parseInt(fontSizeOptions.reduce((prev, curr) => {
      return Math.abs(parseInt(curr.value) - optimalFontSize) < Math.abs(parseInt(prev.value) - optimalFontSize) ? curr : prev;
    }).value, 10);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = event.target;
    setFormValues({
      ...formValues,
      [id]: {
        ...formValues[id as keyof FormValues],
        content: value,
      },
    });
  };

  const handleFontSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = event.target;
    setFormValues({
      ...formValues,
      [id]: {
        ...formValues[id as keyof FormValues],
        fontSize: parseFloat(value),
      },
    });
  };

  const handleGlobalFontSizeChange = (eventOrValue: React.ChangeEvent<HTMLSelectElement> | number) => {
    let newFontSize: number;
    const isTriggeredBySelect = typeof eventOrValue !== 'number';

    if (isTriggeredBySelect) {
      const selectedValue = eventOrValue.target.value;
      if (selectedValue === "") return;
      newFontSize = parseFloat(selectedValue);
      setGlobalFontSize(selectedValue);
    } else {
      newFontSize = eventOrValue;
      setGlobalFontSize(newFontSize.toString());
    }

    const updatedFormValues = Object.keys(formValues).reduce((acc, key) => {
      const field = formValues[key as keyof FormValues];

      acc[key as keyof FormValues] = {
        ...field,
        fontSize: isTriggeredBySelect
          ? newFontSize
          : field.content === ''
            ? newFontSize
            : field.fontSize,
      };

      return acc;
    }, {} as FormValues);

    setFormValues(updatedFormValues);
  };



  const handleImageScaleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const scale = parseFloat(event.target.value);
    setFormValues({
      ...formValues,
      imageScale: {
        ...formValues.imageScale,
        scale: scale,
      },
    });
  };

  const resetFormValues = () => {
    const updatedFormValues = Object.keys(formValues).reduce((acc, key) => {
      acc[key as keyof FormValues] = {
        ...formValues[key as keyof FormValues],
        x: null,
        y: null,
        scale: 0.5
      };
      return acc;
    }, {} as FormValues);

    setFormValues(updatedFormValues);
  };

  const handleStageClick = (event: any) => {
    if (selectedTextId !== null) {
      const { offsetX: x, offsetY: y } = event.nativeEvent;
      if (selectedTextId === 'imageScale') {
        setFormValues({
          ...formValues,
          imageScale: {
            ...formValues.imageScale,
            x: Math.round(x / zoom),
            y: Math.round(y / zoom),
          },
        });
      } else {
        const selectedText = formValues[selectedTextId];
        setFormValues({
          ...formValues,
          [selectedTextId]: {
            ...selectedText,
            x: Math.round(x / zoom),
            y: Math.round(y / zoom),
          },
        });
      }
    }
  };

  const saveData = async () => {
    try {
      await ApiClient.put(`/deals/${deal?.id}`, { measureData: JSON.stringify(formValues) });
      setSelectedTextId(null);
      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      console.error(error.message as AxiosError);
    }
  };

  const loadInitialData = () => {
    if (initialFormValues) {
      setFormValues(initialFormValues);
      setInitialValues(initialFormValues);
    } else {
      setFormValues(defaultFormValues);
      setInitialValues(defaultFormValues)
    }
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0));
  };

  const createImageScaleGroup = (
    label: string,
    options: { value: string; label: string }[],
  ) => (
    <Form.Group className='mb-5 text-black' controlId="imageScale">
      <Form.Label className='text-black'>{label}</Form.Label>
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <Button
            className={`${selectedTextId === 'imageScale' ? 'btn-primary pulsate' : 'btn-soft-primary'} round-modal-close-button me-2 p-0`}
            onClick={() => (selectedTextId === 'imageScale' ? setSelectedTextId(null) : setSelectedTextId('imageScale'))}
          >
            <FontAwesomeIcon icon={faCrosshairsSimple} size="sm" />
          </Button>
        </div>
        <Form.Select
          value={formValues.imageScale?.scale?.toFixed(2)}
          onChange={handleImageScaleChange}
          className='text-black bg-grey'
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      </div>
    </Form.Group>
  );

  const createFormRow = (
    id: keyof FormValues,
    label: string,
    fontSizeOptions: { value: string; label: string }[],
    placeholder?: string,
    required = false,
  ) => (
    <div className="d-flex flex-column mb-5" key={id}>
      <Form.Label className='text-black'>{label}</Form.Label>
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <Button
            className={`${selectedTextId === id ? 'btn-primary pulsate' : 'btn-soft-primary'} round-modal-close-button me-2 p-0`}
            onClick={() => (selectedTextId === id ? setSelectedTextId(null) : setSelectedTextId(id))}
          >
            <FontAwesomeIcon icon={faCrosshairsSimple} size="sm" />
          </Button>
        </div>
        <div className="me-2 w-150-px">
          <SelectGroup
            id={id}
            label=''
            options={fontSizeOptions}
            value={formValues[id]?.fontSize}
            onChange={handleFontSizeChange}
            required={required}
            marginBottom='0'
          />
        </div>
        <FormGroup
          id={id}
          type={id === 'modules' ? 'textarea' : 'text'}
          label=''
          value={formValues[id]?.content}
          required={required}
          onChange={handleInputChange}
          marginBottom='0'
        />
      </div>
    </div>
  );

  return (
    <Row className="h-100">
      <Col className="p-0 h-100 position-relative" lg={inEditMode ? 8 : 12}>
        <div className="p-0 h-100 image-container" style={{ overflow: 'auto', cursor: selectedTextId ? 'crosshair' : '' }}>
          <Stage
            width={imageDimensions.width * zoom}
            height={imageDimensions.height * zoom}
            options={{ backgroundColor: 0xffffff }}
            onPointerDown={handleStageClick}
            onMount={(app) => {
              stageRef.current = app;
            }}
          >
            <Container scale={{ x: zoom, y: zoom }}>
              <Sprite image={imageUrl} />

              {formValues.imageScale?.x !== null && formValues.imageScale?.y !== null &&
                <Sprite
                  image={companyId === 'oc' ? stempelLogoOc : stempelLogoCn}
                  anchor={{ x: 0, y: 1 }}
                  x={formValues.imageScale?.x}
                  y={formValues.imageScale?.y}
                  scale={{ x: formValues.imageScale?.scale!, y: formValues.imageScale?.scale! }}
                />}

              {Object.values(formValues).map((field, index) =>
                'content' in field && field?.x && field?.y ? (
                  <Text
                    key={index}
                    text={field.content}
                    x={field?.x}
                    y={field?.y}
                    anchor={{ x: 0, y: 1 }}
                    style={
                      new TextStyle({
                        fontSize: field.fontSize,
                        fill: 'black',
                      })
                    }
                  />
                ) : null
              )}
            </Container>
          </Stage>
        </div>
        <div className='position-absolute' style={{ top: 20, right: 20, zIndex: 10 }}>
          <Button
            variant="primary"
            className="round-modal-close-button mb-2 p-0"
            onClick={handleZoomOut}
          >
            <FontAwesomeIcon icon={faMagnifyingGlassMinus} size="sm" />
          </Button>
          <Button
            variant="primary"
            className="round-modal-close-button me-2 p-0"
            onClick={handleZoomIn}
          >
            <FontAwesomeIcon icon={faMagnifyingGlassPlus} size="sm" />
          </Button>
        </div>
      </Col>

      {inEditMode && (
        <Col lg={4} className="h-100" style={{ overflowY: 'auto' }}>
          <div className="bg-white p-3">
            <Form.Group className="text-black w-100 my-3">
              <Form.Label>Globale Schriftgröße</Form.Label>
              <Form.Select
                value={globalFontSize}
                onChange={handleGlobalFontSizeChange}
                className='text-black bg-grey'
              >
                <option value="">Bitte wählen</option>
                {fontSizeOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <p>Wähle eine globale Schriftgröße aus, um jede individuelle Schriftgröße zu überschreiben.</p>
            <div className="horizontal-line my-5"></div>
            <h5>Felder ausfüllen & platzieren</h5>
            <p>
              Fülle ein Textfeld aus, ändere ggf. die Schriftgröße und platziere es mit dem Setzen-Symbol{' '}
              <FontAwesomeIcon className="text-primary" icon={faCrosshairsSimple} size="sm" /> an eine beliebige Position im
              Dokument.
            </p>

            {createFormRow("actionNrLeft", labels.actionNrLeft, fontSizeOptions)}
            {createFormRow("actionNrMiddle", labels.actionNrMiddle, fontSizeOptions)}
            {createFormRow("actionNrRight", labels.actionNrRight, fontSizeOptions)}
            {createFormRow("designation", labels.designation, fontSizeOptions)}
            {createFormRow("start", labels.start, fontSizeOptions)}
            {createFormRow("end", labels.end, fontSizeOptions)}
            {createFormRow("participant", labels.participant, fontSizeOptions)}
            {createFormRow("participationStart", labels.participationStart, fontSizeOptions)}
            {createFormRow("participationEnd", labels.participationEnd, fontSizeOptions)}
            {createFormRow("durationWeeks", labels.durationWeeks, fontSizeOptions)}
            {createFormRow("modules", labels.modules, fontSizeOptions)}
            {createFormRow("locationDate", labels.locationDate, fontSizeOptions)}
            {createImageScaleGroup('Logo + Signatur', imageScaleOptions)}
          </div>
        </Col>
      )}
    </Row>
  );
};

export default forwardRef(BGSImageEditor);
