import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useToast } from '../services/context/ToastContext';
import { NotificationToast } from './NotificationToast';
/**
 * Global toast notification manager component that:
 * 1. Listens for API error events and displays error notifications
 * 2. Renders all toast notifications from the toast context (success and error)
 *
 * This component serves as the central display point for both API-related error
 * messages and manually triggered notifications throughout the application.
 *
 * @returns {JSX.Element} Component that renders toast notifications
 */
const ToastManager = () => {
    const { show, message, error, showToast, hideToast } = useToast();
    useEffect(() => {
        /**
         * Event handler for API errors
         * @param {CustomEvent} event - Custom event containing error details
         */
        const handleApiError = (event) => {
            showToast(event.detail.message, true);
        };
        // Add event listener
        window.addEventListener('api-error', handleApiError);
        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('api-error', handleApiError);
        };
    }, [showToast]);
    // Render the toast notification
    return (_jsx(NotificationToast, { show: show, onClose: hideToast, message: message, error: error }));
};
export { ToastManager };
