import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Webhook } from 'c1g-ui-library';
import AddEditWebhookModal from '../modal/AddEditWebhookModal';
import { ActiveInactiveMapping, WebhookModuleEnum, getEnumValue } from '../../../utils/enum';
import { getWebhookUrl } from '../../../utils/media/utils';
import Card from '../../bootstrap/card';
import LogAccordion from '../../LogAccordion';
import SkeletonCard from '../../skeleton/SkeletonCard';
import GenericInfosCard from '../../GenericInfosCard';

interface WebhookDetailsProps {
    isLoading?: boolean;
    webhook?: Webhook | null
    onSubmitSuccess: (webhookId: number) => void;
}

const WebhookDetails: React.FC<WebhookDetailsProps> = ({
    isLoading,
    webhook,
    onSubmitSuccess
}) => {
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: webhook?.title || '-',
            },
            {
                label: 'Methode',
                value: webhook?.method || '-',
            },
            {
                label: 'Modul',
                value: getEnumValue(WebhookModuleEnum, webhook?.module.toString()),
            },

        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Status',
                value: getEnumValue(ActiveInactiveMapping, webhook?.status?.toString()),
            },
            {
                label: 'Beschreibung',
                value: webhook?.description || '-',
            },
            {
                label: 'URL',
                value: webhook?.code ? getWebhookUrl(webhook) : '-',
                isCopyToClipboard: true
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{webhook?.title}</h4>
                {webhook && <AddEditWebhookModal
                    buttonName="Bearbeiten"
                    modalTitle={`${webhook.title} bearbeiten`}
                    webhook={webhook}
                    onSubmitSuccess={onSubmitSuccess}
                >
                </AddEditWebhookModal>
                }
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <h5 className="mb-3">Aktivitäten</h5>
                            {webhook && webhook.logs?.length > 0 ? (
                                <LogAccordion logs={webhook?.logs} />
                            ) : (
                                <div>Keine Aktivitäten gefunden</div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default WebhookDetails;
