import React from 'react';
import { Row, Col } from 'react-bootstrap';

import SkeletonCard from '../../../skeleton/SkeletonCard';
import GenericInfosCard from '../../../GenericInfosCard';
import { AbsenceReport } from '../../../../interfaces';
import { formatDate } from '../../../../utils/utils';
import { getMediaUrl } from '../../../../utils/media/utils';
interface AbsenceReportDetailsProps {
    isLoading?: boolean;
    absenceReport?: AbsenceReport | null
}

const AbsenceReportDetails: React.FC<AbsenceReportDetailsProps> = ({
    isLoading,
    absenceReport,
}) => {
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: absenceReport?.title || '-',
            },
            {
                label: 'Versendet am',
                value: absenceReport?.sentAt ? formatDate(absenceReport?.sentAt, 'dd.mm.yyyy') : '-',
            },
            {
                label: 'Jahr',
                value: absenceReport?.year || '-',
            },
            {
                label: 'Monat',
                value: absenceReport?.month || '-',
            },
            {
                label: 'Anzahl Abwesenheitstage',
                value: absenceReport?.amountAbsenceDays || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Fehlzeitenbericht',
                value: absenceReport?.media && (
                    <a href={getMediaUrl(absenceReport.media)} target="_blank" rel="noopener noreferrer">
                        {absenceReport.media.title}
                    </a>
                ),
            },
        ];
        return info2;
    }

    return (
        <>  
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default AbsenceReportDetails;
