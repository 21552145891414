import { faUserGraduate } from '@fortawesome/pro-regular-svg-icons';
import { AxiosError } from 'axios';
import { ApiClient, CollapsibleCard, FieldConfig, NotificationToast, useDocumentTitle, usePermissions, useTabState, useToast } from 'c1g-ui-library';
import { useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ComboButtonGroup, { ComboButton } from '../../components/ComboButtonGroup';
import CompanionAssignedDeals from '../../components/companions/detail/CompanionAssignedDeals';
import CompanionDetails from '../../components/companions/detail/CompanionDetails';
import CompanionDetailsHeader from '../../components/companions/detail/CompanionDetailsHeader';
import { Companion, Permissions } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';
import { fetchAndCombineFieldConfigs } from '../../utils/utils';

function CompanionDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'ClientOffice', moduleTranslations: moduleTranslations });;
  const { companionId } = useParams();
  const [companion, setCompanion] = useState<Companion>();
  const [selectedTab, setSelectedTab] = useTabState('companion_participant');
  const [isLoading, setIsLoading] = useState(false);

  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();
  const { show, message, error, showToast, hideToast } = useToast();
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);

  const comboButtons: ComboButton[] = useMemo(() => {
    return [
      { id: 'companion_participant', label: 'Teilnehmer', icon: faUserGraduate },
    ] as ComboButton[];
  }, [companion]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewCompanions, 'read');

      if (hasPermission) {
        companionId && fetchCompanion(companionId);
        if (!fieldConfigs || fieldConfigs.length === 0) {
          fetchAndCombineFieldConfigs(['companions'], setFieldConfigs);
        }
      } else {
        navigate('/errors/error404');
      }
    }
  }, [companionId, permissionsLoaded]);

  const fetchCompanion = async (companionId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/companions/${companionId}`);
      const companionResponse = response.data;
      setCompanion(companionResponse);
      setDynamicTitle(companionResponse.title)
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = (message?: string) => {
    setCompanion(undefined);
    companionId && fetchCompanion(companionId);
    if (message) {
      showToast(message, false);
    }
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col >
          <CompanionDetailsHeader
            companion={companion}
            isLoading={isLoading}
            onSubmitSuccess={handleModalSubmit}
            fieldConfigs={fieldConfigs}>
          </CompanionDetailsHeader>
        </Col>
      </Row>
      <Row>
        <Col lg={9}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedTab}
                    setSelectedCombo={(tab) => setSelectedTab(tab)}
                    borderRadius="normal"
                  ></ComboButtonGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col >
              {/* Collapsible Card Persona */}
              {selectedTab === 'companion_participant' &&
                <>
                  <CollapsibleCard
                    title="Zugewiesene Deals"
                    initialOpen
                    showDivider
                  >
                    <div className="p-3">
                      {companion && <CompanionAssignedDeals companionId={companion?.id}></CompanionAssignedDeals>}
                    </div>
                  </CollapsibleCard>
                </>
              }
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          {/* Collapsible Card Companion Details */}
          <CollapsibleCard
            title="Details"
            showDivider
            initialOpen
          >
            <div className='p-3'>
              <CompanionDetails companion={companion} isLoading={isLoading} fieldConfigs={fieldConfigs}></CompanionDetails>
            </div>
          </CollapsibleCard>
        </Col>
      </Row >

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default CompanionDetail;
