import { faPlus, faClock, faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import Card from "../../bootstrap/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComputerMouse, faFileCertificate } from "@fortawesome/pro-light-svg-icons";
import HoldButton from "../../HoldButton";
import { ApiClient } from 'c1g-ui-library';
import { useToast } from 'c1g-ui-library';
import { useState } from "react";
import InfoDialog from "../../InfoDialog";
import { Deal } from "../../../interfaces";
import { AxiosError } from "axios";

type DealProcessesProps = {
  deal: Deal
};

/**
 * DealProcesses Component
 * 
 * This component renders a list of process actions that can be performed on a deal.
 * Each process is triggered via a `HoldButton`, requiring the user to hold down before execution.
 * 
 * Features:
 * - **Dynamic API Calls**: Uses a single `handleProcessAction` function to handle different actions.
 * - **Visual Feedback**: Uses a modal (`InfoDialog`) or toast notifications to inform the user about the result.
 * 
 * Props:
 * - `deal` (Deal): The deal object containing relevant data.
 * 
 * How it Works:
 * - The `HoldButton` triggers the `handleProcessAction` function with a specific API endpoint.
 * - The `handleProcessAction` makes a request to the backend and handles the response.
 * - If a response requires a toast or a modal, it updates the state accordingly.
 */
const DealProcesses: React.FC<DealProcessesProps> = ({ deal }) => {
  const { showToast } = useToast();
  const [dialogContent, setDialogContent] = useState<{ headline: string; message: string } | null>(null);
  const [selectedDeal, setSelectedDeal] = useState<number | null>(deal.id ?? null);

  const processSectionsDeals = [
    {
      title: "Deal-spezifische Prozesse",
      buttons: [
        {
          label: "Teilnahmebescheinigung / Zertifikat erstellen",
          disabled: !deal.participant.lwActivated,
          icon: faFileCertificate,
          onConfirm: () => handleProcessAction(`/deals/${selectedDeal}/createNewCertificate`),
        },
        {
          label: "Offene/Fehlende Anwesenheiten versenden",
          icon: faClock,
          onConfirm: () => handleProcessAction(`/deals/${selectedDeal}/sendOpenPresences`),
        },
        {
          label: "Anlage des Maßnahmenträgers erstellen",
          icon: faPlus,
          onConfirm: () => handleProcessAction(`/deals/${selectedDeal}/measureAttachment/generate`),
        },
      ],
    },
  ];

  const handleProcessAction = async (
    url: string,
  ) => {
    try {
      const response = await ApiClient.post(url, {});

      if (response?.data?.type === "toast") {
        setDialogContent({
          headline: response.data.headline,
          message: response.data.message,
        });

        setTimeout(() => {
          setDialogContent(null);
        }, 7000);
      } else {
        showToast(`Erfolgreich`);
      }
    } catch (error: any) {
      console.error(error.message as AxiosError);
    }
  };

  return (
    <div className="deal-processes">
      <Card className="border rounded shadow-none card-block card-stretch card-height card">
        <Card.Body>
          <h4 className="text-light-black">Prozesse</h4>
          <p className="mb-5">
            <FontAwesomeIcon icon={faComputerMouse} /> Gedrückt halten zum Starten
          </p>

          {processSectionsDeals.map((section, index) => (
            <div key={index}>
              <h5 className="mb-3 text-light-black">{section.title}</h5>
              <div className="d-flex flex-wrap gap-2 mb-5">
                {section.buttons.map((button, btnIndex) => (
                  <HoldButton key={btnIndex} {...button} />
                ))}
              </div>
            </div>
          ))}
        </Card.Body>
      </Card>

      {dialogContent && (
        <InfoDialog onClose={() => setDialogContent(null)} title={dialogContent.headline}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="text-black text-break">{dialogContent.message}</span>
            <FontAwesomeIcon icon={faCircleCheck} className="text-success ms-2" size="lg" />
          </div>
        </InfoDialog>
      )}
    </div>
  );
};

export default DealProcesses;
