import { getFieldConfigByResourceName } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatEuro } from "../utils";
import { dealStatusIconMap } from "../../components/filter/iconAndColorMappings";
import { formatDateWithoutTime } from "../utils";
import { dealStatusColorMap } from "../../components/filter/iconAndColorMappings";
import { Deal } from "../../interfaces";
import { getEnumValue } from "../enum";
import { FieldConfig } from "c1g-ui-library";

export const formatDealSubtitle = (deal: Deal, fieldConfigs: FieldConfig[]) => {
  const statusColor = dealStatusColorMap[deal.status] || '';
  const statusIcon = dealStatusIconMap[deal.status] || '';
  const statusText = getEnumValue(
    getFieldConfigByResourceName(fieldConfigs, 'lastDeal.status')?.options ?? {},
    deal.status?.toString()
  );

  const closerName = deal.closerName || 'Unbekannt';
  const dealType = deal.timeModel === 'parttime' ? 'Teilzeit' : 'Vollzeit';

  const subtitleParts = [
    <span key="status" className={statusColor}>
      <FontAwesomeIcon icon={statusIcon} className="me-1" />
      {statusText}
    </span>,
    `${formatEuro(deal.priceTotalGross)}`,
    `${deal.ue} UE`,
    dealType,
    `${formatDateWithoutTime(deal.start)} - ${formatDateWithoutTime(deal.extendedEnd ?? deal.end)}`,
    <span key="closer" className="d-inline-flex align-items-center">
      Closer:{' '}
      {closerName}
    </span>,
  ];

  return subtitleParts.reduce((acc, curr, idx) => (
    <>
      {acc}
      {idx > 0 && ' · '}
      {curr}
    </>
  ));
};