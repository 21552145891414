import { usePermissions } from 'c1g-ui-library';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Permissions } from '../interfaces';
import DeleteModal from './DeleteModal';

interface SelectionActionsProps {
    selectedCount: number;
    selectedItems: any;
    handleDeSelectAll: () => void;
    onSubmit: (message: string, isError: boolean) => void;
    amountAllItems: number;
    entityType: string
    queryParams?: string;
    allowDelete?: boolean
}

const GeneralSelectionActions: React.FC<SelectionActionsProps> = ({
    selectedCount,
    selectedItems,
    handleDeSelectAll,
    onSubmit,
    amountAllItems,
    entityType,
    queryParams,
    allowDelete = true
}) => {
    const { userHasPermissionByRight } = usePermissions<Permissions>();
    const [isAllSelected, setIsAllSelected] = useState(false)
    return (
        <Row
            className="d-flex justify-content-between p-3 mx-1 mb-2 bg-light-blue"
        >
            <Col className="d-flex align-items-center gap-3" md={4}>
                <span>{isAllSelected ? amountAllItems : selectedCount} Elemente wurden ausgewählt</span>
                <Button variant="link" onClick={handleDeSelectAll}>
                    Auswahl aufheben
                </Button>
                {
                    !isAllSelected && <Button hidden variant="link" className='ps-0' onClick={() => setIsAllSelected(true)}>
                        Alle Auswählen ({amountAllItems})
                    </Button>
                }
            </Col>
            <Col className="d-flex justify-content-end" md={8}>
                {allowDelete && <DeleteModal
                    queryParams={queryParams}
                    modalTitle="Löschen?"
                    buttonName="Löschen"
                    entityType={entityType}
                    entityIds={Object.keys(selectedItems)
                        .filter(key => selectedItems[key] === true)
                        .map(Number)}
                    onSubmit={onSubmit}
                    isDisabled={!userHasPermissionByRight(entityType, 'delete')}>
                </DeleteModal>}

            </Col>
        </Row>
    );
};

export default GeneralSelectionActions;
