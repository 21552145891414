import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEnumValue } from '../../utils/enum';
import { FieldConfig } from 'c1g-ui-library';
import { getFieldConfigByResourceName } from '../../utils/utils';
import { dealStatusColorMap, dealStatusIconMap } from './../filter/iconAndColorMappings';

/**
 * DealStatusIndicator Component
 * 
 * This component displays the status of a deal, including an icon, color, and textual representation.
 * 
 * It takes the following props:
 * - dealStatus: A numeric value representing the status of the deal.
 * - fieldConfigs: An array of field configuration objects to extract status information and display options.
 * - dealType: Specifies whether this is for a regular 'deals' entry or a 'lastDeal' entry,
 *   which affects how the field name is constructed for config lookup.
 */

interface DealStatusIndicatorProps {
    dealStatus: number;
    fieldConfigs: FieldConfig[];
    dealType: 'deals' | 'lastDeal';
}

const DealStatusIndicator: React.FC<DealStatusIndicatorProps> = ({ dealStatus, fieldConfigs, dealType }) => {
    const dealStatusIcon = dealStatusIconMap[Number(dealStatus)];
    const dealStatusColor = dealStatusColorMap[Number(dealStatus)];
    const dealStatusFieldConfig = getFieldConfigByResourceName(fieldConfigs, `${dealType}.status`);
    const dealStatusText = dealStatusFieldConfig?.options && getEnumValue(dealStatusFieldConfig.options, dealStatus?.toString());

    return (
        <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${dealStatusColor}`}>
            <FontAwesomeIcon width={15} icon={dealStatusIcon} className="me-2" />
            <span>{dealStatusText}</span>
        </div>
    );
};

export default DealStatusIndicator;
