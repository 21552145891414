import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import GenericInfosCard from '../../GenericInfosCard';
import SkeletonCard from '../../skeleton/SkeletonCard';
import { Education } from '../../../interfaces';
import { getFieldConfigByResourceName, getFieldLabel } from '../../../utils/utils';
import { getEnumValue, PermissionsEnum } from '../../../utils/enum';
import AddEditEducationModal from '../modal/AddEditEducationModal';
import { FieldConfig, usePermissions } from 'c1g-ui-library';
import { Permissions } from '../../../interfaces';

interface EducationDetailsProps {
    isLoading?: boolean;
    education?: Education | null;
    fieldConfigs: FieldConfig[];
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
}

/**
 * EducationDetails Component
 * 
 * This component displays detailed information about an education course 
 * and provides an option to edit the course details via a modal.
 * 
 * Props:
 * - `isLoading`: Indicates if data is being loaded
 * - `education`: Object containing the course details
 * - `fieldConfigs`: Configuration for field labels and options
 * - `onSubmitSuccess`: Callback when editing is successful
 */
const EducationDetails: React.FC<EducationDetailsProps> = ({
    isLoading,
    education,
    fieldConfigs,
    onSubmitSuccess
}) => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const { userHasPermissionByRight } = usePermissions<Permissions>();

    function getInfo1() {
        return [
            { label: getFieldLabel(fieldConfigs, 'educations.title'), value: education?.title || '-' },
            {
                label: getFieldLabel(fieldConfigs, 'educations.type'),
                value: getEnumValue(
                    getFieldConfigByResourceName(fieldConfigs, 'educations.type')?.options ?? {},
                    education?.type?.toString()
                )
            },
            {
                label: getFieldLabel(fieldConfigs, 'educations.status'), value: getEnumValue(
                    getFieldConfigByResourceName(fieldConfigs, 'educations.status')?.options ?? {},
                    education?.status?.toString()
                )
            },
            { label: getFieldLabel(fieldConfigs, 'educations.slug'), value: education?.slug || '-' },
            { label: getFieldLabel(fieldConfigs, 'educations.lwId'), value: education?.lwId || '-' },
            { label: getFieldLabel(fieldConfigs, 'educations.lwUrl'), value: education?.lwUrl || '-' },
            { label: getFieldLabel(fieldConfigs, 'educations.lwId_old'), value: education?.lwId_old || '-' },
            { label: getFieldLabel(fieldConfigs, 'educations.lwUrl_old'), value: education?.lwUrl_old || '-' },
        ];
    }


    function getInfo2() {
        return [
            { label: getFieldLabel(fieldConfigs, 'educations.course'), value: education?.course || '-' },
            { label: getFieldLabel(fieldConfigs, 'educations.module'), value: education?.module || '-' },
            { label: getFieldLabel(fieldConfigs, 'educations.unit'), value: education?.unit || '-' },
            { label: getFieldLabel(fieldConfigs, 'educations.durationUes'), value: education?.durationUes?.toString() || '-' },
            { label: getFieldLabel(fieldConfigs, 'educations.durationBase'), value: education?.durationBase?.toString() || '-' },
            { label: getFieldLabel(fieldConfigs, 'educations.educations'), value: education?.educations?.join(', ') || '-' },
            { label: getFieldLabel(fieldConfigs, 'educations.integratedActivities'), value: education?.integratedActivities?.join(', ') || '-' },
            {
                label: getFieldLabel(fieldConfigs, 'educations.topicQuestions'),
                value: education?.topicQuestions?.length ? (
                    <ol className="mb-0 ps-3">
                        {education.topicQuestions.map((question, index) => (
                            <li key={index} className='mb-2'>{question}</li>
                        ))}
                    </ol>
                ) : '-',
            },
        ];
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{education?.title}</h4>

                <Button
                    disabled={!userHasPermissionByRight(PermissionsEnum.ViewEducations, 'write') || !education || !fieldConfigs}
                    variant="primary"
                    onClick={() => setShowAddEditModal(true)}
                >
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? <SkeletonCard sectionCount={2} /> : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={5}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? <SkeletonCard sectionCount={2} /> : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={5}
                        />
                    )}
                </Col>
            </Row>

            {showAddEditModal && education && (
                <AddEditEducationModal
                    education={education}
                    modalTitle="Kurs bearbeiten"
                    onSubmitSuccess={onSubmitSuccess}
                    onModalClose={() => setShowAddEditModal(false)}
                    fieldConfigs={fieldConfigs}
                />
            )}
        </>
    );
};

export default EducationDetails;
