import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { getApiKeyStatusIcon, getApiKeysStatusColor } from '../utils/apikeys/utils';
import { ActiveInactiveMapping } from '../utils/enum';
import PortalWrapper from './PortalWrapper';


interface ApiKeyStatusDropdownProps {
  statusApiKey: number;
  handleApiKeyStatusUpdate: (status: number) => void;
  isDisabled?: boolean
}

const ApiKeyStatusDropdown: React.FC<ApiKeyStatusDropdownProps> = ({ statusApiKey, handleApiKeyStatusUpdate, isDisabled }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        disabled={isDisabled}
        variant="link"
        id="dropdown-apikey-basic"
        className={`${getApiKeysStatusColor(statusApiKey)} ps-0 btn-ghost`}
      >
        {getApiKeyStatusIcon(statusApiKey)}
        {ActiveInactiveMapping[statusApiKey as 0 | 1]}
      </Dropdown.Toggle>
      <PortalWrapper>
        <Dropdown.Menu>
          {Object.entries(ActiveInactiveMapping)
            .filter(([statusKey, _]) => Number(statusKey) !== statusApiKey)
            .map(([statusKey, statusValue]) => (
              <Dropdown.Item
                key={statusKey}
                onClick={() =>
                  handleApiKeyStatusUpdate(
                    Number(statusKey) as 0 | 1
                  )
                }
              >
                {getApiKeyStatusIcon(Number(statusKey) as 0 | 1)}
                <span className={`${getApiKeysStatusColor(Number(statusKey) as 0 | 1)}`}>{statusValue}</span>
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </PortalWrapper>
    </Dropdown>
  );
};

export default ApiKeyStatusDropdown;
