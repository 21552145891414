import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEnumValue } from '../../utils/enum';
import { FieldConfig } from 'c1g-ui-library';
import { getFieldConfigByResourceName } from '../../utils/utils';
import { wvStatusColorMap, wvStatusIconMap } from './../filter/iconAndColorMappings';

interface WvStatusIndicatorProps {
    wvStatus: number;
    dealType: 'deals' | 'lastDeal';
    fieldConfigs?: FieldConfig[];
}

/**
 * WvStatusIndicator Component
 * 
 * This component provides a visual representation of the WV (Wiedervorlage) status. 
 * It uses icon and color mappings to display a status indicator and retrieves the 
 * status label from the provided `fieldConfigs` if available.
 * 
 * Props:
 * - `wvStatus` (number): The numerical WV status to be displayed.
 * - `dealType` (string): Specifies whether this is for a regular 'deals' entry or a 'lastDeal' entry,
 * - `fieldConfigs` (optional, FieldConfig[]): Array of field configurations that may include options for translating the `wvStatus` value into a human-readable label.
 */
const WvStatusIndicator: React.FC<WvStatusIndicatorProps> = ({ wvStatus, fieldConfigs, dealType }) => {
    const wvStatusIcon = wvStatusIconMap[Number(wvStatus)];
    const wvStatusColor = wvStatusColorMap[Number(wvStatus)];
    const wvStatusFieldConfig = fieldConfigs
        ? getFieldConfigByResourceName(fieldConfigs, dealType === 'deals' ? 'deals.wvStatus' : 'lastDeal.wvStatus')
        : undefined;

    return (
        <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${wvStatusColor}`}>
            <FontAwesomeIcon width={15} icon={wvStatusIcon} className="me-2" />
            <span>
                {getEnumValue(wvStatusFieldConfig?.options ?? {}, wvStatus.toString()) || 'Status unbekannt'}
            </span>
        </div>
    );
};

export default WvStatusIndicator;
