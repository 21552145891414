import React, { useState } from 'react';
import { ApiClient } from 'c1g-ui-library';
import { useToast } from 'c1g-ui-library';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { AxiosError } from 'axios';

const Test: React.FC = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState({
    success: false,
    error: false
  });

  // Beispiel für erfolgreichen API-Aufruf
  const handleSuccessTest = async () => {
    setLoading({ ...loading, success: true });
    await new Promise(resolve => setTimeout(resolve, 800));
    try {
      // Annahme: '/activities/1' ist ein gültiger Endpunkt, der erfolxgreich antwortet
      const response = await ApiClient.get(`/activities/1`);
      console.log('Success response:', response.data);
      showToast('API-Aufruf war erfolgreich!', false);
    } catch (error: any) {
      // Der globale Error-Handler kümmert sich um die Anzeige des Fehlers
      console.log('Dieser catch-Block wird nur für Debugging ausgeführt');
      console.error(error.message as AxiosError);
    } finally {
      setLoading({ ...loading, success: false });
    }
  };

  // Beispiel für fehlerhaften API-Aufruf
  const handleErrorTest = async () => {
    setLoading({ ...loading, error: true });
    await new Promise(resolve => setTimeout(resolve, 800));

    try {
      // Annahme: '/non-existent' gibt einen 404-Fehler zurück
      await ApiClient.get('/non-existent');
      // Diese Zeile wird bei einem Fehler nie erreicht
      showToast('Dies sollte nie angezeigt werden', false);
    } catch (error: any) {
      // Der globale Error-Handler hat den Fehler bereits abgefangen und angezeigt
      // Wir müssen nur den Ladeindikator zurücksetzen
      console.log('Fehler wurde vom globalen Handler verarbeitet');
      console.error(error.message as AxiosError);
    } finally {
      setLoading({ ...loading, error: false });
    }
  };

  return (
    <Container className="container-fluid p-40">
      <h2>API Client Test</h2>
      <Row className="mt-4">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Erfolgsfall</Card.Title>
              <Card.Text>
                <p>Testet einen API-Aufruf, der erfolgreich ist.</p>
                <p>Der Toast wird angezeigt, wenn der Aufruf erfolgreich ist.</p>
                <p>Der Toast verschwindet von selbst nach 5 Sekunden.</p>
              </Card.Text>
              <Button 
                variant="success" 
                onClick={handleSuccessTest}
                disabled={loading.success}
              >
                {loading.success ? 'Lädt...' : 'Erfolgsfall testen'}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Fehlerfall</Card.Title>
              <Card.Text>
                <p>Testet einen API-Aufruf, der fehlschlagen wird.</p>
                <p>Der globale Error-Handler zeigt den Toast an.</p>
                <p>Der Toast verschwindet nur wenn man ihn manuell schließt.</p>
              </Card.Text>
              <Button 
                variant="danger" 
                onClick={handleErrorTest}
                disabled={loading.error}
              >
                {loading.error ? 'Lädt...' : 'Fehlerfall testen'}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Test;
