import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFilePdf, faFolder, faImage, faMicrophone, faVideo } from "@fortawesome/pro-solid-svg-icons";
import { MediaPlaceholderImages } from "../enum";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { faBoxArchive, faLock } from "@fortawesome/pro-solid-svg-icons";
import { MediaItem, Webhook } from "c1g-ui-library";

export function getMediaIcon(media: MediaItem) {
  let iconComponent;

  if (media.mediaType === 'inode') {
    iconComponent = (
      <FontAwesomeIcon
        className="me-3"
        icon={faFolder}
        style={{ color: '#232D42' }}
      />
    );
  } else if (media.mimeType?.includes('image')) {
    iconComponent = (
      <FontAwesomeIcon
        className="me-3"
        style={{ color: 'var(--bs-primary)' }}
        icon={faImage}
      />
    );
  } else if (media.extension?.includes('doc')) {
    iconComponent = (
      <FontAwesomeIcon
        className="me-3"
        style={{ color: 'var(--bs-success)' }}
        icon={faFile}
      />
    );
  } else if (media.extension?.includes('mkv')) {
    iconComponent = (
      <FontAwesomeIcon
        className="me-3"
        style={{ color: 'var(--bs-warning)' }}
        icon={faVideo}
      />
    );
  } else if (media.extension?.includes('mp3')) {
    iconComponent = (
      <FontAwesomeIcon
        className="me-3"
        style={{ color: 'var(--bs-danger)' }}
        icon={faMicrophone}
      />
    );
  } else if (media.extension?.includes('pdf')) {
    iconComponent = (
      <FontAwesomeIcon
        className="me-3"
        style={{ color: 'var(--bs-success)' }}
        icon={faFilePdf}
      />
    );
  }
  else {
    iconComponent = (
      <FontAwesomeIcon
        className="me-3"
        style={{ color: 'var(--bs-success)' }}
        icon={faFile}
      />
    );
  }

  return iconComponent;
}

export function getMediaType(media: MediaItem) {
  if (media.mediaType === 'inode') {
    return 'Ordner';
  } else if (media.mimeType.includes('image')) {
    return 'Bild';
  } else if (media.extension.includes('doc')) {
    return 'Dokument';
  } else if (media.extension.includes('mkv')) {
    return 'Video';
  } else if (media.extension.includes('mp3')) {
    return 'Audio';
  } else if (media.extension.includes('pdf')) {
    return 'PDF';
  }
  return '-';
}


export function formatSizeUnits(bytes: number) {
  if (bytes >= 1073741824) {
    return (bytes / 1073741824).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' GB';
  } else if (bytes >= 1048576) {
    return (bytes / 1048576).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' MB';
  } else if (bytes >= 1024) {
    return (bytes / 1024).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' KB';
  } else if (bytes > 1) {
    return bytes + ' bytes';
  } else if (bytes === 1) {
    return bytes + ' byte';
  } else {
    return '0 bytes';
  }
}

export function getImageUrl(media: MediaItem) {
  const companyId = sessionStorage.getItem('companyId') || 'oc';
  if (media.mediaType === 'inode') {
    return '';
  } else if (media.mimeType.includes('image')) {
    return `${process.env.REACT_APP_BACKEND_URL}/storage/${companyId}/${media.year}/${media.month}/${media.title}`;
  } else if (media.extension.includes('doc')) {
    return MediaPlaceholderImages.word;
  } else if (media.extension.includes('mkv')) {
    return MediaPlaceholderImages.video;
  } else if (media.extension.includes('mp3')) {
    return MediaPlaceholderImages.music;
  } else if (media.extension.includes('pdf')) {
    return MediaPlaceholderImages.pdf;
  } else if (media.extension.includes('xls')) {
    return MediaPlaceholderImages.excel;
  }
  return '';
}

export function getMediaUrl(media: MediaItem) {
  const companyId = sessionStorage.getItem('companyId') || 'oc';
  return `${process.env.REACT_APP_BACKEND_URL}/storage/${companyId}/${media.year}/${media.month}/${media.title}`
}

export function getWebhookUrl(webhook: Webhook) {
  const companyId = sessionStorage.getItem('companyId') || 'oc';
  return `${process.env.REACT_APP_BACKEND_URL}/${companyId}/webhooks/${webhook.code}`
}

export function getMediaStatusIcons(media: MediaItem) {
  let iconComponents = [];

  if (media.flags) {
    const flags = JSON.parse(media.flags);

    if (flags.secure) {
      iconComponents.push(
        <OverlayTrigger
          key="secure"
          placement="top"
          overlay={<Tooltip id="tooltip-secure">Secure</Tooltip>}
        >
          <FontAwesomeIcon
            className="me-2"
            icon={faLock}
            style={{ color: 'var(--bs-danger)' }}
          />
        </OverlayTrigger>
      );
    }

    if (flags.archived) {
      iconComponents.push(
        <OverlayTrigger
          key="archived"
          placement="top"
          overlay={<Tooltip id="tooltip-archived">Archived</Tooltip>}
        >
          <FontAwesomeIcon
            className="me-2"
            icon={faBoxArchive}
            style={{ color: '#5a6268' }}
          />
        </OverlayTrigger>
      );
    }
  }

  return iconComponents.length > 0 ? iconComponents : null;
}

export function isMediaSecure(media: MediaItem) {
  if (media.flags) {
    const flags = JSON.parse(media.flags);
    return flags.secure === true;
  }
  return false;
}