import React, { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import PortalWrapper from '../PortalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FieldConfig } from 'c1g-ui-library';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';

const getIconByFilter = (key: string | number, iconMap: { [key: string]: any }, colorMap?: { [key: string]: string }) => {
    const iconClass = colorMap && colorMap[key] ? `me-2 ${colorMap[key]}` : 'text-primary me-2';
    return <FontAwesomeIcon width={15} icon={iconMap[key] ? iconMap[key] : faFilter} className={iconClass} />;
};

interface ClusterConfig<T> {
    clusterName: string;
    filters: { type: T; count?: number }[];
}

/**
 * MultiSelectDropdownFilter Component
 * 
 * A customizable dropdown filter component that supports multi-select functionality 
 * with clustering of filter options. It provides checkboxes for selection, optional 
 * icons, color-coded labels, and an optional count for each filter. This component 
 * is designed to organize filters into logical clusters, with each cluster containing
 * related filter options.
 * 
 * Props:
 * - `selectedFilters`: Array of currently selected filter types.
 * - `handleFilterChange`: Callback to handle changes in the selected filters.
 * - `filterEnum`: Mapping of filter types to their display labels.
 * - `iconMap`: (Optional) Mapping of filter types to icons (e.g., FontAwesome icons).
 * - `colorMap`: (Optional) Mapping of filter types to color classes for styling labels.
 * - `clusterConfig`: Configuration object for clusters, each with a name and a list of filters.
 * - `isDisabled`: (Optional) Boolean to disable the dropdown.
 * - `titlePlaceholder`: (Optional) Placeholder text for the dropdown toggle button.
 * 
 * ClusterConfig Type:
 * - `clusterName`: Name of the cluster (e.g., "Schreiben").
 * - `filters`: Array of filter objects with the following structure:
 *   - `type`: Unique identifier for the filter (e.g., numeric or string).
 *   - `count`: (Optional) Integer count to display next to the filter.
 * 
 * Example Usage:
 * ```
 * const clusterConfig = [
 *   {
 *     clusterName: 'Category 1',
 *     filters: [
 *       { type: 1, count: 5 }, // Filter 1 with a count of 5
 *       { type: 2, count: 10 }, // Filter 2 with a count of 10
 *     ],
 *   },
 *   {
 *     clusterName: 'Category 2',
 *     filters: [
 *       { type: 3, count: 2 }, // Filter 3 with a count of 2
 *     ],
 *   },
 * ];
 * 
 * <MultiSelectDropdownFilter
 *   selectedFilters={selectedFilters}
 *   handleFilterChange={setSelectedFilters}
 *   filterEnum={{
 *     1: 'Filter One',
 *     2: 'Filter Two',
 *     3: 'Filter Three',
 *   }}
 *   iconMap={{
 *     1: faCheck,
 *     2: faTimes,
 *     3: faStar,
 *   }}
 *   colorMap={{
 *     1: 'text-success',
 *     2: 'text-danger',
 *     3: 'text-warning',
 *   }}
 *   clusterConfig={clusterConfig}
 *   titlePlaceholder="Select Filters"
 * />
 * ```
 * 
 * This component is ideal for scenarios where filters need to be grouped logically
 * and displayed in a visually appealing, structured manner. The renaming of `key` to 
 * `type` reflects the semantic use of the filter identifier.
 */

interface MultiSelectDropdownFilterProps<T extends string | number> {
    selectedFilters: T[] | null
    handleFilterChange: (filters: T[]) => void;
    filterEnum: { [key in T]?: string };
    iconMap?: { [key: string]: any };
    colorMap?: { [key: string]: string };
    clusterConfig: ClusterConfig<T>[];
    isDisabled?: boolean;
    titlePlaceholder?: string;
    fieldConfigs: FieldConfig[]
}

const MultiSelectDropdownFilter = <T extends string | number>({
    selectedFilters,
    handleFilterChange,
    filterEnum,
    iconMap = {},
    colorMap = {},
    clusterConfig,
    isDisabled,
    titlePlaceholder = 'Filter',
}: MultiSelectDropdownFilterProps<T>) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleCheckboxChange = (key: T) => {
        const currentFilters = selectedFilters || [];
        if (currentFilters.includes(key)) {
            handleFilterChange(currentFilters.filter((filter) => filter !== key));
        } else {
            handleFilterChange([...currentFilters, key]);
        }
    };

    const handleReset = (event: React.MouseEvent) => {
        event.stopPropagation();
        handleFilterChange([]);
    };

    const title =
        (selectedFilters && selectedFilters.length > 0)
            ? selectedFilters.map((key) => filterEnum[key]).join(', ')
            : titlePlaceholder;

    return (
        <Dropdown show={isOpen} onToggle={() => setIsOpen(!isOpen)}>
            <Dropdown.Toggle
                disabled={isDisabled}
                variant={isDisabled ? 'outline-muted' : 'outline-primary'}
                id="multi-select-dropdown-filter"
                className="custom-dropdown-focus me-2"
            >
                {selectedFilters && selectedFilters.length > 0 && (
                    <FontAwesomeIcon icon={faTimes} className="me-2 cursor-pointer" onClick={handleReset} />
                )}
                {titlePlaceholder}
            </Dropdown.Toggle>
            <PortalWrapper>
                <Dropdown.Menu>
                    {clusterConfig.map((cluster) => (
                        <div key={cluster.clusterName}>
                            <div className="px-3 py-2 text-muted">{cluster.clusterName}</div>
                            {cluster.filters.map((filter) => (
                                <div
                                    key={filter.type}
                                    className="d-flex align-items-center justify-content-between dropdown-item"
                                    onClick={() => handleCheckboxChange(filter.type)}
                                >
                                    <div className="d-flex align-items-center">
                                        <Form.Check
                                            type="checkbox"
                                            className="me-2"
                                            checked={selectedFilters?.includes(filter.type as T) || false}
                                            onChange={() => handleCheckboxChange(filter.type as T)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                        {getIconByFilter(filter.type, iconMap, colorMap)}
                                        <label className="ms-2 me-2 mb-0 cursor-pointer">{filterEnum[filter.type]}</label>
                                    </div>
                                    {filter.count && filter.count > 0 ? (
                                        <div className="text-center" style={{ width: '25px' }}>
                                            <span className="text-muted">{filter.count}</span>
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    ))}
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};


export default MultiSelectDropdownFilter;
