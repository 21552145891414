import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, ColumnMapping, FieldConfig, NotificationToast, SortCaret, usePermissions, useResetUrlParams, useSortableData, useTableHeight, useToast } from 'c1g-ui-library';
import {
    Fragment,
    useEffect,
    useState,
} from 'react';
import { Button, Col, Dropdown, Row, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ColumnSelection from '../../../components/ColumnSelection';
import {
    ComboButtonId,
} from '../../../components/ComboButtonGroup';
import PortalWrapper from '../../../components/PortalWrapper';
import SearchInput from '../../../components/SearchInput';
import ViewSelector from '../../../components/ViewSelector';
import { bgsStatusColorMap, bgsStatusIconMap, dealStatusColorMap, dealStatusIconMap, wvStatusColorMap, wvStatusIconMap, } from '../../../components/filter/iconAndColorMappings';
import { DefaultColumnRender } from '../../../components/table/DefaultColumnRender';
import DynamicPagination from '../../../components/table/DynamicPagination';
import PaginationInfo from '../../../components/table/PaginationInfo';
import TableNoDataMessage from '../../../components/table/TableNoDataMessage';
import SkeletonRow from '../../../components/table/skeletonRow/SkeletonRow';
import { defaultRenderHeader } from '../../../components/table/utils';
import { CompanionDeal, Permissions } from '../../../interfaces';
import {
    getEnumValue,
    PermissionsEnum,
} from '../../../utils/enum';
import { addPrefixToFilters, fetchAndCombineFieldConfigs, getFieldConfigByResourceName } from '../../../utils/utils';

export interface CompanionDealFilters {
    status: | number | null
}

interface CompanionDealsResponse {
    page: number;
    itemsPerPage: number;
    amountPages: number;
    amountAllItems: number;
    searchFilters: string[];
    list: CompanionDeal[];
}

interface CompanionAssignedDealsProps {
    companionId: number
}

const CompanionAssignedDeals: React.FC<CompanionAssignedDealsProps> = ({ companionId }) => {
    useTableHeight();
    const navigate = useNavigate();
    const resetUrlParams = useResetUrlParams();
    const { companyId = 'oc' } = useParams();
    const { show, message, error, showToast, hideToast } = useToast();
    const [selectedSearchColumn, setSelectedSearchColumn] = useState<ComboButtonId | ''>('all');
    const [deals, setDeals] = useState<CompanionDeal[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(20);
    const [limit, setLimit] = useState<number>(25);
    const [totalEntries, setTotalEntries] = useState<number>(200);
    const [availableFilter, setAvailableFilter] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
    const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
    const [showPopup, setShowPopup] = useState(false);
    const { requestSort, sortConfig, setSortConfig, getSortDirection } = useSortableData(deals, showPopup);
    const [pendingChanges, setPendingChanges] = useState<{ [key: string]: any }>({});
    const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
    const [editableCell, setEditableCell] = useState<{ rowId: number | null; columnKey: string | null }>({
        rowId: null,
        columnKey: null,
    });
    const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<CompanionDealFilters>({
        status: null,
    });

    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

    // Fetch the list of deals based on the current filters, search, sort and pagination
    const fetchDeals = async () => {
        setDeals([]);
        setIsLoading(true);
        let queryParams = `?page=${currentPage}&companion_id=${companionId}`;

        if (selectedFilters.status?.toString()) {
            queryParams += `&deals.status=${selectedFilters.status?.toString()}`;
        }

        if (limit.toString()) {
            queryParams += `&limit=${limit}`;
        }
        if (searchQuery) {
            queryParams += `&search=${encodeURIComponent(searchQuery)}`;
            if (selectedSearchColumn !== 'all') {
                queryParams += `&column=${encodeURIComponent(selectedSearchColumn)}`;
            }
        }

        if (sortConfig?.field) {
            queryParams += `&sort[field]=${encodeURIComponent(sortConfig.field)}`;
            queryParams += `&sort[type]=${sortConfig.type}`;
        }

        try {
            const response = await ApiClient.get(`/companionsdeals${queryParams}`);
            const dealsResponse = response.data as CompanionDealsResponse;
            setTotalPages(dealsResponse.amountPages);
            setDeals(dealsResponse.list ?? []);
            setCurrentPage(dealsResponse.page);
            setLimit(dealsResponse.itemsPerPage);
            setTotalEntries(dealsResponse.amountAllItems);
            setAvailableFilter(addPrefixToFilters(dealsResponse.searchFilters, 'companionsDeals'));
            setIsLoading(false);
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsLoading(false);
        }
    };

    // Effect to trigger fetching of deals when filters, search, paginationm or other dependenies change
    useEffect(() => {
        if (permissionsLoaded) {
            const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewCompanions, 'read');

            if (hasPermission) {
                fetchDeals();

                // Fetch only once
                if (!fieldConfigs || Object.keys(fieldConfigs).length === 0) {
                    fetchAndCombineFieldConfigs(['companionsdeals'], setFieldConfigs)
                }

            } else {
                navigate('/errors/error404');
            }
        }
    }, [selectedFilters, currentPage, searchQuery, selectedSearchColumn, selectedColumns, limit, sortConfig]);

    // Effect to reset search input
    useEffect(() => {
        if (resetSearchInput) {
            setResetSearchInput(false);
        }
    }, [resetSearchInput]);

    // Routes to detail view
    const handleRouteToDetailView = (participantId: number) => {
        const url = `/${companyId}/participants/${participantId}?tab=participant_deals`;
        window.open(url, '_blank');
    };

    // Resets all filters and search fields
    const resetSearch = () => {
        resetUrlParams();
        setSearchQuery('');
        setSelectedSearchColumn('all');
        setCurrentPage(1);
        setResetSearchInput(true);
    };

    // Handles view selection changes from the view selection component.
    const handleSelectionChange = (
        selectedColumns: string[],
        selectedFilters: any,
        selectedSortConfig: any,
        selectedLimit: number,
        selectedSearchTerm: string,
        selectedSearchColumn: string
    ) => {
        resetSearch()
        setSelectedColumns(selectedColumns);
        setSelectedFilters(selectedFilters);
        setSortConfig(selectedSortConfig);
        setLimit(selectedLimit);

        if (selectedSearchTerm || selectedSearchColumn) {
            setSearchQuery(selectedSearchTerm);
            setSelectedSearchColumn(selectedSearchColumn);
        }
    };

    // Handle clicks on table cells for editing
    const handleCellClick = (rowId: number | null, columnKey: string | null) => {
        setEditableCell({ rowId, columnKey });
    };

    // Handle changes to table cells for editing
    const handleFieldChange = (rowId: number, columnKey: string, value: any) => {
        setPendingChanges(prevChanges => ({
            ...prevChanges,
            [`${rowId}-${columnKey}`]: { rowId, columnKey, value },
        }));
        setShowPopup(true);
    };

    // Revert a single change in editable cell
    const handleRevertChange = () => {
        const { rowId, columnKey } = editableCell;

        if (rowId === null || columnKey === null) return;

        const newPendingChanges = { ...pendingChanges };
        delete newPendingChanges[`${rowId}-${columnKey}`];

        setPendingChanges(newPendingChanges);
        setEditableCell({ rowId: null, columnKey: null });
        setShowPopup(Object.keys(newPendingChanges).length > 0);
    };

    // Handle search input change
    const handleSearch = (data: { query: string; filter?: string }) => {
        const { query, filter } = data;

        if (query) {
            setSearchQuery(query);
            setSelectedSearchColumn(filter || '');
            setCurrentPage(1);
        } else if (searchQuery) {
            resetSearch();
        }
    };

    // Define how special columns should be rendered
    const columnMapping: { [key: string]: ColumnMapping<CompanionDeal> } = {
        'deal.title': {
            renderHeader: (key: any) => (
                <th
                    key={key}
                    className="sticky-col cursor-pointer"
                    scope="col"
                    title="Titel"
                    onClick={() => requestSort('deal.title')}
                >
                    <div className="d-flex align-items-center position-relative table-cell-wrap max-w-100">
                        Titel <div className="position-absolute" style={{ right: 0 }}><SortCaret direction={getSortDirection('deal.title')} /></div>
                    </div>
                </th>
            ),

            render: (companionDeal: CompanionDeal) => (
                <td key={companionDeal.id} className="sticky-col py-3">
                    <div className="d-flex align-items-center justify-content-start">
                        <a
                            href={`/${companyId}/participants/${companionDeal.deal.participants_id}?tab=participant_deals`}
                            className="btn-link ps-0 text-start table-cell-wrap max-w-table-title"
                            title={companionDeal.deal.title ?? ''}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {companionDeal.deal.title}
                        </a>
                    </div>
                </td>
            ),
        },
        'deal.status': {
            render: (companionDeal: CompanionDeal) => (
                <td key={`${companionDeal.id}-status`}>
                    <FontAwesomeIcon
                        icon={dealStatusIconMap[companionDeal.deal.status]}
                        className={`me-2 ${dealStatusColorMap[companionDeal.deal.status]}`}
                    />
                    {getEnumValue(
                        getFieldConfigByResourceName(fieldConfigs, 'deal.status')?.options ?? {},
                        companionDeal.deal.status.toString()
                    )}
                </td>
            ),
        },
        'deal.wvStatus': {
            render: (companionDeal: CompanionDeal) => (
                <td key={`${companionDeal.id}-wvStatus`}>
                    <FontAwesomeIcon
                        icon={wvStatusIconMap[companionDeal.deal.wvStatus]}
                        className={`me-2 ${wvStatusColorMap[companionDeal.deal.wvStatus]}`}
                    />
                    {getEnumValue(
                        getFieldConfigByResourceName(fieldConfigs, 'deal.wvStatus')?.options ?? {},
                        companionDeal.deal.wvStatus.toString()
                    )}
                </td>
            ),
        },
        'deal.bgsStatus': {
            render: (companionDeal: CompanionDeal) => (
                <td key={`${companionDeal.id}-bgsStatus`}>
                    <FontAwesomeIcon
                        icon={bgsStatusIconMap[companionDeal.deal.bgsStatus]}
                        className={`me-2 ${bgsStatusColorMap[companionDeal.deal.bgsStatus]}`}
                    />
                    {getEnumValue(
                        getFieldConfigByResourceName(fieldConfigs, 'deal.bgsStatus')?.options ?? {},
                        companionDeal.deal.bgsStatus.toString()
                    )}
                </td>
            ),
        },
    };

    return (
        <Fragment>
            <div className='table-controls-wrapper'>
                <Row className="d-flex justify-content-between mb-4">
                    <Col md={9}>
                        {searchQuery &&
                            <div className="d-flex align-items-baseline mb-3">
                                <h4 className="m-0">Suchergebnisse</h4>
                                <span className="ms-3 d-flex align-items-baseline">
                                    <Button
                                        className="btn-ghost m-0 p-0 fs-6"
                                        variant="link"
                                        onClick={resetSearch}
                                    >
                                        Suche beenden
                                    </Button>
                                </span>
                            </div>
                        }
                        <ViewSelector
                            selectedSortConfig={sortConfig}
                            selectedFilters={selectedFilters}
                            selectedColumns={selectedColumns}
                            selectedLimit={limit}
                            selectedSearchColumn={selectedSearchColumn}
                            selectedSearchTerm={searchQuery}
                            entityType='companionsDeals'
                            onSelectionChange={handleSelectionChange}
                        />
                    </Col>
                    <Col md={3}>
                        <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} initialSearchTerm={searchQuery} initialSearchColumn={selectedSearchColumn} fieldConfigs={fieldConfigs} />
                    </Col>
                </Row>

            </div>
            <div style={{ overflowX: 'auto' }}>
                <Table className="sticky-table" responsive="md" size="sm" style={{ overflow: 'auto' }}>
                    <thead>
                        <tr>
                            {selectedColumns.map((columnKey) =>
                                columnMapping[columnKey]?.renderHeader
                                    ? columnMapping[columnKey].renderHeader!(columnKey)
                                    : defaultRenderHeader(columnKey, fieldConfigs, requestSort, getSortDirection, 'companionsDeals')
                            )}
                            <th className="cursor-pointer text-end align-top sticky-right bg-grey w-40-px" scope="col">
                                <ColumnSelection
                                    selectedColumns={selectedColumns}
                                    onSelectionChange={columns => setSelectedColumns(columns)}
                                    fieldConfigs={fieldConfigs}
                                    entityType='companionsDeals'
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading
                            ? Array.from({ length: 8 }).map((_, index) => (
                                <SkeletonRow key={`skeleton-row-${index}`} columnCount={selectedColumns.length + 1} />
                            ))
                            : deals.map((companionDeal) => (
                                <tr key={companionDeal.id} className="bg-white">
                                    {selectedColumns.map((columnKey) =>
                                        columnMapping[columnKey]
                                            ? columnMapping[columnKey].render(companionDeal)
                                            : <DefaultColumnRender
                                                key={`${companionDeal.id}-${columnKey}`}
                                                item={companionDeal}
                                                columnKey={columnKey}
                                                editableCell={editableCell}
                                                handleCellClick={handleCellClick}
                                                handleFieldChange={handleFieldChange}
                                                handleRevertChange={handleRevertChange}
                                                fieldConfigs={fieldConfigs}
                                                pendingChanges={pendingChanges}
                                                activeTooltip={activeTooltip}
                                                setActiveTooltip={setActiveTooltip}
                                                module={'companionsDeals'}
                                            />
                                    )}
                                    <td className='sticky-right bg-white' key={companionDeal.id}>
                                        <Dropdown>
                                            <Dropdown.Toggle as="div" className="no-caret cursor-pointer d-inline-block">
                                                <div className="px-2">
                                                    <FontAwesomeIcon icon={faEllipsisVertical} />
                                                </div>
                                            </Dropdown.Toggle>
                                            <PortalWrapper>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleRouteToDetailView(companionDeal.deal.participants_id)}><FontAwesomeIcon className='me-1' width={15} icon={faCircleInfo} /> Mehr Details</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </PortalWrapper>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        {!isLoading && deals.length === 0 && (
                            <TableNoDataMessage
                                message="Keine Deals"
                            />
                        )}
                    </tbody>
                </Table>
            </div>

            {totalEntries > 0 && (
                <div className='pagination-wrapper pt-2'>
                    <Row>
                        <Col>
                            <PaginationInfo
                                currentPage={currentPage}
                                limit={limit}
                                totalEntries={totalEntries}
                                onLimitChange={(size) => {
                                    setLimit(size);
                                    setCurrentPage(1);
                                }}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <DynamicPagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Col>
                    </Row>
                </div>
            )}

            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </Fragment>
    );
};

export default CompanionAssignedDeals;
