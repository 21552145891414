import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-regular-svg-icons';
import { CollapsibleCard } from 'c1g-ui-library';

interface InfoDialogProps {
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

/**
 * InfoDialog Component
 *
 * A reusable, fixed-position dialog component designed for displaying 
 * informational messages or content. This dialog is collapsible and 
 * includes a close button for dismissing it.
 *
 * Props:
 * - `onClose` (function): Callback function triggered when the close button is clicked.
 * - `title` (string): The title displayed in the dialog header.
 * - `children` (React.ReactNode): The content to be displayed inside the dialog.
 *
 * Example Usage:
 * ```tsx
 * <InfoDialog onClose={handleClose} title="Download Status">
 *   <p>Your file is being prepared for download.</p>
 * </InfoDialog>
 * ```
 */
const InfoDialog: React.FC<InfoDialogProps> = ({
    onClose,
    title,
    children,
}) => {
    return (
        <div
            className="position-fixed bottom-0 end-0 m-3 info-dialog"
        >
            <CollapsibleCard
                showExpandCollapseIcon={false}
                title={title}
                initialOpen={true}
                customCardCssClass="mb-0"
                headerRightElement={
                    <div className="cursor-pointer" onClick={onClose}>
                        <FontAwesomeIcon className="text-primary" icon={faX} />
                    </div>
                }
            >
                <div className="pb-3 px-3">
                    {children}
                </div>
            </CollapsibleCard>
        </div>
    );
};

export default InfoDialog;
