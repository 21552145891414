import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { getInitials } from '../../../utils/utils';
import { Participant, } from '../../../interfaces';
interface DealHeaderIconProps {
  participant: Participant;
}

/**
 * DealHeaderIcon Component
 * 
 * This component displays a circular icon with a badge containing the initials of a participant.
 * It uses FontAwesome icons to visually represent the handshake icon.
 * 
 * Props:
 * - `participant`: The participant object containing the participant's title.
 */

const DealHeaderIcon: React.FC<DealHeaderIconProps> = ({ participant }) => {
  return (
    <div className="deal-header-icon bg-primary rounded-circle d-flex justify-content-center align-items-center">
        <FontAwesomeIcon className='text-primary' size="2x" icon={faHandshake} />
          <div className='deal-header-icon-badge'>{getInitials(participant?.title ?? '')}</div>
    </div>
  );
};

export default DealHeaderIcon;
