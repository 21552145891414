import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { usePermissions, Activity } from 'c1g-ui-library';
import { ApiClient } from 'c1g-ui-library';
import ActivityDetails from '../../components/activities/detail/ActivityDetails';
import { mockActivities } from './acitivities';
import { Permissions } from '../../interfaces';
import { AxiosError } from 'axios';

function ActivityDetail() {
  const { activityId } = useParams();
  const [activity, setActivity] = useState<Activity | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewActivities, 'read');

      if (hasPermission) {
        activityId && fetchActivity(activityId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [activityId, permissionsLoaded]);

  const fetchActivity = async (activityId: string) => {
    setIsLoading(true);
    try {
      // const response = await ApiClient.get(`/activities/${activityId}`);
      // const settingResponse = response.data;
      //setActivity(settingResponse);
      setActivity(mockActivities[Number(activityId) - 1]);
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setActivity(null)
    activityId && fetchActivity(activityId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <ActivityDetails activity={activity} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></ActivityDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ActivityDetail;
