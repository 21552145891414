import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPaperPlane, faTriangleExclamation, faMoneyBill } from '@fortawesome/pro-regular-svg-icons';
import ProgressBarComponent from '../ProgressBarComponent';
import { getEnumValue } from '../../utils/enum';
import { calculateProgressFromStartAndEndDate, formatDateWithoutTime, getFieldConfigByResourceName, parseDate } from '../../utils/utils';
import DealStatusIndicator from './DealStatusIndicator';
import WvStatusIndicator from './WvStatusIndicator';
import BgsStatusIndicator from './BgsStatusIndicator';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Deal } from '../../interfaces';
import { timeModelIconMap } from '../filter/iconAndColorMappings';
import { FieldConfig } from 'c1g-ui-library';

interface StatusDisplayProps {
    fieldConfigs: FieldConfig[];
    deal: Deal
}

/**
 * StatusDisplay Component
 * 
 * This component displays the status of a deal, including various indicators and information.
 * 
 * Props:
 *   - `deal`: The deal object containing deal information.
 *   - `fieldConfigs`: An array of field configurations for the deal.
 */
const StatusDisplay: React.FC<StatusDisplayProps> = ({ deal, fieldConfigs }) => {
    const dealStartDate = deal.start ? formatDateWithoutTime(deal.start) : '';
    const dealEndDate = deal.extendedEnd ? formatDateWithoutTime(deal.extendedEnd) : deal.end ? formatDateWithoutTime(deal.end) : '';
    const dealAbortDate = deal?.abortDate ? formatDateWithoutTime(deal.abortDate) : '';

    const progress = calculateProgressFromStartAndEndDate(dealStartDate, dealEndDate, dealAbortDate);
    const today = new Date();
    const start = parseDate(dealStartDate);

    const timeDiff = Math.floor((today.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    const timeModelFieldConfig = getFieldConfigByResourceName(fieldConfigs, 'deals.timeModel');

    if (!deal) {
        return <span>Kein Deal vorhanden</span>
    }

    return (
        <div className="d-flex flex-column flex-lg-row align-items-lg-center flex-wrap">
            <DealStatusIndicator fieldConfigs={fieldConfigs} dealType="deals" dealStatus={deal.status} />
            {/* 
            // Check if both BGS and WV have the status "Erfolgreich versendet an AfA Berater" (BGS: 4, WV: 6).
            // If the deal starts in the future (status === 0), display a combined status: "BGS & WV an AfA verschickt".
            // Otherwise, show individual indicators for BGS and WV statuses. */}
            {deal.bgsStatus === 4 && deal.wvStatus === 6 ? (
                deal.status === 0 ? (
                    <div className="d-flex align-items-center me-md-4 mb-2 mb-md-0 text-success">
                        <FontAwesomeIcon width={15} icon={faPaperPlane} className="me-2" />
                        <span>BGS & WV and AfA verschickt</span>
                    </div>
                ) : null
            ) : (
                <>
                    <BgsStatusIndicator fieldConfigs={fieldConfigs} dealType="deals" bgsStatus={deal.bgsStatus} />
                    <WvStatusIndicator fieldConfigs={fieldConfigs} dealType="deals" wvStatus={deal.wvStatus} />
                </>
            )}

            {timeDiff <= 14 && timeDiff >= 0 && (
                <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 text-danger`}>
                    <FontAwesomeIcon width={15} icon={faTriangleExclamation} className="me-2" />
                    <span>Rücktrittsrecht</span>
                </div>
            )}

            {deal.selfPay && (
                <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 text-muted`}>
                    <FontAwesomeIcon width={15} icon={faMoneyBill} className="me-2" />
                    <span>Selbstzahler</span>
                </div>
            )}

            <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 text-muted`}>
                {deal.besidesTheJob ? (
                    <>
                        <FontAwesomeIcon width={15} icon={timeModelIconMap['besidesTheJob']} className="me-2" />
                        <span>Berufsbegleitend</span>
                    </>
                ) : (
                    deal.timeModel && (
                        <>
                            {deal.timeModel === 'fulltime' && (
                                <FontAwesomeIcon width={15} icon={timeModelIconMap['fulltime']} className="me-2 text-muted" />
                            )}
                            {deal.timeModel === 'parttime' && (
                                <FontAwesomeIcon width={15} icon={timeModelIconMap['parttime']} className="me-2 text-muted" />
                            )}
                            {timeModelFieldConfig?.options && (
                                <span className="text-muted">
                                    {getEnumValue(timeModelFieldConfig.options, deal.timeModel)}
                                </span>
                            )}
                        </>
                    )
                )}
            </div>
            <div className="d-flex flex-column flex-md-row align-items-lg-center me-md-4 mb-2 mb-md-0">
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon width={15} icon={faCalendar} className="me-2 text-muted d-none d-lg-block" />
                    <span className="text-muted">{dealStartDate}</span>
                </div>
                <div className="d-flex align-items-center my-2 my-md-0">
                    <span className="mx-2 w-100-px">
                        <ProgressBarComponent isCompleteTimeframe startOffset={0} progress={progress || 0} now={progress} abort={deal.abort} />
                    </span>
                </div>
                {deal.abort ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-abort`}>
                                Abbruchdatum: {dealAbortDate}
                            </Tooltip>
                        }
                    >
                        <div className="d-flex align-items-center">
                            <span className="text-danger">{dealEndDate}</span>
                        </div>
                    </OverlayTrigger>
                ) : (
                    <div className="d-flex align-items-center">
                        <span className="text-muted">{dealEndDate}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StatusDisplay;
