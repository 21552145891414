import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleX, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { formatDate, formatDateWithoutTime, getFieldConfigByResourceName } from '../../../utils/utils';
import { CancellationLog } from '../../../interfaces';
import { getEnumValue } from '../../../utils/enum';
import { FieldConfig } from 'c1g-ui-library';

interface DealCancellationLogsProps {
    cancellationLogs: CancellationLog[];
    fieldConfigs: FieldConfig[];
}

/**
 * DealCancellationLogs Component
 *
 * Displays a list of cancellation logs for a deal, allowing users to track
 * when a deal was aborted or reactivated, who made the change, and why.
 *
 * Props:
 * @param {CancellationLog[]} cancellationLogs - List of cancellation logs for the deal.
 * @param {FieldConfig[]} fieldConfigs - Field configuration used to get translated reason values.
 *
 * Example usage:
 * ```tsx
 * <DealCancellationLogs cancellationLogs={deal.cancellationLogs} fieldConfigs={fieldConfigs} />
 * ```
 *
 * @returns {JSX.Element} The rendered component with logs or a message if no logs are available.
 */
const DealCancellationLogs: React.FC<DealCancellationLogsProps> = ({ cancellationLogs, fieldConfigs }) => {
    return (
        <Accordion className="mt-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    Abbruchhistorie ({cancellationLogs.length})
                </Accordion.Header>
                <Accordion.Body>
                    {cancellationLogs.length === 0 ? (
                        <p className="text-muted">Keine Abbruchhistorie vorhanden</p>
                    ) : (
                        cancellationLogs.map((log) => (
                            <Card key={log.id} className="rounded mb-3 shadow-none">
                                <Card.Body>
                                    {/* Card Title with Date, Status, and Icons */}
                                    <Card.Title className="d-flex align-items-center">
                                        <span className="fw-bold text-black">{formatDate(log.created, "d.m.Y [um] H:i [Uhr]")}</span>
                                        <span className={`ms-2 fs-6 ${log.abort ? 'text-danger' : 'text-success'}`}>
                                            <FontAwesomeIcon icon={log.abort ? faCircleX : faCircleCheck} className="me-1" />
                                            {log.abort ? 'Abgebrochen' : 'Fortgesetzt'}
                                        </span>
                                    </Card.Title>

                                    {/* Details */}
                                    {log.abort && (
                                        <Row className="py-1">
                                            <Col xs={5}>Abbruchdatum</Col>
                                            <Col xs={7}><span className="text-black">{formatDateWithoutTime(log.abortDate ?? '')}</span></Col>
                                        </Row>
                                    )}
                                    <Row className="py-1">
                                        <Col xs={5}>Grund</Col>
                                        <Col xs={7}>
                                            <span className="text-black">
                                                {getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'lastDeal.abortReason')?.options ?? {}, log?.reason)}
                                            </span>
                                        </Col>
                                    </Row>
                                    {log.notice && (
                                        <Row className="py-1">
                                            <Col xs={5}>Kommentar</Col>
                                            <Col xs={7}><span className="text-black">{log.notice}</span></Col>
                                        </Row>
                                    )}
                                    <Row className="py-1">
                                        <Col xs={5}>Durchgeführt von</Col>
                                        <Col xs={7}><span className="text-black">{log.changedBy}</span></Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        ))
                    )}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default DealCancellationLogs;
