import React from 'react';
import { Row, Col, Table, Image } from 'react-bootstrap';
import { Presence } from '../../../interfaces';
import SkeletonCard from '../../skeleton/SkeletonCard';
import GenericInfosCard from '../../GenericInfosCard';
import { ApiClient } from 'c1g-ui-library';
import StatusDropdownButton from '../../StatusDropdownButton';
import { presencesAbsencesStateColorMap, presencesAbsencesStateIconMap, presencesStatusColorMap } from '../../filter/iconAndColorMappings';
import { formatDateWithoutTime, getFieldConfigByResourceName, getFieldLabel } from '../../../utils/utils';
import { getEnumValue, PresencesStatusEnum } from '../../../utils/enum';
import Card from '../../bootstrap/card';
import { getMediaUrl } from '../../../utils/media/utils';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { FieldConfig } from 'c1g-ui-library';
import { AxiosError } from 'axios';

interface PresenceDetailsProps {
    isLoading?: boolean;
    presence?: Presence | null;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    fieldConfigs: FieldConfig[]
}

/**
 * PresenceDetails Component
 *
 * This component displays detailed information about a specific presence record.
 * It includes:
 * - General presence information in two info cards.
 * - A dropdown to change the presence state.
 * - A table displaying daily presence details for the week.
 *
 * Props:
 * - `isLoading` (boolean) - Displays skeleton loaders when data is loading.
 * - `presence` (Presence | null) - The presence data object.
 * - `onSubmitSuccess` (function) - Callback function triggered after a successful state update.
 * - `fieldConfigs`: Array of `FieldConfig` objects.
 */
const PresenceDetails: React.FC<PresenceDetailsProps> = ({
    isLoading,
    presence,
    onSubmitSuccess,
    fieldConfigs
}) => {
    const { companyId = 'oc' } = useParams();
    const handleStateChange = async (state: number) => {
        if (!presence) return;

        try {
            await ApiClient.put(`/presences/${presence.id}`, { state });
            onSubmitSuccess('Erfolgreich gespeichert');
        } catch (error: any) {
            console.error(error.message as AxiosError);
        }
    };

    function getInfo1() {
        return [
            {
                label: getFieldLabel(fieldConfigs, 'presences.title'),
                value: presence?.title || '-',
            },
            {
                label: 'Teilnehmer',
                value: presence?.deal?.participant?.title || '-',
                link: `/${companyId}/participants/${presence?.deal?.participants_id}`
            },
            {
                label: 'Deal',
                value: presence?.deal?.title || '-',
                link: `/${companyId}/deals/${presence?.deal?.id}`
            },
            {
                label: getFieldLabel(fieldConfigs, 'presences.calendarWeek'),
                value: presence?.calendarWeek && presence?.year ? `KW ${presence?.calendarWeek} / ${presence?.year}` : '-',
            },
            {
                label: 'Zeitraum',
                value: presence?.start ? `${formatDateWithoutTime(presence.start)} bis ${formatDateWithoutTime(presence.end)}` : '-',
            },
            {
                label: getFieldLabel(fieldConfigs, 'presences.notes'),
                value: presence?.notes || '-',
            },
        ];
    }
    return (
        <>
            {/* Title and Status Dropdown */}
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{presence?.title}</h4>
                {presence && (
                    <StatusDropdownButton
                        disabled={presence.state === 1 || presence.state === 2}
                        status={presence.state}
                        onStatusChange={handleStateChange}
                        statusColorMap={presencesAbsencesStateColorMap}
                        statusIconMap={presencesAbsencesStateIconMap}
                        filterEnum={getFieldConfigByResourceName(fieldConfigs, 'presences.state')?.options ?? {}}
                    />
                )}
            </div>

            {/* Info Cards */}
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : <Card className="card-block card-stretch card-height">
                        <Card.Body>
                            <h5>Unterschrift</h5>
                            {presence?.signature ? (
                                <Image
                                    src={getMediaUrl(presence.signature)}
                                    alt="Unterschrift"
                                    fluid
                                    className="border p-2"
                                />
                            ) : (
                                '-'
                            )}
                        </Card.Body>
                    </Card>}
                </Col>
            </Row>

            {/* Table for Weekly Data */}
            <h5 className="mt-4">Wochendetails</h5>
            <Table responsive className="mt-3">
                <thead>
                    <tr>
                        <th>Tag</th>
                        <th>Datum</th>
                        <th>Soll-Zeit</th>
                        <th>Ist-Zeit</th>
                        <th>Status</th>
                        <th ><FontAwesomeIcon icon={faFileAlt} /></th>
                    </tr>
                </thead>
                <tbody>
                    {presence &&
                        Object.entries(presence.week).map(([dayKey, dayData]) => (
                            <tr key={dayKey}>
                                <td>{dayData.label}</td>
                                <td>{dayData.date}</td>
                                <td>{dayData.targetTime} Std.</td>
                                <td>{dayData.actualTime} Std.</td>
                                <td className={presencesStatusColorMap[dayData.status]}>{getEnumValue(PresencesStatusEnum, dayData?.status.toString())}</td>
                                <td>{dayData.absenceUrl ? <a
                                    href={dayData.absenceUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="no-style-link"
                                >
                                    <span>
                                        <FontAwesomeIcon icon={faFileAlt} />
                                    </span>
                                </a> : "-"}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </>
    );
};

export default PresenceDetails;
