/**
 * Returns the readable status of a document (WV)
 * @param status - The numeric status code of the WV
 * @returns The readable status as string
 */
export const getWvStatusText = (status: number): string => {
  const statusMap: { [key: number]: string } = {
      0: 'WV nicht erstellt',
      1: 'WV wird erstellt',
      2: 'WV unterschriftslos',
      3: 'WV an Teilnehmer verschickt',
      4: 'WV vom TN geöffnet',
      5: 'WV unterschrieben',
      6: 'WV an AfA verschickt',
      7: 'WV abgebrochen'
  };

  return statusMap[status] || 'Unbekannter Status';
};