import { faCalendarDays, faChevronLeft, faChevronRight, faCircleCheck, faDownload, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, useToast } from 'c1g-ui-library';
import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Deal } from '../../../interfaces';
import InfoDialog from '../../InfoDialog';
import PortalWrapper from '../../PortalWrapper';

type CalendarToolbarAgendaProps = {
  onPrevClick: () => void;
  onNextClick: () => void;
  onTodayClick: () => void;
  deal: Deal
};

/**
 * CalendarToolbarAgenda Component
 *
 * This component renders a toolbar for navigating and managing a calendar view.
 * It includes navigation controls to move between previous, current, and next views,
 * and a button for downloading engagement data.
 *
 * Props:
 * - `onPrevClick` (function): Callback function triggered when the "Previous" button is clicked.
 * - `onNextClick` (function): Callback function triggered when the "Next" button is clicked.
 * - `onTodayClick` (function): Callback function triggered when the "Today" button is clicked.
 * - `dealId` (number): ID of the deal.
 */
const CalendarToolbarAgenda: React.FC<CalendarToolbarAgendaProps> = ({
  onPrevClick,
  onNextClick,
  onTodayClick,
  deal
}) => {
  const { show, message, error, hideToast, showToast } = useToast();
  const [dialogContent, setDialogContent] = useState<{ headline: string; message: string } | null>(null);

  /**
   * Handles downloading the curriculum agenda PDF
   * 
   * This function triggers an API request to generate a PDF of the curriculum agenda
   * for the current deal and handles the response appropriately.
   * 
   * Steps:
   * 1. Makes a POST request to the API endpoint to generate the agenda
   * 2. Handles different response types:
   *    - If response type is 'toast', shows a dialog that auto-closes after 7 seconds
   *    - Otherwise, displays a toast notification with the response message
   * 3. Handles any errors by showing a toast notification with error details
   * 
   * The function is accessible via both the download button and the dropdown menu.
   */
  const handleAgendaDownload = async () => {
    try {
      // Send API request to generate the agenda PDF for the current deal
      const response = await ApiClient.post(`/deals/${deal.id}/agenda/generate`, {});

      // If response type is 'toast', show a dialog with auto-close functionality
      if (response.data.type === 'toast') {
        setDialogContent({
          headline: response.data.headline,
          message: response.data.message,
        });

        // Automatically close the dialog after 7 seconds
        setTimeout(() => {
          setDialogContent(null);
        }, 7000);

      } else {
        // For other response types, show a regular toast notification
        showToast(`${response.data.headline}: ${response.data.message}`);
      }
    } catch (error: any) {
      console.error('Download failed:', error.message as AxiosError);
    }
  };

  /**
   * Handles opening the education plan in a new browser tab
   * 
   * This function creates a URL to access the education plan based on the deal's timetableHash.
   * The URL follows the pattern: https://bildungsplan.[company].de/[timetableHash].html
   * 
   * Steps:
   * 1. Gets the company ID from session storage
   * 2. Builds the education plan URL using the company ID and the deal's timetableHash
   * 3. Opens the URL in a new browser tab
   * 
   * The function is only available when the deal has a valid timetableHash property.
   */
  const handleOpenEducationPlan = () => {
    try {
      // Get companyId from sessionStorage and set companySlug based on companyId
      const companyId = sessionStorage.getItem('companyId') || 'oc';
      const companySlug = companyId === 'oc' ? 'onecareer' : 'chapternext';
      
      // Build the URL according to the specified pattern
      const url = `https://bildungsplan.${companySlug}.de/${deal.timetableHash}`;
      
      // Open the URL in a new tab
      window.open(url, '_blank');
    } catch (error: any) {
      console.error('Error opening education plan:', error.message as AxiosError);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex gap-1">
          <Dropdown className="me-2 rounded">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow h-100 py-10-px px-3"
              >
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <PortalWrapper>
                <Dropdown.Menu>

                  {/* Download Lehrplan */}
                  <Dropdown.Item disabled={!!dialogContent} onClick={handleAgendaDownload}>
                    <FontAwesomeIcon className="text-primary" width={20} icon={faDownload} /> Download Lehrplan
                  </Dropdown.Item>

                  {/* opens Bildungsplan in new tab if timetableHash exists */}
                  {deal.timetableHash && (  
                    <Dropdown.Item onClick={handleOpenEducationPlan}>
                      <FontAwesomeIcon className="text-primary" width={20} icon={faCalendarDays} /> Bildungsplan aufrufen
                    </Dropdown.Item>
                  )}

                </Dropdown.Menu>
              </PortalWrapper>
            </Dropdown>


          <Button className="btn-soft-primary px-3" variant="secondary" onClick={onPrevClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
          <Button variant="primary" onClick={onTodayClick}>
            Heute
          </Button>
          <Button className="btn-soft-primary px-3" variant="secondary" onClick={onNextClick}>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </div>
      </div>

      {dialogContent && (
        <InfoDialog onClose={() => setDialogContent(null)} title={dialogContent.headline}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="text-black text-break">{dialogContent.message}</span>
            <FontAwesomeIcon icon={faCircleCheck} className="text-success ms-2" size="lg" />
          </div>
        </InfoDialog>
      )}
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default CalendarToolbarAgenda;
