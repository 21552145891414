import { faArrowUpRightFromSquare, faCalendar, faClock, faGraduationCap, faHouse, faPlayCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import { AxiosError } from 'axios';
import { ApiClient, CollapsibleCard, FieldConfig, NotificationToast, useDocumentTitle, usePermissions, useTabState, useToast } from 'c1g-ui-library';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ComboButtonGroup, { ComboButton } from '../../components/ComboButtonGroup';
import DealStatusIndicator from '../../components/deals/DealStatusIndicator';
import DealDetailsHeader from '../../components/deals/detail/DealDetailsHeader';
import DealInfos from '../../components/deals/detail/DealInfos';
import DealProcesses from '../../components/deals/detail/DealProcesses';
import ParticipantInitialsComponent from '../../components/ParticipantInitialsComponent';
import AgendaCalendar from '../../components/participants/detail/AgendaCalendar';
import CalendarToolbarAgenda from '../../components/participants/detail/CalendarToolbarAgenda';
import CalendarToolbarEngagements from '../../components/participants/detail/CalendarToolbarEngagements';
import DealModules from '../../components/participants/detail/DealModules';
import Engagements from '../../components/participants/detail/Engagements';
import FullHeader from '../../components/ui/header/FullHeader';
import { Deal, Permissions } from '../../interfaces';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';
import { fetchAndCombineFieldConfigs, getInitials } from '../../utils/utils';

function DealDetail() {
  const updateDocumentTitle = useDocumentTitle({ appName: 'ClientOffice', moduleTranslations: moduleTranslations });
  const { dealId } = useParams();
  const [deal, setDeal] = useState<Deal>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();
  const { show, message, error, showToast, hideToast } = useToast();
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
  const [selectedTab, setSelectedTab] = useTabState('deal_overview');
  const engagementsCalendarRef = useRef<FullCalendar | null>(null);
  const [viewMode, setViewMode] = useState<'calendar' | 'list'>('list');
  const agendaCalendarRef = useRef<FullCalendar | null>(null);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewDeals, 'read');

      if (hasPermission) {
        dealId && fetchDeal(dealId);
        if (!fieldConfigs || fieldConfigs.length === 0) {
          fetchAndCombineFieldConfigs(['deals'], setFieldConfigs);
        }
      } else {
        navigate('/errors/error404');
      }
    }
  }, [dealId, permissionsLoaded]);

  const fetchDeal = async (dealId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/deals/${dealId}`);
      const dealResponse = response.data;
      setDeal(dealResponse);
      updateDocumentTitle(dealResponse.title)
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = (message?: string, isError?: boolean) => {
    dealId && fetchDeal(dealId);
    if (message) {
      showToast(message, isError);
    }
  };

  /**
   * Handles the selection of a tab in the deal detail view.
   * 
   * @param tab - The ID of the tab to be selected.
   */
  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  /**
   * Navigation tabs for the deal detail view.
   * 
   * @returns An array of ComboButton objects.
   */
  const comboButtons: ComboButton[] = useMemo(() => {
    return [
      { id: 'deal_overview', label: 'Übersicht', icon: faHouse },
      { id: 'deal_presence', label: 'An- und Abwesenheiten', icon: faClock },
      { id: 'deal_education_plan', label: "Lehrplan", icon: faCalendar },
      { id: 'deal_modules', label: 'Module', icon: faGraduationCap },
      { id: 'deal_processes', label: 'Prozesse', icon: faPlayCircle },
    ] as ComboButton[];
  }, []);

  return (
    <>
      <FullHeader>
        {/* Deals Details Header */}
        <DealDetailsHeader
          deal={deal}
          isLoading={isLoading}
          onSubmitSuccess={handleModalSubmit}
          fieldConfigs={fieldConfigs}>
        </DealDetailsHeader>
      </FullHeader>

      {/* Deal Details Content */}
      <div className='container-fluid p-40'>
        {deal &&
          <Row>
            {/* Deal Details Content Left Column */}
            <Col lg={9}>
              <Card>
                <Card.Body>
                  <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedTab}
                    setSelectedCombo={handleSelectedTab}
                    borderRadius="normal"
                  ></ComboButtonGroup>
                </Card.Body>
              </Card>

              {/* Overview Tab Content */}
              {selectedTab === 'deal_overview' &&
                <Card className="card-block card-stretch">
                  <Card.Body>
                    <DealInfos
                      dealType="deals"
                      participant={deal?.participant}
                      deal={deal}
                      isLoading={isLoading}
                      label=""
                      onSubmitSuccess={handleModalSubmit}
                      fieldConfigs={fieldConfigs}
                    />
                  </Card.Body>
                </Card>
              }

              {/* Presence Tab Content */}
              {selectedTab === 'deal_presence' &&
                <Card className="card-block card-stretch">
                  <Card.Body>
                    {deal?.participant && (
                      <>
                        <div className="engagements-toolbar pb-4 d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="mb-0">An- und Abwesenheiten</h4>
                          </div>
                          <CalendarToolbarEngagements
                            viewMode={viewMode}
                            onViewModeChange={setViewMode}
                            onPrevClick={() => engagementsCalendarRef.current?.getApi().prev()}
                            onNextClick={() => engagementsCalendarRef.current?.getApi().next()}
                            onTodayClick={() => engagementsCalendarRef.current?.getApi().today()}
                            dealId={deal?.id}
                          />
                        </div>

                        {/* Engagements Calendar / List */}
                        <Engagements
                          dealType="deals"
                          dealId={deal?.id}
                          participant={deal?.participant}
                          calendarRef={engagementsCalendarRef}
                          viewMode={viewMode}
                        />
                      </>
                    )}
                  </Card.Body>
                </Card>
              }

              {/* Education Plan Tab Content */}
              {selectedTab === 'deal_education_plan' &&
                <Card className="card-block card-stretch">
                  <Card.Body>

                    {/* Education Plan Header */}
                    {deal?.participant && (
                      <>
                        <div className="engagements-toolbar pb-4 d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="mb-0">Lehrplan</h4>
                          </div>
                          <CalendarToolbarAgenda
                            onPrevClick={() => agendaCalendarRef.current?.getApi().prev()}
                            onNextClick={() => agendaCalendarRef.current?.getApi().next()}
                            onTodayClick={() => agendaCalendarRef.current?.getApi().today()}
                            deal={deal}
                          />
                        </div>

                        {/* Education Plan Calendar */}
                        <AgendaCalendar
                          type="deal"
                          data={deal}
                          calendarRef={agendaCalendarRef}
                          onSubmitSuccess={handleModalSubmit}
                        />
                      </>
                    )}
                  </Card.Body>
                </Card>
              }

              {/* Modules Tab Content */}
              {selectedTab === 'deal_modules' &&
                <Card className="card-block card-stretch">
                  <Card.Body>
                    <div className="modules-header pb-4 d-flex justify-content-between align-items-center">
                      <h4 className="mb-0">Module</h4>
                    </div>

                    {deal?.id ?
                      <DealModules dealId={deal.id} />
                      :
                      <p>Kein Deal vorhanden</p>
                    }
                  </Card.Body>
                </Card>
              }

              {/* Processes Tab Content */}
              {selectedTab === 'deal_processes' &&
                <Card className="card-block card-stretch">
                  <Card.Body>
                    <div className="processes-header pb-4 d-flex justify-content-between align-items-center">
                      <h4 className="mb-0">Prozesse</h4>
                    </div>

                    {deal?.participant &&
                      <DealProcesses deal={deal} />
                    }
                  </Card.Body>
                </Card>
              }
            </Col>

            {/* Deal Details Content Right Column */}
            <Col lg={3}>
              <CollapsibleCard
                title="Teilnehmer"
                showDivider
                initialOpen
              >
                <div className='p-3'>
                  <div onClick={() => window.open(`/oc/participants/${deal?.participant?.id}`, '_blank')} className='d-flex justify-content-start gap-4 border rounded cursor-pointer p-3 hover-bg-primary-light'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <ParticipantInitialsComponent size={32} initials={getInitials(deal?.participant?.title ?? '')}></ParticipantInitialsComponent>
                    </div>
                    <div className='d-flex flex-column justify-content-start w-100 overflow-hidden text-wrap'>
                      <div>
                        <div className='d-flex justify-content-between align-items-center text-dark'>
                          <span>{deal?.participant?.firstname} {deal?.participant?.lastname} ({deal?.participant?.id})</span><FontAwesomeIcon style={{ fontSize: '12px' }} icon={faArrowUpRightFromSquare} />
                        </div>
                        <div className='text-nowrap'>
                          <DealStatusIndicator dealStatus={deal?.participant?.lastDeal?.status} fieldConfigs={fieldConfigs} dealType="deals" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CollapsibleCard>
            </Col>
          </Row>
        }

        {/* Notification Toast */}
        <NotificationToast
          show={show}
          onClose={hideToast}
          message={message}
          error={error}
        />
      </div>
    </>
  );
}

export default DealDetail;
