import { ReactNode } from 'react';
import { useFavicon } from 'c1g-ui-library';
import { ToastManager } from 'c1g-ui-library';

// Importing SCSS/CSS files
import './assets/scss/hope-ui.scss';
import './assets/scss/pro.scss';
import './assets/scss/custom.scss';
import './assets/scss/dark.scss';
import './assets/scss/rtl.scss';
import './assets/scss/customizer.scss';
import './assets/custom/scss/custom.scss';
import 'react-nestable/dist/styles/index.css'
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-json-view-lite/dist/index.css';
import 'tippy.js/dist/tippy.css';

interface AppProps {
  children?: ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
  useFavicon()

  return (
    <div className="App">
      {children}
      <ToastManager />
    </div>
  );
};

export default App;
