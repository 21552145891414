import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { activityTypesColorMap, activityTypesIconMap, smileyColorMap, smileyMap } from './filter/iconAndColorMappings';
import { faComment } from '@fortawesome/pro-regular-svg-icons';

interface ParticipantInitialsComponentProps {
    initials: string;
    size?: number;
    smileyLevel?: number;
    type?: 'comment' | 'smiley' | 'activity';
    activityType?: number;
    mediaUrl?: string;
    inHeader?: boolean;
}

/**
 * ParticipantInitialsComponent
 * 
 * This component displays either a participant's initials or a profile image inside a circular container.
 * It optionally shows an icon, which can represent a "smiley" (mood/status), a "comment", 
 * or an "activity" icon, depending on the specified type.
 * 
 * Props:
 * - `initials` (string): The initials to display within the circular container (if no image is provided).
 * - `mediaUrl` (string, optional): A URL for the participant's profile picture. If provided, replaces initials.
 * - `size` (number, optional): The diameter of the circular container in pixels (default is 60px).
 * - `smileyLevel` (number, optional): A numeric value (1 to 5) that determines which smiley icon 
 *   and color to display based on predefined mappings. Ignored if `type` is not set to "smiley".
 * - `type` ('comment' | 'smiley' | 'activity', optional): Determines the type of icon to display. 
 *   - If set to "smiley", the smiley icon corresponding to the `smileyLevel` will be shown.
 *   - If set to "comment", a comment icon will be displayed.
 *   - If set to "activity", an activity icon corresponding to the `activityType` will be displayed.
 * - `activityType` (number, optional): A numeric value used to fetch the corresponding activity icon 
 *   and color from `activityLabels`. Ignored if `type` is not set to "activity".
 */
const ParticipantInitialsComponent: React.FC<ParticipantInitialsComponentProps> = ({
    initials,
    size = 60,
    smileyLevel = 5,
    type = 'smiley',
    activityType,
    mediaUrl,
    inHeader = false
}) => {
    const fontSize = size * 0.4;
    const smileyIcon = smileyLevel ? smileyMap[smileyLevel] : null;
    const smileyColor = smileyLevel ? smileyColorMap[smileyLevel] : 'transparent';
    const activityIcon = activityType?.toString() ? activityTypesIconMap[activityType] : null;
    const activityIconColor = activityType?.toString() ? activityTypesColorMap[activityType] : null;

    return (
        <span className="participant-initials" style={{ width: size, height: size }}>
            {/* Display Image if mediaUrl exists, otherwise show initials */}
            {mediaUrl ? (
                <img src={mediaUrl} alt="Profile" className="participant-initials-image" />
            ) : (
                <span className="participant-initials-text" style={{ fontSize }}>{initials}</span>
            )}

            {/* Overlay Icons (Smiley, Comment, Activity) */}
            {smileyIcon && (
                <div className={`${inHeader ? 'header-smiley-icon-container' : 'smiley-icon-container '}`}>
                    {type === 'smiley' && <FontAwesomeIcon fontSize={fontSize * 1.3} color={smileyColor} icon={smileyIcon} />}
                    {type === 'comment' && (
                        <div className="activity-icon-container" style={{ backgroundColor: '#8A92A6' }}>
                            <FontAwesomeIcon width={30} size="sm" icon={faComment} />
                        </div>
                    )}
                    {type === 'activity' && activityIcon && activityIconColor && (
                        <div className="activity-icon-container" style={{ backgroundColor: activityIconColor }}>
                            <FontAwesomeIcon width={30} size="sm" icon={activityIcon} />
                        </div>
                    )}
                </div>
            )}
        </span>
    );
};

export default ParticipantInitialsComponent;
