import React from 'react';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFieldConfigByResourceName } from '../../../utils/utils';
import { activityTypesColorMap, activityTypesIconMap } from '../../filter/iconAndColorMappings';
import { FieldConfig } from 'c1g-ui-library';

export interface ActivityCounts {
    type: number;
    count: number;
}

interface ActivityBadgeListProps {
    activityCounts: ActivityCounts[];
    fieldConfigs: FieldConfig[];
    onBadgeClick: (type: number) => void;
}

/**
 * ActivityBadgeList Component
 * 
 * This component renders a list of activity badges based on the provided activity data.
 * Each badge represents an activity type and includes:
 * - A label derived from the `fieldConfigs` options for `activities.type`.
 * - An icon and background color determined by `activityTypesIconMap` and `activityTypesColorMap`.
 * - An optional count of activities, shown next to the label if the count is greater than zero.
 * - A clickable badge that triggers the `onBadgeClick` callback with the activity type as its parameter.
 * 
 * Props:
 * - `activityCounts` (ActivityCounts[]): Array of activity counts, where each object contains:
 *    - `type` (number): The activity type, used to fetch its icon, color, and label.
 *    - `count` (number): The number of activities of this type.
 * - `fieldConfigs` (FieldConfig[]): Array of field configurations, used to fetch labels for activity types.
 * - `onBadgeClick` (function): Callback triggered when a badge is clicked, receiving the activity type as an argument.
 * 
 * Example Usage:
 * ```
 * <ActivityBadgeList 
 *     activityCounts={[{ type: 1, count: 5 }, { type: 2, count: 3 }]}
 *     fieldConfigs={fieldConfigs}
 *     onBadgeClick={(type) => console.log(`Badge clicked for type: ${type}`)}
 * />
 * ```
 */
const ActivityBadgeList: React.FC<ActivityBadgeListProps> = ({ activityCounts, fieldConfigs, onBadgeClick }) => {
    const fieldConfig = getFieldConfigByResourceName(fieldConfigs, 'activities.type');
    const options = fieldConfig?.options || {};

    return (
        <div className="d-flex flex-wrap">
            {activityCounts.map((activity, index) => {
                const icon = activityTypesIconMap[activity.type] || activityTypesIconMap[0];
                const color = activityTypesColorMap[activity.type] || activityTypesColorMap[0];
                const label = options[activity.type] || `Activitytype ${activity.type}`;

                return (
                    <ActivityBadge
                        key={index}
                        count={activity.count}
                        color={color}
                        icon={icon}
                        label={label}
                        onClick={() => onBadgeClick(activity.type)}
                    />
                );
            })}
        </div>
    );
};

interface ActivityProps {
    count: number;
    color: string;
    icon: any;
    label: string;
    onClick: () => void;
}

const ActivityBadge: React.FC<ActivityProps> = ({ count, color, icon, label, onClick }) => {
    return (
        <Badge
            pill
            bg="none"
            className="m-2 d-flex align-items-center text-black border rounded cursor-pointer"
            onClick={onClick}
        >
            <div
                className="d-flex justify-content-center align-items-center rounded-circle"
                style={{ width: '22px', height: '22px', background: color }}
            >
                <FontAwesomeIcon icon={icon} className="text-white" size="lg" />
            </div>

            <span className="ms-2 text-black fw-normal fs-6">
                {label} {count > 0 && <span className="ms-1">({count})</span>}
            </span>
        </Badge>
    );
};

export default ActivityBadgeList;
