import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, Setting, usePermissions, useSortableData, useTableHeight, useToast } from 'c1g-ui-library';
import {
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Button, Col, Row, Table, } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/bootstrap/card';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import GenericDropdownFilter from '../../components/filter/GenericDropdownFilter';
import SearchInput from '../../components/SearchInput';
import DynamicPagination from '../../components/table/DynamicPagination';
import PaginationInfo from '../../components/table/PaginationInfo';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import TableNoDataMessage from '../../components/table/TableNoDataMessage';
import { Permissions } from '../../interfaces';
import {
    PermissionsEnum,
    SettingArea,
    SettingAreaEnum,
} from '../../utils/enum';
import { getSettingIcon } from '../../utils/settings/utils';

const Settings = memo(() => {
  useTableHeight();
  const navigate = useNavigate();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [settings, setSettings] = useState<Setting[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [selectedAreaFilter, setSelectedAreaFilter] = useState<SettingArea | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('',);
  const [selectedSearchColumns, setSelectedSearchColumns] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const { items: sortedSettings, requestSort } = useSortableData(settings, false, { field: 'title', type: 'asc' });


  const fetchSettings = useCallback(async () => {
    setSettings([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (limit.toString()) {
      queryParams += `&limit=${limit}`;
    }

    if (selectedAreaFilter?.toString()) {
      queryParams += `&area=${selectedAreaFilter}`;
    }

    if (searchQuery) {
      queryParams += `&search=${encodeURIComponent(searchQuery)}`;
      if (selectedSearchColumns !== 'all') {
        queryParams += `&column=${encodeURIComponent(selectedSearchColumns)}`
      }
    }

    try {
      const response = await ApiClient.get(`/settings${queryParams}`);
      const settingResponse = response.data
      const parsedSettings: Setting[] = settingResponse.list.map((item: { details: string; }) => ({
        ...item,
        details: item.details ?? null
      })) ?? [];

      setTotalPages(settingResponse.amountPages);
      setSettings(parsedSettings);
      setCurrentPage(settingResponse.page);
      setLimit(settingResponse.itemsPerPage);
      setTotalEntries(settingResponse.amountAllItems);
      setAvailableFilter(settingResponse.searchFilters)
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, limit, selectedAreaFilter, searchQuery]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewSettings, 'read');

      if (hasPermission) {
        fetchSettings();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    fetchSettings,
    permissionsLoaded,
    limit,
    selectedAreaFilter,
    searchQuery
  ]);

  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchColumns(data.filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  const handleSelectedAreaFilterChange = (status: SettingArea | null) => {
    setCurrentPage(1)
    setSelectedAreaFilter(status);
  };

  const resetSearch = () => {
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
    setSelectedAreaFilter(null);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);

  return (
    <div className='container-fluid p-40'>
      <div className='table-controls-wrapper'>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <h3>Settings</h3>
        </div>
        <Card className="card-block card-stretch card-height">
          <Card.Body>
            <Row className="d-flex justify-content-between mb-4">
              <Col md={6}>

                <Col md={6}>
                  {searchQuery ?
                    <div className="d-flex align-items-baseline">
                      <h4 className="m-0">Suchergebnisse</h4>
                      <span className="ms-3 d-flex align-items-baseline">
                        <Button
                          className="btn-ghost m-0 p-0 fs-6"
                          variant="link"
                          onClick={resetSearch}
                        >
                          Suche beenden
                        </Button>
                      </span>
                    </div>
                    : <ComboButtonGroup
                      buttons={[
                        { id: 'all', label: 'Alle' },
                      ]}
                      selectedCombo={'all'}
                      setSelectedCombo={() => { }}
                      borderRadius="normal"
                    ></ComboButtonGroup>}
                </Col>

              </Col>
              <Col md={3}>
                <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex justify-content-end flex-wrap">
                  <GenericDropdownFilter
                    selectedFilter={selectedAreaFilter}
                    handleFilterChange={handleSelectedAreaFilterChange}
                    filterEnum={SettingAreaEnum}
                    titlePlaceholder="Bereich"
                  />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table className="sticky-table" responsive="md" size="sm" style={{ overflow: 'auto' }}>
          <thead>
            <tr>
              <th
                style={{ cursor: 'pointer', width: '10px' }}
                scope="col"
                onClick={() => requestSort('area')}
              >
                Bereich
              </th>
              <th
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('ident')}
              >
                Titel
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('ident')}
              >
                Ident / Kürzel
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedSettings.map((setting) => (
                <tr key={setting.id} className="bg-white">
                  <td className='text-center'>
                    {getSettingIcon(setting.area)}
                  </td>
                  <td className="d-flex align-items-center py-3">
                    <Link
                      to={`/${companyId}/settings/${setting.ident}`}
                      className="btn-link ps-0"
                    >
                      {setting.title}
                    </Link>
                  </td>

                  <td>
                    {setting.ident}
                  </td>
                </tr>
              ))}
            {!isLoading && sortedSettings.length === 0 && (
              <TableNoDataMessage
                message="Keine Einstellungen"
              />
            )}
          </tbody>
        </Table>
      </div>

      {totalEntries > 0 && (
        <div className='pagination-wrapper pt-2'>
          <Row>
            <Col>
              <PaginationInfo
                currentPage={currentPage}
                limit={limit}
                totalEntries={totalEntries}
                onLimitChange={(size) => {
                  setLimit(size);
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <DynamicPagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
          </Row>
        </div>
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
});

export default Settings;
