import React, { useState } from 'react';
import { Modal, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import FormGroup from '../../form/FormGroup';

interface SendPsmCodeModalProps {
    onModalClose: () => void;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
}

interface FormValues {
    psmSelection: string[];
    ccSelection: string[];
    ccInput: string;
    subject: string;
    message: string;
    selectedSubjectTemplate?: string;
    selectedMessageTemplate?: string;
}

const templates = {
    subject: [
        { id: 'template1', label: 'Vorlage 1', value: 'Einladung zur PSM Prüfung' },
        { id: 'template2', label: 'Vorlage 2', value: 'Wichtige Informationen zur PSM Prüfung' },
    ],
    message: [
        { id: 'template1', label: 'Vorlage 1', value: 'Sehr geehrte Damen und Herren,\n\nbitte beachten Sie die folgenden Details zur PSM Prüfung.\n\nMit freundlichen Grüßen' },
        { id: 'template2', label: 'Vorlage 2', value: 'Liebes Team,\n\nhier sind wichtige Details zur anstehenden Prüfung.\n\nBeste Grüße' },
    ],
};

/**
 * SendPsmCodeModal Component
 * 
 * This component renders a modal for sending PSM codes to participants. 
 * Users can select multiple PSM Prüfungen, choose CC recipients either via predefined buttons or manually input,
 * and use predefined templates for the subject and message.
 * 
 * Props:
 * - `onModalClose`: A callback to close the modal.
 * - `onSubmitSuccess`: A callback triggered upon successful submission, accepting a success message and error flag.
 */
const SendPsmCodeModal: React.FC<SendPsmCodeModalProps> = ({
    onModalClose,
    onSubmitSuccess,
}) => {
    const [formValues, setFormValues] = useState<FormValues>({
        psmSelection: [],
        ccSelection: [],
        ccInput: '',
        subject: '',
        message: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const handlePsmSelection = (psm: string) => {
        setFormValues((prev) => ({
            ...prev,
            psmSelection: prev.psmSelection.includes(psm)
                ? prev.psmSelection.filter((item) => item !== psm)
                : [...prev.psmSelection, psm],
        }));
    };

    const handleCcSelection = (cc: string) => {
        setFormValues((prev) => ({
            ...prev,
            ccSelection: prev.ccSelection.includes(cc)
                ? prev.ccSelection.filter((item) => item !== cc)
                : [...prev.ccSelection, cc],
        }));
    };

    const handleTemplateClick = (field: keyof FormValues, templateId: string, templateValue: string) => {
        setFormValues((prev) => ({
            ...prev,
            [field]: templateValue,
            ...(field === 'subject' && { selectedSubjectTemplate: templateId }),
            ...(field === 'message' && { selectedMessageTemplate: templateId }),
        }));
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { id, value } = e.target;
        setFormValues((prev) => ({ ...prev, [id]: value }));
    };

    const handleSend = async () => {
        setIsLoading(true);
        try {
            console.log('Form Data:', formValues);
            await new Promise((resolve) => setTimeout(resolve, 1000));
            onSubmitSuccess('PSM Code erfolgreich gesendet', false);
        } catch (error) {
            console.error('Error sending PSM Code:', error);
            onSubmitSuccess('Fehler beim Senden des PSM Codes', true);
        } finally {
            setIsLoading(false);
            onModalClose();
        }
    };

    return (
        <Modal show onHide={onModalClose} centered>
            <Modal.Header>
                <Modal.Title>PSM Code an Teilnehmer senden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6 className="mb-3">PSM Prüfung(en) auswählen</h6>
                <div className="d-flex mb-3">
                    <Button
                        size="sm"
                        className={`${formValues.psmSelection.includes('PSM I Prüfung')
                            ? 'btn-soft-primary'
                            : 'btn-outline-neutral'
                            } me-2 rounded-pill`}
                        onClick={() => handlePsmSelection('PSM I Prüfung')}
                    >
                        PSM I Prüfung
                    </Button>
                    <Button
                        size="sm"
                        className={`${formValues.psmSelection.includes('PSM II Prüfung')
                            ? 'btn-soft-primary'
                            : 'btn-outline-neutral'
                            } me-2 rounded-pill`}
                        onClick={() => handlePsmSelection('PSM II Prüfung')}
                    >
                        PSM II Prüfung
                    </Button>
                </div>

                <Form>
                    <FormGroup<FormValues>
                        id="ccInput"
                        label="CC"
                        type="text"
                        value={formValues.ccInput}
                        onChange={handleInputChange}
                        placeholder="Manuell CC hinzufügen"
                    />

                    <div className="d-flex mb-3 flex-wrap">
                        {['Teilnehmer Coach', 'AfA Berater', 'Sales Berater Setting', 'Sales Berater Closing'].map(
                            (cc) => (
                                <Button
                                    key={cc}
                                    size="sm"
                                    className={`${formValues.ccSelection.includes(cc)
                                        ? 'btn-soft-primary'
                                        : 'btn-outline-neutral'
                                        } me-2 mb-2 rounded-pill`}
                                    onClick={() => handleCcSelection(cc)}
                                >
                                    {cc}
                                </Button>
                            )
                        )}
                    </div>

                    <FormGroup<FormValues>
                        id="subject"
                        label="Betreff"
                        type="text"
                        value={formValues.subject}
                        onChange={handleInputChange}
                        placeholder="Betreff"
                    />
                    <div className="d-flex mb-3 flex-wrap">
                        {templates.subject.map((template) => (
                            <Button
                                key={template.id}
                                size="sm"
                                className={`${formValues.selectedSubjectTemplate === template.id
                                    ? 'btn-soft-primary'
                                    : 'btn-outline-neutral'
                                    } me-2 mb-2 rounded-pill`}
                                onClick={() =>
                                    handleTemplateClick('subject', template.id, template.value)
                                }
                            >
                                {template.label}
                            </Button>
                        ))}
                    </div>

                    <FormGroup<FormValues>
                        id="message"
                        label="Nachricht"
                        type="textarea"
                        value={formValues.message}
                        onChange={handleInputChange}
                        placeholder="Nachricht"
                        noResize
                    />
                    <div className="d-flex mb-3 flex-wrap">
                        {templates.message.map((template) => (
                            <Button
                                key={template.id}
                                size="sm"
                                className={`${formValues.selectedMessageTemplate === template.id
                                    ? 'btn-soft-primary'
                                    : 'btn-outline-neutral'
                                    } me-2 mb-2 rounded-pill`}
                                onClick={() =>
                                    handleTemplateClick('message', template.id, template.value)
                                }
                            >
                                {template.label}
                            </Button>
                        ))}
                    </div>

                    <Row className="mt-4 justify-content-end">
                        <Col xs="auto">
                            <Button onClick={onModalClose} className="btn btn-soft-primary me-2">
                                Abbrechen
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSend}
                                disabled={
                                    isLoading ||
                                    !formValues.psmSelection.length ||
                                    !formValues.message
                                }
                            >
                                Senden
                                {isLoading && (
                                    <Spinner
                                        className="ms-2"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></Spinner>
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SendPsmCodeModal;