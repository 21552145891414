import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from 'c1g-ui-library';
import { Companion, Contact } from '../../../interfaces';
import { useParams } from 'react-router-dom';
import SelectGroup from '../../form/SelectGroup';
import FormGroup from '../../form/FormGroup';
import { getFieldLabel, getFieldOptions } from '../../../utils/utils';
import MediaPickerModal from '../../media/modal/MediaPickerModal';
import SelectedMediaPreview from '../../media/SelectMediaPreview';
import ComboButtonGroup, { ComboButtonId } from '../../ComboButtonGroup';
import { AxiosError } from 'axios';
import { MediaItem } from 'c1g-ui-library';

type AddEditCompanionModalProps = {
    modalTitle: string;
    companion?: Companion;
    onSubmitSuccess: (message?: string) => void;
    onModalClose: () => void;
    fieldConfigs: any[];
};

interface FormValues {
    salutation: number;
    firstname: string;
    middleName: string;
    lastname: string;
    email: string;
    mobileNumber: string
    phoneNumber: string
    image_id: number | null;
    image: MediaItem | null;
}

const comboButtons = [
    { id: 'general', label: 'Allgemeines' },
    { id: 'contact', label: 'Kontakt' },
    { id: 'image', label: 'Profilbild' }
];

/**
 * AddEditCompanionModal Component
 *
 * This component is a modal used to create or edit a companion entity.
 * It supports multiple sections via a combo button group, allowing users to switch between
 * "Allgemeines" (General), "Kontakt" (Contact), and "Profilbild" (Profile Image).
 *
 * Props:
 * @param {string} modalTitle - Title of the modal.
 * @param {Companion} [companion] - The companion object (if editing an existing companion).
 * @param {Function} onSubmitSuccess - Callback function executed on successful submission.
 * @param {Function} onModalClose - Function to close the modal.
 * @param {FieldConfig[]} fieldConfigs - Configuration array for fields.
 */
const AddEditCompanionModal: React.FC<AddEditCompanionModalProps> = ({
    modalTitle,
    companion,
    onSubmitSuccess,
    onModalClose,
    fieldConfigs,
}) => {

    const { companionId } = useParams<{ companionId?: string }>();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showMediaPicker, setShowMediaPicker] = useState<boolean>(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [selectedCombo, setSelectedCombo] = useState<ComboButtonId>('general');

    const [formValues, setFormValues] = useState<FormValues>({
        salutation: companion?.contact?.salutation ?? 0,
        firstname: companion?.contact?.firstname ?? '',
        middleName: companion?.contact?.middleName ?? '',
        lastname: companion?.contact?.lastname ?? '',
        email: companion?.contact?.email ?? '',
        mobileNumber: companion?.contact?.mobileNumber ?? '',
        phoneNumber: companion?.contact?.phoneNumber ?? '',
        image_id: companion?.contact?.image_id ?? null,
        image: companion?.contact?.image ?? null,
    });

    const [initialFormValues] = useState<FormValues>(formValues);

    const checkIfDataChanged = (): boolean =>
        JSON.stringify(formValues) !== JSON.stringify(initialFormValues);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormValues((prev) => ({ ...prev, [id]: value }));
    };

    const handleProfileImageSelect = (media: MediaItem) => {
        setFormValues({ ...formValues, image_id: media.id, image: media });
    };

    const handleProfileImageDelete = () => {
        setFormValues({ ...formValues, image_id: null, image: null });
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                if (companionId) {
                    await ApiClient.put(`/contacts/${companion?.contact_id}`, formValues)
                } else {
                    const createdContact = (await ApiClient.post('/contacts', formValues)).data as Contact
                    await ApiClient.post('/companions', { contact_id: createdContact.id })
                }
                onSubmitSuccess('Erfolgreich gespeichert');

            } catch (error: any) {
                console.error(error.message as AxiosError);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    return (
        <>
            <Modal centered size="xl" show onHide={onModalClose} backdrop="static" keyboard={false} fullscreen="xl-down">
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="btn-soft-primary dropdown-no-arrow round-button" style={{ padding: 0 }}>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}>
                                    <FontAwesomeIcon width={30} icon={faXmark} /> Schließen
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button disabled={isLoading} variant="primary" onClick={() => hiddenSubmitButtonRef.current?.click()}>
                            Fertig
                            {isLoading && <Spinner className="ms-2" as="span" animation="border" size="sm" />}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <ComboButtonGroup buttons={comboButtons} selectedCombo={selectedCombo} setSelectedCombo={setSelectedCombo} borderRadius="normal" />
                    <div className="horizontal-line my-3"></div>

                    <Form noValidate validated={validated} onSubmit={submit}>


                        <div style={{
                            display:
                                selectedCombo === 'general'
                                    ? 'block'
                                    : 'none',
                        }}>
                            <Row>
                                <Col lg={6}>
                                    <SelectGroup id="salutation" label={getFieldLabel(fieldConfigs, 'contact.salutation')} options={getFieldOptions(fieldConfigs, 'contact.salutation')} value={formValues.salutation} onChange={handleInputChange} />
                                    <FormGroup required id="firstname" label={getFieldLabel(fieldConfigs, 'contact.firstname')} value={formValues.firstname} onChange={handleInputChange} />
                                    <FormGroup id="middleName" label={getFieldLabel(fieldConfigs, 'contact.middleName')} value={formValues.middleName} onChange={handleInputChange} />
                                    <FormGroup required id="lastname" label={getFieldLabel(fieldConfigs, 'contact.lastname')} value={formValues.lastname} onChange={handleInputChange} />
                                </Col>

                                {/* <Col lg={6}>
                                    <SelectGroup id="role" label={getFieldLabel(fieldConfigs, 'companions.role')} options={getFieldOptions(fieldConfigs, 'companions.role')} value={formValues.role} onChange={handleInputChange} />
                                </Col> */}
                            </Row>
                        </div>

                        <div style={{
                            display:
                                selectedCombo === 'contact'
                                    ? 'block'
                                    : 'none',
                        }}>

                            <Row>
                                <Col lg={6}>
                                    <FormGroup required type="email" id="email" label={getFieldLabel(fieldConfigs, 'contact.email')} value={formValues.email} onChange={handleInputChange} />
                                    <FormGroup id="phoneNumber" label={getFieldLabel(fieldConfigs, 'contact.phoneNumber')} value={formValues.phoneNumber} onChange={handleInputChange} />
                                    <FormGroup id="mobileNumber" label={getFieldLabel(fieldConfigs, 'contact.mobileNumber')} value={formValues.mobileNumber} onChange={handleInputChange} />
                                </Col>
                            </Row>
                        </div>


                        <div style={{
                            display:
                                selectedCombo === 'image'
                                    ? 'block'
                                    : 'none',
                        }}>
                            <Row>
                                <Col lg={6}>
                                    <Button disabled={!companionId} className='btn btn-soft-primary mb-3' onClick={() => setShowMediaPicker(true)}>Profilbild {formValues.image ? 'ändern' : 'auswählen'}</Button>
                                    {formValues.image && (
                                        <SelectedMediaPreview
                                            selectedMediaForMultiSelect={[formValues.image]}
                                            handleDeleteMedia={handleProfileImageDelete}
                                            colProps={12}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                        <Button className='d-none' type="submit" ref={hiddenSubmitButtonRef} />
                    </Form>
                </Modal.Body>
            </Modal>

            {showMediaPicker && <MediaPickerModal
                initialFolderId={companion?.contact.folder_id ?? 0}
                allowedMimeTypesSelection={['image/png', 'image/jpeg', 'inode/directory']}
                uploadAllowed
                onSelectMedia={handleProfileImageSelect}
                onModalClose={() => setShowMediaPicker(false)} />}
        </>
    );
};

export default AddEditCompanionModal;
