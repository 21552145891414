import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export type ComboButtonId = string;

export interface ComboButton {
  id: ComboButtonId;
  label: string;
  icon?: IconDefinition;
  disabled?: boolean;
  disabledText?: string;
  error?: boolean;
  type?: string;
}

interface ComboButtonGroupProps {
  buttons: ComboButton[];
  selectedCombo: ComboButtonId | '';
  setSelectedCombo: (value: ComboButtonId | '') => void;
  borderRadius?: 'pill' | 'normal';
}

/**
 * ComboButtonGroup Component
 * 
 * A customizable button group that allows users to select one option from multiple choices.
 * Similar to a radio button group but styled as buttons.
 * 
 * Features:
 * - Selectable buttons with visual active state
 * - Optional icons for each button
 * - Disabled state with tooltip explanation
 * - Different styling options based on button type (success, abort, warning)
 * - Customizable border radius (pill or normal)
 * 
 */

const ComboButtonGroup: React.FC<ComboButtonGroupProps> = ({
  buttons,
  selectedCombo,
  setSelectedCombo,
  borderRadius = 'pill',
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>
  ) => {
    if ('target' in event && event.target instanceof HTMLInputElement) {
      setSelectedCombo(event.target.id);
    }
  };

  // Get the class for the button based on the id and type
  const getButtonClass = (id: ComboButtonId, type?: string) => {
    let classes = 'combo-button btn btn-sm';
    if (borderRadius === 'pill') {
      classes += ' rounded-pill';
    } else {
      classes += ' rounded';
    }
    if (selectedCombo === id) {
      if (type === 'success') {
        classes += ' custom-success-badge';
      } else if (type === 'abort') {
        classes += ' custom-danger-badge';
      } else {
        classes += ' btn-soft-primary';
      }
    }

    return classes;
  };
 
  // Render the button with the correct class and style
  const renderButton = ({ id, label, icon, disabled, disabledText, error, type }: ComboButton) => {
    const button = (
      <div key={id}>
        <input
          type="radio"
          className="d-none"
          name="btnradio"
          id={id}
          autoComplete="off"
          onChange={handleChange}
          onClick={handleChange}
          disabled={disabled}
        />
        <label
          style={{ fontSize: '12px' }}
          className={`${getButtonClass(id, type)} me-2 btn-sm ${borderRadius === 'pill' ? 'rounded-pill' : 'rounded'
            } ${error && 'text-danger'} ${type === 'success' && 'text-success'} ${type === 'abort' && 'text-danger'} ${type === 'warning' && 'text-primary'}`}
          htmlFor={id}
        >
          {icon && <FontAwesomeIcon icon={icon} className='me-2'></FontAwesomeIcon>}
          {label}
        </label>
      </div>
    );

    // If the button is disabled, show a tooltip with the disabled text
    if (disabled && disabledText) {
      return (
        <OverlayTrigger
          key={id}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-disabled-${id}`}>{disabledText}</Tooltip>
          }
        >
          <span style={{ cursor: 'not-allowed' }}>{button}</span>
        </OverlayTrigger>
      );
    }

    return button;
  };

  return <div className="scrollable-container">{buttons.map((button) => renderButton(button))}</div>;
};

export default ComboButtonGroup;
