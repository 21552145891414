import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../../utils/enum';

import { ApiClient } from 'c1g-ui-library';
import { AbsenceReport } from '../../../interfaces';
import { usePermissions } from 'c1g-ui-library';
import AbsenceReportDetails from '../../../components/deals/absence-reports/detail/AbsenceReportDetails';
import FullHeader from '../../../components/ui/header/FullHeader';
import { useDocumentTitle } from 'c1g-ui-library';
import { Permissions } from '../../../interfaces';
import { AxiosError } from 'axios';

function AbsenceReportDetail() {
  const { absenceReportId } = useParams();
  const [absenceReport, setAbsenceReport] = useState<AbsenceReport | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();
  const updateDocumentTitle = useDocumentTitle({ appName: 'ClientOffice', moduleTranslations: moduleTranslations });

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewActivities, 'read');

      if (hasPermission) {
        absenceReportId && fetchAbsenceReport(absenceReportId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [absenceReportId, permissionsLoaded]);


  const fetchAbsenceReport = async (absenceReportId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get('/absencesreports?id=' + absenceReportId);
      const settingResponse = response.data.list[0];
      setAbsenceReport(settingResponse);
      updateDocumentTitle(settingResponse.title);
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FullHeader>
        <h3>{absenceReport?.title}</h3>
      </FullHeader>
      <div className='container-fluid p-40'>
        <Card className="detail-card card-block card-stretch card-height">
          <Card.Body>
            <AbsenceReportDetails absenceReport={absenceReport} isLoading={isLoading}></AbsenceReportDetails>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default AbsenceReportDetail;
