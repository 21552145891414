import React, { useState } from 'react';
import { Companion, } from '../../../interfaces';
import Card from '../../bootstrap/card';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import ParticipantInitialsComponent from '../../ParticipantInitialsComponent';
import { getInitials } from '../../../utils/utils';
import Skeleton from 'react-loading-skeleton';
import ArchiveModal from '../../ArchiveModal';
import { FieldConfig, usePermissions } from 'c1g-ui-library';
import { getEnumValue, PermissionsEnum } from '../../../utils/enum';
import AddEditCompanionModal from '../modal/AddEditCompanionModal';
import PortalWrapper from '../../PortalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { getMediaUrl } from '../../../utils/media/utils';
import { Permissions } from '../../../interfaces';

interface CompanionDetailsHeaderProps {
    isLoading?: boolean;
    companion?: Companion
    onSubmitSuccess: (message?: string) => void;
    fieldConfigs: FieldConfig[]
}

/**
 * CompanionDetailsHeader Component
 *
 * This component displays the details of a companion, including their name, role, and profile image.
 * It also provides options to edit or archive the companion, depending on their status and user permissions.
 *
 * Features:
 * - Displays companion information with a loading skeleton when data is loading.
 * - Shows an "(Archiviert)" label if the companion is archived (status === 3).
 * - Allows editing the companion via the `AddEditCompanionModal`.
 * - Provides an option to archive the companion via the `ArchiveModal`, if not already archived.
 *
 * Props:
 * @param {boolean} isLoading - Indicates if data is still loading.
 * @param {Companion} companion - The companion object containing details.
 * @param {Function} onSubmitSuccess - Callback function to handle successful submission of changes.
 * @param {FieldConfig[]} fieldConfigs - Configuration array for fields.
 */
const CompanionDetailsHeader: React.FC<CompanionDetailsHeaderProps> = ({
    isLoading,
    companion,
    onSubmitSuccess,
    fieldConfigs
}) => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const { userHasPermissionByRight } = usePermissions<Permissions>();

    return (
        <>
            <Card>
                <Card.Body>
                    <Row>
                        <Col >
                            <div className="d-flex justify-content-between align-items-start align-items-lg-center flex-wrap mb-3">
                                <div className='d-flex justify-content-center align-items-center'>
                                    <ParticipantInitialsComponent
                                        mediaUrl={companion?.contact.image ? getMediaUrl(companion?.contact.image) : ''}
                                        smileyLevel={0}
                                        size={60}
                                        initials={getInitials(companion?.contact.title ?? '')}
                                    />
                                    <h4 className='mb-0 ms-3'>
                                        {isLoading ? (
                                            <Skeleton width={150} />
                                        ) : (
                                            <>
                                                {companion?.contact.title || '-'}
                                                {companion?.status === 3 && <span className="text-muted ms-2">(Archiviert)</span>}
                                            </>
                                        )}
                                    </h4>
                                </div>

                                <div className='d-flex mt-2 mt-lg-0'>
                                    {companion && companion.status !== 3 && (
                                        <Dropdown className="me-2 rounded">
                                            <Dropdown.Toggle
                                                variant="secondary"
                                                id="dropdown-basic"
                                                className="btn-soft-primary dropdown-no-arrow h-100 py-2 px-3"
                                            >
                                                <FontAwesomeIcon icon={faEllipsis} />
                                            </Dropdown.Toggle>

                                            <PortalWrapper>
                                                <Dropdown.Menu>
                                                    <ArchiveModal
                                                        modalTitle="Auswahl archivieren?"
                                                        buttonName="Archivieren"
                                                        entityIds={companion?.id}
                                                        entityType='companions'
                                                        onSubmitSuccess={onSubmitSuccess}
                                                        isDropdownItem={true}
                                                        isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewCompanions, 'write')}
                                                    />
                                                </Dropdown.Menu>
                                            </PortalWrapper>
                                        </Dropdown>
                                    )}
                                    <Button
                                        disabled={!userHasPermissionByRight(PermissionsEnum.ViewCompanions, 'write') || !companion || !fieldConfigs}
                                        variant="primary"
                                        onClick={() => setShowAddEditModal(true)}
                                    >
                                        Bearbeiten
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="d-flex flex-column flex-lg-row align-items-lg-center text-nowrap">
                                {/* <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 text-muted`}>
                                    <span className="me-2 text-muted">
                                        {getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'companions.role')?.options ?? {}, companion?.role?.toString())}
                                    </span>
                                </div> */}

                                {companion && <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 text-muted`}>
                                    <span className="me-2 text-muted">
                                        # {companion?.responsibilitiesCount} Deals zugewiesen
                                    </span>
                                </div>}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {showAddEditModal && companion && (
                <AddEditCompanionModal
                    companion={companion}
                    modalTitle="Kontaktperson bearbeiten"
                    onSubmitSuccess={onSubmitSuccess}
                    onModalClose={() => setShowAddEditModal(false)}
                    fieldConfigs={fieldConfigs}
                />
            )}
        </>
    );
};

export default CompanionDetailsHeader;