import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { ApiClient } from 'c1g-ui-library';
import { Satisfaction } from '../../interfaces';
import { usePermissions } from 'c1g-ui-library';
import SatisfactionDetails from '../../components/satisfactions/detail/SatisfactionDetails';
import { mockSatisfactions } from './satisfactions';
import { Permissions } from '../../interfaces';
import { AxiosError } from 'axios';

function SatisfactionDetail() {
  const { satisfactionId } = useParams();
  const [satisfaction, setSatisfaction] = useState<Satisfaction | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewSatisfactions, 'read');

      if (hasPermission) {
        satisfactionId && fetchSatisfaction(satisfactionId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [satisfactionId, permissionsLoaded]);

  const fetchSatisfaction = async (satisfactionId: string) => {
    setIsLoading(true);
    try {
      // const response = await ApiClient.get(`/satisfactions/${satisfactionId}`);
      // const settingResponse = response.data;
      setSatisfaction(mockSatisfactions[0]);
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setSatisfaction(null)
    satisfactionId && fetchSatisfaction(satisfactionId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <SatisfactionDetails satisfaction={satisfaction} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></SatisfactionDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default SatisfactionDetail;
