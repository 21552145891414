import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldConfig, MediaItem } from 'c1g-ui-library';
import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Deal } from '../../../interfaces';
import { getEnumValue } from '../../../utils/enum';
import { formatEuro, getFieldConfigByResourceName, getFieldLabel } from '../../../utils/utils';
import BGSViewerModal from '../../deals/BGSViewerModal';
import WvViewerModal from '../../deals/WvViewerModal';
import { bgsStatusColorMap, bgsStatusIconMap, dealStatusColorMap, dealStatusIconMap, wvStatusColorMap, wvStatusIconMap } from '../../filter/iconAndColorMappings';
import UploadMediaModal from '../../media/modal/UploadMediaModal';
import MediaViewerModal from '../../MediaViewerModal';
import StatusIndicator from '../../StatusIndicator';

interface CurrentDealInfosProps {
    deal?: Deal;
    fieldConfigs: FieldConfig[];
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
}

/**
 * CurrentDealInfos Component
 *
 * This component displays detailed information about a current deal, including:
 * - Deal overview with title, status, equipment (e.g., macbook), and price details.
 * - Document-related information, including the Bildungsgutschein and Weiterbildungsvertrag.
 * 
 * The component uses multiple helper functions and mappings for labels, status icons, and colors.
 * 
 * Props:
 * - deal (Deal): An optional deal object containing various properties such as title, status, price, etc.
 * - fieldConfigs (FieldConfig[]): Array of field configuration objects used to dynamically fetch labels and options.
 * - onSubmitSuccess: Callback function triggered after a successful or failed API request. It accepts a message string and an optional error flag.
 */
const CurrentDealInfos: React.FC<CurrentDealInfosProps> = ({
    deal,
    fieldConfigs,
    onSubmitSuccess,
}) => {
    const [showBgsViewerModal, setShowBgsViewerModal] = useState<boolean>(false);
    const [showWvViewerModal, setShowWvViewerModal] = useState<boolean>(false);
    const [showMediaViewer, setShowMediaViewer] = useState<boolean>(false);
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [mediaToPreview, setMediaToPreview] = useState<MediaItem[]>([]);
    const [isUploadOfferModalOpen, setIsUploadOfferModalOpen] = useState(false);

    const handleShowMediaViewer = (media: MediaItem[], index: number = 0) => {
        setMediaToPreview(media);
        setCurrentMediaIndex(index);
        setShowMediaViewer(true);
    };
    if (!deal) return null;

    return (
        <Row className="g-3">
            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body>
                        <Card.Title className="mb-4">Deal Übersicht</Card.Title>
                        <Row className="mb-3">
                            <Col xs={4}><span>Titel:</span></Col>
                            <Col xs={8}><span className="text-black">{deal.offerTitle}</span></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}><span>Status:</span></Col>
                            <Col xs={8}>
                                <span className={`${dealStatusColorMap[deal.status]}`}>
                                    <FontAwesomeIcon icon={dealStatusIconMap[deal.status]} className="me-1" />
                                    {getEnumValue(
                                        getFieldConfigByResourceName(fieldConfigs, 'lastDeal.status')?.options ?? {},
                                        deal.status?.toString()
                                    )}
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.macbook')}</span></Col>
                            <Col xs={8}><span className="text-black">{getEnumValue(
                                getFieldConfigByResourceName(fieldConfigs, 'lastDeal.macbook')?.options ?? {},
                                deal.macbook?.toString()
                            )}</span></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}><span>Preis:</span></Col>
                            <Col xs={8}>
                                <span className="text-black">
                                    {formatEuro(deal.priceTotalGross)} (Brutto) <br /> {formatEuro(deal.priceTotalNet)} (Netto)
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.besidesTheJob')}</span></Col>
                            <Col xs={8}><span className="text-black">{getEnumValue(
                                getFieldConfigByResourceName(fieldConfigs, 'lastDeal.besidesTheJob')?.options ?? {},
                                deal.besidesTheJob?.toString()
                            )}</span></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}><span>{getFieldLabel(fieldConfigs, 'lastDeal.businessSegment')}</span></Col>
                            <Col xs={8}><span className="text-black">{getEnumValue(
                                getFieldConfigByResourceName(fieldConfigs, 'lastDeal.businessSegment')?.options ?? {},
                                deal.businessSegment?.toString()
                            )}</span></Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={6}>
                <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                    <Card.Body className='pe-1'>
                        <Card.Title className="mb-4">Dokumente</Card.Title>
                        <Row className="mb-3 cursor-pointer" onClick={() => setShowBgsViewerModal(true)}>
                            <Col xs={10}>
                                <span className="text-black">Bildungsgutschein</span> <br />
                                <span className={`${bgsStatusColorMap[deal.bgsStatus]}`}>
                                    <FontAwesomeIcon icon={bgsStatusIconMap[deal.bgsStatus]} className="me-2" />
                                    {getEnumValue(
                                        getFieldConfigByResourceName(fieldConfigs, 'lastDeal.bgsStatus')?.options ?? {},
                                        deal.bgsStatus?.toString()
                                    )}
                                    {deal.bgsnumber && <span className="text-muted"> · {deal.bgsnumber}</span>}
                                </span>
                            </Col>
                            <Col xs={2} className="d-flex justify-content-center align-items-center">
                                <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                            </Col>
                        </Row>

                        <Row className="mb-3 cursor-pointer" onClick={() => setShowWvViewerModal(true)}>
                            <Col xs={10}>
                                <span className="text-black">Weiterbildungsvertrag</span> <br />
                                <span className={`${wvStatusColorMap[deal.wvStatus]}`}>
                                    <FontAwesomeIcon icon={wvStatusIconMap[deal.wvStatus]} className="me-2" />
                                    {getEnumValue(
                                        getFieldConfigByResourceName(fieldConfigs, 'lastDeal.wvStatus')?.options ?? {},
                                        deal.wvStatus?.toString()
                                    )}
                                    {deal.wvnumber && <span className="text-muted"> · {deal.wvnumber}</span>}
                                </span>
                            </Col>
                            <Col xs={2} className="d-flex justify-content-center align-items-center">
                                <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                            </Col>
                        </Row>

                        <Row className="mb-3 cursor-pointer" onClick={() => deal.offer ? handleShowMediaViewer([deal.offer], 0) : setIsUploadOfferModalOpen(true)}>
                            <Col xs={10}>
                                <span className="text-black">Angebot</span> <br />
                                <span>
                                    <StatusIndicator
                                        isActive={!!deal.offer}
                                        activeText="Vorhanden"
                                        inactiveText="Nicht vorhanden"
                                    />
                                    <span className="text-muted"> · {deal.offerNumber}</span>
                                </span>
                            </Col>
                            <Col xs={2} className="d-flex justify-content-center align-items-center" >
                                <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>

            {showBgsViewerModal && (
                <BGSViewerModal
                    handleClose={() => {
                        setShowBgsViewerModal(false);
                        onSubmitSuccess();
                    }}
                    title="Bildungsgutschein"
                    fieldConfigs={fieldConfigs}
                    dealId={deal.id}
                    dealType='lastDeal'
                />
            )}

            {showWvViewerModal && <WvViewerModal
                handleClose={() => {
                    setShowWvViewerModal(false);
                    onSubmitSuccess();
                }}
                title="Weiterbildungsvertrag"
                fieldConfigs={fieldConfigs}
                dealId={deal.id}
                dealType='lastDeal'
            />}

            {showMediaViewer && (
                <MediaViewerModal
                    show={showMediaViewer}
                    handleClose={() => setShowMediaViewer(false)}
                    media={mediaToPreview}
                    currentMediaIndex={currentMediaIndex}
                    setCurrentMediaIndex={setCurrentMediaIndex}
                    title="Dokument Vorschau"
                />
            )}

            <UploadMediaModal
                modalTitle="Angebot hochladen"
                onSubmitSuccess={onSubmitSuccess}
                onUploadMediaModalChange={setIsUploadOfferModalOpen}
                isModalOpen={isUploadOfferModalOpen}
                customUploadEndpoint={'/deals/uploadOffer'}
                accept={{
                    'application/pdf': ['.pdf'],
                }}
                singleFile
                dealId={deal.id}
            />
        </Row>
    );
};

export default CurrentDealInfos;
