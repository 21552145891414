import { Link, useNavigate, useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import SidebarMenu, { SubmenuItem } from '../../sidebar/sidebar-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshake,
  faUser,
  faUserGraduate,
  faBoxesStacked,
  faWebhook,
  faUsers,
  faShieldKeyhole,
  faImage,
  faHouse,
  faRightFromBracket,
  faGear,
  faWavePulse,
  faClipboardUser,
  faCalendarXmark,
  faUsersViewfinder,
} from '@fortawesome/pro-solid-svg-icons';

import { faFaceSmile } from '@fortawesome/pro-light-svg-icons';
import { PermissionsEnum } from '../../../utils/enum';
import { usePermissions } from 'c1g-ui-library';
import { Permissions } from '../../../interfaces';

/**
 * VerticalNav component for rendering the sidebar menu structure.
 *
 * - Dynamically displays different sections (e.g., Dashboard, Module, Administration)
 *   based on the logged-in user's permissions.
 * - Uses `SidebarMenu` component to render each menu item.
 * - Icons are shown using FontAwesome.
 * - Navigates using `react-router-dom` and handles logout.
 *
 * Sections:
 * - Dashboard (always visible)
 * - Module section (Participants, Deals, Activities, etc.) if user has relevant permissions
 * - Administration section (Users, Roles, API Keys, etc.) if user has admin rights
 * - Logout option at the bottom
 *
 * This component is designed to be used inside the Sidebar layout.
 */
const VerticalNav: React.FC = () => {
  const { userHasPermissionByRight } = usePermissions<Permissions>();
  const { companyId = 'oc' } = useParams<{ companyId?: string }>();
  const navigate = useNavigate();

  const handleLogout = async (): Promise<void> => {
    const companyId = sessionStorage.getItem('companyId') || 'oc';
    localStorage.removeItem(`accessToken_${companyId}`);
    localStorage.removeItem(`lastPath_${companyId}`);
    navigate(`/${companyId}/login`);
  };

  const hasModulePermissions = (): boolean => {
    return (
      userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewDeals, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewEducations, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewSatisfactions, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewMedia, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewActivities, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewPresences, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewAbsences, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewCompanions, 'read')
    );
  };

  const hasAdministrationPermissions = (): boolean => {
    return (
      userHasPermissionByRight(PermissionsEnum.ViewUsers, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewRoles, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewWebhooks, 'read') ||
      userHasPermissionByRight(PermissionsEnum.ViewSettings, 'read')
    );
  };

  // deals submenu items
  const dealsSubmenuItems: SubmenuItem[] = [
    {
      title: 'Fehlzeitenberichte',
      pathname: `/${companyId}/deals/absence-reports`,
    }
  ];


  return (
    <>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <SidebarMenu isTag="true" pathname={`/${companyId}/`} title="Dashboard">
          <div>
            <FontAwesomeIcon width={15} icon={faHouse} />
          </div>
        </SidebarMenu>
      </Accordion>

      {hasModulePermissions() && (
        <Accordion as="ul" className="navbar-nav iq-main-menu">
          <div className="d-flex flex-column justify-content-center gap-2">
            <li className="nav-item static-item ps-0">
              <Link
                className="nav-link static-item disabled"
                to="#"
                tabIndex={-1}
              >
                <span className="default-icon ps-1">MODULE</span>
                <div className="mini-icon" style={{ paddingLeft: '10px' }}>
                  -
                </div>
              </Link>
            </li>

            {userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'read') && (
              <SidebarMenu 
                isTag="true" 
                pathname={`/${companyId}/participants`} 
                title="Teilnehmer"
              >
                <div><FontAwesomeIcon width={15} icon={faUserGraduate} /></div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(PermissionsEnum.ViewDeals, 'read') && (
              <SidebarMenu 
                isTag="true" 
                pathname={`/${companyId}/deals`} 
                title="Deals"
                hasSubmenu={true}
                submenuItems={dealsSubmenuItems}
              >
                <div><FontAwesomeIcon width={15} icon={faHandshake} /></div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(PermissionsEnum.ViewEducations, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/educations`} title="Kursverwaltung">
                <div><FontAwesomeIcon width={15} icon={faBoxesStacked} /></div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(PermissionsEnum.ViewSatisfactions, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/satisfactions`} title="Zufriedenheiten">
                <div><FontAwesomeIcon width={15} icon={faFaceSmile} /></div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(PermissionsEnum.ViewActivities, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/activities`} title="Aktivitäten">
                <div><FontAwesomeIcon width={15} icon={faWavePulse} /></div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(PermissionsEnum.ViewPresences, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/presences`} title="Anwesenheiten">
                <div><FontAwesomeIcon width={15} icon={faClipboardUser} /></div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(PermissionsEnum.ViewAbsences, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/absences`} title="Abwesenheiten">
                <div><FontAwesomeIcon width={15} icon={faCalendarXmark} /></div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(PermissionsEnum.ViewCompanions, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/companions`} title="Kontaktpersonen">
                <div><FontAwesomeIcon width={15} icon={faUsersViewfinder} /></div>
              </SidebarMenu>
            )}

            {userHasPermissionByRight(PermissionsEnum.ViewMedia, 'read') && (
              <SidebarMenu isTag="true" pathname={`/${companyId}/media/0`} title="Medien">
                <div><FontAwesomeIcon width={15} icon={faImage} /></div>
              </SidebarMenu>
            )}
          </div>
        </Accordion>
      )}

      {hasAdministrationPermissions() && (
        <Accordion as="ul" className="navbar-nav iq-main-menu">
          <li className="nav-item static-item ps-0">
            <Link className="nav-link static-item disabled" to="#" tabIndex={-1}>
              <span className="default-icon ps-1">ADMINISTRATION</span>
              <div className="mini-icon" style={{ paddingLeft: '10px' }}>-</div>
            </Link>
          </li>

          {userHasPermissionByRight(PermissionsEnum.ViewUsers, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/users`} title="Nutzerverwaltung">
              <div><FontAwesomeIcon width={15} icon={faUser} /></div>
            </SidebarMenu>
          )}

          {userHasPermissionByRight(PermissionsEnum.ViewRoles, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/roles`} title="Rollenverwaltung">
              <div><FontAwesomeIcon width={15} icon={faUsers} /></div>
            </SidebarMenu>
          )}

          {userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/apikeys`} title="API-Schlüssel">
              <div><FontAwesomeIcon width={15} icon={faShieldKeyhole} /></div>
            </SidebarMenu>
          )}

          {userHasPermissionByRight(PermissionsEnum.ViewWebhooks, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/webhooks`} title="Webhooks">
              <div><FontAwesomeIcon width={15} icon={faWebhook} /></div>
            </SidebarMenu>
          )}

          {userHasPermissionByRight(PermissionsEnum.ViewSettings, 'read') && (
            <SidebarMenu isTag="true" pathname={`/${companyId}/settings`} title="Einstellungen">
              <div><FontAwesomeIcon width={15} icon={faGear} /></div>
            </SidebarMenu>
          )}
        </Accordion>
      )}

      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <SidebarMenu
          pathname={`/${companyId}/login`}
          isTag="true"
          title="Ausloggen"
          onClick={handleLogout}
        >
          <div><FontAwesomeIcon width={15} icon={faRightFromBracket} /></div>
        </SidebarMenu>
      </Accordion>
    </>
  );
};

VerticalNav.displayName = 'VerticalNav';
export default VerticalNav;
