import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { Activity, ApiClient, FieldConfig, usePermissions, User } from 'c1g-ui-library';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { Permissions } from '../../../interfaces';
import { getEnumValue } from '../../../utils/enum';
import { formatDate, formatDuration, getClusterConfigWithCounts, getFieldConfigByResourceName, getInitials, groupActivitiesByMonthAndYear } from '../../../utils/utils';
import ComboButtonGroup, { ComboButton } from '../../ComboButtonGroup';
import DateRangeDropdownFilter from '../../filter/DateRangeDropdownFilter';
import GenericDropdownFilter from '../../filter/GenericDropdownFilter';
import { activityTypesIconMap } from '../../filter/iconAndColorMappings';
import MultiSelectDropdownFilter from '../../filter/MultiSelectDropdownFilter';
import MediaDetails from '../../MediaDetails';
import ParticipantInitialsComponent from '../../ParticipantInitialsComponent';
import ResetFiltersButton from '../../ResetFilterButton';
import SearchInput from '../../SearchInput';
import DynamicPagination from '../../table/DynamicPagination';
import PaginationInfo from '../../table/PaginationInfo';
import AddCommentModal from '../modal/AddCommentModal';
import EditActivityModal from '../modal/EditActivityModal';
import ActionButtons from './ActionButtons';
import { ActivityCounts } from './ActivityBadgeList';

interface ActivitiesFilters {
    types: number[] | null
    pinned: boolean | null
    person: string | null
    startDate: string | null
    endDate: string | null
}

interface ActivitiesResponse {
    page: number;
    itemsPerPage: number;
    amountPages: number;
    amountAllItems: number;
    searchFilters: string[];
    list: Activity[];
    activityCounts: ActivityCounts[]
}

interface ActivitiesListProps {
    type?: number;
    elementId: number;
    elementClass: string;
    isPinnedSection?: boolean;
    onActivitiesUpdate: () => void;
    refreshKey?: number;
    fieldConfigs: FieldConfig[]
    selectedActivityTypeFilter?: number | null;
}

/**
 * ActivitiesList Component
 * 
 * This component displays a paginated, filterable, and searchable list of activities
 * associated with a specific element (e.g., a participant). It allows users to:
 * - Apply various filters, including activity types, pinned status, date range, and person.
 * - Search for specific activities by text query.
 * - View activities grouped by month and year.
 * - Interact with activities through actions like pinning, archiving, commenting, or deleting.
 * - Automatically respond to an external `selectedActivityFilter` prop to adjust filters dynamically.
 * 
 * Props:
 * - type: Optional filter to predefine an activity type.
 * - elementId: ID of the element the activities are associated with.
 * - elementClass: The class name of the element model (e.g., participant model).
 * - isPinnedSection: If true, only pinned activities are displayed.
 * - onActivitiesUpdate: Callback function triggered when activities are updated.
 * - refreshKey: Key to trigger a refresh of activities.
 * - fieldConfigs: Configurations for fields used in the component.
 * - selectedActivityFilter: An external filter for selecting specific activity types.
 */
const ActivitiesList: React.FC<ActivitiesListProps> = ({ type, elementId, elementClass, isPinnedSection = false, onActivitiesUpdate, refreshKey, fieldConfigs, selectedActivityTypeFilter }) => {
    const [activities, setActivities] = useState<Activity[]>([]);
    const [selectedTab, setSelectedTab] = useState('active');
    const [searchQuery, setSearchQuery] = useState('');
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalEntries, setTotalEntries] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
    const [userIdToTitleMap, setUserIdToTitleMap] = useState<{ [key: number]: string }>({});
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [showEditActivityModal, setShowEditActivityModal] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
    const [activityCounts, setActivityCounts] = useState<ActivityCounts[]>([]);
    const { userId } = usePermissions<Permissions>();

    const [selectedFilters, setSelectedFilters] = useState<ActivitiesFilters>({
        types: null,
        pinned: null,
        startDate: null,
        endDate: null,
        person: null
    });

    const mainTabs: ComboButton[] = useMemo(() => [
        { id: 'active', label: 'Aktiv' },
        { id: 'archived', label: 'Archiviert' },
    ], []);

    const fetchActivities = async () => {
        setIsLoading(true);
        let queryParams = `?elementId=${elementId}&elementClass=${elementClass}&page=${currentPage}&limit=${limit}`;

        queryParams += '&sort[field]=timeOfActivity'
        queryParams += `&sort[type]=desc`;

        if (searchQuery) {
            queryParams += `&search=${encodeURIComponent(searchQuery)}&column=activities.info`;
        }

        if (selectedFilters.types?.length) {
            const types = selectedFilters.types.map(type => `type[]=${type}`).join('&');
            queryParams += `&${types}`;
        }

        if (selectedFilters.startDate?.toString()) {
            queryParams += `&filter_period[start]=${selectedFilters.startDate?.toString()}`;
        }

        if (selectedFilters.endDate?.toString()) {
            queryParams += `&filter_period[end]=${selectedFilters.endDate?.toString()}`;
        }

        if (selectedFilters.pinned || isPinnedSection) {
            queryParams += `&pinned=true`;
        }

        if (selectedFilters.person?.toString()) {
            queryParams += `&user_id=${selectedFilters.person?.toString()}`;
        }

        if (selectedTab === 'archived') {
            queryParams += `&archived=true`;
        } else {
            queryParams += `&archived=false`;
        }

        try {
            const response = await ApiClient.get(`/activities${queryParams}`);
            const activityResponse = response.data as ActivitiesResponse;
            setActivities(activityResponse.list ?? []);
            setTotalEntries(activityResponse.amountAllItems);
            setTotalPages(activityResponse.amountPages);
            setCurrentPage(activityResponse.page);
            setLimit(activityResponse.itemsPerPage);
            setActivityCounts(activityResponse.activityCounts)
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUserMap = async () => {
        const userResponse = await ApiClient.get(`/activities/users?elementId=${elementId}&elementClass=${elementClass}`);
        const users = userResponse.data.list as User[];
        const map = users.reduce<{ [key: number]: string }>((acc, user) => {
            acc[user.id] = user.title;
            return acc;
        }, {});
        setUserIdToTitleMap(map);
    };

    const handleSearch = (data: { query: string, filter: string | undefined }) => {
        if (data.query) {
            setSearchQuery(data.query);
            setCurrentPage(1);
        } else if (searchQuery) {
            resetSearch();
        }
    };

    const resetSearch = () => {
        setSearchQuery('');
        setCurrentPage(1);
    };

    const handleActionClick = async (activity: Activity, action: string, isCurrentlyPinned: boolean, isCurrentlyArchived: boolean) => {
        try {
            if (action === 'comment') {
                setSelectedActivity(activity);
                setShowCommentModal(true);
                return;
            } else if (action === 'editActivity') {
                setSelectedActivity(activity);
                setShowEditActivityModal(true);
                return
            }
            else if (action === 'pin') {
                await ApiClient.put(`/activities/${activity.id}`, { pinned: !isCurrentlyPinned });
            } else if (action === 'archive') {
                await ApiClient.put(`/activities/${activity.id}`, { archived: !isCurrentlyArchived });
            } else if (action === 'delete') {
                await ApiClient.delete(`/activities/${activity.id}`);
            }
            onActivitiesUpdate();
        } catch (error: any) {
            console.error(`Failed to ${action} activity:`, error.message as AxiosError);
        }
    };

    const groupedActivities = groupActivitiesByMonthAndYear(activities);

    useEffect(() => {
        if (resetSearchInput) {
            setResetSearchInput(false);
        }
    }, [resetSearchInput]);

    useEffect(() => {
        if (selectedActivityTypeFilter !== null && selectedActivityTypeFilter !== undefined) {
            setSelectedFilters((filters) => ({
                ...filters,
                types: [selectedActivityTypeFilter],
            }));
            setCurrentPage(1);
        }
    }, [selectedActivityTypeFilter]);

    useEffect(() => {
        fetchActivities();
        if (!Object.keys(userIdToTitleMap).length) {
            fetchUserMap();
        }
    }, [type, currentPage, limit, searchQuery, selectedTab, selectedFilters, refreshKey]);


    const renderActivityInfo = (activity: Activity) => {
        if (!activity) return null;

        const details: JSX.Element[] = [
            <span key="time">{formatDate(activity.timeOfActivity, 'd.m.Y, H:i Uhr')}</span>
        ];

        const typeConfig: Record<number, (details: any) => JSX.Element[]> = {
            // Intercom
            12: ({ link = '', rating = '', tags = [] }) => [
                rating && <span key="rating">Rating: {rating}/5</span>,
                ...tags.map((tag: string, index: number) => <span key={`tag-intercom-${index}`}>{tag}</span>),
                link && (
                    <a key="link-intercom" className="no-style-link" rel="noreferrer" target="_blank" href={String(link)}>
                        <span className="cursor-pointer">Von Intercom <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></span>
                    </a>
                ),
            ],
            // AirCall
            2: ({ duration = 0, direction = '', link = '', tags = [] }) => [
                duration && <span key="duration">Gesprächsdauer: {formatDuration(duration)}</span>,
                direction && <span key="direction">{direction === 'outbound' ? 'Ausgehend' : 'Eingehend'}</span>,
                ...tags.map((tag: string, index: number) => <span key={`tag-aircall-${index}`}>{tag}</span>),
                link && (
                    <a key="link-aircall" className="no-style-link" rel="noreferrer" target="_blank" href={String(link)}>
                        <span className="cursor-pointer">Von AirCall <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></span>
                    </a>
                ),
            ],
            7: ({ amount = 0 }) => [
                amount && <span key="amount">{amount} {amount > 1 ? 'Aktivitäten' : 'Aktivität'}</span>,
            ],
        };

        const safeDetails = activity.details ?? {};

        const getDetails = typeConfig[activity.type] ?? (() => []);
        details.push(...getDetails(safeDetails).filter(Boolean));

        return <span className="text-gray">{details.map((item, index) => (index > 0 ? [' · ', item] : item))}</span>;
    };

    return (
        <div>
            <>
                {!isPinnedSection && (
                    <Row className="d-flex justify-content-between mb-4">
                        <Col md={6}>
                            {searchQuery && (
                                <div className="d-flex align-items-baseline mb-3">
                                    <h4 className="m-0">Suchergebnisse</h4>
                                    <span className="ms-3 d-flex align-items-baseline">
                                        <Button
                                            className="btn-ghost m-0 p-0 fs-6"
                                            variant="link"
                                            onClick={resetSearch}
                                        >
                                            Suche beenden
                                        </Button>
                                    </span>
                                </div>
                            )}

                            <ComboButtonGroup
                                buttons={mainTabs}
                                selectedCombo={selectedTab}
                                setSelectedCombo={(tab) => {
                                    setSelectedTab(tab as string);
                                    setCurrentPage(1);
                                }}
                                borderRadius="normal"
                            />
                        </Col>
                        <Col md={3}>
                            <SearchInput onSearch={handleSearch} reset={resetSearchInput} />
                        </Col>
                    </Row>
                )}

                {/* Main Content */}
                {!isPinnedSection && (
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-start align-items-center mb-3">
                                <div className="d-flex custom-scrollbar-x horizontal-scroll">
                                    <MultiSelectDropdownFilter
                                        clusterConfig={getClusterConfigWithCounts(activityCounts)}
                                        selectedFilters={selectedFilters.types}
                                        handleFilterChange={(types) => {
                                            setSelectedFilters((filters) => ({ ...filters, types: types.length ? types : null }));
                                            setCurrentPage(1);
                                        }}
                                        iconMap={activityTypesIconMap}
                                        filterEnum={getFieldConfigByResourceName(fieldConfigs, 'activities.type')?.options ?? {}}
                                        titlePlaceholder="Aktivitätstyp"
                                        isDisabled={false}
                                        fieldConfigs={fieldConfigs}
                                    />

                                    <GenericDropdownFilter
                                        selectedFilter={selectedFilters.pinned === true ? 1 : selectedFilters.pinned === false ? 0 : null}
                                        handleFilterChange={(pinned) => {
                                            setSelectedFilters((filters) => ({
                                                ...filters,
                                                pinned: pinned?.toString() === '1' ? true : pinned?.toString() === '0' ? false : null
                                            }));
                                            setCurrentPage(1);
                                        }}
                                        filterEnum={{ 0: 'Nein', 1: 'Ja' }}
                                        titlePlaceholder="Angepinnt"
                                    />
                                    <GenericDropdownFilter
                                        selectedFilter={selectedFilters.person ?? null}
                                        handleFilterChange={(person) => {
                                            setSelectedFilters((filters) => ({ ...filters, person }));
                                            setCurrentPage(1);
                                        }}
                                        filterEnum={userIdToTitleMap}
                                        isDisabled={Object.keys(userIdToTitleMap).length === 0}
                                        titlePlaceholder="Person"
                                    />
                                    <DateRangeDropdownFilter
                                        onDateRangeChange={(startDate: string | null, endDate: string | null) => {
                                            setSelectedFilters((filters) => ({
                                                ...filters,
                                                startDate: startDate ? startDate : null,
                                                endDate: endDate ? endDate : null
                                            }));
                                            setCurrentPage(1);
                                        }}
                                        initialStartDate={selectedFilters.startDate ? new Date(selectedFilters.startDate) : null}
                                        initialEndDate={selectedFilters.endDate ? new Date(selectedFilters.endDate) : null}
                                        isDisabled={false}
                                        titlePlaceholder="Zeitraum"
                                    />

                                    {!type?.toString() && (
                                        <div className="sticky-right-reset-filter">
                                            <ResetFiltersButton filters={selectedFilters} setFilters={(newFilters: ActivitiesFilters) => {
                                                setSelectedFilters(newFilters);
                                                setCurrentPage(1);
                                            }} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                        <Spinner animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <div>
                        {Object.keys(groupedActivities).map((monthYear) => (
                            <div key={monthYear} className="mb-4">
                                <h6 className="d-flex align-items-center text-muted mb-3">
                                    <span className="text-nowrap">{monthYear}</span>
                                    <div className="flex-grow-1 ms-2 horizontal-line"></div>
                                </h6>
                                {groupedActivities[monthYear].map((activity, index) => (
                                    <div key={activity.id} className="d-flex mb-4">
                                        <div className='me-3'>
                                            {activity.type === 4 ?
                                                <ParticipantInitialsComponent smileyLevel={Number(activity?.details?.smiley) ?? 0} type='smiley' size={40} initials={getInitials(activity.user?.title)} /> :
                                                <ParticipantInitialsComponent activityType={activity.type} type='activity' size={40} initials={getInitials(activity.user?.title ?? activity.details?.user_name)} />}
                                        </div>
                                        <div className="flex-grow-1">
                                            <div className="d-flex flex-column mb-3 text-black">
                                                <div className='d-flex justify-content-between'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='mb-1'>{activity.user?.title || activity.details?.user_name || 'Unbekannt'} · {getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'activities.type')?.options ?? {}, activity.type.toString())}</span>
                                                        {renderActivityInfo(activity)}
                                                    </div>
                                                    <div>
                                                        <ActionButtons
                                                            activityId={activity.id}
                                                            isPinned={activity.pinned}
                                                            isArchived={activity.archived}
                                                            isCreator={userId === activity.user?.id}
                                                            createdDate={activity.created}
                                                            onActionClick={(activityId, action) =>
                                                                handleActionClick(activity, action, activity.pinned, activity.archived)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 text-black">
                                                {(activity.info || activity.details?.title as string)?.split("\\n").map((line, index) => (
                                                    <p key={index} className='text-wrap' style={{ wordBreak: 'break-word' }}>
                                                        {line}
                                                    </p>
                                                ))}
                                                {activity.media.length > 0 && (
                                                    <MediaDetails
                                                        handleDelete={console.log}
                                                        mediaItems={activity.media}
                                                        title={`Notizdokumente von ${activity.user?.title ?? 'Unbekannt'} - ${formatDate(activity.timeOfActivity, 'd.m.Y, H:i Uhr')}`}
                                                        showDropdown={false}
                                                        isGrid
                                                    />
                                                )}

                                                {activity.comments.map(c => (
                                                    <div key={c.id} className='d-flex'>
                                                        <div className='me-2'>
                                                            <ParticipantInitialsComponent
                                                                type='comment'
                                                                size={40}
                                                                initials={getInitials(c.user?.title)}
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column mb-3 text-black w-100">
                                                            <div className='d-flex justify-content-between w-100'>
                                                                <div className='d-flex flex-column w-100'>
                                                                    <span className='mb-1'>
                                                                        {c.user?.title ?? 'Unbekannt'} ·{' '}
                                                                        <span className="text-muted">
                                                                            {formatDate(c.created, 'd.m.Y, H:i Uhr')}
                                                                        </span>
                                                                    </span>
                                                                    <p
                                                                        className='ms-2 border-radius-comment p-2 bg-grey text-wrap'
                                                                        style={{ wordBreak: 'break-word' }}
                                                                    >
                                                                        {c.info}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>

                                            {index < groupedActivities[monthYear].length - 1 && (
                                                <div className="horizontal-line mt-3"></div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}


                {!isLoading && activities.length === 0 && (
                    <div
                        className="d-flex justify-content-center align-items-center border rounded my-3"
                        style={{ height: '50px' }}
                    >
                        <p className="p-0 m-0">Keine Aktivitäten gefunden</p>
                    </div>
                )}

                <Row>
                    <Col>
                        <PaginationInfo
                            currentPage={currentPage}
                            limit={limit}
                            totalEntries={totalEntries}
                            onLimitChange={(size) => {
                                setLimit(size);
                                setCurrentPage(1);
                            }}
                            pageSizes={[5, 10, 15, 20]}
                        />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <DynamicPagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Col>
                </Row>

                {showCommentModal && selectedActivity !== null && (
                    <AddCommentModal
                        activity={selectedActivity}
                        onModalClose={() => setShowCommentModal(false)}
                        onSubmitSuccess={onActivitiesUpdate}
                    />
                )}

                {showEditActivityModal && selectedActivity && (
                    <EditActivityModal
                        fieldConfigs={fieldConfigs}
                        activity={selectedActivity}
                        onModalClose={() => setShowEditActivityModal(false)}
                        onSubmitSuccess={onActivitiesUpdate}
                    />
                )}
            </>
        </div>
    );
};

export default ActivitiesList;
