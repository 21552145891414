import { faSync, faTrash, faPlus, faClock, faFileCertificate, faArrowRotateRight, faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import Card from "../../bootstrap/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComputerMouse } from "@fortawesome/pro-light-svg-icons";
import HoldButton from "../../HoldButton";
import { ApiClient } from 'c1g-ui-library';
import { useToast } from 'c1g-ui-library';
import { useState } from "react";
import InfoDialog from "../../InfoDialog";
import { Participant } from "../../../interfaces";
import { Dropdown } from "react-bootstrap";

type ParticipantProcessesProps = {
  participant: Participant
  onSubmitSuccess: (message?: string, isError?: boolean) => void;
};

/**
 * ParticipantProcesses Component
 * 
 * This component renders a list of process actions that can be performed on a participant.
 * Each process is triggered via a `HoldButton`, requiring the user to hold down before execution.
 * 
 * Features:
 * - **Dynamic API Calls**: Uses a single `handleProcessAction` function to handle different actions.
 * - **Deal Selection**: Allows switching between different deals using a `Dropdown` (if more than one deal exists).
 * - **Visual Feedback**: Uses a modal (`InfoDialog`) or toast notifications to inform the user about the result.
 * 
 * Props:
 * - `participant` (Participant): The participant object containing available deals and relevant data.
 * - `disabled` (boolean, optional): If true, the button is deactivated and visually dimmed.
 * 
 * How it Works:
 * - The `HoldButton` triggers the `handleProcessAction` function with a specific API endpoint.
 * - The `handleProcessAction` makes a request to the backend and handles the response.
 * - If a response requires a toast or a modal, it updates the state accordingly.
 * - A dropdown is displayed if multiple deals exist, allowing the user to select a deal.
 */
const ParticipantProcesses: React.FC<ParticipantProcessesProps> = ({ participant, onSubmitSuccess }) => {
  const { showToast } = useToast();
  const [dialogContent, setDialogContent] = useState<{ headline: string; message: string } | null>(null);
  const [selectedDeal, setSelectedDeal] = useState<number | null>(participant.lastDeal.id ?? null);

  const processSectionsParticipant = [
    {
      title: "Lernwelt - Teilnehmer",
      buttons: [
        {
          label: "Teilnehmer Account erstellen",
          icon: faPlus,
          disabled: participant.lwActivated,
          onConfirm: async () => {
            await handleProcessAction(`/participants/${participant.id}/createLernweltUser`);
            onSubmitSuccess();
          },
        },
        {
          label: "Teilnehmer Daten aktualisieren",
          icon: faSync,
          disabled: !participant.lwActivated,
          onConfirm: async () => {
            await handleProcessAction(`/participants/${participant.id}/updateLernweltUser`);
            onSubmitSuccess();
          },
        },
        {
          label: "Teilnehmer Account löschen",
          icon: faTrash,
          disabled: !participant.lwActivated,
          onConfirm: async () => {
            await handleProcessAction(`/participants/${participant.id}/deleteLernweltUser`);
            onSubmitSuccess();
          },
        },
        {
          label: "Anmeldelink nochmals per E-Mail versenden",
          icon: faArrowRotateRight,
          disabled: participant.lwActivated !== null,
          onConfirm: () => handleProcessAction(`/participants/${participant.id}/sendNewLernweltLogin`),
        },
      ],
    },
  ];

  const processSectionsDeals = [
    {
      title: "Lernwelt - Weiteres",
      buttons: [

        {
          label: "Teilnahmebescheinigung / Zertifikat an Lernwelt übertragen",
          disabled: !participant.lwActivated || !participant.lastDeal.certificate,
          icon: faFileCertificate,
          onConfirm: () => handleProcessAction(`/deals/${selectedDeal}/sendCertificateToLernwelt`),
        },
      ],
    },
    {
      title: "Deal-spezifische Prozesse",
      buttons: [
        {
          label: "Teilnahmebescheinigung / Zertifikat erstellen",
          disabled: !participant.lwActivated,
          icon: faFileCertificate,
          onConfirm: () => handleProcessAction(`/deals/${selectedDeal}/createNewCertificate`),
        },
        {
          label: "Offene/Fehlende Anwesenheiten versenden",
          icon: faClock,
          onConfirm: () => handleProcessAction(`/deals/${selectedDeal}/sendOpenPresences`),
        },
        {
          label: "Anlage des Maßnahmenträgers erstellen",
          icon: faPlus,
          onConfirm: () => handleProcessAction(`/deals/${selectedDeal}/measureAttachment/generate`),
        },
      ],
    },
  ];

  const handleProcessAction = async (
    url: string,
  ) => {
    const response = await ApiClient.post(url, {});

    if (response?.data?.type === "toast") {
      setDialogContent({
        headline: response.data.headline,
        message: response.data.message,
      });

      setTimeout(() => {
        setDialogContent(null);
      }, 7000);
    } else {
      showToast(`Erfolgreich`);
    }
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <h4 className="text-light-black">Prozesse</h4>
          <p className="mb-5">
            <FontAwesomeIcon icon={faComputerMouse} /> Gedrückt halten zum Starten
          </p>

          {processSectionsParticipant.map((section, index) => (
            <div key={index}>
              <h5 className="mb-3">{section.title}</h5>
              <div className="d-flex flex-wrap gap-2 mb-5">
                {section.buttons.map((button, btnIndex) => (
                  <HoldButton key={btnIndex} {...button} />
                ))}
              </div>
            </div>
          ))}
          {participant.deals.length > 1 &&
            <div className="mb-3 d-flex justify-content-end">
              <Dropdown>
                <Dropdown.Toggle variant="link" className="text-primary">
                  {selectedDeal === participant.lastDeal.id ? 'Aktueller Deal' : participant.deals.find(d => d.id === selectedDeal)?.title}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {participant.deals.map(deal => (
                    <Dropdown.Item key={deal.id} onClick={() => setSelectedDeal(deal.id)}>
                      {deal.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>}
          {processSectionsDeals.map((section, index) => (
            <div key={index}>
              <h5 className="mb-3 text-light-black">{section.title}</h5>
              <div className="d-flex flex-wrap gap-2 mb-5">
                {section.buttons.map((button, btnIndex) => (
                  <HoldButton key={btnIndex} {...button} />
                ))}
              </div>
            </div>
          ))}
        </Card.Body>
      </Card>

      {dialogContent && (
        <InfoDialog onClose={() => setDialogContent(null)} title={dialogContent.headline}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="text-black text-break">{dialogContent.message}</span>
            <FontAwesomeIcon icon={faCircleCheck} className="text-success ms-2" size="lg" />
          </div>
        </InfoDialog>
      )}
    </div>
  );
};

export default ParticipantProcesses;
