import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import { useToast } from 'c1g-ui-library';

interface FileDropzoneProps {
    onFilesChange: (files: File[]) => void;
    accept?: Accept;
    multiple?: boolean;
}

/**
 * FileDropzone Component
 * 
 * This component provides a user-friendly drag-and-drop interface for uploading files. It supports file validation, 
 * customizable file types, and size restrictions. Notifications are displayed when files are rejected due to invalid 
 * formats or size limits.
 * 
 * Props:
 * - `onFilesChange` (function): Callback function triggered with an array of accepted files.
 * - `accept` (Accept): Object defining the allowed MIME types and file extensions (default: allow all files).
 * - `multiple` (boolean): Whether multiple files can be uploaded at once (default: true).
 */
const FileDropzone: React.FC<FileDropzoneProps> = ({ onFilesChange, accept = {}, multiple = true }) => {
    const { showToast } = useToast();
    const maxSize = 50 * 1024 * 1024; // 50MB

    const allowedExtensions = Object.keys(accept).length > 0 ? Object.values(accept).flat() : null;

    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: any[]) => {
            if (fileRejections.length > 0) {
                const rejectedFile = fileRejections[0];
                const errors = rejectedFile.errors.map((e: any) => e.message).join(', ');

                showToast(
                    `Die Datei "${rejectedFile.file.name}" konnte nicht hochgeladen werden: ${errors}`,
                    true
                );
                return;
            }

            const invalidFiles = acceptedFiles.filter((file) => {
                const fileExtension = file.name.split('.').pop()?.toLowerCase();
                return (
                    (allowedExtensions && !allowedExtensions.includes(`.${fileExtension}`)) ||
                    file.size > maxSize
                );
            });

            if (invalidFiles.length > 0) {
                invalidFiles.forEach((file) => {
                    showToast(
                        `Die Datei "${file.name}" ist ungültig. Erlaubte Formate: ${allowedExtensions ? allowedExtensions.join(', ') : 'Alle'
                        }. Maximale Dateigröße: 50MB.`,
                        true
                    );
                });
                return;
            }

            onFilesChange(acceptedFiles);
        },
        [onFilesChange, showToast, allowedExtensions]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxSize,
        accept: allowedExtensions ? accept : undefined,
        multiple,
    });

    return (
        <div
            {...getRootProps()}
            className="p-5 text-center rounded"
            style={{ border: '2px dashed #EEEEEE' }}
        >
            <input {...getInputProps()} />
            <div className="d-flex justify-content-center mb-4">
                <FontAwesomeIcon size="2xl" icon={faUpload} />
            </div>
            {isDragActive ? (
                <p>Drop the file here ...</p>
            ) : (
                <p>
                    Dateien hier ablegen oder zum{' '}
                    <span className="text-decoration-underline">Hochladen</span> anklicken
                    (Maximale Dateigröße: 50MB, Erlaubte Formate:{' '}
                    {allowedExtensions ? allowedExtensions.join(', ') : 'Alle'})
                </p>
            )}
        </div>
    );
};

export default FileDropzone;
