import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { NameConventionDeals } from '../../interfaces';
import { usePermissions, Setting } from 'c1g-ui-library';
import { PermissionsEnum } from '../../utils/enum';
import SkeletonCard from '../skeleton/SkeletonCard';
import GenericInfosCard from '../GenericInfosCard';
import NameConventionDealsModal from './modal/NameConventionDealsModal';
import { Permissions } from '../../interfaces';

interface NameConventionDealsDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null
    onSubmitSuccess: (settingsId: number) => void;
}

/**
 * NameConventionDealsDetails Component
 *
 * This component displays details of naming conventions for deals, including general settings 
 * and specific naming guidelines for contracts, vouchers, and offers. It provides an option 
 * to edit these details through a modal.
 *
 * Props:
 * - `isLoading?`: Optional boolean indicating whether the component is in a loading state.
 * - `setting?`: Optional `Setting` object containing the naming convention details.
 * - `onSubmitSuccess`: Function invoked when settings are successfully updated, receiving the updated settings ID.
 */
const NameConventionDealsDetails: React.FC<NameConventionDealsDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions<Permissions>();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const fileNames = setting?.details as NameConventionDeals

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Benennungsrichtlinie für Weiterbildungsverträge',
                value: fileNames?.wv || '-',
            },
            {
                label: 'Benennungsrichtlinie für Bildungsgutscheine',
                value: fileNames?.bgs || '-',
            },
            {
                label: 'Benennungsrichtlinie für Angebote',
                value: fileNames?.offer || '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewSettings, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            {setting && showAddEditModal && <NameConventionDealsModal
                modalTitle={`${setting.title} bearbeiten`}
                setting={setting}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </NameConventionDealsModal>
            }
        </>
    );
};

export default NameConventionDealsDetails;
