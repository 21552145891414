import React from 'react';
import { Row, Col } from 'react-bootstrap';
import AddEditActivityModal from '../modal/AddEditActivityModal';
import Card from '../../bootstrap/card';
import LogAccordion from '../../LogAccordion';
import SkeletonCard from '../../skeleton/SkeletonCard';
import GenericInfosCard from '../../GenericInfosCard';
import { Activity } from 'c1g-ui-library';

interface ActivityDetailsProps {
    isLoading?: boolean;
    activity?: Activity | null
    onSubmitSuccess: (activityId: number) => void;
}

const ActivityDetails: React.FC<ActivityDetailsProps> = ({
    isLoading,
    activity,
    onSubmitSuccess
}) => {
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Name',
                value:  '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                {/* <h4>{activity?.title}</h4>
                {activity && <AddEditActivityModal
                    buttonName="Bearbeiten"
                    modalTitle={`${activity.title} bearbeiten`}
                    activity={activity}
                    onSubmitSuccess={onSubmitSuccess}
                >
                </AddEditActivityModal>
                } */}
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default ActivityDetails;
