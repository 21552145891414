import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from 'c1g-ui-library';
import ApiKeyDetails from '../../components/apiKeys/detail/ApiKeyDetails';
import { useDocumentTitle, ApiKey, usePermissions } from 'c1g-ui-library';
import { Permissions } from '../../interfaces';
import { AxiosError } from 'axios';

function ApiKeyDetail() {
  const setDynamicTitle = useDocumentTitle({ appName: 'ClientOffice', moduleTranslations: moduleTranslations });;
  const { apikeyId } = useParams();
  const [apiKey, setApiKey] = useState<ApiKey | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'read');

      if (hasPermission) {
        apikeyId && fetchApiKey(apikeyId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [apikeyId, permissionsLoaded]);

  const fetchApiKey = async (apikeyId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/apikeys/${apikeyId}`);
      const apikeyResponse = response.data;
      setApiKey(apikeyResponse);
      setDynamicTitle(apikeyResponse.title)
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setApiKey(null)
    apikeyId && fetchApiKey(apikeyId);
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <ApiKeyDetails apiKey={apiKey} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></ApiKeyDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ApiKeyDetail;
