import React, { useState } from 'react';
import { Deal } from '../../../interfaces';
import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { FieldConfig, usePermissions } from 'c1g-ui-library';
import InfoDialog from '../../InfoDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import StatusDisplay from '../StatusDisplay'
import DealHeaderDropdown from './DealHeaderDropdown';
import { PermissionsEnum } from '../../../utils/enum';
import AddEditDealModal from '../modal/AddEditDealModal';
import DealHeaderIcon from './DealHeaderIcon';
import { Permissions } from '../../../interfaces';

interface DealDetailsHeaderProps {
    isLoading?: boolean;
    deal?: Deal;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    fieldConfigs: FieldConfig[];
}

/**
 * DealDetailsHeader Component
 * 
 * This component displays the header section for a deal's details. It includes the deal's
 * icon, title, status, and provides actions like editing or accessing related HubSpot records.
 * 
 * Props:
 * - `isLoading` (boolean): Indicates whether the component is in a loading state, displaying skeleton placeholders.
 * - `deal` (Deal): The deal data object containing details like title, ID, and status.
 * - `onSubmitSuccess` (function): Callback function triggered after a successful edit action.
 * - `fieldConfigs` (FieldConfig[]): Array of field configurations for rendering dynamic options.
 * 
 * Example Usage:
 * 
 * <DealDetailsHeader
 *   isLoading={false}
 *   deal={dealData}
 *   onSubmitSuccess={handleSuccess}
 *   fieldConfigs={fieldConfigs}
 * />
 */
const DealDetailsHeader: React.FC<DealDetailsHeaderProps> = ({
    isLoading,
    deal,
    onSubmitSuccess,
    fieldConfigs,
}) => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [dialogContent, setDialogContent] = useState<{ headline: string; message: string } | null>(null);

    const { userHasPermissionByRight } = usePermissions<Permissions>();

    const handleDropdownChange = (action: string) => {
        if (action === 'openHubSpotTN') {
            const url = `https://app-eu1.hubspot.com/contacts/26690801/record/0-1/${deal?.participant.hubspotId}`;
            window.open(url, '_blank');
        }
        if (action === 'openHubSpotDeal') {
            const url = `https://app-eu1.hubspot.com/contacts/26690801/record/0-3/${deal?.hubspotDealId}`;
            window.open(url, '_blank');
        }
    };

    return (
        <>
            <div className="d-flex gap-4 justify-content-start align-items-start align-items-lg-center flex-nowrap">
                <div>
                    {deal?.participant && <DealHeaderIcon participant={deal?.participant} />}
                </div>
                <div className="d-flex flex-column gap-2 flex-shrink-1">
                    <h4 className="m-0">{isLoading ? <Skeleton width={150} /> : deal?.title || '-'}</h4>
                    {deal && <StatusDisplay deal={deal} fieldConfigs={fieldConfigs} />}
                </div>

                <div className="d-flex mt-2 mt-lg-0 flex-shrink-0 ms-auto">
                    <DealHeaderDropdown
                        disabled={!!dialogContent}
                        onActionClick={handleDropdownChange}
                    />
                    <Button
                        disabled={!userHasPermissionByRight(PermissionsEnum.ViewDeals, 'write') || !deal || !fieldConfigs}
                        variant="primary"
                        onClick={() => setShowAddEditModal(true)}
                    >
                        Bearbeiten
                    </Button>
                </div>
            </div>

            {showAddEditModal && (
                <AddEditDealModal
                    dealType="deals"
                    deal={deal}
                    modalTitle="Deal bearbeiten"
                    onSubmitSuccess={onSubmitSuccess}
                    onModalClose={() => setShowAddEditModal(false)}
                    fieldConfigs={fieldConfigs}
                />
            )}

            {dialogContent && (
                <InfoDialog onClose={() => setDialogContent(null)} title={dialogContent.headline}>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <span className="text-black text-break">{dialogContent.message}</span>
                        <FontAwesomeIcon icon={faCircleCheck} className="text-success ms-2" size="lg" />
                    </div>
                </InfoDialog>
            )}
        </>
    );
};

export default DealDetailsHeader;
