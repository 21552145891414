import React, { useState } from 'react';
import { Row, Col, Button, Card, Table } from 'react-bootstrap';
import { ModuleReason } from '../../interfaces';
import { usePermissions, Setting } from 'c1g-ui-library';
import { PermissionsEnum } from '../../utils/enum';
import SkeletonCard from '../skeleton/SkeletonCard';
import GenericInfosCard from '../GenericInfosCard';
import UpdateModuleReasonsModal from './modal/UpdateModuleReasonsModal';
import { Permissions } from '../../interfaces';

/**
 * Categories mapping for different module-related reasons.
 */
export const categories = [
    { key: "setFullProgress", title: "Vollständigen Fortschritt setzen" },
    { key: "setEmptyProgress", title: "Fortschritt zurücksetzen" },
    { key: "moduleUnlock", title: "Modul freischalten" },
    { key: "moduleLock", title: "Modul sperren" },
];

interface UpdateModuleReasonsProps {
    isLoading?: boolean;
    setting?: Setting | null;
    onSubmitSuccess: (settingsId: number) => void;
}

/**
 * UpdateModuleReasons Component
 *
 * This component displays categorized reasons for module-related changes,
 * such as unlocking, locking, setting full progress, or resetting progress.
 * Each category is displayed in a separate card for clarity.
 *
 * Props:
 * @param {boolean} [isLoading] - Indicates if data is loading.
 * @param {Setting | null} [setting] - The setting object containing module reason data.
 * @param {(settingsId: number) => void} onSubmitSuccess - Callback for successful updates.
 *
 * @component
 */
const UpdateModuleReasons: React.FC<UpdateModuleReasonsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess,
}) => {
    const { userHasPermissionByRight } = usePermissions<Permissions>();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const reasons = setting?.details as Record<string, Record<string, ModuleReason>> ?? {};

    function getInfo1() {
        return [
            { label: 'Titel / Bezeichnung', value: setting?.title || '-' },
            { label: 'Ident / Kürzel', value: setting?.ident || '-' },
            { label: 'Beschreibung', value: setting?.description || '-' },
        ];
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <Button
                    disabled={!userHasPermissionByRight(PermissionsEnum.ViewSettings, 'write')}
                    variant="primary"
                    onClick={() => setShowAddEditModal(true)}
                >
                    Bearbeiten
                </Button>
            </div>

            <Row>
                {/* General Information Card */}
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard title="Allgemein" infos={getInfo1()} firstColumnSize={4} />
                    )}
                </Col>

                <Col lg={6}></Col>
            </Row>

            {/* Category Tables */}
            <Row>
                {categories.map(({ key, title }) => (
                    <Col lg={6} key={key}>
                        <Card className="card-block card-stretch card-height">
                            <Card.Body>
                                <h5>{title}</h5>
                                <Table size="sm">
                                    <thead>
                                        <tr>
                                            <th>Label</th>
                                            <th>Text</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(reasons[key] ?? {}).length > 0 ? (
                                            Object.entries(reasons[key]).map(([reasonKey, reason]) => (
                                                <tr key={reasonKey}>
                                                    <td>{reason.label}</td>
                                                    <td className='text-wrap'>{reason.text || '-'}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={2} className="text-center">Keine Gründe vorhanden</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            {setting && showAddEditModal && (
                <UpdateModuleReasonsModal
                    modalTitle={`${setting.title} bearbeiten`}
                    setting={setting}
                    onSubmitSuccess={onSubmitSuccess}
                    onModalClose={() => setShowAddEditModal(false)}
                />
            )}
        </>
    );
};

export default UpdateModuleReasons;
