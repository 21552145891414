import React, { useState } from 'react';
import { Modal, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import FormGroup from '../../form/FormGroup';
import { ApiClient } from 'c1g-ui-library';
import { getEnumValue } from '../../../utils/enum';
import { getFieldConfigByResourceName } from '../../../utils/utils';
import { Activity, FieldConfig } from 'c1g-ui-library';
import { AxiosError } from 'axios';

interface EditActivityModalProps {
    activity: Activity;
    fieldConfigs: FieldConfig[];
    onModalClose: () => void;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
}

interface FormValues {
    info: string;
}

/**
 * EditActivityModal
 *
 * A modal component for editing an existing activity's `info` field.
 * 
 * Props:
 * - `activity`: The `Activity` object being edited.
 * - `fieldConfigs`: A list of field configurations to determine the appropriate label.
 * - `onModalClose`: Function to close the modal.
 * - `onSubmitSuccess`: Callback triggered after a successful update, passing a success message and an error flag if needed.
 */
const EditActivityModal: React.FC<EditActivityModalProps> = ({ onModalClose, onSubmitSuccess, activity, fieldConfigs }) => {
    const [formValues, setFormValues] = useState<FormValues>({ info: activity.info || '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdateActivity = async () => {
        setIsLoading(true);

        try {
            await ApiClient.put(`/activities/${activity.id}`, {
                info: formValues.info,
            });
            onSubmitSuccess('Aktivität erfolgreich aktualisiert', false);
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsLoading(false);
            onModalClose();
        }
    };

    const handleInfoChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if ('value' in e.target) {
            setFormValues({ ...formValues, info: e.target.value });
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'Enter' && formValues.info.length > 0) {
            e.preventDefault();
            handleUpdateActivity();
        }
    };

    return (
        <Modal show onHide={onModalClose} centered>
            <Modal.Body>
                <h4 className="mb-3">Aktivität bearbeiten</h4>
                <Form>
                    <FormGroup<FormValues>
                        id="info"
                        label={`Neue ${getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'activities.type')?.options ?? {}, activity.type.toString())}`}
                        type="textarea"
                        value={formValues.info}
                        onChange={handleInfoChange}
                        onKeyDown={handleKeyDown}
                        noResize
                    />
                    <Row className="mt-4 justify-content-end">
                        <Col xs="auto">
                            <Button onClick={onModalClose} className="btn btn-soft-primary me-2">
                                Abbrechen
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleUpdateActivity}
                                disabled={isLoading || formValues.info.length === 0}
                            >
                                Aktualisieren
                                {isLoading && (
                                    <Spinner
                                        className="ms-2"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditActivityModal;
