import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, ColumnMapping, FieldConfig, NotificationToast, SortCaret, useDocumentTitle, usePermissions, useResetUrlParams, useSelection, useSortableData, useTableHeight, useToast } from 'c1g-ui-library';
import {
    memo,
    useEffect,
    useState,
} from 'react';
import { Button, Col, Dropdown, Form, Row, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ColumnSelection from '../../../components/ColumnSelection';
import {
    ComboButtonId,
} from '../../../components/ComboButtonGroup';
import GeneralSelectionActions from '../../../components/GeneralSelectionActions';
import PortalWrapper from '../../../components/PortalWrapper';
import ResetFiltersButton from '../../../components/ResetFilterButton';
import SearchInput from '../../../components/SearchInput';
import ViewSelector from '../../../components/ViewSelector';
import Card from '../../../components/bootstrap/card';
import SendMailModal from '../../../components/participants/modal/SendMailModal';
import { DefaultColumnRender } from '../../../components/table/DefaultColumnRender';
import DynamicPagination from '../../../components/table/DynamicPagination';
import PaginationInfo from '../../../components/table/PaginationInfo';
import SaveCancelPopup from '../../../components/table/SaveCancelPopup';
import TableNoDataMessage from '../../../components/table/TableNoDataMessage';
import SkeletonRow from '../../../components/table/skeletonRow/SkeletonRow';
import { defaultRenderHeader } from '../../../components/table/utils';
import FullHeader from '../../../components/ui/header/FullHeader';
import { AbsenceReport, Permissions } from '../../../interfaces';
import {
    moduleTranslations,
    PermissionsEnum,
} from '../../../utils/enum';
import { addPrefixToFilters, fetchAndCombineFieldConfigs, getFieldConfigByResourceName } from '../../../utils/utils';

export interface AbsenceReportFilters {
    status: | number | null
}

interface AbsenceReportsResponse {
    page: number;
    itemsPerPage: number;
    amountPages: number;
    amountAllItems: number;
    searchFilters: string[];
    list: AbsenceReport[];
}

const AbsenceReports = memo(() => {
    useTableHeight();
    const navigate = useNavigate();
    const resetUrlParams = useResetUrlParams();
    const updateDocumentTitle = useDocumentTitle({ appName: 'ClientOffice', moduleTranslations: moduleTranslations });

    const { companyId = 'oc' } = useParams();
    const { show, message, error, showToast, hideToast } = useToast();
    const [selectedSearchColumn, setSelectedSearchColumn] = useState<ComboButtonId | ''>('all');
    const [absenceReports, setAbsenceReports] = useState<AbsenceReport[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(20);
    const [limit, setLimit] = useState<number>(25);
    const [totalEntries, setTotalEntries] = useState<number>(200);
    const [availableFilter, setAvailableFilter] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
    const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
    const [showPopup, setShowPopup] = useState(false);
    const { requestSort, sortConfig, setSortConfig, getSortDirection } = useSortableData(absenceReports, showPopup);
    const [pendingChanges, setPendingChanges] = useState<{ [key: string]: any }>({});
    const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
    const [editableCell, setEditableCell] = useState<{ rowId: number | null; columnKey: string | null }>({
        rowId: null,
        columnKey: null,
    });
    const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<AbsenceReportFilters>({
        status: null,
    });

    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const {
        selectedItems: selectedAbsenceReports,
        isAllSelected,
        selectedCount,
        handleSelectAll,
        handleDeSelectAll,
        handleSelectRow
    } = useSelection(absenceReports);

    const [sendMailConfig, setSendMailConfig] = useState<{
        title: string;
        email: string;
        dealId: number;
        type?: number;
        id?: number;
    } | null>(null);

    // Fetch the list of absence reports based on the current filters, search, sort and pagination
    const fetchAbsenceReports = async () => {
        updateDocumentTitle('Fehlzeitenberichte');
        setAbsenceReports([]);
        setIsLoading(true);
        let queryParams = `?page=${currentPage}`;

        // remove?
        if (selectedFilters.status?.toString()) {
            queryParams += `&absencesreports.status=${selectedFilters.status?.toString()}`;
        }

        if (limit.toString()) {
            queryParams += `&limit=${limit}`;
        }
        if (searchQuery) {
            queryParams += `&search=${encodeURIComponent(searchQuery)}`;
            if (selectedSearchColumn !== 'all') {
                queryParams += `&column=${encodeURIComponent(selectedSearchColumn)}`;
            }
        }

        if (sortConfig?.field) {
            queryParams += `&sort[field]=${encodeURIComponent(sortConfig.field)}`;
            queryParams += `&sort[type]=${sortConfig.type}`;
        }

        try {
            const response = await ApiClient.get(`/absencesreports${queryParams}`);
            const absencesResponse = response.data as AbsenceReportsResponse;
            setTotalPages(absencesResponse.amountPages);
            setAbsenceReports(absencesResponse.list ?? []);
            setCurrentPage(absencesResponse.page);
            setLimit(absencesResponse.itemsPerPage);
            setTotalEntries(absencesResponse.amountAllItems);
            setAvailableFilter(addPrefixToFilters(absencesResponse.searchFilters, 'absencesReports'));
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsLoading(false);
        }
    };

    // Effect to trigger fetching of absence reports when filters, search, pagination or other dependencies change
    useEffect(() => {
        if (permissionsLoaded) {
            const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewDeals, 'read');

            if (hasPermission) {
                fetchAbsenceReports();

                // Fetch only once
                if (!fieldConfigs || Object.keys(fieldConfigs).length === 0) {
                    fetchAndCombineFieldConfigs(['absencesreports'], setFieldConfigs)
                }

            } else {
                navigate('/errors/error404');
            }
        }
    }, [selectedFilters, currentPage, searchQuery, selectedSearchColumn, selectedColumns, limit, sortConfig]);

    // Effect to reset search input
    useEffect(() => {
        if (resetSearchInput) {
            setResetSearchInput(false);
        }
    }, [resetSearchInput]);

    // Routes to detail view
    const handleRouteToDetailView = (absenceReportId: number) => {
        console.log(absenceReportId);
        navigate(`/${companyId}/deals/absence-reports/${absenceReportId}`);
    };

    // Fetch absence reports again after an update, optionally showing a message
    const handleAbsenceReportUpdateSubmit = (message?: string, isError?: boolean) => {
        fetchAbsenceReports();
        if (message) {
            showToast(message, isError);
        }
    };

    // Resets all filters and search fields
    const resetSearch = () => {
        resetUrlParams();
        setSearchQuery('');
        setSelectedSearchColumn('all');
        setCurrentPage(1);
        setResetSearchInput(true);
    };

    // Handles view selection changes from the view selection component.
    const handleSelectionChange = (
        selectedColumns: string[],
        selectedFilters: any,
        selectedSortConfig: any,
        selectedLimit: number,
        selectedSearchTerm: string,
        selectedSearchColumn: string
    ) => {
        resetSearch()
        setSelectedColumns(selectedColumns);
        setSelectedFilters(selectedFilters);
        setSortConfig(selectedSortConfig);
        setLimit(selectedLimit);

        if (selectedSearchTerm || selectedSearchColumn) {
            setSearchQuery(selectedSearchTerm);
            setSelectedSearchColumn(selectedSearchColumn);
        }
    };

    // Handle clicks on table cells for editing
    const handleCellClick = (rowId: number | null, columnKey: string | null) => {
        setEditableCell({ rowId, columnKey });
    };

    // Handle changes to table cells for editing
    const handleFieldChange = (rowId: number, columnKey: string, value: any) => {
        setPendingChanges(prevChanges => ({
            ...prevChanges,
            [`${rowId}-${columnKey}`]: { rowId, columnKey, value },
        }));
        setShowPopup(true);
    };

    // Save changes made in editable cells
    const handleSaveChange = async () => {
        const changesToSubmit = Object.values(pendingChanges);

        try {
            const updatePromises = changesToSubmit.map(change => {
                if (typeof change.value === 'object' && change.value !== null) {
                    const relation = change.columnKey.split('.')[0]
                    return ApiClient.put(`/absencesreports/${change.rowId}`, { [`${relation}_id`]: change.value.value });
                } else {
                    return ApiClient.put(`/absencesreports/${change.rowId}`, { [getFieldConfigByResourceName(fieldConfigs, change.columnKey)?.fieldName as string]: change.value });
                }
            });

            await Promise.all(updatePromises);

            setPendingChanges({});
            setEditableCell({ rowId: null, columnKey: null });

            await fetchAbsenceReports();
            showToast('Erfolgreich gespeichert', false);
            setShowPopup(false);
        } catch (error: any) {
            console.error(error.message as AxiosError);
        }
    };

    // Revert a single change in editable cell
    const handleRevertChange = () => {
        const { rowId, columnKey } = editableCell;

        if (rowId === null || columnKey === null) return;

        const newPendingChanges = { ...pendingChanges };
        delete newPendingChanges[`${rowId}-${columnKey}`];

        setPendingChanges(newPendingChanges);
        setEditableCell({ rowId: null, columnKey: null });
        setShowPopup(Object.keys(newPendingChanges).length > 0);
    };

    // Revert all changes made in the editable cells
    const handleRevertChanges = () => {
        setPendingChanges({});
        setEditableCell({ rowId: null, columnKey: null });
        setShowPopup(false);
    };

    // Handle search input change
    const handleSearch = (data: { query: string; filter?: string }) => {
        const { query, filter } = data;

        if (query) {
            setSearchQuery(query);
            setSelectedSearchColumn(filter || '');
            setCurrentPage(1);
        } else if (searchQuery) {
            resetSearch();
        }
    };

    /**
 * Handles successful operations like sending emails
 * Shows a toast message if provided and refreshes the report data
 */
    const handleSuccess = (message?: string, isError?: boolean) => {
        if (message) {
            showToast(message, isError);
        }
        fetchAbsenceReports();
        setSendMailConfig(null);
    };

    // Define how special columns should be rendered
    const columnMapping: { [key: string]: ColumnMapping<AbsenceReport> } = {
        'absencesReports.title': {
            renderHeader: (key: any) => (
                <th
                    key={key}
                    className="sticky-col cursor-pointer py-3"
                    scope="col"
                    title="Titel"
                    onClick={() => requestSort('absencesReports.title')}
                >
                    <div className="d-flex align-items-center position-relative table-cell-wrap max-w-100">
                        <Form.Check
                            disabled={!userHasPermissionByRight(PermissionsEnum.ViewDeals, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewDeals, 'delete')}
                            className="me-3"
                            type="checkbox"
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        />
                        Titel <div className="position-absolute" style={{ right: 0 }}><SortCaret direction={getSortDirection('absencesReports.title')} /></div>
                    </div>
                </th>
            ),

            render: (absenceReport: AbsenceReport) => (
                <td key={absenceReport.id} className="sticky-col py-3">
                    <div className="d-flex align-items-center justify-content-start">
                        <Form.Check
                            disabled={!userHasPermissionByRight(PermissionsEnum.ViewDeals, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewDeals, 'delete')}
                            className="me-3"
                            type="checkbox"
                            checked={selectedAbsenceReports[absenceReport.id] ?? false}
                            onChange={() => { }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSelectRow(absenceReport.id, e);
                            }}
                        />
                        <Link
                            to={`/${companyId}/deals/absence-reports/${absenceReport.id}`}
                            className="btn-link ps-0 text-start table-cell-wrap max-w-table-title"
                            title={absenceReport.title ?? ''}
                        >
                            {absenceReport.title}
                        </Link>
                    </div>
                </td>
            ),
        },
    };

    return (
        <>
            <FullHeader>
                <h3 className='m-0'>Fehlzeitenberichte</h3>
            </FullHeader>
            <div className='absence-reports-container container-fluid p-40'>
                <Card>
                    <Card.Body className='p-0'>
                        <div className='table-controls-wrapper'>
                            <Card className="m-0">
                                <Card.Body >
                                    <Row className="d-flex justify-content-between mb-4">
                                        <Col md={9}>
                                            {searchQuery &&
                                                <div className="d-flex align-items-baseline mb-3">
                                                    <h4 className="m-0">Suchergebnisse</h4>
                                                    <span className="ms-3 d-flex align-items-baseline">
                                                        <Button
                                                            className="btn-ghost m-0 p-0 fs-6"
                                                            variant="link"
                                                            onClick={resetSearch}
                                                        >
                                                            Suche beenden
                                                        </Button>
                                                    </span>
                                                </div>
                                            }
                                            <ViewSelector
                                                selectedSortConfig={sortConfig}
                                                selectedFilters={selectedFilters}
                                                selectedColumns={selectedColumns}
                                                selectedLimit={limit}
                                                selectedSearchColumn={selectedSearchColumn}
                                                selectedSearchTerm={searchQuery}
                                                entityType='absencesReports'
                                                onSelectionChange={handleSelectionChange}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} initialSearchTerm={searchQuery} initialSearchColumn={selectedSearchColumn} fieldConfigs={fieldConfigs} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div className="d-flex custom-scrollbar-x horizontal-scroll">
                                                    <div className="sticky-right-reset-filter">
                                                        <ResetFiltersButton filters={selectedFilters} setFilters={(newFilters: AbsenceReportFilters) => {
                                                            setSelectedFilters(newFilters);
                                                            setCurrentPage(1);
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {selectedCount > 0 && (
                                <GeneralSelectionActions
                                    selectedItems={selectedAbsenceReports}
                                    selectedCount={selectedCount}
                                    handleDeSelectAll={handleDeSelectAll}
                                    onSubmit={handleAbsenceReportUpdateSubmit}
                                    amountAllItems={totalEntries}
                                    entityType='absencesReports'
                                ></GeneralSelectionActions>
                            )}
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <Table className="sticky-table" responsive="md" size="sm" style={{ overflow: 'auto' }}>
                                <thead>
                                    <tr className='tr-header'>
                                        {selectedColumns.map((columnKey) =>
                                            columnMapping[columnKey]?.renderHeader
                                                ? columnMapping[columnKey].renderHeader!(columnKey)
                                                : defaultRenderHeader(columnKey, fieldConfigs, requestSort, getSortDirection, 'absencesReports')
                                        )}

                                        <th className="cursor-pointer text-end align-top sticky-right bg-grey w-40-px py-3" scope="col">
                                            <ColumnSelection
                                                selectedColumns={selectedColumns}
                                                onSelectionChange={columns => setSelectedColumns(columns)}
                                                fieldConfigs={fieldConfigs}
                                                entityType='absencesReports'
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading
                                        ? Array.from({ length: 8 }).map((_, index) => (
                                            <SkeletonRow key={`skeleton-row-${index}`} columnCount={selectedColumns.length + 1} />
                                        ))
                                        : absenceReports.map((absenceReport) => (
                                            <tr key={absenceReport.id} className="bg-white">
                                                {selectedColumns.map((columnKey) =>
                                                    columnMapping[columnKey]
                                                        ? columnMapping[columnKey].render(absenceReport)

                                                        : <DefaultColumnRender
                                                            key={`${absenceReport.id}-${columnKey}`}
                                                            item={absenceReport}
                                                            columnKey={columnKey}
                                                            editableCell={editableCell}
                                                            handleCellClick={handleCellClick}
                                                            handleFieldChange={handleFieldChange}
                                                            handleRevertChange={handleRevertChange}
                                                            fieldConfigs={fieldConfigs}
                                                            pendingChanges={pendingChanges}
                                                            activeTooltip={activeTooltip}
                                                            setActiveTooltip={setActiveTooltip}
                                                            module={'absencesReports'}
                                                        />
                                                )}
                                                <td className='sticky-right bg-white' key={absenceReport.id}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle as="div" className="no-caret cursor-pointer d-inline-block">
                                                            <div className="px-2">
                                                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <PortalWrapper>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleRouteToDetailView(absenceReport.id)}><FontAwesomeIcon className='me-1' width={15} icon={faCircleInfo} /> Mehr Details</Dropdown.Item>
                                                                {absenceReport.sentAt === null && (
                                                                    <Dropdown.Item onClick={() => setSendMailConfig({
                                                                        title: "Fehlzeitenbericht senden",
                                                                        email: "",
                                                                        dealId: absenceReport.deal_id,
                                                                        type: 3,
                                                                        id: absenceReport.id
                                                                    })}>
                                                                        <FontAwesomeIcon className='me-1' width={15} icon={faEnvelope} /> Jetzt versenden
                                                                    </Dropdown.Item>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </PortalWrapper>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    {!isLoading && absenceReports.length === 0 && (
                                        <TableNoDataMessage
                                            message="Keine Fehlzeitenberichte gefunden"
                                        />
                                    )}
                                </tbody>
                            </Table>
                        </div>

                        {totalEntries > 0 && (
                            <div className='pagination-wrapper px-4 py-2'>
                                <Row>
                                    <Col>
                                        <PaginationInfo
                                            currentPage={currentPage}
                                            limit={limit}
                                            totalEntries={totalEntries}
                                            onLimitChange={(size) => {
                                                setLimit(size);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <DynamicPagination
                                            totalPages={totalPages}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )}

                        {showPopup && (
                            <SaveCancelPopup
                                onSave={handleSaveChange}
                                onAbort={handleRevertChanges}
                                pendingChangesCount={Object.keys(pendingChanges).length}
                            />
                        )}

                        {sendMailConfig && (
                            <SendMailModal
                                dealId={sendMailConfig.dealId}
                                email={sendMailConfig.email}
                                title={sendMailConfig.title}
                                type={sendMailConfig.type}
                                onModalClose={() => setSendMailConfig(null)}
                                onSubmitSuccess={handleSuccess}
                                id={sendMailConfig.id}
                            />
                        )}

                        <NotificationToast
                            show={show}
                            onClose={hideToast}
                            message={message}
                            error={error}
                        />
                    </Card.Body>
                </Card>
            </div>
        </>
    );
});

export default AbsenceReports;
