import React, { useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Spinner,
  Dropdown,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxArchive } from '@fortawesome/pro-regular-svg-icons';
import { ApiClient } from 'c1g-ui-library';
import { AxiosError } from 'axios';

type ArchiveModalProps = {
  modalTitle: string;
  buttonName: string;
  entityType: string;
  queryParams?: string;
  onSubmitSuccess: (message?: string) => void;
  onArchiveModalChange?: (open: boolean) => void;
  isDropdownItem?: boolean;
  isDisabled?: boolean;
  entityIds: number[] | number;
};

/**
 * ArchiveModal Component
 *
 * This component provides a confirmation modal for archiving one or multiple entities.
 * It allows users to trigger the archive action via a dropdown or button.
 *
 * Props:
 * @param {string} modalTitle - Title of the modal dialog.
 * @param {string} buttonName - Label for the archive button.
 * @param {string} entityType - Type of the entity to archive (used for API request).
 * @param {number | number[]} entityIds - ID(s) of the entity/entities to archive.
 * @param {string} [queryParams] - Optional query parameters for the request.
 * @param {Function} onSubmit - Callback function executed on successful archive operation.
 * @param {Function} [onArchiveModalChange] - Optional callback when the modal is opened/closed.
 * @param {boolean} [isDropdownItem] - If true, renders as a dropdown item.
 * @param {boolean} [isDisabled] - If true, disables the archive action.
 */
const ArchiveModal: React.FC<ArchiveModalProps> = ({
  modalTitle,
  buttonName,
  entityIds,
  entityType,
  onSubmitSuccess,
  onArchiveModalChange,
  isDropdownItem,
  isDisabled,
  queryParams = ''
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onArchiveModalChange && onArchiveModalChange(false);
    setShowModal(false);
  };

  const handleShow = () => {
    onArchiveModalChange && onArchiveModalChange(true);
    setShowModal(true);
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      let url = `/${entityType}`;

      if (typeof entityIds === 'number') {
        url += `/${entityIds}`;
      } else if (Array.isArray(entityIds) && entityIds.length === 0) {
        url += `${queryParams}`;
      } else if (Array.isArray(entityIds)) {
        const ids = entityIds.map(id => `id[]=${id}`).join('&');
        url += `${queryParams}&${ids}`;
      }

      await ApiClient.put(url, {
        status: 3,
      });
      onSubmitSuccess("Erfolgreich archiviert");
    
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      {isDropdownItem ? (
        <Dropdown.Item
          disabled={isDisabled}
          as="div"
          className={`text-danger dropdownDeleteButton ${isDisabled ? 'disabled-item' : ''}`}
          onClick={handleShow}
        >
          <FontAwesomeIcon width={30} icon={faBoxArchive} />{buttonName}
        </Dropdown.Item>
      ) : (
        <Button disabled={isDisabled} variant="link" className='text-danger' onClick={handleShow}>
          {buttonName}
        </Button>
      )}
      <Modal
        centered
        size="lg"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>Bist du dir sicher, dass du die ausgewählten Elemente archivieren möchtest?</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn-soft-primary" variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            variant="danger"
            onClick={submit}
          >
            Archivieren
            {isLoading && (
              <Spinner
                className="ms-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              ></Spinner>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ArchiveModal;
