import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";


/**
 * ConfirmationModal Component
 *
 * This is a reusable modal component used to confirm user actions.
 * It displays a title, a message, and two buttons: "Abbrechen" (Cancel) and "Bestätigen" (Confirm).
 * 
 * Props:
 * - `show` (boolean): Controls the visibility of the modal.
 * - `handleClose` (function): Callback triggered when the modal is closed.
 * - `handleConfirm` (function): Callback triggered when the confirm button is clicked.
 * - `title` (string): The title of the modal.
 * - `message` (string): The message shown in the modal body.
 * - `loading` (boolean, optional): If true, the confirm button shows a spinner and disables interactions.
 *
 * The modal is disabled while `loading` is true, preventing multiple clicks during an ongoing operation.
 */
interface ConfirmationModalProps {
    show: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    title: string;
    message: string;
    loading?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    show,
    handleClose,
    handleConfirm,
    title,
    message,
    loading = false,
}) => (
    <Modal centered size="lg" backdrop="static" keyboard={false} show={show} onHide={!loading ? handleClose : undefined}>
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button className="btn-soft-primary" onClick={handleClose} disabled={loading}>
                Abbrechen
            </Button>
            <Button variant="danger" onClick={handleConfirm} disabled={loading}>
                <span>
                    Bestätigen
                    {loading && (
                        <Spinner
                            className="ms-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                        />
                    )}
                </span>
            </Button>

        </Modal.Footer>
    </Modal>
);

export default ConfirmationModal;
