import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import PortalWrapper from '../../PortalWrapper';

interface DealHeaderDropdownProps {
    disabled?: boolean;
    onActionClick: (action: string) => void;
}

/**
 * DealHeaderDropdown Component
 * 
 * This component renders a dropdown menu with various actions that can be triggered for a deal.
 * It uses FontAwesome icons to visually represent each action in the dropdown.
 * 
 * Props:
 * - `onActionClick`: A callback function that is triggered when an action is selected. 
 *   It receives a string parameter representing the selected action.
 * - `disabled`: Should the button be disabled.
 */
const DealHeaderDropdown: React.FC<DealHeaderDropdownProps> = ({ onActionClick, disabled }) => {
    const handleActionClick = (action: string) => () => {
        onActionClick(action);
    };

    return (
        <Dropdown className="me-2 rounded">
            <Dropdown.Toggle
                disabled={disabled}
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow h-100 py-2 px-3"
            >
                <FontAwesomeIcon icon={faEllipsis} />
            </Dropdown.Toggle>

            <PortalWrapper>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={handleActionClick('openHubSpotTN')}>
                        <FontAwesomeIcon className="text-primary" width={20} icon={faHubspot} /> HubSpot (TN) aufrufen
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleActionClick('openHubSpotDeal')}>
                        <FontAwesomeIcon className="text-primary" width={20} icon={faHubspot} /> HubSpot (Deal) aufrufen
                    </Dropdown.Item>
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default DealHeaderDropdown;
