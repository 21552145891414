// auth
import SignIn from '../views/sign-in/sign-in'

// errors
import Error404 from '../views/errors/error404'
import Error500 from '../views/errors/error500'

export const SimpleRouter = [
    {
        path: ':companyId/login',
        element: <SignIn app_name='ClientOffice' />
    },
    {
        path: 'errors/error404',
        element: <Error404 />
    },
    // Utilities
    {
        path: 'errors/error500',
        element: <Error500 />
    },
]