import { faEllipsis, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, NotificationToast, Setting, useToast } from 'c1g-ui-library';
import React, { FormEvent, useRef, useState } from 'react';
import {
    Button,
    Col,
    Dropdown,
    Form,
    Modal,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import { CompanyMeasure } from '../../../interfaces';
import { handleInputChange } from '../../../utils/form/utils';
import { formatDateWithoutTime } from '../../../utils/utils';
import Card from '../../bootstrap/card';
import FormGroup from '../../form/FormGroup';

type CompanyMeasuresModalProps = {
    modalTitle: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    details: Record<string, CompanyMeasure>;
}

/**
 * CompanyMeasuresModal Component
 *
 * This component provides a modal interface for managing Unternehmensmaßnahmen (Company Measures).
 * It allows users to edit general settings (title and description), add new measures, 
 * and remove existing measures. The measures include a unique identifier, designation, 
 * start date, end date, and PSM-Free status.
 *
 * Props:
 * @param {string} modalTitle - The title of the modal.
 * @param {Setting | undefined} setting - The current settings object, including existing measures.
 * @param {(settingsId: number) => void} onSubmitSuccess - Callback function triggered on successful submission.
 * @param {() => void} onModalClose - Callback function triggered when the modal is closed.
 * ```
 */
const CompanyMeasuresModal: React.FC<CompanyMeasuresModalProps> = ({
    modalTitle,
    setting,
    onSubmitSuccess,
    onModalClose,
}) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newEntryKey, setNewEntryKey] = useState('');
    const [newEntry, setNewEntry] = useState<CompanyMeasure>({
        designation: '',
        startDate: '',
        endDate: '',
        psmFree: false,
    });

    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);

    const [formValues, setFormValues] = useState<FormValues>({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        details: setting?.details ? { ...setting.details } as Record<string, CompanyMeasure> : {},
    });

    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
        details: formValues.details ?? {},
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = await ApiClient.put(
                    `/settings/${setting?.id}`,
                    prepareFormDataForSubmit(formValues)
                );
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error: any) {
                console.error(error.message as AxiosError);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const prepareFormDataForSubmit = (formValues: FormValues) => ({
        title: formValues.title,
        ident: formValues.ident,
        description: formValues.description,
        details: formValues.details,
    });

    const handleAddEntry = () => {
        if (newEntryKey.trim() && newEntry.designation.trim() && newEntry.startDate && newEntry.endDate) {
            if (formValues.details[newEntryKey]) {
                showToast('Schlüssel muss eindeutig sein', true);
            } else {
                setFormValues((prev) => ({
                    ...prev,
                    details: {
                        ...prev.details,
                        [newEntryKey]: newEntry,
                    },
                }));
                setNewEntry({ designation: '', startDate: '', endDate: '', psmFree: false });
                setNewEntryKey('');
            }
        } else {
            showToast('Alle Felder müssen ausgefüllt sein', true);
        }
    };

    const handleRemoveEntry = (key: string) => {
        const updatedDetails = { ...formValues.details };
        delete updatedDetails[key];
        setFormValues((prev) => ({
            ...prev,
            details: updatedDetails,
        }));
    };

    return (
        <>
            <Modal centered show onHide={onModalClose} backdrop="static" keyboard={false} fullscreen>
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button p-0"
                            >
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}>
                                    <FontAwesomeIcon width={30} icon={faXmark} />
                                    Schließen
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <Row>
                            {/* Title & Description */}
                            <Col lg={6}>
                                <Card className="card-block card-stretch card-height">
                                    <Card.Body>
                                        <h5>Allgemein</h5>
                                        <FormGroup<FormValues>
                                            id="title"
                                            label="Titel"
                                            value={formValues.title}
                                            onChange={(e) => handleInputChange(e, setFormValues)}
                                        />
                                        <FormGroup<FormValues>
                                            id="description"
                                            label="Beschreibung"
                                            value={formValues.description}
                                            onChange={(e) => handleInputChange(e, setFormValues)}
                                            type="textarea"
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg={6}>
                                <Card className="card-block card-stretch card-height">
                                    <Card.Body>
                                        <h5>Neue Unternehmensmaßnahme hinzufügen</h5>
                                        {/* Add Entry Row */}
                                        <div>
                                            <FormGroup id="newEntryKey" label="Nummer" value={newEntryKey} onChange={(e) => setNewEntryKey(e.target.value)} />
                                            <FormGroup id="newEntryDesignation" label="Bezeichnung" value={newEntry.designation} onChange={(e) => setNewEntry((prev) => ({ ...prev, designation: e.target.value }))} />
                                            <FormGroup id="newEntryStartDate" label="Startdatum" type="date" value={newEntry.startDate} onChange={(e) => setNewEntry((prev) => ({ ...prev, startDate: e.target.value }))} />
                                            <FormGroup id="newEntryEndDate" label="Enddatum" type="date" value={newEntry.endDate} onChange={(e) => setNewEntry((prev) => ({ ...prev, endDate: e.target.value }))} />
                                            <div className='d-flex justify-content-end'>
                                                <Button variant="secondary" onClick={handleAddEntry}>
                                                    <FontAwesomeIcon icon={faPlus} /> Hinzufügen
                                                </Button>
                                            </div>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Col>

                            {/* Measures Table */}
                            <Col lg={12}>
                                <Card className="card-block card-stretch card-height">
                                    <Card.Body>
                                        <h5>Unternehmensmaßnahmen</h5>
                                        <Table size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Nummer</th>
                                                    <th>Bezeichnung</th>
                                                    <th>Startdatum</th>
                                                    <th>Enddatum</th>
                                                    <th>PSM-Frei</th>
                                                    <th>Aktionen</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(formValues.details).length > 0 ? (
                                                    Object.entries(formValues.details).map(([key, detail]) => (
                                                        <tr key={key}>
                                                            <td>{key}</td>
                                                            <td>{detail.designation}</td>
                                                            <td>{detail.startDate ? formatDateWithoutTime(detail.startDate) : '-'}</td>
                                                            <td>{detail.endDate ? formatDateWithoutTime(detail.endDate) : '-'}</td>
                                                            <td>{detail.psmFree ? 'Ja' : 'Nein'}</td>
                                                            <td className='text-center'>
                                                                <Button variant="danger" onClick={() => handleRemoveEntry(key)}>
                                                                    <FontAwesomeIcon icon={faXmark} />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={6} className="text-center">Keine Unternehmensmaßnahmen vorhanden</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast show={show} onClose={hideToast} message={message} error={error} />
        </>
    );
};

export default CompanyMeasuresModal;
