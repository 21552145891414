import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Modal, Button, Form, Spinner, Dropdown } from 'react-bootstrap';
import { ApiClient } from 'c1g-ui-library';
import { useToast } from 'c1g-ui-library';
import SelectWithSearch, { SelectOption } from './form/SelectWithSearch';
import { FieldConfig } from 'c1g-ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { handleInputChange } from '../utils/form/utils';
import { AxiosError } from 'axios';

interface BulkEditModalProps {
  modalTitle: string;
  buttonName: string;
  entityIds: number[]
  onSubmitSuccess: (message?: string) => void;
  entityType: string;
  isDisabled?: boolean;
  isDropdownItem?: boolean;
  fieldConfigs?: FieldConfig[];
  queryParams: string
}

interface FormValues {
  [key: string]: any;
}

const BulkEditModal: React.FC<BulkEditModalProps> = ({
  modalTitle,
  buttonName,
  entityIds,
  onSubmitSuccess,
  isDisabled,
  entityType,
  isDropdownItem,
  fieldConfigs = [],
  queryParams
}) => {
  const { showToast } = useToast();
  
  // Modal-related states
  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Form data states
  const [formValues, setFormValues] = useState<FormValues>({});
  const [selectedField, setSelectedField] = useState<string | null>(null);
  const [fetchedOptions, setFetchedOptions] = useState<{ [key: string]: string } | null>(null);

  // Updates the selected field and fetches related options if needed
  const handleFieldSelectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedField(e.target.value);

    // Fetch options if the field uses an external provider
    const selectedConfig = fieldConfigs.find(config => config.fieldName === e.target.value);
    if (selectedConfig?.optionsprovider?.includes('settings')) {
      fetchSettings(selectedConfig.optionsprovider!);
    }
    setFormValues({});
  };

  // Handles changes for SelectWithSearch components
  const handleSelectChange = (id: string, selectedOption: SelectOption | null) => {
    setFormValues((prev: any) => ({
      ...prev,
      [`${id}_id`]: selectedOption ? selectedOption.value : '',
    }));
  };

  // Submits the form data and triggers the bulk update process
  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        await updateSelectedItems();
        onSubmitSuccess("Erfolgreich gespeichert");
        showToast('Erfolgreich gespeichert', false);
        handleClose();
      } catch (error: any) {
        console.error(error.message as AxiosError);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Performs the bulk update for selected items
  const updateSelectedItems = async () => {
    let fieldName = selectedField;

    if (!fieldName) {
      console.error('Field name is undefined.');
      return;
    }

    const fieldValue = formValues[fieldName as keyof FormValues];

    if (entityIds.length === 0) {
      await ApiClient.put(`/${entityType}${queryParams}`, {
        [fieldName]: fieldValue,
      })
    } else {
      const ids = entityIds.map(id => `id[]=${id}`).join('&');
      await ApiClient.put(`/${entityType}${queryParams}&${ids}`, {
        [fieldName]: fieldValue,
      })
    }
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // Maps response data to SelectOption format
  const mapResponseToOptions = (response: any): SelectOption[] => {
    return response.list.map((item: any) => ({
      value: item.id,
      label: item.title,
    }));
  };

  // Fetches external options based on the settings URL
  const fetchSettings = async (url: string) => {
    const res = await ApiClient.get(url);
    setFetchedOptions(res.data.list[0].details || {});
  };

  return (
    <>
      {isDropdownItem ? (
        <Dropdown.Item
          disabled={isDisabled}
          as="div"
          className={`${isDisabled ? 'disabled-item' : ''}`}
          onClick={handleShow}
        >
          <FontAwesomeIcon width={30} icon={faEdit} />
          {buttonName}
        </Dropdown.Item>
      ) : (
        <div className="custom-button-focus">
          <Button disabled={isDisabled} variant="outline-primary" onClick={handleShow}>
            {buttonName}
          </Button>
        </div>
      )}

      <Modal centered size="lg" show={showModal} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Form className="text-black" noValidate validated={validated} onSubmit={submit}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="fieldSelection">
              <Form.Label>Feld auswählen</Form.Label>
              <Form.Select
                className="text-black bg-grey"
                value={selectedField || ''}
                onChange={handleFieldSelectionChange}
                required
              >
                <option value="">Wählen Sie ein Feld zum Bearbeiten</option>
                {fieldConfigs
                  .filter(config => config.bulkedit)
                  .map(config => (
                    <option key={config.fieldName} value={config.fieldName}>
                      {config.fieldLabel}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            {selectedField && (
              <>
                {fieldConfigs.map(config =>
                  config.resourceName === `${entityType}.${selectedField}` ? (
                    <Form.Group key={config.fieldName} className="mb-3" controlId={config.fieldName}>
                      <Form.Label>{config.fieldLabel}</Form.Label>
                      {config.fieldType === 'select' && config.options ? (
                        <Form.Select
                          className="text-black bg-grey"
                          name={config.fieldName}
                          value={formValues[config.fieldName] || ''}
                          onChange={(e) => handleInputChange(e, setFormValues)}
                          required
                        >
                          <option value="">Wähle eine Option</option>
                          {Object.entries(config.options).map(([value, label]) => (
                            <option key={value} value={value}>
                              {label as string}
                            </option>
                          ))}
                        </Form.Select>
                      ) : config.fieldType === 'textarea' ? (
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="text-black bg-grey"
                          name={config.fieldName}
                          value={formValues[config.fieldName] || ''}
                          onChange={(e) => handleInputChange(e, setFormValues)}
                          required
                        />
                      ) : config.fieldType === 'date' ? (
                        <Form.Control
                          type="date"
                          className="text-black bg-grey"
                          name={config.fieldName}
                          value={formValues[config.fieldName] || ''}
                          onChange={(e) => handleInputChange(e, setFormValues)}
                          required
                        />
                      ) : config.fieldType === 'select' && config.optionsprovider?.includes('search') ? (
                        <SelectWithSearch
                          id={config.fieldName}
                          label=""
                          placeholder="Suche..."
                          apiEndpoint={`${config.optionsprovider.split('?')[0]}`}
                          mapResponseToOptions={mapResponseToOptions}
                          onChange={handleSelectChange}
                          initialValue={null}
                        />
                      ) : config.fieldType === 'select' && config.optionsprovider?.includes('settings') && fetchedOptions ? (
                        <Form.Control
                          as="select"
                          name={config.fieldName}
                          value={formValues[config.fieldName] || ''}
                          onChange={(e) => handleInputChange(e, setFormValues)}
                          className="text-black bg-grey"
                        >
                          <option value="">{`Bitte wählen...`}</option>
                          {Object.entries(fetchedOptions).map(([value, label]) => (
                            <option key={value} value={value}>
                              {label as string}
                            </option>
                          ))}
                        </Form.Control>
                      ) : (
                        <Form.Control
                          type="text"
                          className="text-black bg-grey"
                          name={config.fieldName}
                          value={formValues[config.fieldName] || ''}
                          onChange={(e) => handleInputChange(e, setFormValues)}
                          required
                        />
                      )}
                    </Form.Group>
                  ) : null
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Abbrechen
            </Button>
            <Button disabled={!selectedField || isLoading} type="submit" variant="primary">
              Speichern
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default BulkEditModal;
