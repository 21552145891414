import { faArrowsRotate, faEye, faX } from '@fortawesome/pro-regular-svg-icons';
import { faPaperPlane, faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { ApiClient, FieldConfig, MediaItem, useToast } from 'c1g-ui-library';
import React, { useEffect, useState } from 'react';
import { Button, Col, ListGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { Deal } from '../../interfaces';
import { EmailTemplateType } from '../../utils/enum';
import { getMediaUrl } from '../../utils/media/utils';
import { formatDate } from '../../utils/utils';
import { getWvStatusText } from '../../utils/wvStatus';
import ConfirmationModal from './../ConfirmationModal';
import SendMailModal from './../participants/modal/SendMailModal';
import WvStatusIndicator from './WvStatusIndicator';

interface WvViewerModalProps {
    handleClose: () => void;
    title?: string;
    fieldConfigs?: FieldConfig[];
    dealId: number;
    dealType: 'deals' | 'lastDeal';
}

/**
 * WvViewerModal Component
 * 
 * This component provides a detailed view of a deal's Wiedervorlage (WV) document within a modal. 
 * It allows users to view, manage, and interact with the WV, including the following features:
 * 
 * Props:
 * - handleClose: Function to close the modal.
 * - title: Optional title to display in the modal header.
 * - fieldConfigs: Optional array of field configurations for the status indicator.
 * - dealId: ID of the deal for which the WV data is displayed.
 */
const WvViewerModal: React.FC<WvViewerModalProps> = ({
    handleClose,
    title,
    fieldConfigs,
    dealId,
    dealType,
}) => {
    const [deal, setDeal] = useState<Deal>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [wv, setWv] = useState<MediaItem | null>();
    const [showGenerateNewWvModal, setShowGenerateNewWvModal] = useState<boolean>(false);
    const { showToast } = useToast();

    const [sendMailConfig, setSendMailConfig] = useState<{
        title: string;
        email: string;
        dealId: number;
        type?: number;
    } | null>(null);

    const handleSuccess = (message?: string, isError?: boolean) => {
        if (message) {
            showToast(message, isError);
        }
        fetchDeal()
        setSendMailConfig(null);
    };

    const getSendMailModalConfig = async (receiverType: 'participant' | 'advisor') => {
        if (receiverType === 'participant') {
            setSendMailConfig({
                title: 'WV an Teilnehmer senden',
                email: deal?.participant.email ?? '',
                dealId: dealId,
            });
        } else if (receiverType === 'advisor') {
            setSendMailConfig({
                title: 'WV an AfA Berater senden',
                email: '',
                dealId: dealId,
                type: EmailTemplateType.WV_TO_AFA,
            });
        }
    };

    const sendMailToParticipant = async (emailPayload: { subject: string; body: string, recipientEmail: string }) => {
        setIsLoading(true);
        try {
            await ApiClient.post(`/deals/${dealId}/wv/send`, emailPayload);
            showToast('E-Mail erfolgreich gesendet');
            fetchDeal();
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsLoading(false);
        }
    };

    const generateNewWv = async () => {
        setIsLoading(true);
        try {
            await ApiClient.post(`/deals/${dealId}/wv`, {});
            showToast(`Erstellung des WV's erfolgreich getriggert`);
            fetchDeal();
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDeal = async () => {
        try {
            const res = await ApiClient.get(`/deals/${dealId}`);
            const deal = res.data as Deal
            setDeal(deal);
            setWv(deal.wv);
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDeal();
    }, []);

    return (
        <Modal show onHide={handleClose} keyboard={false} fullscreen>
            {(showGenerateNewWvModal || sendMailConfig) && <div className="custom-backdrop"></div>}
            {isLoading || !deal ? (
                <Modal.Body className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status" />
                </Modal.Body>
            ) : (
                <>
                    <Modal.Header className="d-flex flex-column flex-md-row align-items-start justify-content-lg-between align-items-lg-center">
                        <Row className="w-100">
                            <Col lg={2}>
                                <div className="d-flex align-items-center mb-3 mb-lg-0">
                                    <Button
                                        variant="secondary"
                                        className="btn-soft-primary round-modal-close-button me-2"
                                        onClick={handleClose}
                                    >
                                        <FontAwesomeIcon icon={faX} size="sm" />
                                    </Button>

                                    <div>
                                        <h5 className="mb-0 text-break">{title}</h5>
                                        {deal.wvnumber && <a
                                            href={`https://app.pandadoc.com/a/#/documents/${deal.wvnumber}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ cursor: 'text' }}
                                        >
                                            <p className={`p-0 m-0 text-gray`}>Pandadoc-ID: {deal.wvnumber}</p>
                                        </a>
                                        }
                                    </div>
                                </div>
                            </Col>

                            <Col lg={10}>
                                <div className="d-flex flex-column flex-md-row justify-content-between">
                                    <div className="mb-3 mb-lg-0">
                                        {!wv ? (
                                            <>
                                                <h5 className="mb-0">Noch nicht vorhanden</h5>
                                                <p className="p-0 m-0 text-gray">
                                                    -
                                                </p>
                                            </>
                                        ) : (
                                            <>  
                                                <h5 className="mb-0">{getWvStatusText(deal.wvStatus)}</h5>
                                                <p className="p-0 m-0 text-gray">
                                                    {wv?.created
                                                        ? formatDate(wv.created, 'd.m.Y [um] H:i:s [Uhr]')
                                                        : '-'}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <>
                                            <Button
                                                variant="secondary"
                                                className="btn-soft-primary me-2"
                                                onClick={() => setShowGenerateNewWvModal(true)}
                                                disabled={deal.wvStatus === 1} // WV wird erstellt
                                            >
                                                Neu Generieren <FontAwesomeIcon icon={faArrowsRotate} size="sm" />
                                            </Button>

                                            {wv && (
                                                <>

                                                    <a
                                                        href={wv ? getMediaUrl(wv) : ''}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <Button
                                                            variant="secondary"
                                                            className="btn-soft-primary me-2"
                                                            disabled={!wv}
                                                        >
                                                            Anzeigen <FontAwesomeIcon icon={faEye} size="sm" />
                                                        </Button>
                                                    </a>
                                                </>
                                            )}
                                            {/* Show "An Teilnehmer senden" button only if WV status is "WV unterschriftslos" (wvStatus === 2)*/}
                                            {deal.wvStatus === 2 && <Button
                                                variant="primary"
                                                onClick={() => getSendMailModalConfig('participant')}
                                            >
                                                An Teilnehmer senden <FontAwesomeIcon icon={faPaperPlaneTop} size="sm" />
                                            </Button>}

                                            {/* Show "An AfA Berater senden" button only if WV status is "WV unterschrieben" (wvStatus === 5)*/}
                                            {deal.wvStatus === 5 && <Button
                                                variant="primary"
                                                onClick={() => getSendMailModalConfig('advisor')}
                                            >
                                                An AfA Berater senden <FontAwesomeIcon icon={faPaperPlane} size="sm" />
                                            </Button>}
                                        </>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Header>

                    <Modal.Body className="py-0">
                        <Row className="h-100">
                            <Col lg={2}>
                                <div className='my-4'>
                                    <div className='bg-light-blue p-2 ps-4 rounded'> <WvStatusIndicator fieldConfigs={fieldConfigs} dealType={dealType} wvStatus={deal?.wvStatus ?? 0}></WvStatusIndicator> </div>
                                </div>

                                <div className="horizontal-line"></div>

                                <div className="media-list mt-5" style={{ overflowY: 'auto' }}>
                                    <ListGroup>
                                        <>
                                            {!wv ? <ListGroup.Item
                                                className={`py-3 no-border rounded`}
                                                active
                                                action
                                            >
                                                Noch nicht vorhanden
                                                <p className={`p-0 m-0 text-gray`}>
                                                    -
                                                </p>
                                            </ListGroup.Item>
                                                : <ListGroup.Item
                                                    disabled={!wv?.created}
                                                    className={`py-3 no-border rounded`}
                                                    key={wv?.id}
                                                    active={true}
                                                    action
                                                >
                                                    {getWvStatusText(deal.wvStatus)}
                                                    <p className={`p-0 m-0 text-gray`}>
                                                        {wv?.created
                                                            ? formatDate(wv.created, `d.m.Y [um] H:i:s [Uhr]`)
                                                            : 'Noch nicht vorhanden'}{' '}
                                                    </p>
                                                </ListGroup.Item>}
                                        </>
                                    </ListGroup>
                                </div>
                            </Col>
                            <Col lg={10} className="h-100 bg-grey p-0">
                                {wv ? (
                                    <div className="h-100 overflow-hidden">
                                        {wv.extension === 'pdf' && (
                                            <iframe
                                                src={`${getMediaUrl(wv)}?nocache=${wv.modified}`}
                                                title={wv.title}
                                                width="100%"
                                                height="100%"
                                                style={{ border: 'none' }}
                                            ></iframe>
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center w-100 h-100 text-center"
                                    >
                                        {deal.wvStatus === 1 ? (
                                            <p className="p-3 border">WV wird gerade erstellt...</p>
                                        ) : (
                                            <p className="p-3 border">Kein WV vorhanden, bitte neu generieren!</p>
                                        )}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Modal.Body>
                </>
            )}

            {sendMailConfig && (
                <SendMailModal
                    dealId={sendMailConfig.dealId}
                    email={sendMailConfig.email}
                    title={sendMailConfig.title}
                    type={sendMailConfig.type}
                    onModalClose={() => setSendMailConfig(null)}
                    onSubmitSuccess={handleSuccess}
                    onCustomEmailSend={sendMailToParticipant}
                />
            )}

            <ConfirmationModal
                show={showGenerateNewWvModal}
                handleClose={() => setShowGenerateNewWvModal(false)}
                handleConfirm={async () => {
                    setShowGenerateNewWvModal(false);
                    generateNewWv()
                }}
                title="WV neu generieren?"
                message="Möchten Sie den WV wirklich neu generieren?"
            />
        </Modal>
    );
};

export default WvViewerModal;
