import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner, InputGroup, Form } from 'react-bootstrap';
import { ApiClient } from 'c1g-ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faEye,
    faEyeSlash,
    faCircleCheck,
    faCircleX,
    faClock,
    faSpinner,
} from '@fortawesome/pro-regular-svg-icons';
import { useToast } from 'c1g-ui-library';
import { LwStagingAccessData } from '../../../interfaces';
import { formatDate, isOlderThanMonths } from '../../../utils/utils';
import { AxiosError } from 'axios';
interface ParticipantAccessLernweltProps {
    participantId: number;
    onClose: () => void;
}

/**
 * ParticipantAccessLernweltModal
 *
 * This modal allows administrators to view and manage a participant's access credentials for the staging environment.
 *
 * Props:
 * @param {number} participantId - The unique identifier of the participant. Used to fetch login data and trigger data transfers.
 * @param {() => void} onClose - Callback function triggered when the modal is closed.
 *
 * Example Usage:
 * ```tsx
 * <ParticipantAccessLernweltModal
 *   participantId={123}
 *   onClose={() => setShowModal(false)}
 * />
 * ```
 */

const ParticipantAccessLernweltModal: React.FC<ParticipantAccessLernweltProps> = ({ participantId, onClose }) => {
    const [accessData, setAccessData] = useState<LwStagingAccessData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isTransferring, setIsTransferring] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { showToast } = useToast();

    useEffect(() => {
        fetchAccessData();
    }, []);

    const fetchAccessData = async () => {
        setIsLoading(true);
        try {
            const response = await ApiClient.get(`/participants/${participantId}/lwStagingCheckLogin`);
            setAccessData(response.data);
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTransferData = async () => {
        setIsTransferring(true);
        try {
            const response = await ApiClient.post(`/participants/${participantId}/lwStagingMigrateUser`, {});
            setAccessData(response.data);
            showToast("Daten erfolgreich übertragen!");
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsTransferring(false);
        }
    };

    const copyToClipboard = (text: string, message: string) => {
        navigator.clipboard.writeText(text);
        showToast(message);
    };

    const getDataStatus = () => {
        return (
            <>
                <p className="mb-1">Daten Status</p>

                {isTransferring ? (
                    <div className="d-flex align-items-center text-warning mb-3">
                        <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                        <span>Daten werden übertragen</span>
                    </div>
                ) : !accessData?.lwStagingLoginLastSync ? (
                    <div className="d-flex align-items-center text-danger mb-3">
                        <FontAwesomeIcon icon={faCircleX} className="me-2" />
                        <span>Noch nie übertragen</span>
                    </div>
                ) : (
                    <div className={`d-flex align-items-center mb-3 ${isOlderThanMonths(accessData.lwStagingLoginLastSync) ? 'text-warning' : 'text-success'}`}>
                        <FontAwesomeIcon icon={isOlderThanMonths(accessData.lwStagingLoginLastSync) ? faClock : faCircleCheck} className="me-2" />
                        <span>
                            {isOlderThanMonths(accessData.lwStagingLoginLastSync) ? "Daten älter als 3 Monate" : "Daten wurden übertragen"} – {formatDate(accessData.lwStagingLoginLastSync, "dd.mm.Y [um] H:i:s [Uhr]")}
                        </span>
                    </div>
                )}
            </>
        );
    };

    if (isLoading) {
        return (
            <Modal show onHide={onClose} centered>
                <Modal.Body className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" role="status" />
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal show onHide={onClose} centered>
            <Modal.Header className="d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Lernwelt als Teilnehmer sehen</h4>
                <Button className="btn-soft-primary" onClick={onClose}>
                    Fertig
                </Button>
            </Modal.Header>

            <Modal.Body>
                {/* Step 1 */}
                <div className="d-flex align-items-center mb-3">
                    <div className="step-indicator-access-lernwelt me-3">1.</div>
                    <h5 className='text-light-black mb-0'>Daten von Teilnehmer übertragen</h5>
                </div>
                <p className='text-light-black mb-3'>Übertrage die aktuellen Daten des Teilnehmers, damit du darauf zugreifen kannst.</p>

                {/* Data Status */}
                {getDataStatus()}

                <Button variant="primary w-100" onClick={handleTransferData} disabled={isTransferring}>
                    {isTransferring ? (
                        <>
                            <Spinner animation="border" size="sm" className="me-2" />
                            Übertragung läuft...
                        </>
                    ) : (
                        accessData ? "Daten aktualisieren" : "Aktuelle Daten übertragen"
                    )}
                </Button>

                {/* Hide Step 2 during transfer */}
                {!isTransferring && accessData?.lwStagingLoginEmail && accessData?.lwStagingLoginPassword && (
                    <>
                        <div className="horizontal-line my-4"></div>

                        {/* Step 2 */}
                        <div className="d-flex align-items-center mb-3">
                            <div className="step-indicator-access-lernwelt me-3">2.</div>
                            <h5 className='text-light-black mb-0'>Anmeldedaten kopieren</h5>
                        </div>
                        <p className='text-light-black mb-3'>Rufe die Staging Version der Lernwelt auf und melde dich mit den folgenden Anmeldedaten an:</p>

                        {/* Email Field */}
                        <Form.Group className="mb-3">
                            <Form.Label>E-Mail-Adresse</Form.Label>
                            <InputGroup>
                                <Form.Control className="text-black bg-grey" type="text" value={accessData.lwStagingLoginEmail} disabled />
                                <Button className='border bg-grey' onClick={() => copyToClipboard(accessData.lwStagingLoginEmail, 'E-Mail-Adresse kopiert')}>
                                    <FontAwesomeIcon icon={faCopy} className='text-primary' />
                                </Button>
                            </InputGroup>
                        </Form.Group>

                        {/* Password Field */}
                        <Form.Group className="mb-3">
                            <Form.Label>Passwort</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    className="text-black bg-grey"
                                    type={showPassword ? 'text' : 'password'}
                                    value={accessData.lwStagingLoginPassword}
                                    disabled
                                />
                                <Button
                                    className='border bg-grey'
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    <FontAwesomeIcon className='text-primary' icon={showPassword ? faEyeSlash : faEye} />
                                </Button>
                                <Button className='border bg-grey' onClick={() => copyToClipboard(accessData.lwStagingLoginPassword, 'Passwort kopiert')}>
                                    <FontAwesomeIcon className='text-primary' icon={faCopy} />
                                </Button>
                            </InputGroup>
                        </Form.Group>

                        {/* Open Staging Button */}
                        <Button variant="primary w-100 mt-3" onClick={() => window.open(accessData.lwStagingFrontendUrl, '_blank')}>
                            Lernwelt in neuem Tab öffnen
                        </Button>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ParticipantAccessLernweltModal;
