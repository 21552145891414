import React, { useState } from 'react';
import { Modal, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import FormGroup from '../../form/FormGroup';
import { Activity } from 'c1g-ui-library';
import { AxiosError } from 'axios';
import { ApiClient } from 'c1g-ui-library';

interface AddCommentModalProps {
    activity: Activity;
    onModalClose: () => void;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
}

interface FormValues {
    info: string;
}

/**
 * AddCommentModal
 * 
 * A modal component for adding comments to a specific activity. 
 * It provides a text area for entering a comment, displays a character counter 
 * with color-coded feedback, and limits input to 500 characters.
 * 
 * Props:
 * - `activity`: The `Activity` object to which the comment is being added. 
 *   The modal displays a preview of the activity's existing info, truncated to 500 characters.
 * - `onModalClose`: A callback function that closes the modal.
 * - `onSubmitSuccess`: A callback function that is invoked upon successful submission, 
 *   with parameters for an optional success message and error flag.
 */

const AddCommentModal: React.FC<AddCommentModalProps> = ({ onModalClose, onSubmitSuccess, activity }) => {
    const [formValues, setFormValues] = useState<FormValues>({ info: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleAddComment = async () => {
        setIsLoading(true);

        try {
            await ApiClient.post('/comments', {
                activity_id: activity.id,
                info: formValues.info,
            });
            onSubmitSuccess(`Kommentar erfolgreich hinzugefügt`, false);
        } catch (error: any) {
            console.error('Error during comment submission:', error.message as AxiosError);
        } finally {
            setIsLoading(false);
            onModalClose();
        }
    };

    const handleInfoChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if ('value' in e.target && e.target.value.length <= 500) {
            setFormValues({ ...formValues, info: e.target.value });
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'Enter' && formValues.info.length > 0) {
            e.preventDefault();
            handleAddComment();
        }
    };

    const getCharacterCountColor = () => {
        if (formValues.info.length >= 500) return 'red';
        if (formValues.info.length >= 450) return 'orange';
        return 'green';
    };

    return (
        <Modal show onHide={onModalClose} centered>
            <Modal.Body>
                <h4 className="mb-3">Kommentar hinzufügen</h4>

                <div className="mb-3">
                    <h6 className="text-muted">Für folgende Aktivität:</h6>
                    <div className="p-3 border rounded bg-light">
                        <p className="mb-0 fst-italic">
                            {typeof activity.info === "string" && activity.info.length > 500
                                ? activity.info.slice(0, 500) + "…"
                                : activity.info ||
                                (Array.isArray(activity.details?.tags) && activity.details.tags.length > 0
                                    ? <>
                                        {activity.details?.user_name && <span>{activity.details.user_name} · </span>}
                                        {activity.details.tags.map((tag: string, index: number) => (
                                            <span key={index}>{tag}</span>
                                        ))}
                                    </>
                                    : activity.details?.user_name || "Keine Informationen verfügbar"
                                )
                            }
                        </p>
                    </div>
                </div>

                <Form>
                    <FormGroup<FormValues>
                        id="info"
                        label="Dein Kommentar"
                        type="textarea"
                        value={formValues.info}
                        onChange={handleInfoChange}
                        onKeyDown={handleKeyDown}
                        noResize
                    />
                    <div
                        className="text-end"
                        style={{ color: getCharacterCountColor() }}
                    >
                        {formValues.info.length}/500
                    </div>
                    <Row className="mt-4 justify-content-end">
                        <Col xs="auto">
                            <Button onClick={onModalClose} className="btn btn-soft-primary me-2">
                                Abbrechen
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleAddComment}
                                disabled={isLoading || formValues.info.length === 0}
                            >
                                Hinzufügen
                                {isLoading && (
                                    <Spinner
                                        className="ms-2"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></Spinner>
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddCommentModal;
