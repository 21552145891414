import {
    faCircle,
    faCircleCheck,
    faCircleX,
    faSadCry,
    faQuestionCircle,
    faBriefcase,
    faBan,
    faExclamationTriangle,
    faFile,
    faFileAlt,
    faPaperPlane,
    faFileContract,
    faEnvelopeOpen,
    faFileSignature,
    faPersonBreastfeeding,
    faClock,
    IconDefinition,
    faNote,
    faBook,
    faLightbulb,
    faFaceSmile,
    faCircleExclamation,
    faPhone,
    faGraduationCap,
    faBlockQuestion,
    faCircleXmark,
    faUserShield,
    faCrystalBall,
    faLoader,
    faLock,
    faUnlock,
    faUserGraduate,
    faSun,
    faPersonSign,
    faDatabase,
} from '@fortawesome/pro-regular-svg-icons';
import { faHourglass, faHourglassHalf } from '@fortawesome/pro-duotone-svg-icons';
import { faFrown, faGrin, faMeh, faSadTear, faSmile } from '@fortawesome/pro-solid-svg-icons';
import { faHubspot, faIntercom } from '@fortawesome/free-brands-svg-icons';

export const abortReasonIconMap: { [key: string]: IconDefinition } = {
    workFound: faBriefcase,
    workFoundNotParttime: faBriefcase,
    rival: faSadCry,
    lostValidity: faExclamationTriangle,
    lost: faExclamationTriangle,
    cancellation: faBan,
    maternityLeave: faPersonBreastfeeding,
    terminationbyProvider: faBan,
    notStarted: faQuestionCircle,
    notKnown: faQuestionCircle,
};

export const abortReasonColorMap: { [key: string]: string } = {
    workFound: 'text-gray',
    workFoundNotParttime: 'text-gray',
    rival: 'text-gray',
    lostValidity: 'text-gray',
    lost: 'text-gray',
    maternityLeave: 'text-gray',
    cancellation: 'text-gray',
    terminationbyProvider: 'text-gray',
    notStarted: 'text-gray',
    notKnown: 'text-gray',
};

export const dealStatusIconMap: { [key: number]: IconDefinition } = {
    0: faCircle, // Started bald
    1: faLoader, // Laufend
    2: faCircleCheck, // Abgeschlossen
    3: faCircleX, // Abgebrochen
};

export const dealStatusColorMap: { [key: number]: string } = {
    0: 'text-danger', // Started bald
    1: 'text-pending', // Laufend
    2: 'text-success', // Abgeschlossen
    3: 'text-danger', // Abgebrochen
};

export const timeModelIconMap: { [key: string]: any } = {
    fulltime: faHourglass,
    parttime: faHourglassHalf,
    besidesTheJob: faClock
};

export const timeModelColorMap: { [key: string]: string } = {
    fulltime: 'text-gray',
    parttime: 'text-gray',
    besidesTheJob: 'text-gray'
};

export const bgsStatusIconMap: { [key: number]: IconDefinition } = {
    0: faBan, // BGS nicht vorhanden
    1: faFile, // BGS Blanko
    2: faLoader, // BGS in Arbeit
    3: faFileAlt, // BGS ausgefüllt
    4: faPaperPlane, // BGS an AfA verschickt
};

export const bgsStatusColorMap: { [key: number]: string } = {
    0: 'text-danger', // BGS nicht vorhanden
    1: 'text-warning', // BGS Blanko
    2: 'text-warning', // BGS in Arbeit
    3: 'text-warning', // BGS ausgefüllt
    4: 'text-success', // BGS an AfA verschickt
};
export const wvStatusIconMap: { [key: number]: IconDefinition } = {
    0: faCircleX, // WV nicht erstellt
    1: faLoader, // WV wird erstellt
    2: faFileContract, // WV unterschriftslos
    3: faPaperPlane, // WV an Teilnehmer verschickt
    4: faEnvelopeOpen, // WV vom TN Geöffnet
    5: faFileSignature, // WV unterschrieben
    6: faPaperPlane, // WV an AfA verschickt
    7: faBan, // WV abgebrochen
};

export const wvStatusColorMap: { [key: number]: string } = {
    0: 'text-danger', // WV nicht erstellt
    1: 'text-warning', // WV wird erstellt
    2: 'text-warning', // WV unterschriftslos
    3: 'text-warning', // WV an Teilnehmer verschickt
    4: 'text-warning', // WV vom TN Geöffnet
    5: 'text-warning', // WV unterschrieben
    6: 'text-success', // WV an AfA verschickt
    7: 'text-danger', // WV abgebrochen
};

export const smileyMap: Record<number, IconDefinition> = {
    1: faSadTear,
    2: faFrown,
    3: faMeh,
    4: faSmile,
    5: faGrin,
};

export const smileyColorMap: Record<number, string> = {
    1: '#FF6B6B',
    2: '#FFC107',
    3: '#E6BF26',
    4: '#8BC34A',
    5: '#4CAF50',
};

export const activityTypesIconMap: Record<number, IconDefinition> = {
    0: faNote, // Notiz
    1: faBook, // Klassenbucheintrag
    2: faPhone, // Telefonate
    3: faLightbulb, // Expertengespräch
    4: faFaceSmile, // Unsere Teilnehmerbewertung
    5: faCircleExclamation, // Abmahnung
    6: faFaceSmile, // Feedbacks vom Teilnehmer
    7: faGraduationCap, // Lernfortschritt
    8: faBlockQuestion, // // Wissenstest
    9: faHubspot, // HupSpot
    10: faLock, // Modul gesperrt
    11: faUnlock, // Modul entsperrt
    12: faIntercom, // Intercom
    13: faUserGraduate, // Lernwelt Teilnehmer
    14: faSun, // LW: Erste Interaktion des Tages
    15: faQuestionCircle, // LW: Quiz
    16: faDatabase, // Migration TN-Daten
    17: faPaperPlane, // Offene Anwesenheiten versendet
    18: faPersonSign // Beschwerde
};

export const activityTypesColorMap: Record<number, string> = {
    0: '#232D42',
    1: '#08B1BA',
    2: '#1AA053',
    3: '#B28E00',
    4: '#E6BF26',
    5: '#C03221',
    6: '#E6BF26',
    7: '#232D42',
    8: '#232D42',
    9: '#232D42',
    10: '#d9534f',
    11: '#5cb85c',
    12: '#1F8DED',
    13: '#4A90E2',
    14: '#FFA500',
    15: '#7D3C98',
    16: '#4E5D6C',
    17: '#7A5230',
    18: '#D0705A'
};

export const engagementStatusIconMap: Record<number, IconDefinition> = {
    0: faCircleXmark, // Fehlende Unterschrift, nicht signiert (Bestätigung fehlt)
    1: faCircleCheck, // Alles in Ordnung und signiert (Bestätigt)
    2: faUserShield, // Signiert, aber in Überprüfung (In Prüfung)
    3: faCrystalBall, // Woche in der Zukunft (In der Zukunft)
    4: faLoader, // Aktuelle Woche (Aktuelle Woche)
};

export const engagementStatusTextMap: Record<number, string> = {
    0: "Bestätigung fehlt", // Fehlende Unterschrift, nicht signiert
    1: "Bestätigt", // Alles in Ordnung und signiert
    2: "In Prüfung", // Signiert, aber in Überprüfung
    3: "In der Zukunft", // Woche in der Zukunft
    4: "Aktuelle Woche", // Aktuelle Woche
};

export const engagementStatusColorMap: Record<number, string> = {
    0: "text-danger",
    1: "text-success",
    2: "text-warning",
    3: "text-muted",
    4: "text-info",
};

export const presencesAbsencesStateIconMap: Record<number, IconDefinition> = {
    0: faCircleCheck,
    1: faCircleCheck,
};

export const presencesAbsencesStateColorMap: Record<number, string> = {
    0: "text-warning",
    1: "text-success",
};

export const presencesStatusColorMap: Record<number, string> = {
    0: "text-secondary", // Außerhalb des Vertragszeitraums
    1: "text-danger",    // Abwesend
    2: "text-success",   // Bestätigt
    3: "text-primary",   // Feiertag
    4: "text-warning"    // Noch nicht bestätigt
};