import React, { FormEvent, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
    Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from 'c1g-ui-library';
import { useToast } from 'c1g-ui-library';
import { ModuleReason } from '../../../interfaces';
import Card from '../../bootstrap/card';
import { handleInputChange } from '../../../utils/form/utils';
import FormGroup from '../../form/FormGroup';
import { categories } from '../UpdateModuleReasons';
import { AxiosError } from 'axios';
import { Setting } from 'c1g-ui-library';

type UpdateModuleReasonsModalProps = {
    modalTitle: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    details: Record<string, Record<string, ModuleReason>>;
}

/**
 * UpdateModuleReasonsModal Component
 *
 * This modal allows users to manage reasons for module-related changes.
 * Users can add or remove reasons under the four fixed categories.
 *
 * Props:
 * @param {string} modalTitle - Title of the modal.
 * @param {Setting | undefined} setting - The current setting containing reasons.
 * @param {(settingsId: number) => void} onSubmitSuccess - Callback for successful submission.
 * @param {() => void} onModalClose - Callback for closing the modal.
 *
 * @component
 */
const UpdateModuleReasonsModal: React.FC<UpdateModuleReasonsModalProps> = ({
    modalTitle,
    setting,
    onSubmitSuccess,
    onModalClose,
}) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);

    const [formValues, setFormValues] = useState<FormValues>({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        details: setting?.details ? { ...setting.details } as Record<string, Record<string, ModuleReason>> : {},
    });

    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
        details: formValues.details ?? {},
    });


    const [newEntry, setNewEntry] = useState<{ label: string; text: string; category: string }>({
        label: '',
        text: '',
        category: 'setFullProgress',
    });

    const normalizeKey = (label: string) => label.toLowerCase().replace(/\s+/g, '-');

    const handleAddEntry = () => {
        const categoryKey = newEntry.category;
        const reasonKey = normalizeKey(newEntry.label);

        if (!newEntry.label.trim() || !newEntry.text.trim()) {
            showToast('Label und Text müssen ausgefüllt sein', true);
            return;
        }

        if (formValues.details[categoryKey]?.[reasonKey]) {
            showToast('Label muss eindeutig sein', true);
            return;
        }

        setFormValues((prev) => ({
            ...prev,
            details: {
                ...prev.details,
                [categoryKey]: {
                    ...prev.details[categoryKey],
                    [reasonKey]: {
                        label: newEntry.label,
                        text: newEntry.text,
                    },
                },
            },
        }));


        setNewEntry({ label: '', text: '', category: categoryKey });
    };

    const handleRemoveEntry = (categoryKey: string, reasonKey: string) => {
        const updatedDetails = { ...formValues.details };
        delete updatedDetails[categoryKey][reasonKey];

        setFormValues({ ...formValues, details: updatedDetails });
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!e.currentTarget.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        setIsLoading(true);
        try {
            const response = await ApiClient.put(
                `/settings/${setting?.id}`,
                { details: formValues.details }
            );
            onSubmitSuccess(response.data.id);
            setFormValues(response.data);
            setInitialFormValues(response.data);
            showToast('Erfolgreich gespeichert', false);
        } catch (error: any) {
            console.error(error.message as AxiosError);
        } finally {
            setIsLoading(false);
            onModalClose();
        }
    };

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    return (
        <>
            <Modal centered show onHide={onModalClose} backdrop="static" keyboard={false} fullscreen>
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button p-0"
                            >
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}>
                                    <FontAwesomeIcon width={30} icon={faXmark} />
                                    Schließen
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <Row>
                            {/* General Information */}
                            <Col lg={6}>
                                <Card className="card-block card-stretch card-height">
                                    <Card.Body>
                                        <h5>Allgemein</h5>
                                        <FormGroup<FormValues>
                                            id="title"
                                            label="Titel"
                                            value={formValues.title}
                                            onChange={(e) => handleInputChange(e, setFormValues)}
                                        />
                                        <FormGroup<FormValues>
                                            id="description"
                                            label="Beschreibung"
                                            value={formValues.description}
                                            onChange={(e) => handleInputChange(e, setFormValues)}
                                            type="textarea"
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>

                            {/* Add New Reason */}
                            <Col lg={6}>
                                <Card className="card-block card-stretch card-height">
                                    <Card.Body>
                                        <h5>Neuen Grund hinzufügen</h5>
                                        <FormGroup
                                            id="newEntryCategory"
                                            label="Kategorie"
                                            value={newEntry.category}
                                            as="select"
                                            onChange={(e) => setNewEntry((prev) => ({ ...prev, category: e.target.value }))}
                                        >
                                            {categories.map(({ key, title }) => (
                                                <option key={key} value={key}>{title}</option>
                                            ))}
                                        </FormGroup>
                                        <FormGroup id="newEntryLabel" label="Bezeichnung" value={newEntry.label} onChange={(e) => setNewEntry((prev) => ({ ...prev, label: e.target.value }))} />
                                        <FormGroup id="newEntryText" label="Text" type="textarea" value={newEntry.text} onChange={(e) => setNewEntry((prev) => ({ ...prev, text: e.target.value }))} />
                                        <div className='d-flex justify-content-end'>
                                            <Button variant="secondary" onClick={handleAddEntry}>
                                                <FontAwesomeIcon icon={faPlus} /> Hinzufügen
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        {/* Category Tables */}
                        <Row className="mt-3">
                            {categories.map(({ key, title }) => (
                                <Col lg={6} key={key}>
                                    <Card className="card-block card-stretch card-height">
                                        <Card.Body>
                                            <h5>{title}</h5>
                                            <Table size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Label</th>
                                                        <th>Text</th>
                                                        <th>Aktionen</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.entries(formValues.details[key] ?? {}).map(([reasonKey, reason]) => (
                                                        <tr key={reasonKey}>
                                                            <td>{reason.label}</td>
                                                            <td className='text-wrap'>{reason.text}</td>
                                                            <td className='text-center'>
                                                                <Button variant="danger" onClick={() => handleRemoveEntry(key, reasonKey)}>
                                                                    <FontAwesomeIcon icon={faXmark} />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default UpdateModuleReasonsModal;
