import { useSearchParams } from 'react-router-dom';
/**
 * Custom hook to manage and persist tab state via the `tab` query parameter in the URL.
 *
 * Useful for:
 * - Keeping selected tab in sync with the URL
 * - Supporting deep linking and page refreshes without losing tab context
 *
 * @param defaultTab - Fallback tab if no `tab` query param is present
 * @returns [selectedTab, setTab] - The current tab value and a setter function
 *
 * @example
 * const [selectedTab, setTab] = useTabState('overview');
 *
 * // Update the tab
 * setTab('details');
 *
 * // Resulting URL: ?tab=details
 */
function useTabState(defaultTab) {
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedTab = searchParams.get('tab') || defaultTab;
    const setTab = (tab) => {
        setSearchParams({ tab });
    };
    return [selectedTab, setTab];
}
export { useTabState };
