import React from 'react';
import { Companion } from '../../../interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faEnvelope, faPhone, faMobile, faIdCard } from '@fortawesome/pro-regular-svg-icons';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { getFieldLabel } from '../../../utils/utils';
import { FieldConfig } from 'c1g-ui-library';

interface CompanionDetailsProps {
    isLoading?: boolean;
    companion?: Companion | null;
    fieldConfigs: FieldConfig[];
}

/**
 * CompanionDetails Component
 *
 * This component displays details of a companion, including their title, email, phone number,
 * mobile number, and ID. Each field is represented with an icon and a label, with data 
 * dynamically retrieved from the provided `companion` object and `fieldConfigs`.
 *
 * Props:
 * - `isLoading` (boolean) - Displays loading skeletons when data is being fetched.
 * - `companion` (Companion | null) - The companion object containing contact details.
 * - `fieldConfigs` (FieldConfig[]) - Configurations for field labels.
 *
 * If a field value is not available, it defaults to '-'.
 */
const CompanionDetails: React.FC<CompanionDetailsProps> = ({
    isLoading = false,
    companion,
    fieldConfigs,
}) => {
    const fields = [
        {
            icon: faCircleUser,
            label: getFieldLabel(fieldConfigs, 'contact.title'),
            value: companion?.contact.title,
        },
        {
            icon: faEnvelope,
            label: getFieldLabel(fieldConfigs, 'contact.email'),
            value: companion?.contact.email,
        },
        {
            icon: faPhone,
            label: getFieldLabel(fieldConfigs, 'contact.phoneNumber'),
            value: companion?.contact.phoneNumber,
        },
        {
            icon: faMobile,
            label: getFieldLabel(fieldConfigs, 'contact.mobileNumber'),
            value: companion?.contact.mobileNumber,
        },
        {
            icon: faIdCard,
            label: "Berater-ID",
            value: companion?.id,
        },
    ];

    return (
        <div>
            {fields.map((field, index) => (
                <Row key={index} className="mb-2 align-items-start">
                    <Col xs={5} className="text-wrap">
                        <FontAwesomeIcon
                            width={15}
                            icon={field.icon}
                            className="me-2 text-muted"
                        />
                        {field.label}
                    </Col>
                    <Col xs={7} className="text-wrap text-black">
                        {isLoading ? <Skeleton width={150} /> : field.value || '-'}
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default CompanionDetails;
