import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, Col, Dropdown, ListGroup, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getEnumValue,
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';
import { usePermissions, FieldConfig } from 'c1g-ui-library';
import { useToast } from 'c1g-ui-library';
import { NotificationToast } from 'c1g-ui-library';
import { ApiClient } from 'c1g-ui-library';
import ParticipantDetailsHeader from '../../components/participants/detail/ParticipantDetailsHeader';
import ParticipantDetails from '../../components/participants/detail/ParticipantDetails';
import ComboButtonGroup, { ComboButton } from '../../components/ComboButtonGroup';
import { fetchAndCombineFieldConfigs, formatDealMonthYear, formatEuro, getFieldConfigByResourceName } from '../../utils/utils';
import { faHouse, faRectangleHistory, faFileInvoiceDollar, faCalendar, faFile, faPlayCircle, faNote, faPersonSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dealStatusColorMap, dealStatusIconMap } from '../../components/filter/iconAndColorMappings';
import CurrentDealInfos from '../../components/participants/detail/CurrentDealInfos';
import ActivitiesList from '../../components/participants/detail/ActivitiesList';
import AddActivityModal from '../../components/participants/modal/AddActivityModal';
import ActivityBadgeList, { ActivityCounts } from '../../components/participants/detail/ActivityBadgeList';
import DealInfos from '../../components/deals/detail/DealInfos';
import AddEditDealModal from '../../components/deals/modal/AddEditDealModal';
import { useTabState } from 'c1g-ui-library';
import AgendaCalendar from '../../components/participants/detail/AgendaCalendar';
import DealModules from '../../components/participants/detail/DealModules';
import Engagements from '../../components/participants/detail/Engagements';
import EmbeddedMedia from '../../components/media/EmbeddedMedia';
import FullCalendar from '@fullcalendar/react';
import CalendarToolbarEngagements from '../../components/participants/detail/CalendarToolbarEngagements';
import CalendarToolbarAgenda from '../../components/participants/detail/CalendarToolbarAgenda';
import ParticipantProcesses from '../../components/participants/detail/ParticipantProcesses';
import { useDocumentTitle } from 'c1g-ui-library';
import { formatDealSubtitle } from '../../utils/deals/utils';
import { CollapsibleCard } from 'c1g-ui-library';
import FullHeader from '../../components/ui/header/FullHeader';
import AbsenceReportList from '../../components/participants/detail/AbsenceReportList';
import { Deal, Participant, Permissions } from '../../interfaces';
import { AxiosError } from 'axios';

function ParticipantDetail() {
  const { participantId } = useParams();
  const setDynamicTitle = useDocumentTitle({ appName: 'ClientOffice', moduleTranslations: moduleTranslations });;
  const [participant, setParticipant] = useState<Participant>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useTabState('participant_overview');
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();
  const { show, message, error, hideToast, showToast } = useToast();
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
  const [activityCounts, setActivityCounts] = useState<ActivityCounts[]>([]);
  const [showAddNoteModal, setShowAddNoteModal] = useState<number | null>();
  const [showAddEditDealModal, setShowAddEditDealModal] = useState<Deal | null>();
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedActivityTypeFilter, setSelectedActivityTypeFilter] = useState<number | null>();
  const [viewMode, setViewMode] = useState<'calendar' | 'list'>('list');
  const engagementsCalendarRef = useRef<FullCalendar | null>(null);
  const agendaCalendarRef = useRef<FullCalendar | null>(null);
  const activitiesListRef = useRef<HTMLDivElement>(null);
  const [openDealCards, setOpenDealCards] = useState<Record<string, boolean>>({});
  const dealCardRefs = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'read');

      if (hasPermission) {
        participantId && fetchParticipant(participantId);
        // Fetch only once
        if (!fieldConfigs || fieldConfigs.length === 0) {
          fetchAndCombineFieldConfigs(['participants', 'activities'], setFieldConfigs);
        }

        if (!activityCounts.length) {
          fetchActivitiesCount()
        }
      } else {
        navigate('/errors/error404');
      }
    }
  }, [participantId, permissionsLoaded]);


  const fetchActivitiesCount = async () => {
    try {
      const response = await ApiClient.get(`/activities?elementId=${participantId}&elementClass=${'App\\Models\\ParticipantsModel'}&limit=1`);
      const activityResponse = response.data;
      setActivityCounts(activityResponse.activityCounts)
    } catch (error: any) {
      console.error(error.message as AxiosError);
    }
  };

  const fetchParticipant = async (participantId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/participants/${participantId}`);
      const participantResponse = response.data;
      setParticipant(participantResponse);
      setDynamicTitle(participantResponse.title)
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = (message?: string, isError?: boolean) => {
    if (message) {
      showToast(message, isError);
    }
    participantId && fetchParticipant(participantId);
  };

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleActivitiesUpdate = (message?: string, isError?: boolean) => {
    if (message) {
      showToast(message, isError);
    }
    setRefreshKey((prevKey) => prevKey + 1);
    fetchActivitiesCount()
  };

  const handleBadgeClick = (type: number) => {
    setSelectedActivityTypeFilter(type);
    activitiesListRef.current?.scrollIntoView();
  };

  /**
   * Handles the selection of a deal tab and controls the collapsible card behavior of deal cards.
   * 
   * @param tab - The tab to set.
   * @param deal - The deal to handle.
   * 
   * This function:
   * 1. Sets the selected tab to the deals tab
   * 2. Closes all deal cards except the selected one
   * 3. Toggles the selected card (opens if closed, closes if open)
   * 4. Scrolls to the target card when opened
   */
  const handleSelectedDeal = (tab: string, deal: Deal) => {
    setSelectedTab(tab);
    
    // Create a new object for the state of the open cards
    const newOpenState: Record<string, boolean> = {};
    
    // Set all cards to closed
    participant?.deals.forEach(d => {
      newOpenState[d.id] = false;
    });
    
    // Toggle the state of the selected card
    newOpenState[deal.id] = !openDealCards[deal.id];
    
    // Update the state
    setOpenDealCards(newOpenState);
    
    // Scroll to the target card after a short timeout to ensure the component is updated
    setTimeout(() => {
      dealCardRefs.current[deal.id]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  };

  const comboButtons: ComboButton[] = useMemo(() => {
    const dealsCount = participant?.deals.length;
    return [
      { id: 'participant_overview', label: 'Übersicht', icon: faHouse },
      { id: 'participant_activities', label: 'Aktivitäten', icon: faRectangleHistory },
      { id: 'participant_deals', label: `Deals (${dealsCount})`, icon: faFileInvoiceDollar },
      { id: 'participant_lessons', label: 'Unterricht', icon: faCalendar },
      //{ id: 'participant_satisfactions', label: 'Zufriedenheit', icon: faSmile },
      { id: 'participant_documents', label: 'Unterlagen', icon: faFile },
      { id: 'participant_processes', label: 'Prozesse', icon: faPlayCircle },
      //{ id: 'participant_logins', label: 'Logins', icon: faKey },
    ] as ComboButton[];
  }, [participant]);

  return (
    <>
      <FullHeader>
        {/* Participant Details Header */}
        <ParticipantDetailsHeader
          participant={participant}
          isLoading={isLoading}
          onSubmitSuccess={handleModalSubmit}
          fieldConfigs={fieldConfigs}>
        </ParticipantDetailsHeader>
      </FullHeader>
    <div className='container-fluid p-40'>
      {/* Participant Details Content */}
      <Row>
        {/* Participant Details Content Main Column */}
        <Col lg={9}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedTab}
                    setSelectedCombo={handleSelectedTab}
                    borderRadius="normal"
                  ></ComboButtonGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {selectedTab === 'participant_overview' &&
                <>
                  <CollapsibleCard
                    title="Aktueller Deal"
                    headerRightElement={
                      <Button className="btn-soft-primary" onClick={() => setSelectedTab('participant_deals')}>
                        Alle anzeigen
                      </Button>
                    }
                    initialOpen
                    disabled={!participant?.lastDeal}
                  >
                    <div className="p-3">
                      <CurrentDealInfos
                        deal={participant?.lastDeal}
                        fieldConfigs={fieldConfigs}
                        onSubmitSuccess={handleModalSubmit}
                      />
                    </div>

                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Angepinnte Aktivitäten"
                    initialOpen
                    headerRightElement={
                      <Button onClick={() => setSelectedTab('participant_activities')} className="btn-soft-primary">
                        Alle anzeigen
                      </Button>
                    }
                  >
                    <div className='p-3'>
                      {participant && <ActivitiesList fieldConfigs={fieldConfigs} elementId={participant?.id} elementClass='App\Models\ParticipantsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} isPinnedSection />}
                    </div>
                  </CollapsibleCard>

                  {/* <CollapsibleCard
                    title="Unsere Teilnehmerbewertung"
                    headerRightElement={
                      <Button disabled className="btn-soft-primary">
                        Alle anzeigen
                      </Button>
                    }
                    disabled
                  >
                  </CollapsibleCard> */}

                  {/* <CollapsibleCard
                    title="Feedback vom Teilnehmer"
                    headerRightElement={
                      <Button disabled className="btn-soft-primary">
                        Alle anzeigen
                      </Button>
                    }
                    disabled
                  >
                  </CollapsibleCard> */}
                </>
              }

              {/* Location Activities Content */}
              {selectedTab === 'participant_activities' &&
                <>
                  <CollapsibleCard title="Übersicht" initialOpen>
                    <div className="p-3">
                      {activityCounts.length ? (
                        <ActivityBadgeList fieldConfigs={fieldConfigs} activityCounts={activityCounts} onBadgeClick={handleBadgeClick} />
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center border rounded my-3"
                          style={{ height: '50px' }}
                        >
                          <p className="p-0 m-0">Keine Aktivitätstypen vorhanden</p>
                        </div>

                      )}
                    </div>
                  </CollapsibleCard>


                  <CollapsibleCard
                    title="Angepinnte Aktivitäten"
                    initialOpen
                  >
                    <div className='p-3'>
                      {participant && <ActivitiesList fieldConfigs={fieldConfigs} elementId={participant?.id} elementClass='App\Models\ParticipantsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} isPinnedSection />}
                    </div>
                  </CollapsibleCard>

                  <CollapsibleCard
                    title="Aktivitäten"
                    initialOpen
                    headerRightElement={
                      <>
                        <Dropdown>
                          <Dropdown.Toggle>
                            Hinzufügen
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setShowAddNoteModal(0)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faNote} />Notiz
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowAddNoteModal(18)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faPersonSign} />Beschwerde
                            </Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => setShowAddNoteModal(1)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faBook} />Klassenbucheintrag
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowAddNoteModal(3)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faLightbulb} />Expertengespräch
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowAddNoteModal(4)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faFaceSmile} />Unsere Teilnehmerbewertung
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowAddNoteModal(5)}>
                              <FontAwesomeIcon className="text-primary" width={30} icon={faCircleExclamation} />Abmahnung
                            </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown></>

                    }
                  >
                    <div ref={activitiesListRef} className='p-3'>
                      {participant && <ActivitiesList selectedActivityTypeFilter={selectedActivityTypeFilter} fieldConfigs={fieldConfigs} elementId={participant?.id} elementClass='App\Models\ParticipantsModel' onActivitiesUpdate={handleActivitiesUpdate} refreshKey={refreshKey} />}
                    </div>
                  </CollapsibleCard>
                </>
              }

              {/* Location Deals Content */}
              {selectedTab === 'participant_deals' && (
                <>
                  {participant?.deals.map((deal, index) => (
                    <div ref={el => dealCardRefs.current[deal.id] = el} key={deal.id || index}>
                      <CollapsibleCard
                        title={formatDealMonthYear(deal)}
                        subTitle={formatDealSubtitle(deal, fieldConfigs)}
                        headerRightElement={
                          <>
                            <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write')} variant="primary" onClick={() => setShowAddEditDealModal(deal)}>
                              Bearbeiten
                            </Button>
                          </>
                        }
                        initialOpen={openDealCards[deal.id] || (participant?.deals.length === 1 && index === 0)}
                      >
                        <div className="p-3">
                          <DealInfos
                            dealType="lastDeal"
                            participant={participant}
                            deal={deal}
                            isLoading={isLoading}
                            label=""
                            onSubmitSuccess={handleModalSubmit}
                            fieldConfigs={fieldConfigs}
                          />
                        </div>
                      </CollapsibleCard>
                    </div>
                  ))}
                </>
              )}

              {selectedTab === 'participant_deals' && participant?.deals?.length === 0 && (
                <p>No deals available for this participant.</p>
              )}

              {/* Lessons Content */}
              {selectedTab === 'participant_lessons' && participant?.lastDeal?.id && (
                <>

                {/* Presence-List */}
                <CollapsibleCard
                  title="An-/Abwesenheiten"
                  headerRightElement={
                    <CalendarToolbarEngagements
                      viewMode={viewMode}
                      onViewModeChange={setViewMode}
                      onPrevClick={() => engagementsCalendarRef.current?.getApi().prev()}
                      onNextClick={() => engagementsCalendarRef.current?.getApi().next()}
                      onTodayClick={() => engagementsCalendarRef.current?.getApi().today()}
                      dealId={participant?.lastDeal.id}
                    />
                  }
                  showDivider
                >
                  <div className="p-3">
                    {participant && (
                      <Engagements
                        dealType="participants"
                        dealId={participant?.lastDeal.id}
                        participant={participant}
                        calendarRef={engagementsCalendarRef}
                        viewMode={viewMode}
                      />
                    )}
                  </div>
                </CollapsibleCard>

                {/* Absence-Report */}
                <CollapsibleCard
                  title="Fehlzeitenberichte"
                  showDivider
                >
                  <div className='p-3'>
                    {participant?.lastDeal.id && <AbsenceReportList dealId={participant?.lastDeal.id} />}
                  </div>
                </CollapsibleCard>
                <CollapsibleCard
                  title="Lehrplan"
                  headerRightElement={
                    <CalendarToolbarAgenda
                      onPrevClick={() => agendaCalendarRef.current?.getApi().prev()}
                      onNextClick={() => agendaCalendarRef.current?.getApi().next()}
                      onTodayClick={() => agendaCalendarRef.current?.getApi().today()}
                      deal={participant?.lastDeal}
                    />
                  }
                  showDivider
                >
                  <div className='p-3'>
                    {participant && <AgendaCalendar type="participant" data={participant} calendarRef={agendaCalendarRef} onSubmitSuccess={handleModalSubmit} ></AgendaCalendar>}
                  </div>
                </CollapsibleCard>

                <CollapsibleCard
                  title="Module"
                  showDivider
                >
                  <div className='p-3'>
                    {participant?.lastDeal.id ? <DealModules dealId={participant.lastDeal.id} /> : <p>Kein Deal vorhanden</p>}
                  </div>
                </CollapsibleCard>
              </>
              )}

              {selectedTab === 'participant_documents' && participant?.lastDeal && (<>
                <div className='mb-5 pb-5'><EmbeddedMedia participant={participant} initialFolderId={participant?.lastDeal?.folder_id ?? 0} /></div>
              </>
              )}

              {selectedTab === 'participant_processes' && (
                <>
                  <div>
                    {participant && <ParticipantProcesses participant={participant} onSubmitSuccess={handleModalSubmit}></ParticipantProcesses>}
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Col>

        {/* Participant Details Content Right Column */}
        <Col lg={3}>

          {/* Collapsible Card Participant Details */}
          <CollapsibleCard
            title="Details"
            showDivider
            initialOpen
          >
            <div className='p-3'>
              <ParticipantDetails participant={participant} isLoading={isLoading} fieldConfigs={fieldConfigs}></ParticipantDetails>
            </div>

          </CollapsibleCard>

          {/* Collapsible Card Deals */}
          <CollapsibleCard
            title="Deals"
            titleInfo={`(${participant?.deals.length})`}
            initialOpen
          >
            <ListGroup className="p-3">
              {participant?.deals.map((deal) => (
                <ListGroup.Item
                  key={deal.id}
                  className="d-flex justify-content-between align-items-center mb-3 border rounded cursor-pointer"
                  onClick={() => handleSelectedDeal('participant_deals', deal)}
                >
                  <div className='p-1'>
                    <div className="mb-1">{formatDealMonthYear(deal)} <span className='text-muted'>({deal.bgsnumber})</span></div>
                    <div>
                      <span className={`${dealStatusColorMap[deal.status]}`}>
                        <FontAwesomeIcon icon={dealStatusIconMap[deal.status]} className='me-1' />
                        <span>
                          {getEnumValue(
                            getFieldConfigByResourceName(fieldConfigs, 'lastDeal.status')?.options ?? {},
                            deal.status?.toString()
                          )}
                        </span>
                      </span>
                      <span className='text-muted'>
                        {` · ${formatEuro(deal.priceTotalGross)} · ${deal.ue} UE`}
                      </span>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </CollapsibleCard>

          {/* Collapsible Card Products */}
          {/* <CollapsibleCard
            title="Produkte"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard> */}

          {/* Collapsible Card Locations */}
          {/* <CollapsibleCard
            title="Standorte"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard> */}

          {/* Collapsible Card Geräte */}
          {/* <CollapsibleCard
            title="Geräte"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard> */}

          {/* Collapsible Card Persons */}
          {/* <CollapsibleCard
            title="Personen"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard> */}

          {/* Collapsible Card Employer */}
          {/* <CollapsibleCard
            title="Arbeitgeber"
            headerRightElement={
              <Button disabled variant="link">
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            disabled
          >
          </CollapsibleCard> */}
        </Col>
      </Row >

      {showAddNoteModal?.toString() && participant && <AddActivityModal platform='clientoffice' fieldConfigs={fieldConfigs} type={showAddNoteModal} elementId={participant?.id} elementClass='App\Models\ParticipantsModel' onSubmitSuccess={handleActivitiesUpdate} onModalClose={() => setShowAddNoteModal(null)}></AddActivityModal>
      }

      {showAddEditDealModal && <AddEditDealModal
        dealType="lastDeal"
        modalTitle={`${formatDealMonthYear(showAddEditDealModal)} bearbeiten`}
        deal={showAddEditDealModal}
        onSubmitSuccess={handleModalSubmit}
        onModalClose={() => setShowAddEditDealModal(null)}
        fieldConfigs={fieldConfigs}
        >
      </AddEditDealModal>}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
    </>
  )
}

export default ParticipantDetail;
