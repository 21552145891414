import React, { type PropsWithChildren } from 'react';
import Card from 'react-bootstrap/esm/Card';

interface FullHeaderProps {
  className?: string;
}

/**
 * FullHeader Component
 * 
 * A reusable FullHeader component that wraps content.
 *
 * @param {PropsWithChildren<FullHeaderProps>} props - Component props including children
 * @returns {JSX.Element} Rendered FullHeader component
 */
const FullHeader: React.FC<PropsWithChildren<FullHeaderProps>> = ({ 
  children,
  className = ''
}) => {
  return (
    <header
      className={`full-header sticky-top ${className}`.trim()}
      role="banner"
    >
      <Card className='m-0 rounded-0'>
        <Card.Body className='p-40 py-5'>
          {children}
        </Card.Body>
      </Card>
    </header>
  );
};

export default FullHeader;
