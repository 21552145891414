import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldConfig, MediaItem } from 'c1g-ui-library';
import { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Deal, Participant, ProgressBarValues } from '../../../interfaces';
import { getEnumValue } from '../../../utils/enum';
import { calculateProgressFromStartAndEndDate, formatDateWithoutTime, formatEuro, formatToGermanNumber, getDealExpirationDetails, getFieldConfigByResourceName, getFieldLabel } from '../../../utils/utils';
import MediaViewerModal from '../../MediaViewerModal';
import ProgressBarComponent from '../../ProgressBarComponent';
import StatusIndicator from '../../StatusIndicator';
import BGSViewerModal from '../../deals/BGSViewerModal';
import DealManagement from '../../deals/DealManagement';
import WvViewerModal from '../../deals/WvViewerModal';
import { bgsStatusColorMap, bgsStatusIconMap, wvStatusColorMap, wvStatusIconMap } from '../../filter/iconAndColorMappings';
import UploadMediaModal from '../../media/modal/UploadMediaModal';
import SendPsmCodeModal from '../modal/SendPsmCodeModal';

interface DealInfosProps {
    dealType: 'deals' | 'lastDeal';
    isLoading?: boolean;
    deal: Deal;
    participant?: Participant
    label: string;
    onSubmitSuccess: (message?: string, isError?: boolean) => void;
    fieldConfigs: FieldConfig[];
}

/**
 * DealInfos Component
 * 
 * This component displays detailed information about a deal, including various sections such as general information, documents, timeframes, financial details, and more.
 * 
 * Props:
 * - `isLoading`: Indicates whether the component is in a loading state.
 * - `deal`: The deal object containing deal information.
 * - `participant`: The participant object containing participant information.
 * - `label`: The label for the deal.
 * - `onSubmitSuccess`: The callback function to be called when the form is submitted.
 * - `fieldConfigs`: The field configurations for the deal.
 */

const DealInfos: React.FC<DealInfosProps> = ({ deal, participant, label, isLoading, onSubmitSuccess, fieldConfigs, dealType }) => {
    const [showBgsViewerModal, setShowBgsViewerModal] = useState<boolean>(false);
    const [showWvViewerModal, setShowWvViewerModal] = useState<boolean>(false);
    const [showSendPsmCodeModal, setShowSendPsmCodeModal] = useState<boolean>(false);
    const [showMediaViewer, setShowMediaViewer] = useState<boolean>(false);
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [mediaToPreview, setMediaToPreview] = useState<MediaItem[]>([]);
    const [isUploadOfferModalOpen, setIsUploadOfferModalOpen] = useState(false);

    const handleShowMediaViewer = (media: MediaItem[], index: number = 0) => {
        setMediaToPreview(media);
        setCurrentMediaIndex(index);
        setShowMediaViewer(true);
    };
    const { dayCount, isActive, expirationDate } = getDealExpirationDetails(deal.start);

    const progress = deal?.start && deal?.end && calculateProgressFromStartAndEndDate(formatDateWithoutTime(deal?.start), formatDateWithoutTime(deal.extendedEnd ?? deal.end), formatDateWithoutTime(deal?.abortDate ??
        "0000-00-00 00:00:00"));

    const dealAbortDate = deal?.abortDate ? formatDateWithoutTime(deal?.abortDate) : ''

    const calculateIndividualProgressBarValues = (
        startDate: Date,
        endDate: Date,
        totalStartDate: Date,
        totalEndDate: Date,
        abort: boolean
    ): ProgressBarValues => {
        const totalDuration = totalEndDate.getTime() - totalStartDate.getTime();
        const segmentStartOffset =
            ((startDate.getTime() - totalStartDate.getTime()) / totalDuration) * 100;
        const segmentProgress =
            ((endDate.getTime() - startDate.getTime()) / totalDuration) * 100;

        return {
            progress: segmentProgress,
            startOffset: segmentStartOffset,
            now: progress || 0,
            label: `${formatDateWithoutTime(startDate.toLocaleDateString())} - ${formatDateWithoutTime(endDate.toLocaleDateString())}`,
            abort
        };
    };


    const totalStartDate = new Date(deal.start);
    const totalEndDate = new Date(deal.extendedEnd ?? deal.end);

    // Calculate values for "Zeitraum Vollzeit"
    const vollzeitValues = calculateIndividualProgressBarValues(
        new Date('2023-10-02'),
        new Date('2024-06-13'),
        totalStartDate,
        totalEndDate,
        deal.abort
    );

    // Calculate values for "Zeitraum Berufsbegleitend"
    const berufsbegleitendValues = calculateIndividualProgressBarValues(
        new Date('2024-06-13'),
        new Date('2025-9-23'),
        totalStartDate,
        totalEndDate,
        deal.abort
    );

    // Calculate values for "Zeitraum verlängert"
    const verlaengertValues = deal.extendedEnd ? calculateIndividualProgressBarValues(
        new Date(deal.end),
        new Date(deal.extendedEnd),
        totalStartDate,
        new Date(deal.extendedEnd),
        deal.abort
    ) : null

    const isAbortDateTodayOrInFuture = new Date(deal.abortDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);

    return (
        <>
            <Row>
                <Col md={12}>
                    <div className='d-flex flex-column'>
                        <h4 className='pb-4 mb-0'>Übersicht</h4>
                    </div>
                </Col>
                <Col md={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Allgemein</Card.Title>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, `${dealType}.offerTitle`)}</span></Col>
                                <Col xs={8}><span className="text-black">{deal.offerTitle}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, `${dealType}.degreeDesignation`)}</span></Col>
                                <Col xs={8}><span className="text-black">{deal.degreeDesignation}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, `${dealType}.start`)}</span></Col>
                                <Col xs={8}><span className="text-black">{formatDateWithoutTime(deal.start)}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, `${dealType}.offerNumber`)}</span></Col>
                                <Col xs={8}><span className="text-black">{deal.offerNumber}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, `${dealType}.offerDate`)}</span></Col>
                                <Col xs={8}><span className="text-black">{formatDateWithoutTime(deal.offerDate)}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, `${dealType}.actionNumber`)}</span></Col>
                                <Col xs={8}><span className="text-black">{deal.actionNumber}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, `${dealType}.bgsnumber`)}</span></Col>
                                <Col xs={8}><span className="text-black">{deal.bgsnumber}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, `${dealType}.macbook`)}</span></Col>
                                <Col xs={8}><span className="text-black">{getEnumValue(
                                    getFieldConfigByResourceName(fieldConfigs, `${dealType}.macbook`)?.options ?? {},
                                    deal.macbook?.toString()
                                )}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, `${dealType}.besidesTheJob`)}</span></Col>
                                <Col xs={8}><span className="text-black">{getEnumValue(
                                    getFieldConfigByResourceName(fieldConfigs, `${dealType}.besidesTheJob`)?.options ?? {},
                                    deal.besidesTheJob?.toString()
                                )}</span></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={4}><span>{getFieldLabel(fieldConfigs, `${dealType}.businessSegment`)}</span></Col>
                                <Col xs={8}><span className="text-black">{getEnumValue(
                                    getFieldConfigByResourceName(fieldConfigs, `${dealType}.businessSegment`)?.options ?? {},
                                    deal.businessSegment?.toString()
                                )}</span></Col>
                            </Row>
                            {/* Arbeitgeber Details */}
                            {/* <Row className="mb-3">
                                <Col xs={4}><span>Arbeitgeber</span></Col>
                                <Col xs={8}>
                                    <Row className="cursor-pointer">
                                        <Col xs={10}>
                                            <span className='text-black'>
                                                OneCareer GmbH (TBD)
                                            </span>
                                        </Col>
                                        <Col xs={2} className="d-flex justify-content-end align-items-center" >
                                            <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body className='pe-1'>
                            <Card.Title className="mb-4">Dokumente</Card.Title>
                            <Row className="mb-3 cursor-pointer" onClick={() => setShowBgsViewerModal(true)}>
                                <Col xs={10}>
                                    <span className="text-black">Bildungsgutschein</span> <br />
                                    <span className={`${bgsStatusColorMap[deal.bgsStatus]}`}>
                                        <FontAwesomeIcon icon={bgsStatusIconMap[deal.bgsStatus]} className="me-2" />
                                        {getEnumValue(
                                            getFieldConfigByResourceName(fieldConfigs, `${dealType}.bgsStatus`)?.options ?? {},
                                            deal.bgsStatus?.toString()
                                        )}
                                        {deal.bgsnumber && <span className="text-muted"> · {deal.bgsnumber}</span>}
                                    </span>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                                </Col>
                            </Row>

                            <Row className="mb-3 cursor-pointer" onClick={() => setShowWvViewerModal(true)}>
                                <Col xs={10}>
                                    <span className="text-black">Weiterbildungsvertrag</span> <br />
                                    <span className={`${wvStatusColorMap[deal.wvStatus]}`}>
                                        <FontAwesomeIcon icon={wvStatusIconMap[deal.wvStatus]} className="me-2" />
                                        {getEnumValue(
                                            getFieldConfigByResourceName(fieldConfigs, `${dealType}.wvStatus`)?.options ?? {},
                                            deal.wvStatus?.toString()
                                        )}
                                        {deal.wvnumber && <span className="text-muted"> · {deal.wvnumber}</span>}
                                    </span>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                                </Col>
                            </Row>

                            <Row className="mb-3 cursor-pointer" onClick={() => deal.offer ? handleShowMediaViewer([deal.offer], 0) : setIsUploadOfferModalOpen(true)}>
                                <Col xs={10}>
                                    <span className="text-black">Angebot</span> <br />
                                    <span>
                                        <StatusIndicator
                                            isActive={!!deal.offer}
                                            activeText="Vorhanden"
                                            inactiveText="Nicht vorhanden"
                                        />
                                        <span className="text-muted"> · {deal.offerNumber}</span>
                                    </span>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-center align-items-center" >
                                    <FontAwesomeIcon icon={faAngleRight} className="me-1 text-primary" />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Zeitraum</Card.Title>
                            <Row className="mb-4">
                                <Col xs={4}><span>Gesamtzeitraum</span></Col>
                                <Col xs={8}>
                                    <div className="d-flex flex-column">
                                        <div className='w-100'>
                                            <div>
                                                <ProgressBarComponent abortDate={dealAbortDate} label={`${formatDateWithoutTime(deal.start)}-${formatDateWithoutTime(deal.extendedEnd ?? deal.end)}`} isCompleteTimeframe startOffset={0} progress={progress || 0} now={progress || 0} abort={deal.abort} />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {/* Unterzeiträume */}

                            {/* <Row className="mb-4">
                                <Col xs={4}><span>Zeitraum Vollzeit</span></Col>
                                <Col xs={8}>                  <div className="d-flex flex-column mb-2">
                                    <div className='mb-2 w-100'>
                                        <div>
                                            <ProgressBarComponent
                                                progress={vollzeitValues.progress}
                                                startOffset={vollzeitValues.startOffset}
                                                now={vollzeitValues.now}
                                                label={vollzeitValues.label}
                                                abort={vollzeitValues.abort}
                                                color="black"
                                            />
                                        </div>
                                    </div>
                                </div>
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col xs={4}><span>Zeitraum berufsbegl.</span></Col>
                                <Col xs={8}>                  <div className="d-flex flex-column mb-2">
                                    <div className='mb-2 w-100'>
                                        <div>
                                            <ProgressBarComponent
                                                progress={berufsbegleitendValues.progress}
                                                startOffset={berufsbegleitendValues.startOffset}
                                                now={berufsbegleitendValues.now}
                                                label={berufsbegleitendValues.label}
                                                abort={berufsbegleitendValues.abort}
                                                color="black"
                                            />
                                        </div>
                                    </div>
                                </div>
                                </Col>
                            </Row> */}

                            {verlaengertValues &&
                                <Row className="mb-4">
                                    <Col xs={4}><span>Zeitraum verlängert</span></Col>
                                    <Col xs={8}><div className="d-flex flex-column mb-2">
                                        <div className='mb-2 w-100'>
                                            <div>
                                                <ProgressBarComponent
                                                    progress={verlaengertValues!.progress}
                                                    startOffset={verlaengertValues!.startOffset}
                                                    now={verlaengertValues!.now}
                                                    label={verlaengertValues!.label}
                                                    abort={verlaengertValues!.abort}
                                                    color="black"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Unterrichtseinheiten</Card.Title>

                            <Row className="mb-3">
                                <Col xs={4}><span>{deal.timeModel === 'parttime' ? 'Teilzeit' : 'Vollzeit'}</span></Col>
                                <Col xs={8}>
                                    <span className="text-black">
                                        {deal.ue} Unterrichtseiheiten <br />
                                        {deal.uePerDay} UE pro Tag ({deal.timeModel === 'parttime' ? 'Teilzeit' : 'Vollzeit'}) <br />
                                        {formatToGermanNumber(deal.durationWeeks)} Wochen; {formatToGermanNumber(deal.durationMonths)} Monate
                                    </span>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4}>
                                    <span>Rücktrittsrecht</span>
                                </Col>
                                <Col xs={8}>
                                    {isActive ? (
                                        <span className="text-danger"> <FontAwesomeIcon icon={faExclamationTriangle} className="me-1" /> Noch {dayCount} Tage aktiv (bis {formatDateWithoutTime(expirationDate.toString())})</span>
                                    ) : (
                                        <span className="text-black">
                                            Abgelaufen am {formatDateWithoutTime(expirationDate.toString())}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Finanzen</Card.Title>

                            <Row className="mb-3">
                                <Col xs={4}><span>Preis</span></Col>
                                <Col xs={8}>
                                    <span className="text-black">
                                        {formatEuro(deal.priceTotalGross)} (Brutto) <br /> {formatEuro(deal.priceTotalNet)} (Netto)
                                    </span>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4}><span>Preis, Steuern</span></Col>
                                <Col xs={8}>
                                    <span className="text-black">
                                        {formatEuro(deal.priceTax)}
                                    </span>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4}><span>Weiteres</span></Col>
                                <Col xs={8}>
                                    <span className="text-black">
                                        Selbstzahler: {getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'deals.selfPay')?.options ?? {}, deal?.selfPay)} <br />
                                        PSM wird vom Bildungsträger bezahlt: {getEnumValue(getFieldConfigByResourceName(fieldConfigs, 'deals.psmIncludet')?.options ?? {}, deal?.psmIncludet)}
                                    </span>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="border rounded mb-3 shadow-none card-block card-stretch card-height">
                        <Card.Body>
                            <Card.Title className="mb-4">Prüfungen</Card.Title>
                            {/* <Row className="mb-3 cursor-pointer">
                                <Col xs={10}>
                                    <span className="text-black">PSM I Prüfung</span> <br />
                                    <span>Code: TBD</span>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-end align-items-center" onClick={() => { }}>
                                    <FontAwesomeIcon icon={faCopy} className="me-3 text-primary" onClick={() => handleCopyClick('somecode1')} />
                                    <FontAwesomeIcon icon={faPaperPlaneTop} className="me-1 text-primary" onClick={() => setShowSendPsmCodeModal(true)} />
                                </Col>
                            </Row>

                            <Row className="mb-3 cursor-pointer">
                                <Col xs={10}>
                                    <span className="text-black">PSM II Prüfung</span> <br />
                                    <span>Code: TBD</span>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-end align-items-center">
                                    <FontAwesomeIcon icon={faCopy} className="me-3 text-primary" onClick={() => handleCopyClick('somecode2')} />
                                    <FontAwesomeIcon icon={faPaperPlaneTop} className="me-1 text-primary" onClick={() => setShowSendPsmCodeModal(true)} />
                                </Col>
                            </Row> */}
                            <Row className="mb-3 cursor-pointer">
                                <Col>
                                    <span className="text-black">Externe Prüfung</span> <br />

                                    {deal.externalTest ? (
                                        <>
                                            <span>
                                                <StatusIndicator
                                                    isActive={deal.externalReg}
                                                    activeText="Teilnehmer registriert"
                                                    inactiveText="Teilnehmer nicht registriert"
                                                />
                                            </span>
                                            <br />
                                            {deal.externalWhen && deal.externalWhere && (
                                                <span>{`Am ${formatDateWithoutTime(deal.externalWhen)} in ${deal.externalWhere}`}</span>
                                            )}
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                {
                    !(deal.abort && isAbortDateTodayOrInFuture && deal.status !== 3) &&
                    (deal.status === 0 || deal.status === 1 || deal.status === 3) && (
                        <DealManagement
                            fieldConfigs={fieldConfigs}
                            onSubmitSuccess={onSubmitSuccess}
                            deal={deal}
                        />
                    )
                }
            </Row>

            {showBgsViewerModal && (
                <BGSViewerModal
                    handleClose={() => {
                        setShowBgsViewerModal(false);
                        onSubmitSuccess();
                    }}
                    title="Bildungsgutschein"
                    fieldConfigs={fieldConfigs}
                    dealId={deal.id}
                    dealType={dealType}
                />
            )}

            {
                showWvViewerModal && <WvViewerModal
                    handleClose={() => {
                        setShowWvViewerModal(false);
                        onSubmitSuccess();
                    }}
                    title="Weiterbildungsvertrag"
                    fieldConfigs={fieldConfigs}
                    dealId={deal.id}
                    dealType={dealType}
                />
            }

            {showSendPsmCodeModal && <SendPsmCodeModal onSubmitSuccess={onSubmitSuccess} onModalClose={() => setShowSendPsmCodeModal(false)}></SendPsmCodeModal>}

            {showMediaViewer && (
                <MediaViewerModal
                    show={showMediaViewer}
                    handleClose={() => setShowMediaViewer(false)}
                    media={mediaToPreview}
                    currentMediaIndex={currentMediaIndex}
                    setCurrentMediaIndex={setCurrentMediaIndex}
                    title="Dokument Vorschau"
                />
            )}

            <UploadMediaModal
                modalTitle="Angebot hochladen"
                onSubmitSuccess={onSubmitSuccess}
                onUploadMediaModalChange={setIsUploadOfferModalOpen}
                isModalOpen={isUploadOfferModalOpen}
                customUploadEndpoint={'/deals/uploadOffer'}
                accept={{
                    'application/pdf': ['.pdf'],
                }}
                singleFile
                dealId={deal.id}
            />
        </>);
};

export default DealInfos;