import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  moduleTranslations,
  PermissionsEnum,
} from '../../utils/enum';

import { AxiosError } from 'axios';
import { ApiClient, FieldConfig, NotificationToast, useDocumentTitle, usePermissions, useToast } from 'c1g-ui-library';
import AbsenceDetails from '../../components/absences/detail/AbsencesDetails';
import { Absence, Permissions } from '../../interfaces';
import { fetchAndCombineFieldConfigs } from '../../utils/utils';

function AbsenceDetail() {
  const { absenceId } = useParams();
  const setDynamicTitle = useDocumentTitle({ appName: 'ClientOffice', moduleTranslations: moduleTranslations });;
  const { showToast, show, hideToast, message, error } = useToast();
  const [absence, setAbsence] = useState<Absence | null>();
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions<Permissions>();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewAbsences, 'read');

      if (hasPermission) {
        absenceId && fetchAbsence(absenceId);
        if (!fieldConfigs || fieldConfigs.length === 0) {
          fetchAndCombineFieldConfigs(['absences'], setFieldConfigs);
        }
      } else {
        navigate('/errors/error404');
      }
    }
  }, [absenceId, permissionsLoaded]);

  const fetchAbsence = async (absenceId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/absences/${absenceId}`);
      const settingResponse = response.data;
      setAbsence(settingResponse);
      setDynamicTitle(settingResponse.title)
    } catch (error: any) {
      console.error(error.message as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalSubmit = (message?: string) => {
    absenceId && fetchAbsence(absenceId);
    if (message) {
      showToast(message, false);
    }
  };

  return (
    <div className='container-fluid p-40'>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <AbsenceDetails absence={absence} isLoading={isLoading} onSubmitSuccess={handleModalSubmit} fieldConfigs={fieldConfigs}></AbsenceDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </div>
  );
}

export default AbsenceDetail;
